import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment';
import Fuse from 'fuse.js';

import { withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';

import Box from '@/components/Box/Box';
import VoucherDetailsForm from '@/components/Vouchers/VoucherDetailsForm';
import Button from '@/components/Button/Button';
import SearchInput from '@/components/SearchInput/SearchInput';
import Table from '../components/Table/Table';
import SubHeader from '@/components/SubHeader/SubHeader';

import { jsonToCsv, download } from '@/utils/helpers';

import * as types from '@/state/promotions/types';
import {
    selectVouchersLoading,
    selectVouchersSaveState
} from '@/state/promotions/reducers';
import { getVoucherCodes as getCodes , generatePdfOfCode } from '@/state/promotions/actions';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';
import * as segmentTypes from '@/state/segments/types';
import * as userTypes from '@/state/users/types';

const fuseOpts = {
    shouldSort: false,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['code', 'userName']
};

const styles = () => ({
    spacer: {
        marginTop: 60
    },
    generateCodesContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
});

class VoucherDetails extends Component {
    constructor(props) {
        super();
        const uuid = this.getUuidFromRoute(props);
        const { selectVoucher, getSegments, getUsers, readOnly } = props;

        selectVoucher(uuid, 0, 10);
        // getUsers();
        getSegments();

        this.state = {
            search: '',
            voucher: {},
            currentPage: 0,
            pageSize: 10,
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedVoucher } = this.props;
        const { selectedVoucher: prevSelectedVoucher } = prevProps;
        if (selectedVoucher !== prevSelectedVoucher) {
            this.setState({
                voucher: {
                    ...selectedVoucher
                }
            });
        }
    }

    getHeaderData = () => [
        { key: 'code', label: 'Code' },
        {
            key: 'userDisplay',
            label: 'Assignee',
            compute: data => data,
            renderer: data => {
                if (data.userUuid !== null && data.userUuid !== '') {
                    return data.userName;
                }
                return (
                    <Button
                        label="Assign code"
                        onClick={e => {
                            e.stopPropagation();
                            this.openAssignCode(data);
                        }}
                    />
                );
            }
        },
        { key: 'redemptions.length', label: 'Claims' },
        {
            key: 'expiryDate',
            label: 'EXP. Date',
            compute: rowData => rowData.expiryDate,
            renderer: data => moment(data).format('DD/MM/YYYY')
        },
        {
            key: 'status',
            label: 'Status',
            compute: data => {
                if (data.status === 'live') return 'Live';
                if (data.status === 'redeemed') return 'Redeemed';
                return 'Archived';
            },
            renderer: data => <Chip label={data} />
        }
    ];

    openAssignCode = code => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.PROMOTIONS_ASSIGN_VOUCHER_CODE_MODAL, {
            code
        });
        openModal();
    };

    menuActions = row => {
        const actions = [
            {
                label: 'Edit',
                func: rowData => {
                    const {
                        setModalComponent,
                        openModal,
                        selectedVoucher
                    } = this.props;
                    setModalComponent(
                        modals.PROMOTIONS_EDIT_VOUCHER_CODE_MODAL,
                        {
                            code: rowData,
                            voucherUuid: selectedVoucher.uuid
                        }
                    );
                    openModal();
                }
            },
            { label: 'Assign', func: this.openAssignCode },
            {
                label: 'Download',
                func: rowData => this.onDownloadPrint(rowData)
            }
        ];

        if (row.userUuid !== null && row.userUuid !== '') {
            // row.expiryDate > today show the button, if not hide it (lib moment or js)
            const currentDate = moment().toISOString()
            if (currentDate < row.expiryDate) {
                actions.push({
                    label: 'Notify',
                    func: rowData => {
                        const {
                            setModalComponent,
                            openModal,
                            selectedVoucher
                        } = this.props;
                        setModalComponent(
                            modals.PROMOTIONS_NOTIFY_VOUCHER_CODE_MODAL,
                            {
                                code: rowData,
                                voucher: selectedVoucher,
                                onSubmit: codeUuid => this.onNotifyUser(codeUuid)
                            }
                        );
                        openModal();
                    }
                });
            }
        }

        if (row.redemptions.length === 0) {
            actions.push({
                label: 'Delete',
                func: rowData => {
                    const { setModalComponent, openModal } = this.props;
                    const { code } = rowData;
                    const assigned = rowData.userName.trim().length > 0;
                    const message = assigned
                        ? `Are you sure you want to delete the voucher code assigned to ${rowData.userName}?`
                        : `Are you sure you want to delete the following voucher code: ${rowData.code} ?`;
                    setModalComponent(modals.CONFIRMATION_MODAL, {
                        saveStateSelector: selectVouchersSaveState,
                        message,
                        onConfirm: () => this.onDeleteVoucherCode(code)
                    });
                    openModal();
                }
            });
        }

        return actions;
    };

    onDeleteVoucherCode = codeUuid => {
        const {
            selectedVoucher: { uuid },
            deleteVoucherCode
        } = this.props;
        deleteVoucherCode(uuid, codeUuid);
    };

    onNotifyUser = codeUuid => {
        const { closeModal, selectedVoucher, sendNotification } = this.props;
        const { uuid } = selectedVoucher;
        sendNotification(uuid, codeUuid);
        closeModal();
    };

    getUuidFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { uuid } = params;
        return uuid;
    };

    searchVoucherCodes = codes => {
        const { search } = this.state;
        const fuseInstance = new Fuse(codes, fuseOpts);
        return fuseInstance.search(search);
    };

    // Specifically for search
    onChange = type => event => {
        this.setState({
            [type]: event.target.value
        }, () => {
            this.getVoucherCodes();
        });
    };

    onFormChange = (type, value) => {
        this.setState(prevState => ({
            voucher: {
                ...prevState.voucher,
                [type]: value
            }
        }));
    };

    onAddSku = sku => {
        this.setState(prevState => ({
            voucher: {
                ...prevState.voucher,
                SKU: [...prevState.voucher.SKU, sku]
            }
        }));
    };

    onRemoveSku = sku => {
        this.setState(prevState => ({
            voucher: {
                ...prevState.voucher,
                SKU: prevState.voucher.SKU.filter(item => item !== sku)
            }
        }));
    };

    onGenerateCodes = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.PROMOTIONS_GENERATE_VOUCHER_CODES_MODAL, {});
        openModal();
    };

    onDownloadPrint = async rowData => {
        const { selectedVoucher } = this.props;
        const { uuid: voucherUuid, title } = selectedVoucher;
        const { code } = rowData;
        try {
            await generatePdfOfCode(voucherUuid, code, title);
        } catch (error) {
            throw error;
        }
    };

    onDownloadAllPrint = async () => {
        const { selectedVoucher } = this.props;
        const data = [];
        // fetch the voucher details here

        const voucherCodes = await getCodes({
            voucherUuid: selectedVoucher.uuid,
            page: 0,
            pageSize: 99999
        });

        voucherCodes.data.forEach(voucherCode => {
            data.push({
                code: voucherCode.code,
                numberOfClaims: voucherCode.redemptions.length,
                maxClaims: voucherCode.maximumRedemptions,
                maxClaimsPerUser: voucherCode.maximumRedemptionsPerUser,
                user: voucherCode.userName,
                userEmail: voucherCode.userEmail,
                createdDate: voucherCode.startDate,
                expiryDate: voucherCode.expiryDate,
            })
        });

        const csv = jsonToCsv(data);
        download(csv, `${selectedVoucher.title}.csv`, 'csv');
    };

    onViewDetails = rowData => {
        const { setModalComponent, openModal, selectedVoucher } = this.props;
        setModalComponent(modals.PROMOTIONS_EDIT_VOUCHER_CODE_MODAL, {
            code: rowData,
            voucherUuid: selectedVoucher.uuid
        });
        openModal();
    };

    onChangePage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber
        }, () => {
            this.getVoucherCodes();
        })
    }

    rowsPerPageChanged = (size) => {
        this.setState({
            pageSize: size
        }, () => {
            this.getVoucherCodes();
        })
    }

    getVoucherCodes = () => {
        const { selectVoucher, selectedVoucher } = this.props;
        const { currentPage, search, pageSize } = this.state;

        selectVoucher(selectedVoucher.uuid, currentPage, pageSize, search);
    }

    onSave = () => {
        const { updateVoucher } = this.props;
        const { voucher } = this.state;
        const {
            createdAt,
            updatedAt,
            maxClaims,
            totalClaims,
            voucherCodes,
            meta,
            ...remainingFields
        } = voucher;
        updateVoucher({
            ...remainingFields,
        });
    };

    render() {
        const {
            classes,
            vouchersLoadingState,
            vouchersSaveState,
            selectedVoucher,
            readOnly
        } = this.props;
        const { search, voucher } = this.state;
        const { meta } = voucher;

        // voucherCodes will be undefined until redux gets selected voucher
        const voucherCodes =
            selectedVoucher.voucherCodes === undefined
                ? []
                : selectedVoucher.voucherCodes;
        const filtered =
            search.trim().length > 0
                ? this.searchVoucherCodes(voucherCodes)
                : voucherCodes;

        return (
            <React.Fragment>
                <SubHeader
                    saving={vouchersSaveState}
                    onSave={this.onSave}
                    lastUpdated={selectedVoucher.updatedAt}
                    readOnly={readOnly}
                />
                <div className={classes.spacer} />
                <Box rootClass={classes.root} title="Voucher Details">
                    <VoucherDetailsForm
                        voucher={voucher}
                        loading={vouchersLoadingState}
                        onChange={this.onFormChange}
                        onAddSku={this.onAddSku}
                        onRemoveSku={this.onRemoveSku}
                        readOnly={readOnly}
                    />
                </Box>

                <div className={classes.generateCodesContainer}>
                    { !readOnly &&
                        <Button
                            label="+ Generate codes"
                            style={{
                                padding: '5px 27px'
                            }}
                            onClick={this.onGenerateCodes}
                            disabled={readOnly}
                        />
                    }
                </div>

                <SearchInput
                    value={search}
                    placeholder="Search by Code or Assignee"
                    onChange={this.onChange('search')}
                    searchFilter={search}
                />

                <Table
                    headerData={this.getHeaderData()}
                    tableData={filtered}
                    showHeader
                    menuActions={row => this.menuActions(row)}
                    pagination
                    loading={vouchersLoadingState}
                    onRowClick={this.onViewDetails}
                    serverSidePagination
                    count={meta ? Number(meta.count) : 0}
                    showEmptyRows={false}
                    pageChanged={this.onChangePage}
                    rowsPerPageChanged={this.rowsPerPageChanged}

                />

                <div className={classes.generateCodesContainer}>
                    <Button
                        label="Download all (CSV)"
                        style={{
                            padding: '5px 27px'
                        }}
                        onClick={this.onDownloadAllPrint}
                    />
                </div>
            </React.Fragment>
        );
    }
}

VoucherDetails.propTypes = {
    vouchersLoadingState: PropTypes.bool.isRequired,
    selectedVoucher: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    selectVoucher: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    getSegments: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    vouchersSaveState: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    selectedVoucher: state.promotions.selectedVoucher,
    vouchersLoadingState: selectVouchersLoading(state),
    vouchersSaveState: selectVouchersSaveState(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            selectVoucher: (uuid, page, pageSize, search) => ({ type: types.SELECT_VOUCHER, uuid, page, pageSize, search }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            getSegments: () => ({ type: segmentTypes.GET_SEGMENTS }),
            getUsers: () => ({ type: userTypes.GET_USERS }),
            sendNotification: (voucherUuid, codeUuid) => ({
                type: types.PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION,
                voucherUuid,
                codeUuid
            }),
            updateVoucher: voucher => ({ type: types.UPDATE_VOUCHER, voucher }),
            deleteVoucherCode: (voucherUuid, codeUuid) => ({
                type: types.DELETE_VOUCHER_CODE,
                voucherUuid,
                codeUuid
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(VoucherDetails))
);

export const GET_USERS_LOYALTY = 'loyalty/GET_USERS_LOYALTY';
export const GET_USERS_LOYALTY_SUCCESS = 'loyalty/GET_USERS_LOYALTY_SUCCESS';
export const GET_USERS_LOYALTY_ERROR = 'loyalty/GET_USERS_LOYALTY_ERROR';

export const REFRESH_USERS_LOYALTY = 'loyalty/REFRESH_USERS_LOYALTY';
export const REFRESH_USERS_LOYALTY_SUCCESS = 'loyalty/REFRESH_USERS_LOYALTY_SUCCESS';

export const GET_ORDER_LOYALTY = 'orders/GET_ORDER_LOYALTY';
export const GET_ORDER_LOYALTY_SUCCESS = 'orders/GET_ORDER_LOYALTY_SUCCESS';
export const GET_ORDER_LOYALTY_ERROR = 'orders/GET_ORDER_LOYALTY_ERROR';

export const RESEND_GIFT_CARD = 'loyalty/RESEND_GIFT_CARD';

export const LOYALTY_LOADING = 'loyalty/LOYALTY_LOADING';
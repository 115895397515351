// actions
import axios from 'axios';

import { uploadImageToCloud } from '@/state/images/actions';

/* eslint-disable */
const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};
/* eslint-enable */

export const updateProduct = async (request) => {
    const { uuid, payload } = request;
    try {
        const result = await axios.put(`/v1/products/${uuid}`, {
            ...payload
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const createProduct = async (request) => {
    const { payload } = request;
    try {
        const result = await axios.post('/v1/products', {
            ...payload
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const getProducts = async () => {
    try {
        const result = await axios.get('/v1/products');

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getProduct = async (request) => {
    const { uuid } = request;
    try {
        const result = await axios.get(`/v1/products/${uuid}`);

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const getShopifyCategories = async () => {
    try {
        const result = await axios.get('/v1/shopify/collections');

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const upsertProducts = (products, product) => {
    const _products = [...products];
    const idx = _products.map(p => p.uuid).indexOf(product.uuid);
    if (idx > -1) {
        _products.splice(idx, 1, product);
    } else {
        _products.push(product);
    }

    return _products;
}

export const removeProduct = (products, uuid) => {
    const _products = [...products];
    return _products.filter(p => p.uuid !== uuid);
}

export const mergeParts = (selectedProduct, parts) => {
    const _product = { ...selectedProduct };
    if (!('parts' in _product) || !('productParts' in _product)) {
        _product.parts = [];
        _product.productParts = [];
    }

    try {
        // this sometimes gives a 'can't call push' error which implies it sometimes isn't an array at this point. mainly on new products.
        _product.parts.push(...Object.keys(parts));
        _product.productParts.push(...Object.values(parts));
    }
    catch (error) {
        console.info('TCL: mergeParts error', error)
        throw error;
    }

    return _product;
}

export const removeParts = (selectedProduct, parts) => {
    const _product = { ...selectedProduct };
    _product.parts = _product.parts.filter(p => parts.map(x => x.partCode).indexOf(p) === -1);
    _product.productParts = _product.productParts.filter(p => parts.map(x => x.partCode).indexOf(p.partCode) === -1)
    return _product;
}

export const updateParts = (selectedProduct, parts) => {
    const _product = { ...selectedProduct };
    _product.parts = parts.map(x => x.partCode);
    _product.productParts = parts;
    return _product;
}

export const populateCategoriesForProducts = async (products, shopifyCategories) => {
    try {
        const mappedShopifyCategories = shopifyCategories.reduce((acc, category) => {
            acc.push({
                id: category.id,
                title: category.title
            });
            category.subCollections.forEach(collection => {
                acc.push({
                    id: collection.id,
                    title: collection.title
                });
            })
            return acc;
        }, [])

        const data = products.reduce((acc, product) => {
            const { categories, ...rest } = product;
            if (categories.length > 0) {
                const lookups = [];
                categories.forEach(c => {
                    const cat = mappedShopifyCategories.find(sc => sc.id === c.toString());
                    if (cat) {
                        lookups.push(cat);
                    }
                })
                acc.push({ ...rest, categories: lookups });
            } else {
                acc.push(product);
            }
            return acc;
        }, []);

        return data;
    } catch (error) {
        throw error;
    }
}

export const formatProductPayload = async (productData) => {
    try {
        let payload = {};
        // images 
        if ('images' in productData) {
            // check for new key
            const { images } = productData;
            payload.images = images.map(img => ({ src: img.src, position: img.position }));
            await asyncForEach(images, async (img, i) => {
                if ('new' in img) {
                    // offload to the 
                    const result = await uploadImageToCloud(img.src);
                    const { data } = result;
                    const { publicUrl } = data;
                    payload.images[i].src = publicUrl;
                }
            });
        }

        if ('quizTags' in productData) {
            payload = { ...payload, ...productData.quizTags };
        }

        if ('details' in productData) {
            payload = { ...payload, ...productData.details };
        }

        if ('categories' in productData) {
            payload = { ...payload, categories: productData.categories };
        }

        if ('tastingNotes' in productData) {
            const tastingNotes = Object.keys(productData.tastingNotes).reduce((acc, tn) => {
                if (productData.tastingNotes[tn]) {
                    acc[tn] = productData.tastingNotes[tn].split(',').map(t => t.trim());
                } else {
                    acc[tn] = [];
                }
                return acc;
            }, {});

            payload = { ...payload, tastingNotes };
        }

        if ('grinds' in productData) {
            payload = { ...payload, ...productData.grinds };
        }

        if ('meta' in productData) {

            if ('tags' in productData.meta) {
                const tags = productData.meta.tags.split(',');
                payload = { ...payload, tags };
            }

            if ('searchTerms' in productData.meta) {
                const searchTerms = productData.meta.searchTerms.split(',');
                payload = { ...payload, searchTerms };
            }

            const { searchTerms, tags, ...rest } = productData.meta;
            payload = { ...payload, ...rest };
        }

        if ('parts' in productData) {
            payload = { ...payload, parts: productData.parts };
        }

        return payload;

    } catch (error) {
        console.info('TCL: error', error)
        throw error;
    }
}

export const deleteProduct = async uuid => {
    try {
        const result = await axios.delete(`/v1/products/${uuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }

};

export const updateAllProducts = async () => {
    try {
        const result = await axios.put('/v1/products/update/all');
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getLastRunUpdateAllProducts = async () => {
    try {
        const result = await axios.get('/v1/products/update/all/lastRun');

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getGrinds = async () => {
    try {
        const result = await axios.get('/v1/products/grinds');
        return result.data;
    } catch (error) {
        throw error;
    }
};



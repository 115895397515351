import React from 'react';
import PropTypes from 'prop-types';


import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const ScrollTable = props => {
    const {
        headerData, tableData, height,
    } = props;


    const makeTableRow = (data) => {
        const rowData = tableData[data];
        return (
            <TableRow key={data}>
                {headerData.map((item, i) => {
                    const align = item.align == null ? 'inherit' : item.align;
                    const cellData = item.select == null ? rowData : item.select(rowData);
                    
                    return (
                        <TableCell key={i} align={align}>
                            {item.render == null ? cellData : item.render(cellData)}
                        </TableCell>
                    )
                })
                }
            </TableRow>
        )
    }

    return (
        <div style={{overflow:'auto'}}>
            <Table>
                <colgroup>
                    {headerData.map((item, i) => <col key={i} style={{width:item.width}}/>)}
                </colgroup>
                <TableHead>
                    <TableRow key={-1}>
                        {headerData.map((item, i) => <TableCell key={i}>{item.label}</TableCell>)}
                    </TableRow>
                </TableHead>
            </Table>
            <div style={{overflow:'auto', height}}>
                <Table style={{tableLayout:'fixed'}}>
                    <colgroup>
                        {headerData.map((item, i) => <col key={i} style={{width:item.width}}/>)}
                    </colgroup>
                    <TableBody>
                        {tableData.map((n, i) => makeTableRow(i))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

ScrollTable.propTypes = {
    headerData: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            select: PropTypes.func,
            render: PropTypes.func,
            align: PropTypes.oneOf(['inherit','left','center','right','justify']),
            width: PropTypes.string,
        })
    ).isRequired,
    tableData: PropTypes.array.isRequired,
    height: PropTypes.string.isRequired,
}

export default ScrollTable;
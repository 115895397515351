import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import Fuse from 'fuse.js';

import Button from '../components/Button/Button';
import UserFilter from '../components/Filters/UserFilter';
import Table from '../components/Table/Table';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import * as types from '@/state/users/types';
import { selectUserLoading, selectUsers, selectUsersMeta } from '@/state/users/reducers';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['profile.firstName', 'profile.lastName', 'profile.email', 'profile.phoneNumber', 'profile.mobileNumber']
};

const headerData = [
    { key: 'profile.firstName', label: 'First Name' },
    { key: 'profile.lastName', label: 'Last Name' },
    { key: 'profile.email', label: 'Email' },
    { key: 'profile.mobileNumber', label: 'Phone' },
    {
        key: 'permissions',
        label: 'Permissions',
        compute: (rowData) => {
            const { accessRoles } = rowData;
            return accessRoles[0];
        },
        renderer: (data) => <Chip label={data} />,
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(a) < compute(b) ? 1 : -1
                : compute(a) > compute(b) ? 1 : -1;
        },
        headerStyle: { align: 'right', size: 'small' },
        styles: { align: 'right' }
    },
    {
        key: 'status',
        label: 'Status',
        compute: (rowData) => {
            const { endedDate, emailVerified } = rowData;
            const active = endedDate.trim().length === 0;
            const pending = active && emailVerified.trim().length === 0;
            const archived = endedDate.trim().length > 0;

            const activeState = active && pending ? 'Pending' : 'Active';

            return archived ? 'Archived' : activeState;
        },
        renderer: (data) => <Chip label={data} />,
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(a) < compute(b) ? 1 : -1
                : compute(a) > compute(b) ? 1 : -1;
        },
        headerStyle: { align: 'right', size: 'small' },
        styles: { align: 'right' }
    },
    {
        key: 'createdDate',
        label: 'Created Date',
        compute: (rowData) => {
            const { createdDate } = rowData;
            return moment(new Date(createdDate));
        },
        renderer: (data) => data.format('DD/MM/YYYY'),
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(a).isBefore(compute(b)) ? 1 : -1
                : compute(a).isAfter(compute(b)) ? 1 : -1;
        },
        headerStyle: { align: 'right', size: 'small' },
        styles: { align: 'right' }
    },
]


const permissions = [
    { label: 'Staff', value: 'staff' },
    { label: 'Customer', value: 'customer' }
];

const statuses = [
    { label: 'Active', value: 'active' },
    { label: 'Pending', value: 'pending' },
    { label: 'Archived', value: 'archived' },
];

const styles = (theme) => ({
    addUserContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    chip: {
        margin: theme.spacing(1),
    },
});

class UserList extends Component {
    constructor(props) {
        super(props);

        const { getUsers } = props;
        getUsers(0, 100);
    }

    state = {
        search: '',
        filteredPermissions: [],
        filteredStatuses: [],
        currentPage: 0,
        pageSize: 100,
        sortBy: 'createdDate',
        order: 'desc'
    };

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }


    onChange = type => event => {
        this.setState({
            [type]: event.target.value,
            currentPage: 0,
        }, () => {
            this.getUsersPagination();
        });

    }

    searchUsers = users => {
        // const { search } = this.state;
        // const fuseInstance = new Fuse(users, fuseOpts);
        // return fuseInstance.search(search);
    };

    removeFilter = filter => {
        const { filteredPermissions } = this.state;
        const isPermission = filteredPermissions.some(permission => filter === permission.value);

        if (isPermission) {
            return this.setState(prevState => ({
                filteredPermissions: prevState.filteredPermissions.filter(permission => permission.value !== filter),
                currentPage: 0,
            }), this.getUsersPagination);
        }

        return this.setState(prevState => ({
            filteredStatuses: prevState.filteredStatuses.filter(status => status.value !== filter),
            currentPage: 0,
        }), this.getUsersPagination);
    };

    clearAllFilters = () => {
        this.setState({
            search: '',
            filteredPermissions: [],
            filteredStatuses: [],
            currentPage: 0,
        }, this.getUsersPagination);
    };

    filterUsers = users => {
        const { filteredPermissions, filteredStatuses } = this.state;
        let results = [...users];

        // filter by permissions
        if (filteredPermissions.length > 0) {
            results = users.filter(user =>
                filteredPermissions.some(permission => user.accessRoles.indexOf(permission.value) > -1),
            );
        }

        // filter by status
        if (filteredStatuses.length > 0) {
            results = results.filter(user => {
                return filteredStatuses.some(status => {
                    const pending = user.emailVerified.trim().length === 0;
                    const active = user.endedDate.trim().length === 0;
                    const archived = user.endedDate.trim().length > 0;

                    switch (status.value) {
                        case 'active':
                            return active && !pending;
                        case 'pending':
                            return pending && !archived;
                        case 'archived':
                            return archived && !pending;
                        default:
                            return false;
                    }
                })
            });
        }

        return results;
    };

    onTextInputChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            currentPage: 0,
        });
    };

    onViewDetails = (rowData) => {
        const { history } = this.props;
        const { uuid } = rowData;
        history.push(`/users/${uuid}`);
    }

    onResetPassword = (rowData) => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.RESET_PASSWORD_MODAL, { user: rowData, title: 'Set Password' });
        openModal(true);
    }

    onRowClick = (rowData) => {
        const { history } = this.props;
        const { uuid } = rowData;
        history.push(`/users/${uuid}`);
    }

    onArchiveUser = (rowData) => {
        const { archiveUser } = this.props;
        const { uuid } = rowData;
        archiveUser(uuid);
    }

    onRestoreUser = (rowData) => {
        const { restoreUser } = this.props;
        const { uuid } = rowData;
        restoreUser(uuid);
    }

    menuActions = (row) => {
        const { endedDate } = row;

        const items = [{ label: 'Set Password', func: rowData => this.onResetPassword(rowData) }];

        return items;
    };

    onChangePage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber
        }, () => {
            this.getUsersPagination();
        })
    }

    onChangeRowsPerPage = (pageSize) => {
        this.setState({
            pageSize,
            currentPage: 0
        }, () => {
            this.getUsersPagination();
        })
    }

    getUsersPagination = () => {
        const { getUsers } = this.props;
        const { currentPage, pageSize, filteredPermissions, filteredStatuses, search, sortBy, order } = this.state;
        const permissions = filteredPermissions.map(p => p.value);
        const statuses = filteredStatuses.map(s => s.value);

        getUsers(currentPage, pageSize, search, permissions.join(','), statuses.join(','), sortBy, order);
    }

    onSort = (data) => {
        this.setState({
            sortBy: data.property,
            order: data.newOrder,
            currentPage: 0,
        }, () => {
            this.getUsersPagination();
        })
    }

    render() {
        const { search, filteredPermissions, filteredStatuses, currentPage } = this.state;
        const { classes, users, userLoadingState, readOnly, meta } = this.props;

        // const filteredTableData = this.filterUsers(users);
        const computedTableData = users;
        // console.log(this.props);

        return (
            <React.Fragment>
                <UserFilter
                    onChange={this.onChange}
                    searchFilter={search}
                    permissions={permissions}
                    filteredPermissions={filteredPermissions}
                    statuses={statuses}
                    filteredStatuses={filteredStatuses}
                    numOfResults={Number(meta.count)}
                    removeFilter={this.removeFilter}
                    clearAll={this.clearAllFilters}
                />

                <Table
                    page={currentPage}
                    setPage={this.setPage}
                    headerData={headerData}
                    tableData={computedTableData}
                    showHeader
                    menuActions={this.menuActions}
                    pagination
                    showEmptyRows={false}
                    loading={userLoadingState}
                    onRowClick={this.onRowClick}
                    readOnly={readOnly}
                    styles={{ paddingRight: 0 }}
                    pageChanged={this.onChangePage}
                    count={Number(meta.count)}
                    defaultSortDir='desc'
                    defaultSortKey='createdDate'
                    rowsPerPageChanged={this.onChangeRowsPerPage}
                    defaultRowsPerPage={100}
                    serverSidePagination
                    serverSideSorting
                    onSort={this.onSort}
                />
            </React.Fragment>
        );
    }
}

UserList.propTypes = {
    classes: PropTypes.object.isRequired,

    getUsers: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    archiveUser: PropTypes.func.isRequired,
    restoreUser: PropTypes.func.isRequired,

    users: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    userLoadingState: PropTypes.bool.isRequired,

    readOnly: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getUsers: (page, pageSize, search, permissions, status, sortBy, order) => ({ type: types.GET_USERS, page, pageSize, search, permissions, status, sortBy, order }),
    openModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true }),
    setModalComponent: (component, props) => ({ type: modalTypes.MODAL_SET_COMPONENT, component, props }),
    archiveUser: (uuid) => ({ type: types.ARCHIVE_USER, uuid }),
    restoreUser: (uuid) => ({ type: types.RESTORE_USER, uuid }),
}, dispatch);

const mapStateToProps = state => ({
    users: selectUsers(state),
    userLoadingState: selectUserLoading(state),
    meta: selectUsersMeta(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserList)));

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = makeStyles({
    buttonRoot: {
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 2,
        boxShadow: 'none',
        padding: '9px 11px',
        outline: '0 !important'
    },
    progress: {
        color: '#ffffff',
    }

});


const MerloButton = props => {
    const { 
        onClick, label, style, className, to, history, loading, 
        icon, iconPosition, variant, disabled,
    } = props;
    const classes = styles();

    const clickHandler = (event) => {
        if (!loading) {
            if (to) {
                history.push(to);
            } else if (onClick) {
                onClick(event);
            }
        }
    }

    const buttonActual = (
        <React.Fragment>
            {icon && iconPosition === 'start' && icon}
            {label}
            {icon && iconPosition === 'end' && icon}
        </React.Fragment>
    );

    return (
        <Button
            variant={variant}
            color='primary'
            className={className}
            classes={{
                root: classes.buttonRoot
            }}
            onClick={clickHandler}
            style={style}
            disabled={disabled}
        >
            {
                loading
                    ? <CircularProgress className={classes.progress} size={16} />
                    : buttonActual
            }
        </Button>
    );
};

MerloButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    to: PropTypes.string,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    variant: PropTypes.string,
    icon: PropTypes.element,
    iconPosition: PropTypes.string,
    disabled: PropTypes.bool,
};

MerloButton.defaultProps = {
    onClick: null,
    label: 'Button',
    style: {},
    className: '',
    to: null,
    loading: false,
    variant: 'contained',
    icon: null,
    iconPosition: '',
    disabled: false,
};

export default withRouter(MerloButton);

// Icons
import PeopleIcon from '@material-ui/icons/People';
import CoffeeIcon from '@material-ui/icons/FreeBreakfast';
import LocationIcon from '@material-ui/icons/LocationOn';
import CartIcon from '@material-ui/icons/ShoppingCart';
import GiftIcon from '@material-ui/icons/CardGiftcard';
import MobileIcon from '@material-ui/icons/StayCurrentPortrait';
import TruckIcon from '@material-ui/icons/LocalShipping';
import DoughnutIcon from '@material-ui/icons/DonutSmallOutlined';
import TrendIcon from '@material-ui/icons/TrendingUp';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';

// Containers
import UserList from '@/containers/UserList';
import UserDetails from '@/containers/UserDetails';

import OrderDetails from '@/containers/OrderDetails';

import Products from '@/containers/Products';
import ProductDetails from '@/containers/ProductDetails';
import ProductCreate from '@/containers/ProductCreate';
import PartDetails from '@/containers/PartDetails';
import Locations from '@/containers/LocationsList';
import LocationDetails from '@/containers/LocationDetails';
import LocationAdd from '@/containers/LocationAdd';
import Orders from '@/containers/Orders';
import Promotions from '@/containers/Promotions';
import VoucherDetails from '@/containers/VoucherDetails';
import MobileApp from '@/containers/MobileApp';
import OfferTileDetails from '@/containers/OfferTileDetails';
import OfferTileCreate from '@/containers/OfferTileCreate';
import Shipping from '@/containers/Shipping';
import SegmentList from '@/containers/SegmentList';
import SegmentDetails from '@/containers/SegmentDetails';
import SegmentCreate from '@/containers/SegmentCreate';
import Trends from '@/containers/Trends';
import Help from '@/containers/Help';
import SettingsContainer from '@/containers/SettingsContainer';
import OptionsContainer from '@/containers/OptionsContainer';
import Diagnostics from '@/containers/Diagnostics';

// TYPES
import * as productTypes from '@/state/products/types';
import * as segmentTypes from '@/state/segments/types';




const Routes = (state, userPermissions = null) => {
    const allRoutes = [
        {
            key: 'users',
            path: '/users/:uuid',
            title: 'User Profile',
            icon: PeopleIcon,
            component: UserDetails,
            roles: [],
            permissions: ['user.edit'],
            navMenuInclude: false,
            parent: '/users',
        },
        {
            key: 'users',

            path: '/users',
            title: 'Users',
            icon: PeopleIcon,
            component: UserList,
            roles: [],
            permissions: ['user.edit'],
        },
        {
            key: 'products',

            path: '/products/create',
            title: 'Product Create',
            icon: CoffeeIcon,
            component: ProductCreate,
            roles: [],
            permissions: ['product.edit'],
            navMenuInclude: false,
            parent: '/products',
            dependencies: [
                productTypes.PRODUCTS_LOADING_STATE
            ]
        },
        {
            key: 'products',
            path: '/products/:uuid',
            title: 'Product Details',
            icon: CoffeeIcon,
            component: ProductDetails,
            roles: [],
            permissions: ['product.edit'],
            navMenuInclude: false,
            parent: '/products',
            dependencies: [
                productTypes.PRODUCTS_LOADING_STATE
            ]
        },
        {
            key: 'products',
            path: '/products',
            title: 'Products',
            icon: CoffeeIcon,
            component: Products,
            roles: [],
            permissions: ['product.edit'],
            tabs: [
                { label: 'Products', tabId: 0 },
                { label: 'Parts', tabId: 1 },
            ],
            dependencies: [
                productTypes.PRODUCTS_LOADING_STATE
            ]
        },
        {
            key: 'products',
            path: '/parts/:partcode',
            title: 'Part Details',
            icon: CoffeeIcon,
            component: PartDetails,
            roles: [],
            navMenuInclude: false,
            permissions: ['product.edit'],
            parent: '/products',
            parentTabId: 1
        },
        {
            key: 'locations',
            path: '/locations/add',
            title: 'Add Location',
            icon: LocationIcon,
            component: LocationAdd,
            roles: [],
            permissions: ['location.edit'],
            navMenuInclude: false,
            parent: '/locations'
        },
        {
            key: 'locations',
            path: '/locations/:uuid',
            title: 'Location Details',
            icon: LocationIcon,
            component: LocationDetails,
            roles: [],
            permissions: ['location.edit'],
            navMenuInclude: false,
            parent: '/locations'
        },
        {
            key: 'locations',
            path: '/locations',
            title: 'Locations',
            icon: LocationIcon,
            component: Locations,
            roles: [],
            permissions: ['location.edit'],
        },
        {
            key: 'orders',
            path: '/orders/:id',
            title: 'Order Details',
            component: OrderDetails,
            roles: [],
            permissions: ['order.edit'],
            navMenuInclude: false,
            parent: '/orders'
        },
        {
            key: 'orders',
            path: '/orders',
            title: 'Orders',
            icon: CartIcon,
            component: Orders,
            roles: [],
            permissions: ['order.edit'],
            tabs: [
                { label: 'Epicor', tabId: 0 },
                { label: 'Givex', tabId: 1 },
            ]
        },
        {
            key: 'promotions',
            path: '/promotions/vouchers/:uuid',
            title: 'Voucher Details',
            icon: GiftIcon,
            component: VoucherDetails,
            roles: [],
            permissions: ['promotion.edit'],
            navMenuInclude: false,
            parent: '/promotions',
            parentTabId: 0
        },
        {
            key: 'promotions',
            path: '/promotions',
            title: 'Promotions',
            icon: GiftIcon,
            component: Promotions,
            roles: [],
            permissions: ['promotion.edit'],
            tabs: [
                { label: 'Vouchers', tabId: 0 },
                { label: 'Automations', tabId: 1 },
                { label: 'Redemptions', tabId: 2 },
                { label: 'Shopify', tabId: 3 },
                { label: 'Bean Drive', tabId: 4 }
            ]
        },
        {
            key: 'mobileApp',
            path: '/mobile-app/offer-tiles/add',
            title: 'Create/Edit New Offer Tile',
            icon: MobileIcon,
            component: OfferTileCreate,
            roles: [],
            permissions: ['notification.edit'],
            navMenuInclude: false,
            parent: '/mobile-app',
            parentTabId: 1
        },
        {
            key: 'mobileApp',
            path: '/mobile-app/offer-tiles/:uuid',
            title: 'Create/Edit New Offer Tile',
            icon: MobileIcon,
            component: OfferTileDetails,
            roles: [],
            permissions: ['notification.edit'],
            navMenuInclude: false,
            parent: '/mobile-app',
            parentTabId: 1
        },
        {
            key: 'mobileApp',
            path: '/mobile-app',
            title: 'Mobile App',
            icon: MobileIcon,
            component: MobileApp,
            roles: [],
            permissions: ['notification.edit'],
            tabs: [
                { label: 'Push Notifications', tabId: 0 },
                { label: 'Offer Tiles', tabId: 1 },
            ]
        },
        {
            key: 'shipping',
            path: '/shipping-labels',
            title: 'Shipping Labels',
            icon: TruckIcon,
            component: Shipping,
            roles: [],
            permissions: ['shipping.edit']
        },
        {
            key: 'segments',
            path: '/segments/create',
            title: 'Create a Segment',
            icon: DoughnutIcon,
            roles: [],
            permissions: ['segment.edit'],
            navMenuInclude: false,
            component: SegmentCreate,
            parent: '/segments',
        },
        {
            key: 'segments',
            path: '/segments/:uuid',
            title: 'Edit a Segment',
            icon: DoughnutIcon,
            roles: [],
            permissions: ['segment.edit'],
            navMenuInclude: false,
            component: SegmentDetails,
            parent: '/segments',
        },
        {
            key: 'segments',
            path: '/segments',
            title: 'Segments',
            icon: DoughnutIcon,
            component: SegmentList,
            roles: [],
            permissions: ['segment.edit']
        },
        {
            key: 'trends',
            path: '/trends',
            title: 'Trends',
            icon: TrendIcon,
            component: Trends,
            roles: [],
            permissions: ['trend.edit']
        },
        {
            key: 'help',
            path: '/support',
            title: 'Help & Support',
            icon: HelpIcon,
            component: Help,
            roles: [],
            permissions: []
        },
        {
            key: 'options',
            path: '/options',
            title: 'Options',
            icon: SettingsIcon,
            component: OptionsContainer,
            roles: [],
            permissions: [],
            navMenuInclude: true,
            tabs: [
                { label: 'Whitelist/Blacklist Domains', tabId: 0 },
            ],
        },
        {
            key: 'settings',
            path: '/settings',
            title: 'Settings',
            icon: HelpIcon,
            component: SettingsContainer,
            roles: [],
            permissions: ['order.edit'],
            navMenuInclude: false,
            tabs: [
                { label: 'Integration Settings', tabId: 0 },
                { label: 'Whitelist/Blacklist Domains', tabId: 1 },
                { label: 'Scripts', tabId: 2 },
            ],
        },
        {
            key: 'diagnostics',
            path: '/diagnostics',
            title: 'Diagnostics',
            icon: HelpIcon,
            component: Diagnostics,
            roles: [],
            permissions: ['permission.edit'],
            requiredPermissions: ['permission.edit'],
        }
    ];

    let routes = allRoutes.filter(route => state[route.key]);
    if (userPermissions != null) {
        routes = routes.filter((route)=>{
            if (!('requiredPermissions' in route)) {
                return true;
            }
            return route.requiredPermissions.every(p => userPermissions.includes(p));
        });
    }
    return routes;
};

export default Routes;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { GoogleApiWrapper } from 'google-maps-react';
import Button from '@/components/Button/Button';
import SubHeader from '@/components/SubHeader/SubHeader';
import StatusChip from '@/components/Locations/StatusChip';

import Details from '@/components/Locations/Details';
import ImageUpload from '@/components/Locations/ImageUpload';
import Options from '@/components/Locations/Options';
import MapDetails from '@/components/Locations/MapDetails';

import * as locationTypes from '@/state/locations/types';
import {
    selectLocation,
    selectLocationSaveState
} from '@/state/locations/reducers';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import { validateLocation } from '@/constants/validation';

const styles = theme => ({
    addUserContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    chipsContainer: {
        flex: 1
    },
    chip: {
        margin: theme.spacing(1)
    },
    spacer: {
        marginTop: 60
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px'
    },
    button: {
        fontSize: 14,
        width: 125,
        padding: '5px 0px'
    },
    deleteContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

class LocationDetails extends Component {
    constructor(props) {
        super(props);

        const uuid = this.getUuidFromRoute(props);
        if (uuid) {
            props.getLocation(uuid);
        }
    }

    state = {
        errors: {},
        bodyHtml: '',
        address: {},
        lat: 0,
        lng: 0
    };

    componentDidUpdate(prevProps) {
        const { selectedLocation } = this.props;
        if (selectedLocation !== prevProps.selectedLocation) {
            this.setState({
                address: (selectedLocation.address) ? selectedLocation.address : '',
                lat: (selectedLocation.lat) ? selectedLocation.lat : 0,
                lng: (selectedLocation.lng) ? selectedLocation.lng : 0,
            })
        }
    }

    getUuidFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { uuid } = params;
        return uuid;
    };

    onChange = (target, data) => {
        this.setState({ [target]: data });
    };

    onSave = () => {
        const {
            facebook,
            twitter,
            instagram,
            errors,
            address,
            ...rest
        } = this.state;
        const { selectedLocation, updateLocation } = this.props;
        const { createdAt, updatedAt, ...location } = selectedLocation;

        // Check if values exist in state before evaluating
        const { title, lat, lng } = this.state;
        const titleValidation =
            title !== undefined ? title : selectedLocation.title;
        const latValidation = lat !== undefined ? lat : selectedLocation.lat;
        const lngValidation = lng !== undefined ? lng : selectedLocation.lng;
        const {
            address1,
            address2,
            suburb,
            city,
            postcode,
            state,
            country
        } = address;

        const addressFormatted = `${address1} ${address2} ${suburb} ${city} ${postcode} ${state} ${country}`

        const newErrors = validateLocation(
            titleValidation,
            latValidation,
            lngValidation,
            address
        );

        if (newErrors !== undefined) {
            this.setState({
                errors: newErrors
            });
        } else {
            // Clear errors
            this.setState({
                errors: {}
            });

            const payload = {
                ...location,
                ...rest,
                address,
                addressFormatted,
                links: {
                    facebook: facebook || selectedLocation.links.facebook,
                    twitter: twitter || selectedLocation.links.twitter,
                    instagram: instagram || selectedLocation.links.instagram,
                }
            };

            updateLocation(payload);
        }
    };

    onAddressChange = (target, data) => {
        this.setState(({ address }) => {
            return {
                address: {
                    ...address,
                    [target]: data
                }
            }
        });
    }

    updateLatLng = () => {
        console.log('YO!');
        const { google } = this.props;
        const geocoder = new google.maps.Geocoder();
        const { address } = this.state;
        const {
            address1,
            address2,
            suburb,
            city,
            postcode,
            state,
            country
        } = address;

        const addressFormatted = `${address1} ${address2} ${suburb} ${city} ${postcode} ${state} ${country}`

        geocoder.geocode({ 'address' : addressFormatted }, ( results, status ) => {
            if( status === google.maps.GeocoderStatus.OK ) {
                const {location} = results[0].geometry;
                console.log('LOG ~ file: LocationDetails.js ~ line 190 ~ LocationDetails ~ geocoder.geocode ~ location', location)
                this.setState({
                    lat: location.lat(),
                    lng: location.lng(),
                });
            } else {
                console.log( `Geocode was not successful for the following reason: ${status} ${results}` );
            }
        });
    }

    onDeleteAddress = () => {
        // Modal things
        const { setModalComponent, selectedLocation, openModal } = this.props;
        setModalComponent(modals.DELETE_LOCATION_MODAL, {
            message: `Are you sure you want to delete ${selectedLocation.title}?`,
            confirmMessage: 'Delete',
            onConfirm: this.onConfirmDelete
        });
        openModal();
    };

    onConfirmDelete = () => {
        const { deleteLocation } = this.props;
        const uuid = this.getUuidFromRoute(this.props);
        deleteLocation(uuid);
    };

    onBodyHtmlChange = e => {
        this.setState({
            bodyHtml: e
        });
    };

    render() {
        const { bodyHtml, errors, lat, lng } = this.state;
        const {
            classes,
            selectedLocation,
            google,
            locationSaveState,
            readOnly
        } = this.props;

        return (
            <React.Fragment>
                <SubHeader saving={locationSaveState} onSave={this.onSave} readOnly={readOnly} lastUpdated={selectedLocation.updatedAt} />

                <div className={classes.actions}>
                    <div className={classes.chipsContainer}>
                        <StatusChip selectedLocation={selectedLocation} />
                    </div>
                </div>
                <div className={classes.deleteContainer}>
                    { !readOnly && 
                        <Button
                            label="Delete location"
                            onClick={this.onDeleteAddress}
                            disabled={readOnly}
                        />
                    }
                </div>
                <ImageUpload
                    location={selectedLocation}
                    onChange={this.onChange}
                    readOnly={readOnly}
                />
                <Details
                    location={selectedLocation}
                    onChange={this.onChange}
                    onAddressChange={this.onAddressChange}
                    onBodyHtmlChange={this.onBodyHtmlChange}
                    bodyHtml={bodyHtml}
                    google={google}
                    errors={errors}
                    readOnly={readOnly}
                    updateLatLng={this.updateLatLng}
                />
                <Options location={selectedLocation}  onChange={this.onChange} readOnly={readOnly} />
                <MapDetails
                    google={google}
                    location={selectedLocation}
                    onChange={this.onChange}
                    errors={errors}
                    lat={lat}
                    lng={lng}
                />
            </React.Fragment>
        );
    }
}

LocationDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object.isRequired,
    getLocation: PropTypes.func.isRequired,
    google: PropTypes.object.isRequired,
    updateLocation: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    locationSaveState: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getLocation: locationUuid => ({
                type: locationTypes.GET_LOCATION,
                locationUuid
            }),
            updateLocation: locationData => ({
                type: locationTypes.UPDATE_LOCATION,
                locationData
            }),
            deleteLocation: locationUuid => ({
                type: locationTypes.DELETE_LOCATION,
                locationUuid
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

const mapStateToProps = state => ({
    selectedLocation: selectLocation(state),
    locationSaveState: selectLocationSaveState(state),
    apiKey:
        process.env.NODE_ENV === 'production'
            ? window.__env__.GOOGLE_MAPS_API_KEY
            : process.env.REACT_APP_GOOGLE_MAPS_API_KEY
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            GoogleApiWrapper(props => {
                return { apiKey: props.apiKey };
            })(LocationDetails)
        )
    )
);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Fuse from 'fuse.js';

import Typography from '@material-ui/core/Typography';
// import SearchInput from '@/components/SearchInput/SearchInput';
import Table from '@/components/Table/Table';

import { selectSegmentsLoading, selectSegmentUsers, selectSegmentCount } from '@/state/segments/reducers';

const fuseOpts = {
    shouldSort: false,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: [
        'profile.firstName',
        'profile.lastName',
        'profile.email',
        'profile.mobileNumber'
    ]
};

const headerData = [
    { key: 'profile.firstName', label: 'First Name' },
    { key: 'profile.lastName', label: 'Last Name' },
    { key: 'profile.email', label: 'Email' },
    {
        key: 'profile.lastLogin',
        label: 'Last login date',
        compute: (rowData) => rowData.profile.lastLogin.trim().length > 0 ? rowData.profile.lastLogin : null,
        renderer: (data) => data && moment(data).format('DD/MM/YY HH:MM:ss a')
    }
];

const styles = makeStyles(theme => ({
    segmentResults: {
        marginBottom: theme.spacing(2)
    },
    segmentSubHeader: {
        fontSize: 12
    }
}));

const SegmentResults = props => {
    const { history } = props;

    const classes = styles();
    const [search, setSearch] = React.useState('');

    const segmentUsers = useSelector(state => selectSegmentUsers(state));
    const segmentLoadingState = useSelector(state => selectSegmentsLoading(state));
    const segmentCount = useSelector(state => selectSegmentCount(state));

    const searchUsers = users => {
        const fuseInstance = new Fuse(users, fuseOpts);
        return fuseInstance.search(search);
    };

    const onRowClick = rowData => {
        const { uuid } = rowData;
        history.push(`/users/${uuid}`);
    };

    const onChange = event => {
        setSearch(event.target.value);
    };

    const computedTableData = search.trim().length > 0 ? searchUsers(segmentUsers) : segmentUsers;

    return (
        <React.Fragment>
            <Typography variant="h6" className={classes.segmentResults}>
                {`Segment results (${segmentCount})`}
            </Typography>
            <Typography className={classes.segmentSubHeader} >
                This is a sample of up to 20 results from the processed segment
            </Typography>
            {
                // <SearchInput
                //     value={search}
                //     onChange={onChange}
                //     placeholder="Search by email address, phone number, or last name"
                // />
            }
            <Table
                showHeader
                headerData={headerData}
                tableData={computedTableData}
                loading={segmentLoadingState}
                onRowClick={onRowClick}
                pagination
            />
        </React.Fragment>
    );
};

SegmentResults.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(SegmentResults);
import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircleRounded';
import ArrowBack from '@material-ui/icons/ArrowBack';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { selectTabIndex } from '@/state/tabs/reducers';
import * as tabTypes from '@/state/tabs/types';

import * as authTypes from '@/state/auth/types';

import PopUpMenu from '@/components/PopupMenu/PopupMenu';
import Routes from '@/constants/routes';

import { selectUserUuid } from '@/state/auth/reducers';
import { selectFeatureState } from '@/state/featureFlag/reducers';

const styles = makeStyles({
    appBar: {
        marginLeft: 200,
        height: 150,
        width: 'calc(100% - 200px)',
        boxShadow: 'none !important',
        display: 'flex',
        justifyContent: 'center'
    },
    heading: {
        flex: 1,
        fontSize: 28,
        fontWeight: 300
    },
    backTo: {
        position: 'absolute',
        bottom: 0,
        padding: '10px 25px',
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#77bae5',
        display: 'flex',
        alignItems: 'center'
    },
    backArrow: {
        marginRight: 10
    },
    tabsRoot: {
        position: 'absolute',
        top: 118,
        minHeight: 32,
        height: 32,
    },
    tabRoot: {
        padding: 0,
        height: 32,
        minHeight: 32,
        minWidth: 150,
        margin: '0px 5px 0px 0px'
    },
    tabWrapper: {
        fontSize: 12,
        textTransform: 'capitalize',
        margin: '0px 5px 0px 5px'
    },
    tabIndicator: {
        backgroundColor: '#ffffff'
    }
});


const Header = props => {
    const classes = styles()
    const { match, history } = props;
    let routeTitle = '';
    let parentRouteTitle = '';
    let isChild = false;
    let isTabbed = false;
    let routeTabs = [];
    let backRoute = '/';

    const selectedTab = useSelector(state => selectTabIndex(state));
    const selectedUserUuid = useSelector(state => selectUserUuid(state));
    const features = useSelector(state => selectFeatureState(state));
    const routeList = Routes(features);

    const dispatch = useDispatch();

    const pathResult = routeList.find(route => route.path === match.path);

    if ('parent' in pathResult) {
        isChild = true;
        const parentRoute = routeList.find(route => route.path === pathResult.parent);
        parentRouteTitle = parentRoute.title;
        backRoute = { pathname: parentRoute.path };
        if ('parentTabId' in pathResult) {
            const parentTab = parentRoute.tabs.find(t => t.tabId === pathResult.parentTabId);
            parentRouteTitle = parentTab.label;
            backRoute.state = pathResult.parentTabId;
        }
    } else if ('tabs' in pathResult) {
        const { tabs } = pathResult;
        isTabbed = true;
        routeTabs = tabs;
    }

    if (pathResult !== undefined) {
        routeTitle = pathResult.title;
    }

    const onLogoutPressed = () => {
        dispatch({ type: authTypes.AUTH_LOGOUT });
    }

    const onAccountPressed = () => {
        history.push(`/users/${selectedUserUuid}`)
    }

    const menuActions = [
        { label: 'Account', func: () => { onAccountPressed() } },
        { label: 'Log out', func: () => { onLogoutPressed() } }
    ]

    const handleChange = (event, value) => {
        dispatch({ type: tabTypes.TABS_SET_TAB_INDEX, tabId: value });
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Typography className={classes.heading} noWrap>
                    {routeTitle}
                </Typography>
                <PopUpMenu
                    menuActions={menuActions}
                    btnIcon={<AccountCircle />}
                    btnStyle={{ color: '#fff' }}
                />
            </Toolbar>
            {
                isChild &&
                <Link
                    to={backRoute}
                >
                    <div className={classes.backTo}>
                        <ArrowBack className={classes.backArrow} />
                        Back to {parentRouteTitle}
                    </div>
                </Link>
            }
            {
                isTabbed &&
                <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabIndicator }} value={selectedTab} onChange={handleChange} >
                    {
                        routeTabs.map(tab => {
                            return <Tab classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} key={tab.tabId} label={tab.label} value={tab.tabId} />
                        })
                    }
                </Tabs>
            }
        </AppBar>
    );
};

Header.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(Header);

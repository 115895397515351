import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import * as snackTypes from '../snack/types';

function* getSegments() {
    try {
        yield put({ type: types.SEGMENT_LOADING_STATE, state: true });
        const response = yield call(actions.getSegments);
        const { segments, meta } = response;
        yield put({ type: types.GET_SEGMENTS_SUCCESS, segments, meta });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_SEGMENTS_ERROR, error });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    }
}

function* getSegmentUsers(segmentUuid) {
    try {
        yield put({ type: types.SEGMENT_LOADING_STATE, state: true });
        const response = yield call(actions.getSegmentUsers, segmentUuid);
        const { result: users, count } = response;
        yield put({ type: types.GET_SEGMENT_USERS_SUCCESS, users, count });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_SEGMENT_USERS_ERROR, error });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    }
}

function* sendPushNotification(action) {
    try {
        const { segmentUuid, notification } = action;
        yield call(actions.sendPushNotification, segmentUuid, notification);
    } catch (error) {
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
        yield put({ type: types.SEND_PUSH_NOTIFICATION_ERROR, error });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* getPushNotificationHistory() {
    try {
        yield put({ type: types.SEGMENT_HISTORY_LOADING_STATE, state: true });
        const response = yield call(actions.segmentPushNotificationHistory);
        const { data: history } = response;
        yield put({
            type: types.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_SUCCESS,
            history
        });
        yield put({ type: types.SEGMENT_HISTORY_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.SEGMENT_HISTORY_LOADING_STATE, state: true });
        yield put({ type: types.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_ERROR, error });
    }
}

function* getSegmentDetails(request) {
    try {
        const { segmentUuid } = request;
        yield put({ type: types.SEGMENT_LOADING_STATE, state: true });

        const response = yield call(actions.getSegmentDetails, { uuid: segmentUuid });
        yield put({ type: types.GET_SEGMENT_DETAILS_SUCCESS, segment: response });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_SEGMENT_DETAILS_ERROR, error });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    }
}

function* getSegmentFields(request) {
    try {
        // const { } = request;
        yield put({ type: types.SEGMENT_LOADING_STATE, state: true });
        const response = yield call(actions.getSegmentFields);
        const { fields } = response;
        yield put({ type: types.GET_SEGMENT_FIELDS_SUCCESS, fields });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_SEGMENTS_ERROR, error });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    }
}

function* createSegment(request) {
    try {
        yield put({ type: types.SEGMENT_SAVING_STATE, state: true });
        yield call(actions.createSegment, request);

    } catch (error) {
        yield put({ type: types.CREATE_SEGMENT_ERROR, error });
        yield put({ type: types.SEGMENT_SAVING_STATE, state: false });
    }
}

function* updateSegment(request) {
    try {
        yield put({ type: types.SEGMENT_SAVING_STATE, state: true });
        yield call(actions.updateSegment, request);

    } catch (error) {
        yield put({ type: types.UPDATE_SEGMENT_ERROR, error });
        yield put({ type: types.SEGMENT_LOADING_STATE, state: false });
    }
}

function* deleteSegment(request) {
    try {
        yield put({ type: types.SEGMENT_SAVING_STATE, state: true });
        yield call(actions.deleteSegment, request);
    } catch (error) {
        yield put({ type: types.DELETE_SEGMENT_ERROR, error });
    }
}

export default [
    takeLatest(types.GET_SEGMENTS, getSegments),
    takeLatest(types.GET_SEGMENT_USERS, getSegmentUsers),
    takeLatest(types.SEND_PUSH_NOTIFICATION, sendPushNotification),
    takeLatest(types.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY, getPushNotificationHistory),
    takeLatest(types.GET_SEGMENT_DETAILS, getSegmentDetails),
    takeLatest(types.GET_SEGMENT_FIELDS, getSegmentFields),
    takeLatest(types.CREATE_SEGMENT, createSegment),
    takeLatest(types.UPDATE_SEGMENT, updateSegment),
    takeLatest(types.DELETE_SEGMENT, deleteSegment),
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as orderTypes from '@/state/orders/types';
import * as loyaltyTypes from '@/state/loyalty/types';

import {
    selectOrder,
    selectOrderEpicorData,
    selectOrdersLoading
} from '@/state/orders/reducers';
import { selectOrderLoyalty } from '@/state/loyalty/reducers';

import OrderDetailsBox from '@/components/Orders/OrderDetails';
import ItemsOrdered from '@/components/Orders/ItemsOrdered';
import ShippingDetails from '@/components/Orders/ShippingDetails';
import BillingDetails from '@/components/Orders/BillingDetails';
import EpicorDetails from '@/components/Orders/EpicorDetails';
import LoyaltyDetails from '@/components/Orders/LoyaltyDetails';
import CustomerDetails from '@/components/Orders/CustomerDetails';
import SubHeader from '@/components/SubHeader/SubHeader';
import OrderShipping from '@/components/OrderShipping/OrderShipping';

class OrderDetails extends Component {
    constructor(props) {
        super();
        const id = this.getIdFromRoute(props);
        props.getOrder(id);
        props.getOrderLoyalty(id);
        props.getOrderEpicorData(id);
        this.state = {
            saveDisabled: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getIdFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { id } = params;
        return id;
    };

    onViewUser = uuid => {
        const { history } = this.props;
        history.push(`/users/${uuid}`);
    };

    onSave = () => {
        // pass the data to the saga for parsing and uploading
        const uuid = this.getIdFromRoute(this.props);
        const { updateOrder } = this.props;
        const { saveDisabled, ...rest } = this.state;
        updateOrder(rest, uuid);
    };

    onChange = data => {
        this.setState({
            ...data,
            saveDisabled: false
        });
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { loadingState, readOnly, orderLoyalty } = this.props;
        const { saveDisabled } = this.state;

        // console.log('SCUP :', nextProps.loadingState !== loadingState || (saveDisabled && !nextState.saveDisabled));
        return (
            nextProps.loadingState !== loadingState ||
            (saveDisabled && !nextState.saveDisabled) ||
            nextProps.readOnly !== readOnly ||
            nextProps.orderLoyalty !== orderLoyalty
        );
    };

    render() {
        const {
            selectedOrder,
            orderLoyalty,
            orderEpicorData,
            readOnly
        } = this.props;

        const {
            source,
            createdAt,
            updatedAt,
            customer,
            lineItems,
            shipping,
            totals,
            discountCodes,
            name,
            orderNote,
            noteAttributes,
            subscription,
            subscriptionCount,
            loadingState,
            orderId
        } = selectedOrder;

        const {
            fomPoints,
            fomCreditSpent,
            processed_at: processedAt,
            manuallyProcessed,
            meta,
            fomCurrency
        } = orderLoyalty;

        const { transactionId, request, response, skus } = meta || {};

        const { shippingAddress, fom, billingAddress } = customer || {};

        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSave}
                    // saving={saving}
                    lastUpdated={updatedAt}
                    readOnly={readOnly}
                    disabled={loadingState}
                />
                <div style={{ marginTop: 60 }} />
                <OrderDetailsBox
                    source={source}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    name={name}
                    readOnly={readOnly}
                    subscription={subscription}
                    subscriptionCount={subscriptionCount}
                    noteAttributes={noteAttributes}
                />
                <OrderShipping orderId={selectedOrder.epicorId || -1} />
                <ItemsOrdered
                    items={lineItems}
                    shipping={shipping}
                    totals={totals}
                    discountCodes={discountCodes}
                    noteAttributes={noteAttributes}
                    orderNote={orderNote}
                    readOnly={readOnly}
                />
                <EpicorDetails
                    order={selectedOrder}
                    epicorData={orderEpicorData}
                    readOnly={readOnly}
                    onChange={this.onChange}
                />
                <LoyaltyDetails
                    fom={fom}
                    accruedPoints={fomPoints}
                    fomCreditSpent={fomCreditSpent}
                    readOnly={readOnly}
                    orderDate={createdAt}
                    processedAt={processedAt}
                    manuallyProcessed={manuallyProcessed}
                    transactionId={transactionId}
                    request={request}
                    response={response}
                    orderId={orderId}
                    fomCurrency={fomCurrency}
                    loyaltySkus={skus}
                />
                <ShippingDetails
                    shippingAddress={shippingAddress}
                    readOnly={readOnly}
                />
                <BillingDetails
                    billingAddress={billingAddress}
                    readOnly={readOnly}
                />
                <CustomerDetails
                    customer={customer}
                    onViewUser={this.onViewUser}
                    readOnly={readOnly}
                />
            </React.Fragment>
        );
    }
}

OrderDetails.propTypes = {
    history: PropTypes.object.isRequired,
    selectedOrder: PropTypes.object.isRequired,
    orderLoyalty: PropTypes.object.isRequired,
    orderEpicorData: PropTypes.object.isRequired,

    getOrder: PropTypes.func.isRequired,
    getOrderLoyalty: PropTypes.func.isRequired,
    getOrderEpicorData: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,

    readOnly: PropTypes.bool.isRequired,
    loadingState: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    selectedOrder: selectOrder(state),
    orderLoyalty: selectOrderLoyalty(state),
    orderEpicorData: selectOrderEpicorData(state),
    loadingState: selectOrdersLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOrder: orderId => ({ type: orderTypes.GET_ORDER, orderId }),
            getOrderLoyalty: orderId => ({
                type: loyaltyTypes.GET_ORDER_LOYALTY,
                orderId
            }),
            getOrderEpicorData: orderId => ({
                type: orderTypes.GET_ORDER_EPICOR_DATA,
                orderId
            }),
            updateOrder: (orderData, orderId) => ({
                type: orderTypes.UPDATE_ORDER,
                orderId,
                orderData
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderDetails)
);

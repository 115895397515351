import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { selectTabIndex } from '@/state/tabs/reducers';

const TabPanel = props => {

    const { children, value, onChange, ...other } = props;
    const index = useSelector(state => selectTabIndex(state));

    useEffect(() => {
        if (onChange) {
            onChange(index);
        }
    }, [index])

    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={value !== index}
            {...other}
        >
            {children}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.number.isRequired,
};

export default TabPanel;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Box from '../Box/Box';
import { getGrinds } from '@/state/products/actions';

import { selectUserLoading } from '@/state/users/reducers';
import { selectLocationsLoading } from '@/state/locations/reducers';

import Loader from '@/components/Loader/Loader';

const styles = makeStyles(theme => ({
    label: {
        fontSize: 11,
        color: theme.palette.label.primary
    },
    divider: {
        marginTop: 3,
        marginBottom: 20,
        marginRight: -20,
        marginLeft: -20
    },
    radioGroup: {
        flexDirection: 'row'
    },
    formControl: {
        width: '50%',
        marginBottom: 20
    }
}));

const MarketingPreferences = props => {
    const classes = styles();
    const { user, onFormPreferencesChange, readOnly } = props;

    const [grinds, setGrinds] = React.useState([]);
    const [userGrind, setUserGrind] = React.useState('');

    const userLoadingState = useSelector(state => selectUserLoading(state));
    const locationLoadingState = useSelector(state => selectLocationsLoading(state));

    const loadingState = userLoadingState || locationLoadingState || grinds.length === 0;

    useEffect(() => {

        if (!loadingState) {
            const { preferences } = user;
            setUserGrind(preferences.grind);
        }
    }, [loadingState])

    useEffect(() => {
        async function fetchGrinds() {
            const response = await getGrinds();
            const hasUserGrind = response.grinds.includes(user.preferences.grind)
            // if (!hasUserGrind) {
            //     response.grinds.push(user.preferences.grind)
            // }
            setGrinds(response.grinds);
        }

        fetchGrinds();
        setUserGrind(user.preferences.grind);
    }, [user])

    const handleGrindChange = (event) => {
        setUserGrind(event.target.value);
        onFormPreferencesChange({ grind: event.target.value });
    };

    return (
        <Box title="Preferences">
            <Loader loading={loadingState}>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink>Preferred Grind</InputLabel>
                    <Select
                        value={userGrind}
                        onChange={handleGrindChange}
                        label="Preferred Grind"
                        disabled={readOnly}
                        displayEmpty={false}
                        defaultValue={userGrind}
                    >
                        {
                            // hide the user grind in the select if it's not in the active grinds list
                            !grinds.includes(userGrind) &&
                            <MenuItem style={{ display: 'none' }} value={userGrind} key={userGrind}>
                                <em>{userGrind}</em>
                            </MenuItem>
                        }
                        {grinds.length > 0 && grinds.map(grind => {
                            return (
                                <MenuItem value={grind} key={grind}>
                                    <em>{grind}</em>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Loader>
        </Box>
    );
};

MarketingPreferences.propTypes = {
    user: PropTypes.object,
    onFormPreferencesChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

MarketingPreferences.defaultProps = {
    user: null,
};

export default MarketingPreferences;

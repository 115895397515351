import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import DevLogo from '@/assets/images/logo_merlo_cloud_development.svg';
import StagingLogo from '@/assets/images/logo_merlo_cloud_staging.svg';
import ProdLogo from '@/assets/images/logo_merlo_cloud_production.svg';
import LocalLogo from '@/assets/images/logo_merlo_cloud_local.svg';
import UALogo from '@/assets/images/logo_merlo_cloud_ua.svg';

import { selectFeatureState, selectEnv } from '@/state/featureFlag/reducers';
import { selectVersion } from '@/state/auth/reducers';
import Routes from '@/constants/routes';
import * as authTypes from '@/state/auth/types';
import * as authActions from '@/state/auth/actions';
import { Typography } from '@material-ui/core';

import { selectPermissions } from '@/state/permissions/reducers';
import { selectUserUuid } from '@/state/auth/reducers';

const styles = makeStyles(theme => ({
    drawerRoot: {
        width: 200,
    },
    drawerPaper: {
        width: 200,
        paddingTop: 19,
        background: theme.palette.background.dark,
    },
    merloLogo: {
        textAlign: 'center',
        marginBottom: 2
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fafafa !important',
        textDecoration: 'none'
    },
    listItem: {
        paddingLeft: 30,
        paddingTop: 12,
        paddingBottom: 12,
        '&:hover': {
            background: '#29303b',
            color: '#39b5ff !important',
            '& svg': {
                color: '#39b5ff !important'
            }
        }
    },
    listItemActive: {
        background: '#29303b',
        color: '#39b5ff !important',
        '& a, svg': {
            color: '#39b5ff !important'
        }
    },
    listItemIcon: {
        minWidth: 0,
        color: '#fafafa',
        paddingRight: 14
    },
    listItemText: {
        color: 'inherit',
        fontSize: '14px !important',
        flexGrow: 1
    },
    version: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 2
    }
}));



const getLogoFromEnv = (env) => {
    switch(env) {
        case 'development':
            return DevLogo;

        case 'staging':
            return StagingLogo;
        
        case 'production':
            return ProdLogo;

        case 'uat':
            return UALogo;

        case 'local':
            return LocalLogo;
        
        default:
            return DevLogo;
    }
}

const MainMenu = ({ location: { pathname } }) => {
    const classes = styles();

    const features = useSelector(state => selectFeatureState(state));
    const env = useSelector(state => selectEnv(state));
    const version = window.__env__.PROJECT_VERSION;

    const permissions = useSelector(state => selectPermissions(state));
    const userUuid = useSelector(state => selectUserUuid(state));
    const [routeList, setRouteList] = useState(Routes(features));
    useEffect(()=>{
        if (permissions && userUuid && permissions[userUuid]) {
            setRouteList(Routes(features, permissions[userUuid].permissions));
        }
    }, [permissions, userUuid]);

    const logo = getLogoFromEnv(env);

    return (
        <Drawer
            classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper
            }}
            variant="permanent"
            anchor="left"
        >
            <div className={classes.merloLogo}>
                <img src={logo} alt="Merlo Coffee Roasters" />
            </div>
            <Typography className={classes.version} variant="caption">{version}</Typography>
            <Divider />
            <List>
                {routeList
                    .filter(route => !('navMenuInclude' in route && route.navMenuInclude === false))
                    .map((route, index) => {
                        const { icon: Icon } = route;
                        const itemClass =
                            route.path === pathname
                                ? `${classes.listItem} ${classes.listItemActive}`
                                : classes.listItem;
                        return (
                            <Link
                                to={route.path}
                                className={classes.link}
                                key={index}
                            >
                                <ListItem button className={itemClass}>
                                    <ListItemIcon
                                        classes={{
                                            root: classes.listItemIcon
                                        }}
                                    >
                                        <Icon style={{ fontSize: 14 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemText
                                        }}
                                        primary={route.title}
                                    />
                                </ListItem>
                            </Link>
                        );
                    })}
            </List>
            <Divider />
        </Drawer>
    );
};

MainMenu.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(MainMenu);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Arrow from '@material-ui/icons/ArrowDropUp';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import TextInput from '@/components/TextInput/TextInput';

const useStyles = makeStyles({
    showHistoryBtn: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
        height: '100%'
    },
    showHistoryArrow: {
        transform: ({ showRewardHistory }) =>
            showRewardHistory ? 'rotateZ(0deg)' : 'rotateZ(180deg)'
    },
    rewardHistoryWrap: {
        width: '100%',
        padding: ({ showRewardHistory }) =>
            showRewardHistory ? '20px 0px' : 0,
        display: ({ showRewardHistory }) =>
            showRewardHistory ? 'block' : 'none'
    }
});

const PartRewards = ({ reward, onRewardChange, rewardHistory, readOnly, setErrors, errors }) => {
    const [showRewardHistory, setShowRewardHistory] = useState(false);

    const toggleRewardHistory = () => setShowRewardHistory(!showRewardHistory);

    const classes = useStyles({ showRewardHistory });

    const hasRewardHistory = rewardHistory.length > 0;

    const invalidPercent = reward > 5 || reward < 0;
    const helperText = invalidPercent ? 'Bean dollar % must be between 0.00 and 5.00' : '';

    const onChange = event => {
        if (event.target.value > 5 || event.target.value < 0) {
            setErrors({ reward: helperText })
        } else {
            delete errors.reward;
            setErrors(errors)
        }
        onRewardChange('reward')(event)
    }

    return (
        <>
            <Grid item xs={6}>
                <TextInput
                    type="number"
                    lang="en"
                    step="any"
                    label="Bean dollar %"
                    value={reward}
                    onChange={onChange}
                    readOnly={readOnly}
                    endAdornment="%"
                    helperText={helperText}
                    error={invalidPercent}
                />
            </Grid>
            {hasRewardHistory && (
                <>
                    <Grid item xs={6}>
                        <div
                            role="presentation"
                            className={classes.showHistoryBtn}
                            onClick={toggleRewardHistory}
                        >
                            View Bean Dollar history
                            <Arrow className={classes.showHistoryArrow} />
                        </div>
                    </Grid>
                    <div className={classes.rewardHistoryWrap}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Set by</TableCell>
                                    <TableCell>Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rewardHistory.map(r => (
                                    <TableRow key={r.createdAt}>
                                        <TableCell>
                                            {moment(r.createdAt).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {r.username || 'N/A'}
                                        </TableCell>
                                        <TableCell>{r.percentage}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </>
            )}
        </>
    );
};

PartRewards.propTypes = {
    reward: PropTypes.number.isRequired,
    onRewardChange: PropTypes.func,
    rewardHistory: PropTypes.array,
    readOnly: PropTypes.bool
};

PartRewards.defaultProps = {
    onRewardChange: () => null,
    rewardHistory: [],
    readOnly: false
};

export default PartRewards;

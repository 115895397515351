import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TabPanel from '@/components/TabPanel/TabPanel';
import VoucherList from '@/containers/VoucherList';
import AutomationList from '@/containers/AutomationList';
import RedemptionList from '@/containers/RedemptionList';
import ShopifyPromo from '@/containers/ShopifyPromo';
import BeanDrivePromo from '@/containers/BeanDrivePromo';
import * as promotionTypes from '@/state/promotions/types';
import * as segmentTypes from '@/state/segments/types';
import * as userTypes from '@/state/users/types';

import { selectSegments } from '@/state/segments/reducers';

import {
    selectVouchers,
    selectAutomations,
    selectRedemptions,
    selectShopifyPromotions
} from '@/state/promotions/reducers';

class Promotions extends Component {
    constructor(props) {
        super(props);

        const {
            getVouchers,
            getAutomations,
            getRedemptions,
            getSegments,
            getPromotions,
            getUsers
        } = props;
        getVouchers();
        getAutomations();
        getRedemptions();
        getSegments();
        getPromotions();
        // getUsers();
    }

    render() {
        const { vouchers, automations, redemptions, segments, promotions, readOnly } = this.props;
        return (
            <React.Fragment>
                <TabPanel value={0}>
                    <VoucherList vouchers={vouchers} readOnly={readOnly} />
                </TabPanel>
                <TabPanel value={1}>
                    <AutomationList
                        automations={automations}
                        vouchers={vouchers}
                        segments={segments}
                        readOnly={readOnly}
                    />
                </TabPanel>
                <TabPanel value={2}>
                    <RedemptionList redemptions={redemptions} readOnly={readOnly} />
                </TabPanel>
                <TabPanel value={3}>
                    <ShopifyPromo promotions={promotions} />
                </TabPanel>
                <TabPanel value={4}>
                    <BeanDrivePromo />
                </TabPanel>
            </React.Fragment>
        );
    }
}

Promotions.propTypes = {
    getVouchers: PropTypes.func.isRequired,
    getAutomations: PropTypes.func.isRequired,
    getRedemptions: PropTypes.func.isRequired,
    getSegments: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    vouchers: PropTypes.array.isRequired,
    automations: PropTypes.array.isRequired,
    redemptions: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    promotions: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    vouchers: selectVouchers(state),
    automations: selectAutomations(state),
    redemptions: selectRedemptions(state),
    segments: selectSegments(state),
    promotions: selectShopifyPromotions(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getVouchers: () => ({
                type: promotionTypes.GET_VOUCHERS
            }),
            getAutomations: () => ({
                type: promotionTypes.GET_AUTOMATIONS
            }),
            getRedemptions: () => ({
                type: promotionTypes.GET_REDEMPTIONS
            }),
            getSegments: () => ({
                type: segmentTypes.GET_SEGMENTS
            }),
            getUsers: () => ({
                type: userTypes.GET_USERS
            }),
            getPromotions: () => ({
                type: promotionTypes.GET_SHOPIFY_PROMOTIONS
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Promotions);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import {  getGrinds } from '@/state/products/actions';
import { selectSelectedProduct } from '@/state/products/reducers';

const styles = makeStyles({
    container: {
        // textAlign: 'center',
        marginBottom: 20
    },
    grindsContainer: {
        backgroundColor: '#f7f7f7',
        height: 200,
        overflowY: 'auto'
    },
    switchLabel: {
        fontSize: 14,
        fontWeight: 300,
        color: '#000000'
    },
    grindRow: {
        fontSize: 14,
        fontWeight: 300,
        padding: '15px 8px '
    }
});

const ProductGrindOptions = props => {
    const classes = styles();
    const { onUpdate, loading, isNew, readOnly } = props;

    const [grindState, setGrindState] = React.useState(false);
    const [grinds, setGrinds] = React.useState(['Loading...']);

    const product = useSelector(state => selectSelectedProduct(state));
    const { isGrindProduct } = product;

    if (!isNew) {
        useEffect(() => {
            if (!loading) {
                setGrindState(isGrindProduct || false);
            }
        }, [loading]);
    }

    const loadGrinds = async () => {
        const data = await getGrinds();
        setGrinds(data.grinds);
    }

    useEffect(() => {
        loadGrinds();
    }, [])

    const onChange = event => {
        setGrindState(event.target.checked);
        onUpdate({ isGrindProduct: event.target.checked });
    };

    return (
        <Box title="Does this product need grind options?">
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={4} className={classes.container}>
                        <div>
                            No
                            <Switch
                                disabled={readOnly}
                                checked={grindState}
                                color="primary"
                                onChange={onChange}
                            />
                            Yes
                        </div>
                        <div className={classes.switchLabel}>
                            If enabled, this product will show a dropdown for the customer to select from these grinds. <br /><br />To change this list, please contact Digital Makers.
                        </div>
                    </Grid>
                    <Grid item xs={8} className={classes.grindsContainer}>
                        {grinds.length > 0 &&
                            grinds.map((grind, i) => {
                                return (
                                    <div
                                        className={classes.grindRow}
                                        key={`grind-${i}`}
                                    >
                                        {grind}
                                    </div>
                                );
                            })}
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductGrindOptions.propTypes = {
    isNew: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductGrindOptions.defaultProps = {
    isNew: false,
};

export default ProductGrindOptions;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Loader from '@/components/Loader/Loader';
import DateTimePicker from '@/components/Datepicker/DateTimePicker';

const styles = makeStyles({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    }
});

const OfferTileScheduleForm = props => {
    const { loading, offerTile, onChange, readOnly } = props;
    const classes = styles();
    const [dates, setDates] = useState({
        startDate: moment(),
        endDate: moment().days(30)
    });

    useEffect(() => {
        if (!loading) {
            setDates({
                startDate: offerTile.startDate
                    ? moment(offerTile.startDate)
                    : moment(),
                endDate: offerTile.endDate
                    ? moment(offerTile.endDate)
                    : moment().days(30)
            });
        }
    }, [loading]);

    const handleChange = type => event => {
        setDates({
            ...dates,
            [type]: event
        });
        onChange({ [type]: event.format() });
    };

    const { startDate, endDate } = dates;
    const startDateClone = startDate.clone();

    return (
        <Loader loading={loading}>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={6}>
                    <DateTimePicker
                        label="Start date"
                        value={startDate}
                        name="startDate"
                        readOnly={readOnly}
                        onChange={handleChange('startDate')}
                        maxDate={new Date(endDate.format())}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker
                        label="End date"
                        value={endDate}
                        name="endDate"
                        readOnly={readOnly}
                        onChange={handleChange('endDate')}
                        minDate={new Date(startDateClone.days(1).format())}
                    />
                </Grid>
            </Grid>
        </Loader>
    );
};

OfferTileScheduleForm.propTypes = {
    offerTile: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

OfferTileScheduleForm.defaultProps = {
    onChange: null,
    readOnly: false
};

export default OfferTileScheduleForm;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import SearchInput from '../SearchInput/SearchInput';
import SelectWithCheckbox from '../SelectWithCheckbox/SelectWithCheckbox';

const styles = makeStyles({
    flexContainer: {
        display: 'flex'
    },
    search: {
        flexBasis: '60%',
        marginRight: 24
    },
    permission: {
        flexBasis: '20%',
        marginRight: 24
    },
    status: {
        flexBasis: '20%'
    },
    filterContainer: {
        marginTop: 24
    },
    results: {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        marginRight: 40
    },
    filterText: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 8
    },
    iconButton: {
        marginLeft: 5,
        outline: '0 !important'
    },
    icon: {
        fontSize: 24
    },
    button: {
        outline: '0 !important'
    }
});

const UserFilter = props => {
    const classes = styles();
    const {
        onChange,
        searchFilter,
        permissions,
        filteredPermissions,
        statuses,
        filteredStatuses,
        numOfResults,
        removeFilter,
        clearAll
    } = props;

    const computedFilters = [...filteredPermissions, ...filteredStatuses];

    return (
        <React.Fragment>
            <div className={classes.flexContainer}>
                <SearchInput
                    className={classes.search}
                    placeholder="Search by email address, phone number, or name"
                    value={searchFilter}
                    onChange={onChange('search')}
                />
                <SelectWithCheckbox
                    className={classes.permission}
                    options={permissions}
                    selected={filteredPermissions}
                    onChange={onChange('filteredPermissions')}
                    label="Permissions"
                />
                <SelectWithCheckbox
                    className={classes.status}
                    options={statuses}
                    selected={filteredStatuses}
                    onChange={onChange('filteredStatuses')}
                    label="Status"
                />
            </div>
            <div className={classes.filterContainer}>
                <div className={classes.flexContainer}>
                    <Typography className={classes.results}>
                        {`Filters (${numOfResults} results):`}
                    </Typography>

                    {computedFilters.map((filter, i) => (
                        <Typography key={`userFilter-${i}`} className={classes.filterText}>
                            {filter.label}
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                onClick={() => removeFilter(filter.value)}
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>
                        </Typography>
                    ))}
                    <Button className={classes.button} onClick={clearAll}>
                        Clear All
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

UserFilter.propTypes = {
    onChange: PropTypes.func.isRequired,

    searchFilter: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
    filteredPermissions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
    statuses: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
    filteredStatuses: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
    numOfResults: PropTypes.number.isRequired,
    removeFilter: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired
};

export default UserFilter;

import io from 'socket.io-client';
// import io from 'socket.io-client/dist/socket.io.slim';

class Sockets {
    constructor() {
        const isProd = process.env.NODE_ENV === 'production';
        const baseUrl = isProd ? window.__env__.BASE_URL : process.env.REACT_APP_BASE_URL;
        const trimmedBaseUrl = baseUrl.substring(0, baseUrl.length - 4);

        this.socket = null;
        this.baseUrl = trimmedBaseUrl;
        this.pathName = '/loopback/connect';
    }

    connect = (registerHandlers) => {
        this.socket = io.connect(this.baseUrl, {
            path: this.pathName,
        });

        this.socket.on('connect', () => {
            this.socket.emit('authentication', {
                token: localStorage.getItem('idToken'),
            });
        });

        this.socket.on('authenticated', () => {
            console.log('connected to Merlo Cloud');
        });

        this.socket.on('unauthorized', (data) => {
            const { message } = data;
            console.log('unauthorized :', message);
        });

        if (!registerHandlers) {
            this.registerHandlers();
        } else {
            registerHandlers(this.socket);
        }
    };

    registerHandlers = () => {
        this.socket.on('log', (data) => {
            console.log(data);
        });

        this.socket.on('message', (data) => {
            console.log('SocketIO message recieved: ', data);
        });

        this.socket.on('error', (error) => {
            console.log('error', error);
        });

        this.socket.on('disconnect', () => {
            console.log('connection closed');
        });
    }

    closeConnection = () => {
        console.log('closing connection');
        if (this.socket) {
            this.socket.close(true);
        }
    }

    getConnection = () => {
        if (this.socket === null) {
            console.log('socket not initialized');
        }
        return this.socket;
    }
}

export const reconnect = async (url) => {
    // get idToken from local storage
    const token = await localStorage.getItem('idToken');
    if (token !== null) {
        // use token to reconnect 
        const _io = new Sockets();
        await _io.connect(url);
    }
}

export default Sockets;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TabPanel from '@/components/TabPanel/TabPanel';

import Settings from '@/containers/Settings';
import SettingsScripts from '@/components/SettingsScripts/SettingsScripts';
import EmailDomainList from '@/components/EmailDomainList/EmailDomainList';
import OptionsContainer from './OptionsContainer';

class SettingsContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { readOnly } = this.props;

        return (
            <React.Fragment>
                <TabPanel value={0}>
                    <Settings readOnly={readOnly} />
                </TabPanel>
                <TabPanel value={1}>
                    <OptionsContainer readOnly={false} />
                </TabPanel>
                <TabPanel value={2}>
                    <SettingsScripts readOnly={readOnly}/>
                </TabPanel>
            </React.Fragment>
        );
    }
}

SettingsContainer.propTypes = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);

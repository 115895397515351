import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { CircularProgress, Container } from '@material-ui/core';
import Button from '@/components/Button/Button';

const styles = makeStyles({
    paper: {
        minHeight: 150,
        maxWidth: 150
    },
    title: {
        backgroundColor: '#F2F2F2',
        padding: 20,
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 16,
        maxWidth: 150
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        textAlign: 'center',
        fontSize: 12
    },
    successIcon: {
        fontSize: 40,
        color: '#3DCF8E',
        margin: 'auto 0',
        marginBottom: 10
    },
    warningIcon: {
        fontSize: 40,
        color: '#EEB111',
        margin: 'auto 0',
        marginBottom: 10
    },
    pendingIcon: {
        fontSize: 40,
        margin: 'auto 0',
        marginBottom: 10
    },
    progress: {
        color: '#7B7B7B',
        marginBottom: 10
    },
    subText: {
        fontSize: 12
    }
});


const HealthBox = ({ name, status, loading }) => {
    const classes = styles();
  
    const statusIcon = (s) => {
        switch(s) {
            case 1:
                return (
                    <div>
                        <CheckCircleIcon className={classes.successIcon} />
                        <Typography className={classes.subText}>Working</Typography>
                    </div>
                )
            case 0:
                return (
                    <div>
                        <WarningIcon className={classes.warningIcon} />
                        <Typography className={classes.subText}>Service not responding</Typography>
                    </div>
                )
            default:
                return (
                    <div>
                        <HourglassEmptyIcon className={classes.pendingIcon} />
                        <Typography className={classes.subText}>Pending</Typography>
                    </div>
                )
        }
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.title}>
                {name.split('Service')}
            </div>
            <div className={classes.body}>
                {
                    loading ?
                        <div>
                            <CircularProgress className={classes.progress} size={30} />
                            <Typography className={classes.subText}>Checking...</Typography>
                        </div> : statusIcon(status)
                }
            </div>
        </Paper>
    );
};

export default HealthBox;

import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@/components/Button/Button';
import HealthBox from '@/components/Diagnostics/HealthBox';
import { healthCheck } from '@/state/common/actions';
import { selectHealthCheck } from '@/state/realtime/reducers';

const styles = makeStyles({
    container: {
        marginTop: 75,
    },
    subHeader: {
        position: 'fixed',
        width: 'calc(100% - 200px)',
        left: 200,
        top: 150,
        backgroundColor: '#e6e6e6',
        display: 'flex',
        alignItems: 'center',
        height: 60,
        paddingRight: 15,
        paddingLeft: 15,
        zIndex: 11,
        minHeight: 75
    },
    left: {
        padding: 20,
        fontSize: 14,
        fontWeight: 400
    },
    right: {
        padding: 20
    },
    button: {
        float: 'right',
        margin: 'auto 0'
    },
    lastRun: {
        fontSize: 12,
        float: 'right',
        padding: 15
    }
});

const Diagnostics = ({ selectedHealthCheck }) => {
    const classes = styles();
    const [loading, setLoading] = useState(false);
    const [lastRun, setLastRun] = useState('-');
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        return () => {
            resetToPending();
        }
    }, [])

    const resetToPending = () => {
        selectedHealthCheck.forEach((check)=> {
            check.loading = false;
            check.status = -1;
        });
        dispatch({
            type: 'UPDATE_ALL_HEALTH_CHECK',
            state: selectedHealthCheck
        })
    }

    const disableRunTestButton = () => {
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false)
        }, 60000)
    }

    const updateHealthChecksFromGrpc = (data) => {
        // if we get grpc failures mark them here
        data.data.forEach(result => {
            if (result.status === 0) {
                const service = selectedHealthCheck.find(s => s.serviceName === result.serviceName)
                if (service) {
                    service.loading = false;
                    service.status = 0;
                }
            }
        })
    }

    const markAsFailedAfterPeriod = (period) => {
        // after 30 sec if still loading mark as failed
        setTimeout(() => {
            selectedHealthCheck.forEach(r => {
                if(r.loading) {
                    r.loading = false;
                    r.status = 0;
                }
            })
            dispatch({
                type: 'UPDATE_ALL_HEALTH_CHECK',
                state: selectedHealthCheck
            })
        }, period)
    }

    const fetchData = async () => {
        selectedHealthCheck.forEach(r => {
            r.loading = true
        })

        setLoading(true)
        return  healthCheck()
    }

    const runTest = async () => {
        disableRunTestButton();

        const data = await fetchData();
        updateHealthChecksFromGrpc(data);

        markAsFailedAfterPeriod(30000);

        setLoading(false)
        setLastRun(moment().format('HH:mmA DD/MM/YYYY'))
    }


    return (
    <>
      <div className={classes.subHeader}>
          <Grid container>
              <Grid item xs={6} className={classes.left}>
            Below is a list of all the microservices under Merlo Coffee, and their status.
            You can quickly identify the status of each microservice by pressing the update button.
              </Grid>
              <Grid item xs={6} className={classes.right}>
                  <Button label="Run New Test" className={classes.button} loading={loading} onClick={() => runTest()} disabled={disabled} />
              </Grid>
          </Grid>
      </div>
      <Container className={classes.container}>
          <Typography className={classes.lastRun}><b>Test Started:</b> {lastRun}</Typography>
          <Grid container spacing={2}>
              {selectedHealthCheck.map(result => (
                  <Grid item md={2}>
                      <HealthBox name={result.serviceName} status={result.status} loading={result.loading} />
                  </Grid>
              ))}
          </Grid>
      </Container>
    </>
    );
};

const mapStateToProps = state => ({
    selectedHealthCheck: selectHealthCheck(state),
});

Diagnostics.propTypes = {
    selectedHealthCheck: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(Diagnostics);


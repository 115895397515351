import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button/Button';

import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';

import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    subtitle: {
        fontSize: 16,
        lineHeight: 1.2,
        marginBottom: 10
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    buttonsContainer2: {
        textAlign: 'center',
        width: '100%',
        marginTop: 6
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    }
});

const ViewRedemption = props => {
    const classes = styles();
    const { redemption, closeModal } = props;

    const viewUser = () => {
        const { history } = props;
        const { assigned } = redemption;
        closeModal();
        history.push(`/users/${assigned}`);
    };
    const viewVoucher = () => {
        const { history } = props;
        const { voucherUuid } = redemption;
        closeModal();
        history.push(`/promotions/vouchers/${voucherUuid}`);
    };

    const status = redemption.status.replace(/^\w/, c => c.toUpperCase());

    return (
        <div>
            <Typography className={classes.title}>View Redemption</Typography>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput label="Code" value={redemption.code} readOnly />
                </Grid>
                <Grid item xs={8}>
                    <TextInput
                        label="User"
                        value={redemption.assignedName}
                        readOnly
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        className={classes.buttonsContainer2}
                        label="View User"
                        onClick={viewUser}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextInput
                        label="Voucher Name"
                        value={redemption.title}
                        readOnly
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        className={classes.buttonsContainer2}
                        label="View Voucher"
                        onClick={viewVoucher}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        multiline
                        rows={3}
                        label="Voucher Description"
                        value={redemption.description}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="POS ID"
                        value={redemption.posId}
                        readOnly
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        readOnly
                        label="Date"
                        value={moment(redemption.date)}
                        name="createdAt"
                        onChange={() => {}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Status" value={status} readOnly />
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Close"
                    variant={null}
                    style={{ color: '#077ABE' }}
                    onClick={closeModal}
                />
            </div>
        </div>
    );
};

ViewRedemption.propTypes = {
    redemption: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

ViewRedemption.defaultProps = {
    redemption: {}
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ViewRedemption)
);

import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles, Typography } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import Fuse from 'fuse.js';
import moment from 'moment'
import axios from 'axios';
import * as shippingActions from '@/state/shipping/actions';
import Table from '../components/Table/Table';
import SearchInput from '@/components/SearchInput/SearchInput';
import Checkbox from '@/components/Checkbox/Checkbox';
import SelectWithCheckbox from '@/components/SelectWithCheckbox/SelectWithCheckbox';

const headerData = [
    {
        key: 'epicorId',
        label: 'Epicor ID',
    },
    { key: 'receiverCompany', label: 'Receiver Company' },
    { key: 'courierName', label: 'Courier Name' },
    { key: 'customerName', label: 'Customer Name' },
    {
        key: 'authorityToLeave',
        label: '',
        compute: data => {
            return data.authorityToLeave === 'Yes' ? 'Authorised to Leave' : '';
        },
        renderer: data => data === '' ? '' : <Chip label={data} />,
    },
    {
        key: 'currentState',
        label: 'Current State',
    },
    {
        key: 'booked', label: 'Booking Time',
        compute: data => {
            return data.booked;
        },
        renderer: data => moment.unix(data).format('DD-MM-YYYY HH:mm A'),
    },
    { key: 'trackingNumber', label: 'Tracking Number' },
    {
        key: '',
        label: '',
        compute: data => data.trackingUrl,
        renderer: data => <a href={data} target="_blank" style={{ color: 'black' }}><LaunchIcon /></a>
    },
];

const styles = makeStyles({
    search: {
        flexBasis: '100%',
        marginRight: 24
    },
    flexContainer: {
        display: 'flex',
        height: 60
    },
    status: {
        flexBasis: '100%',
    },
    statusWrapper: {
        minWidth: 135,
        maxWidth: 135,
        flexBasis: '10%',

    },
    filter: {
        minWidth: 230,
        marginRight: 24,
        flexBasis: '20%',
    }
})

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: [
        'machineName',
        'streetAddress',
        'trackingNumber',
        'epicorId',
        'suburb',
        'receiverCompany',
        'currentStatus',
        'state'
    ]
};

let cancel;

const Shipping = props => {


    const [shippingLabels, setShippingLabels] = React.useState([]);

    const [state, setState] = React.useState({
        loadingState: true
    });
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [limit, setlimit] = React.useState(100);
    const [sortBy, setSortBy] = React.useState('booked');
    const [order, setOrder] = React.useState('asc');
    const [count, setCount] = React.useState(0);
    const [showDeliveredYes, setShowDeliveredYes] = React.useState(false);
    const [showDeliveredNo, setShowDeliveredNo] = React.useState(true);
    const [receiverFilter, setReceiverFilter] = React.useState([]);
    const classes = styles();

    const getShippingLabels = async () => {
        setState({
            ...state,
            loadingState: true
        })

        // since not using sagas, cancel previous request so when searching it's doesn't load wierd
        if (cancel !== undefined) {
            cancel.cancel();
        }

        cancel = axios.CancelToken.source();

        try {
            const filter = receiverFilter.map(f => f.value).filter(f => f !== 'all')
            const data = await shippingActions.getShippingLabels(search, page, limit, sortBy, order, showDeliveredYes, showDeliveredNo, filter, cancel.token);
            setShippingLabels(data.bookings);
            setCount(data.meta.count)
            setState({
                ...state,
                loadingState: false
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                setShippingLabels([]);
                setCount(0)
            } else {
                setState({
                    ...state,
                    loadingState: false
                })
                throw error;
            }
        }
    };

    const epicorFilter = [
        { label: 'All', value: 'all' },
        { label: 'MC', value: 'MC' },
        { label: 'T10', value: 'T10' }
    ]

    useEffect(() => {
        getShippingLabels();
    }, []);

    useEffect(() => {
        getShippingLabels();
    }, [search, sortBy, order, limit, page, showDeliveredYes, showDeliveredNo, receiverFilter]);

    useEffect(() => {
        setPage(0);
    }, [showDeliveredNo, showDeliveredYes]);

    const searchShippingLabels = (data) => {
        const fuseInstance = new Fuse(data, fuseOpts);
        return fuseInstance.search(search);
    };

    const onSetReceiverFilter = (e, v) => {
        const selection = v.props.value;
        let values = [...receiverFilter];

        if (selection.value === 'all') {
            const idx = values.findIndex(v => v.value === epicorFilter[0].value);
            if (idx === -1) {
                values = [...epicorFilter];
            } else {
                console.log('here');
                values = [];
            }
        } else {
            // if any other value is selected - clear the all flag first
            const hasAll = values.findIndex(v => v.value === epicorFilter[0].value);
            if (hasAll > -1) {
                values.splice(hasAll, 1);
            }

            const idx = values.length > 0
                ? values.findIndex(v => v.value === selection.value)
                : -1;
            if (idx > -1) {
                // remove from list (deselected)
                values.splice(idx, 1);
            } else {
                values.push(selection);
            }
        }
        setPage(0);
        setReceiverFilter(values);
    }

    const onChange = type => event => {
        setSearch(event.target.value)
        setPage(0)
    };

    const onChangePage = (newPage) => {
        setPage(newPage)
    }

    const onChangeRowsPerPage = (limit) => {
        setlimit(limit);
    }

    const onSort = (e) => {
        setSortBy(e.property)
        setOrder(e.newOrder);
        setPage(0);
    }

    const onFilter = (e) => {
    
    }

    const rowStyle = (row) => {
        if (row.currentState !== 'completed' && row.currentState !== 'parcel completed') {
            
            return { 'backgroundColor': 'rgba(255, 0, 0, 0.1)' }
        }

        return {};
    }


    return (
        <div>
            <React.Fragment>
                <div className={classes.flexContainer}>
                    <SearchInput
                        placeholder="Search by epicor ID, address, tracking number..."
                        className={classes.search}
                        onChange={onChange('search')}
                        value={search}
                    />

                    <SelectWithCheckbox
                        className={classes.filter}
                        options={epicorFilter}
                        selected={receiverFilter}
                        onChange={onSetReceiverFilter}
                        label="Receiver Company"
                    />
                    <div className={classes.statusWrapper}>
                        <Typography className={classes.status}>
                            Show Completed
                        </Typography>
                        <div className={classes.flexContainer}>
                            <Checkbox
                                label="Yes"
                                checked={showDeliveredYes}
                                onChange={(e) => setShowDeliveredYes(e.target.checked)}
                            />
                            <Checkbox
                                label="No"
                                checked={showDeliveredNo}
                                onChange={(e) => setShowDeliveredNo(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>

                <Table
                    page={page}
                    setPage={setPage} 
                    pagination
                    loading={state.loadingState}
                    headerData={headerData}
                    tableData={shippingLabels}
                    serverSidePagination
                    serverSideSorting
                    count={Number(count)}
                    pageChanged={onChangePage}
                    onChangePage={onChangePage}
                    rowsPerPageChanged={onChangeRowsPerPage}
                    defaultSortDir='asc'
                    defaultSortKey='booked'
                    showEmptyRows={false}
                    onSort={onSort}
                    rowStyle={rowStyle}
                    defaultRowsPerPage={limit}
                />
            </React.Fragment>
        </div>
    );
}

Shipping.propTypes = {

};

export default Shipping;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import Chip from '@material-ui/core/Chip';
import ImageAdd from '@material-ui/icons/AddPhotoAlternate';
import DotFull from '@material-ui/icons/FiberManualRecord';
import DotEmpty from '@material-ui/icons/FiberManualRecordOutlined';
import Delete from '@material-ui/icons/Close';

import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import ImagePicker from '@/components/ImagePicker/ImagePicker';

import { selectSelectedProduct } from '@/state/products/reducers';

const styles = makeStyles({
    container: {
        textAlign: 'center'
    },
    imagesContainer: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    imageContainer: {
        margin: 10,
        position: 'relative'
    },
    featuredChip: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: '#eaeaea'
    },
    chipText: {
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 500,
        paddingRight: 0
    },
    chipLabel: {
        paddingRight: 0
    },
    dotEmpty: {
        fill: '#b1afb1'
    },
    deleteIcon: {
        position: 'absolute',
        left: 5,
        top: 5,
        fill: '#ff0000',
        cursor: 'pointer',
        backgroundColor: '#eaeaea',
        borderRadius: '50%'
    }
});

const ProductImages = props => {
    const classes = styles();
    const { readOnly, onUpdate, loading, isNew } = props;
    const [imageState, setImageState] = React.useState([]);

    const product = useSelector(state => selectSelectedProduct(state));
    const { images } = product;

    if (!isNew) {
        useEffect(() => {
            if (!loading && images) {
                if (images.length > 0) {
                    setImageState(
                        images.reduce((acc, image, idx) => {
                            acc.push({
                                index: idx,
                                src: image.src,
                                position: image.position,
                                featured: image.position === 1
                            });
                            return acc;
                        }, [])
                    );
                }
            } else {
                setImageState([]);
            }
        }, [loading]);
    }

    const onFeaturedClick = image => {
        // there can be only 1 featured image
        const idx = imageState.map(i => i.index).indexOf(image.index);
        let _imageState = [...imageState];

        if (_imageState[idx].featured) {
            _imageState = _imageState.reduce((acc, _image) => {
                acc.push({ ..._image, position: 0, featured: false });
                return acc;
            }, []);
        } else {
            _imageState = _imageState.reduce((acc, _image, i) => {
                acc.push({
                    ..._image,
                    position: i === idx ? 1 : 0,
                    featured: i === idx
                });
                return acc;
            }, []);
        }

        setImageState(_imageState);
        onUpdate(_imageState);
    };

    const onDeleteClick = image => {
        const idx = imageState.map(i => i.index).indexOf(image.index);
        const _imageState = [...imageState];
        _imageState.splice(idx, 1);

        setImageState(_imageState);
        onUpdate(_imageState);
    };

    const onAddImage = imageData => {
        // add to the list of available images
        const newImage = {
            index: imageState.length,
            src: imageData,
            position: 0,
            featured: false,
            new: true
        };
        const _imageState = [...imageState];
        _imageState.push(newImage);

        setImageState(_imageState);
        onUpdate(_imageState);
    };

    // image at index 1 is the 'main' image, the rest are all ordered based on their number.

    return (
        <Box className={classes.container}>
            <Loader loading={loading}>
                <div className={classes.imagesContainer}>
                    {imageState.length > 0 &&
                        imageState.map((image, i) => {
                            const isFeatured = image.featured;
                            const chipLabel = (
                                <div
                                    style={{
                                        color: isFeatured
                                            ? '#000000'
                                            : '#b1afb1'
                                    }}
                                    className={classes.chipText}
                                >
                                    featured
                                    {isFeatured ? (
                                        <DotFull color="primary" />
                                    ) : (
                                        <DotEmpty
                                            className={classes.dotEmpty}
                                        />
                                    )}
                                </div>
                            );
                            return (
                                <div
                                    className={classes.imageContainer}
                                    key={`product-image-${i}`}
                                >
                                    {!readOnly && (
                                        <React.Fragment>
                                            <Chip
                                                size="small"
                                                label={chipLabel}
                                                className={classes.featuredChip}
                                                classes={{
                                                    labelSmall:
                                                        classes.chipLabel
                                                }}
                                                clickable
                                                onClick={() =>
                                                    onFeaturedClick(image)
                                                }
                                            />
                                            <Delete
                                                className={classes.deleteIcon}
                                                onClick={() =>
                                                    onDeleteClick(image)
                                                }
                                            />
                                        </React.Fragment>
                                    )}
                                    <ImagePlaceholder imageUrl={image.src} />
                                </div>
                            );
                        })}
                </div>
                <ImagePicker
                    icon={<ImageAdd fontSize="small" />}
                    iconPosition="start"
                    label="Add an image"
                    disabled={readOnly}
                    style={{ width: 150 }}
                    onImageLoad={onAddImage}
                />
            </Loader>
        </Box>
    );
};

ProductImages.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductImages.defaultProps = {
    isNew: false
};

export default ProductImages;

import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const CustomTableHead = props => {
    const { classes, headerData, order, orderBy, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const rowLabel = (row) => {
        return 'showLabel' in row && !row.showLabel
            ? null
            : <div className={classes.tableHeadLabel}>{row.label}</div>
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadRow}>
                {
                    headerData
                        .filter(row => 'visible' in row ? row.visible : true)
                        .map(row => {
                            const sortable = 'sortable' in row ? row.sortable : true;
                            return (
                                <TableCell
                                    key={row.key}
                                    sortDirection={orderBy === row.key ? order : false}
                                    {...row.headerStyle}
                                >
                                    {
                                        row.key !== 'menu' &&
                                            sortable ?
                                            <TableSortLabel
                                                className={classes.tableHeadLabel}
                                                active={orderBy === row.key}
                                                direction={order}
                                                onClick={createSortHandler(row.key)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                            :
                                            rowLabel(row)
                                    }
                                </TableCell>
                            )
                        })
                }
            </TableRow>
        </TableHead>
    );
};

CustomTableHead.propTypes = {
    classes: PropTypes.object,
    headerData: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string
        })
    ).isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

CustomTableHead.defaultProps = {
    classes: {},
};

export default CustomTableHead;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button/Button';

import TextInput from '@/components/TextInput/TextInput';

import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    subtitle: {
        fontSize: 16,
        lineHeight: 1.2,
        marginBottom: 10
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    }
});

const NotifyVoucherCode = props => {
    const classes = styles();
    const { code, voucher, onSubmit, closeModal } = props;
    const { title } = voucher;

    // Transform code.code to something more readable
    const { code: codeUuid } = code;

    return (
        <div>
            <Typography className={classes.title}>
                Notify user of voucher code
            </Typography>
            <Typography style={{ marginBottom: 10 }}>
                Voucher: {title}
            </Typography>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput label="Delivery Method" readOnly value="Email" />
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077ABE' }}
                    onClick={closeModal}
                />
                <Button
                    className={classes.actionButton}
                    label="Send"
                    onClick={() => onSubmit(codeUuid)}
                />
            </div>
        </div>
    );
};

NotifyVoucherCode.propTypes = {
    code: PropTypes.object.isRequired,
    voucher: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(NotifyVoucherCode);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';

import SubHeader from '@/components/SubHeader/SubHeader';
import Button from '@/components/Button/Button';
import PersonalDetails from '@/components/PersonalDetails/PersonalDetails';
import UserAddresses from '@/components/UserAddresses/UserAddresses';
import MarketingPreferences from '@/components/MarketingPreferences/MarketingPreferences';
import UserMeta from '@/components/UserMeta/UserMeta';
import UserVouchers from '@/components/Vouchers/UserVouchers';
import OrderHistory from '@/components/Orders/OrderHistory';
import PermissionChip from '@/components/PermissionChip/PermissionChip';
import UserLoyalty from '@/components/Loyalty/UserLoyalty';

import { selectUser, selectUsersErrors } from '@/state/users/reducers';
import { selectLocations } from '@/state/locations/reducers';
import { selectUserVouchers } from '@/state/promotions/reducers';
import { selectUserOrders } from '@/state/orders/reducers';
import { selectUserLoyalty } from '@/state/loyalty/reducers';
import { selectPermissions } from '@/state/permissions/reducers';

import * as userTypes from '@/state/users/types';
import * as promotionTypes from '@/state/promotions/types';
import * as loyaltyTypes from '@/state/loyalty/types';
import * as orderTypes from '@/state/orders/types';
import * as locationTypes from '@/state/locations/types';
import * as permissionTypes from '@/state/permissions/types';
import * as authTypes from '@/state/auth/types';
import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

const styles = (theme) => ({

    addUserContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    chipsContainer: {
        flex: 1,
    },
    chip: {
        margin: theme.spacing(1),
    },
    spacer: {
        marginTop: 60,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px'
    },
    button: {
        fontSize: 14,
        width: 125,
        padding: '5px 0px',
    }
});

class UserDetails extends Component {
    constructor(props) {
        super();

        const uuid = this.getUuidFromRoute(props);

        props.getOrders(uuid);
        props.getLoyalty(uuid);
        props.getUser(uuid);
        props.getVouchers(uuid);
        props.getLocations();
        props.getUserPermissions(uuid);
    }

    state = {
        preferences: {},
    }

    getUuidFromRoute = (props) => {
        const { match: { params } } = props;
        const { uuid } = params;
        return uuid;
    }

    // If viewing a user then navigating to own profile - update redux
    componentDidUpdate(prevProps) {
        const { match: { params: { uuid: prevUuid } } } = prevProps;
        const {
            getOrders,
            getLoyalty,
            getUser,
            getVouchers,
            getUserPermissions,
            match: { params: { uuid: currentUuid } },
        } = this.props;

        if (currentUuid !== prevUuid) {
            getOrders(currentUuid);
            getLoyalty(currentUuid);
            getUser(currentUuid);
            getVouchers(currentUuid);
            getUserPermissions(currentUuid);
        }
    }

    onFormChange = type => event => {
        const { [type]: currentState } = this.state;

        const targetName = event.currentTarget.name;
        const newValue = event.currentTarget.value;
        const isMobile = targetName === 'mobileNumber';

        if (isMobile) {
            const parsedNumber = parsePhoneNumber(newValue, { regionCode: 'AU' });
            const isValid = parsedNumber.valid;

            this.setState({
                [type]: {
                    ...currentState,
                    [targetName]: isValid ? parsedNumber.number.international.replace(/[^\d+]+/g, '') : newValue
                }
            });

        } else {
            this.setState({
                [type]: {
                    ...currentState,
                    [targetName]: newValue
                }
            });
        }
    }

    onFormAddressChange = type => addresses => {
        this.setState({ [type]: addresses })
    }

    onFormPreferencesChange = type => preferences => {
        this.setState(prevState => {
            return { [type]: { ...prevState.preferences, ...preferences } };
        });
    }

    onViewVoucherDetails = rowData => {
        console.info('DEBUG: UserDetails -> rowData', rowData);
    }

    onPasswordReset = () => {
        const { selectedUser } = this.props;
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.RESET_PASSWORD_MODAL, { user: selectedUser, title: 'Set Password' });
        openModal(true);
    }

    onSave = () => {
        const { updateUser, userErrors } = this.props;

        if (Object.keys(userErrors).length) {
            return;
        }

        updateUser(this.state);
    }

    onRestoreOrArchive = (type, uuid) => {
        const { selectedUser, setModalComponent, openModal, restoreUser, archiveUser } = this.props;

        switch (type) {
            case 'restore':
                setModalComponent(modals.ARCHIVE_USER_MODAL, {
                    message: `Are you sure you want to restore '${selectedUser.profile.firstName} ${selectedUser.profile.lastName}'?`,
                    confirmMessage: 'Restore',
                    onConfirm: (() => restoreUser(uuid)),
                });
                openModal();
                break;
            case 'archive':
                setModalComponent(modals.ARCHIVE_USER_MODAL, {
                    message: `Are you sure you want to archive '${selectedUser.profile.firstName} ${selectedUser.profile.lastName}'?`,
                    confirmMessage: 'Archive',
                    onConfirm: (() => archiveUser(uuid)),
                });
                openModal();
                break;
            default:
                break;
        }
    }

    onVerificationResend = () => {
        const { resendEmailVerification, selectedUser } = this.props;
        const { profile: { email } } = selectedUser;
        resendEmailVerification(email);
    }

    forceDeleteUser = () => {
        const { selectedUser, forceDeleteUser, setModalComponent, openModal, history } = this.props;
        setModalComponent(modals.ARCHIVE_USER_MODAL, {
            message: `Are you sure you want to permanently delete '${selectedUser.profile.firstName} ${selectedUser.profile.lastName}'?`,
            confirmMessage: 'Delete',
            onConfirm: (() => forceDeleteUser(selectedUser.uuid, history)),
        });
        openModal();
    }

    render() {
        const { classes, selectedUser, userVouchers, userLoyalty, userOrders, locations, readOnly, userErrors, setUserErrors, getLoyalty, refreshLoyalty } = this.props;
        const { endedDate, uuid, emailVerified } = selectedUser;
        // FOM
        // Order history
        console.log(this.state);
        const isPending = emailVerified !== undefined ? emailVerified.trim().length === 0 : true;
        // console.info('DEBUG: UserDetails -> render -> isPending', isPending);

        return (
            <React.Fragment>
                <SubHeader
                    onSave={() => { this.onSave() }}
                    lastUpdated={selectedUser.lastUpdatedDate}
                    readOnly={isPending || readOnly || Object.keys(userErrors).length > 0}
                />

                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <div className={classes.chipsContainer}>
                        <PermissionChip selectedUser={selectedUser} readOnly={isPending || readOnly} />
                    </div>
                    {
                        isPending &&
                        <Button className={classes.button} style={{ width: 150, marginRight: 20 }} label='Resend Verification' disabled={readOnly} onClick={this.onVerificationResend} />
                    }
                    <Button className={classes.button} style={{ marginRight: 20 }} label='Set Password' disabled={readOnly} onClick={this.onPasswordReset} />
                    {
                        endedDate && endedDate.trim().length > 0
                            ? <Button className={classes.button} style={{ marginRight: 20 }} label='Restore User' disabled={readOnly} onClick={() => this.onRestoreOrArchive('restore', uuid)} />
                            : <Button className={classes.button} style={{ marginRight: 20 }} label='Archive User' disabled={readOnly} onClick={() => this.onRestoreOrArchive('archive', uuid)} />
                    }
                    {
                        isPending &&
                        <Button className={classes.button} style={{ marginRight: 20 }} label='Delete User' disabled={readOnly} onClick={() => this.forceDeleteUser()} />
                    }
                </div>

                <PersonalDetails
                    user={selectedUser}
                    onFormChange={this.onFormChange('profile')}
                    readOnly={isPending || readOnly}
                    userErrors={userErrors}
                    setUserErrors={setUserErrors}
                />

                {
                    !isPending &&
                    <React.Fragment>
                        <UserLoyalty
                            userLoyalty={userLoyalty}
                            readOnly={readOnly}
                            refreshLoyalty={refreshLoyalty}
                        />

                        <UserAddresses
                            user={selectedUser}
                            onFormAddressChange={this.onFormAddressChange('addresses')}
                            readOnly={readOnly}
                        />

                        <MarketingPreferences
                            user={selectedUser}
                            locations={locations}
                            onFormPreferencesChange={this.onFormPreferencesChange('preferences')}
                            readOnly={readOnly}
                        />

                        <UserVouchers
                            vouchers={userVouchers}
                            readOnly={readOnly}
                        />

                        <UserMeta
                            user={selectedUser}
                            readOnly={readOnly}
                        />

                        <OrderHistory
                            orders={userOrders}
                            readOnly={readOnly}
                        />
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

UserDetails.propTypes = {
    classes: PropTypes.object.isRequired,

    selectedUser: PropTypes.object.isRequired,
    userVouchers: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,

    userLoyalty: PropTypes.object,
    userOrders: PropTypes.array,

    getUser: PropTypes.func.isRequired,
    getVouchers: PropTypes.func.isRequired,
    getLoyalty: PropTypes.func.isRequired,
    getOrders: PropTypes.func.isRequired,
    getLocations: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    archiveUser: PropTypes.func.isRequired,
    restoreUser: PropTypes.func.isRequired,
    getUserPermissions: PropTypes.func.isRequired,
    resendEmailVerification: PropTypes.func.isRequired,

    openModal: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired,

    readOnly: PropTypes.bool.isRequired,
};

UserDetails.defaultProps = {
    userLoyalty: {},
    userOrders: [],
}

const mapStateToProps = state => ({
    selectedUser: selectUser(state),
    userVouchers: selectUserVouchers(state),
    userLoyalty: selectUserLoyalty(state),
    userOrders: selectUserOrders(state),
    locations: selectLocations(state),
    permissions: selectPermissions(state),
    userErrors: selectUsersErrors(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getUser: (userUuid) => ({ type: userTypes.GET_USER, userUuid }),
    getVouchers: (userUuid) => ({ type: promotionTypes.GET_USERS_VOUCHERS, userUuid }),
    getLoyalty: (userUuid) => ({ type: loyaltyTypes.GET_USERS_LOYALTY, userUuid }),
    refreshLoyalty: (userUuid) => ({ type: loyaltyTypes.REFRESH_USERS_LOYALTY, userUuid }),
    getOrders: (userUuid) => ({ type: orderTypes.GET_USERS_ORDERS, userUuid }),
    getLocations: () => ({ type: locationTypes.GET_MERLO_LOCATIONS }),
    openModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true }),
    setModalComponent: (component, props) => ({ type: modalTypes.MODAL_SET_COMPONENT, component, props }),
    updateUser: (data) => ({ type: userTypes.UPDATE_USER, data }),
    archiveUser: (uuid) => ({ type: userTypes.ARCHIVE_USER, uuid }),
    forceDeleteUser: (uuid, history) => ({ type: userTypes.FORCE_DELETE_USER, uuid, history }),
    restoreUser: (uuid) => ({ type: userTypes.RESTORE_USER, uuid }),
    getUserPermissions: (uuid) => ({ type: permissionTypes.GET_USER_PERMISSIONS, userUuid: uuid }),
    resendEmailVerification: (email) => ({ type: authTypes.RESEND_EMAIL_VERIFICATION, email }),
    setUserErrors: (errors) => ({ type: userTypes.UPDATE_USER_ERROR, error: errors })
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserDetails)));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';

import { withStyles } from '@material-ui/styles';

import * as types from '@/state/segments/types';
import {
    selectSegmentsLoading,
    selectSegments,
    selectSegmentSaving
} from '@/state/segments/reducers';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import Button from '@/components/Button/Button';
import SearchInput from '@/components/SearchInput/SearchInput';
import Table from '@/components/Table/Table';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['name', 'description']
};

const headerData = [
    // { key: 'uuid', label: 'Segment ID' },
    { 
        key: 'name', 
        label: 'Segment Name',
        sortData: (a, b, order) => {
            if(order === 'desc') {
                return b.name.localeCompare(a.name)
            } 
            return a.name.localeCompare(b.name)
            
        },
    },
    { key: 'description', label: 'Segment Description' }
];

const styles = () => ({
    createSegmentContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
});

class SegmentList extends Component {
    constructor(props) {
        super(props);
        const { getSegments } = props;
        getSegments();
    }

    state = {
        search: '',
        currentPage: 0
    };

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    onChange = type => event => {
        this.setState({
            [type]: event.target.value,
            currentPage: 0,
        });
    };

    searchSegments = segments => {
        const { search } = this.state;
        const fuseInstance = new Fuse(segments, fuseOpts);
        return fuseInstance.search(search);
    };

    onRowClick = rowData => {
        const { history } = this.props;
        const { uuid } = rowData;
        history.push(`/segments/${uuid}`);
    };

    onCreateSegment = () => {
        const { history, clearSelectedSegment } = this.props;
        clearSelectedSegment();
        history.push('/segments/create');
    };

    onDeleteSegment = segment => {
        const { setModalComponent, openModal, deleteSegment } = this.props
        setModalComponent(modals.CONFIRMATION_MODAL, {
            message: `Are you sure you want to delete '${segment.name}'?`,
            confirmMessage: 'Delete',
            saveStateSelector: selectSegmentSaving,
            onConfirm: () => deleteSegment(segment.uuid)
        });
        openModal();
    }

    menuActions = () => {
        const { readOnly, deleteSegment } = this.props;
        const items = [
            { label: 'Download CSV', func: rowData => this.openCsvModal(rowData.uuid, rowData.name) }
        ];
        if (!readOnly) {
            items.push({ label: 'Delete', func: rowData => this.onDeleteSegment(rowData) })
        }

        return items;
    };

    openCsvModal = (segmentUuid, segmentName) => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.SEGMENT_EXPORT_CSV, {
            segmentUuid,
            segmentName
        });
        openModal();
    };

    render() {

        const { search, currentPage } = this.state;
        const { classes, segments, segmentLoadingState, readOnly } = this.props;
        const computedTableData = search.trim().length > 0 ? this.searchSegments(segments) : segments;

        return (
            <React.Fragment>
                {/* Wont be creating segments in this release */}
                <div className={classes.createSegmentContainer}>
                    {
                        !readOnly &&
                        <Button
                            label="+ Create segment"
                            onClick={this.onCreateSegment}
                        />
                    }
                </div>
                <SearchInput
                    placeholder="Search by name or description"
                    value={search}
                    onChange={this.onChange('search')}
                />
                <Table
                    page={currentPage}
                    setPage={this.setPage}
                    headerData={headerData}
                    tableData={computedTableData}
                    showHeader
                    menuActions={this.menuActions()}
                    pagination
                    loading={segmentLoadingState}
                    onRowClick={this.onRowClick}
                />
            </React.Fragment>
        );
    }
}

SegmentList.propTypes = {
    classes: PropTypes.object.isRequired,
    getSegments: PropTypes.func.isRequired,
    deleteSegment: PropTypes.func.isRequired,
    clearSelectedSegment: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    segmentLoadingState: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getSegments: () => ({ type: types.GET_SEGMENTS }),
    clearSelectedSegment: () => ({ type: types.SET_SELECTED_SEGMENT, segment: {} }),
    deleteSegment: uuid => ({ type: types.DELETE_SEGMENT, uuid }),
    openModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true }),
    setModalComponent: (component, props) => ({ type: modalTypes.MODAL_SET_COMPONENT, component, props }),
    closeModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
}, dispatch);


const mapStateToProps = state => ({
    segments: selectSegments(state),
    segmentLoadingState: selectSegmentsLoading(state)
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(SegmentList))
);

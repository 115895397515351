import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import Delete from '@material-ui/icons/Close';
import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import ImagePicker from '@/components/ImagePicker/ImagePicker';

import { selectLocationsLoading } from '@/state/locations/reducers';

const styles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    // imageContainer: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     width: 445,
    //     height: 250,
    //     backgroundColor: '#dadada',
    //     marginBottom: 10
    // },
    icon: {
        color: '#bababa'
    },
    deleteIcon: {
        position: 'absolute',
        left: 5,
        top: 5,
        fill: '#ff0000',
        cursor: 'pointer',
        backgroundColor: '#eaeaea',
        borderRadius: '50%'
    },
    imageContainer: {
        margin: 10,
        position: 'relative'
    },
});

const LocationImage = props => {
    const { location, onChange, readOnly } = props;

    const [imageState, setImageState] = React.useState({
        src: null,
        isNew: false
    });

    const loadingState = useSelector(state => selectLocationsLoading(state));
    useEffect(() => {
        if (!loadingState) {
            const { image } = location;

            if (image && image.length > 0) {
                const images = image.split('|'); // seems to be an array of images
                setImageState(prevState => ({ ...prevState, src: images[0] }));
            } else {
                setImageState({ ...image });
            }
        }
    }, [loadingState]);

    const classes = styles();

    const onAddImage = imageData => {
        setImageState({ src: imageData, isNew: true });
        onChange('image', { src: imageData, isNew: true } );
    };

    const removeImage = () => {
        setImageState({ src: null });
        onChange('image', { src: null } );
    }

    return (
        <Box className={classes.container}>
            <Loader loading={loadingState}>
                <div
                    className={classes.imageContainer}
                >
                    { readOnly || imageState.src && 
                        <Delete
                            className={classes.deleteIcon}
                            onClick={() =>
                                removeImage()
                            }
                        />
                    }
                    <ImagePlaceholder imageUrl={imageState.src} styles={{ width: '445px', height: '250px', margin: 10 }} />
                </div>
                { !readOnly && <ImagePicker
                    icon={<AddPhotoIcon fontSize="small" />}
                    iconPosition="start"
                    label="Add store image (445x250 px)"
                    style={{
                        fontSize: 14,
                        padding: '8px 5px'
                    }}
                    onImageLoad={onAddImage}
                /> }
            </Loader>
        </Box>
    );
};

LocationImage.propTypes = {
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

LocationImage.defaultProps = {
    readOnly: false
}

export default LocationImage;

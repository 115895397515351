export const flagsForEnv = env => {
    switch (env) {
        case 'development':
            return {
                users: true,
                products: true,
                locations: true,
                orders: true,
                promotions: true,
                mobileApp: true,
                shipping: true,
                segments: true,
                trends: true,
                help: true,
                settings: true,
                options: true,
                diagnostics: true,
            };
        case 'staging':
            return {
                users: true,
                products: true,
                locations: true,
                orders: true,
                promotions: true,
                mobileApp: true,
                shipping: true,
                segments: true,
                trends: true,
                help: true,
                settings: true,
                options: true,
                diagnostics: true,
            };
        case 'ua':
            return {
                users: true,
                products: true,
                locations: true,
                orders: true,
                promotions: true,
                mobileApp: true,
                shipping: true,
                segments: true,
                trends: true,
                help: true,
                settings: true,
                options: true,
                diagnostics: true,
            };
                
        case 'production':
            return {
                users: true,
                products: true,
                locations: true,
                orders: true,
                promotions: true,
                mobileApp: true,
                shipping: true,
                segments: true,
                trends: true,
                help: true,
                settings: true,
                options: true,
                diagnostics: true,
            };

        default:
            return {
                users: true,
                products: true,
                locations: true,
                orders: true,
                promotions: true,
                mobileApp: true,
                shipping: true,
                segments: true,
                trends: true,
                help: true,
                settings: true,
                options: true,
                diagnostics: true,
            };
    }
}


export default flagsForEnv;
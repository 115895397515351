export const AUTH_CALLBACK = 'auth/AUTH_CALLBACK';
export const AUTH_SUCCESS = 'auth/AUTH_SUCCESS';

export const AUTH_LOGOUT = 'auth/LOGOUT';
export const AUTH_GET_USERUUID = 'auth/AUTH_GET_USERUUID';

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'auth/REFRESH_TOKEN_ERROR';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR';

export const RESEND_EMAIL_VERIFICATION = 'auth/RESEND_EMAIL_VERIFICATION';

export const AUTH_LOADING_STATE = 'auth/AUTH_LOADING_STATE';
export const AUTH_CALLBACK_STATE = 'auth/AUTH_CALLBACK_STATE';
export const AUTH_TIMESTAMP = 'auth/AUTH_TIMESTAMP';

export const DOMAIN_ADD = 'auth/DOMAIN_ADD';
export const DOMAIN_ADD_SUCCESS = 'auth/DOMAIN_ADD_SUCCESS';
export const DOMAIN_ADD_ERROR = 'auth/DOMAIN_ADD_ERROR';

export const GET_VERSION_SUCCESS = 'auth/GET_VERSION_SUCCESS';

export const UPDATE_WHITELIST_SUCCESS = 'auth/UPDATE_WHITELIST_SUCCESS';
export const UPDATE_BLACKLIST_SUCCESS = 'auth/UPDATE_BLACKLIST_SUCCESS';
export const GET_DOMAINS_LIST = 'auth/GET_DOMAINS_LIST';
export const ADD_TO_DOMAINS_LIST = 'auth/ADD_TO_DOMAINS_LIST';

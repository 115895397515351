import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';
import { makeStyles } from '@material-ui/styles';

import CheckCircle from '@material-ui/icons/CheckCircle';

import ProductFilter from '@/components/Filters/ProductFilter';
import Table from '@/components/Table/Table';
import Button from '@/components/Button/Button';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';

import {
    selectProductLoading,
    selectCollections
} from '@/state/products/reducers';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'parts', 'categories.title']
};

const statuses = [
    { label: 'Published', value: 'isPublished' },
    { label: 'Featured', value: 'isFeatured' },
    { label: 'On Sale', value: 'isOnSale' },
    { label: 'Shippable', value: 'isShippable' },
    { label: 'Digital', value: 'isDigital' },
    { label: 'Gift Card', value: 'isGiftCard' },
    { label: 'Grind Product', value: 'isGrindProduct' }
];

const styles = makeStyles(theme => ({
    addProductContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    icon: {
        color: '#08cc08'
    }
}));

const makeCollections = data => {
    return data.reduce((acc, collection) => {
        acc.push({ label: collection.title, value: collection.id });
        collection.subCollections.forEach(c => {
            acc.push({
                label: c.title,
                value: c.id
            });
        });
        return acc;
    }, []);
};

const ProductsList = props => {
    const { products, history, readOnly } = props;

    const classes = styles();

    const headerData = [
        /* no more images for products here, they manage them in shopify. might come back one day.
        {
            key: 'image',
            label: '',
            sortable: false,
            compute: (rowData) => {
                const { images } = rowData;
                return images.length > 0 ? images[0].src : null;
            },
            renderer: (data) => <ImagePlaceholder imageUrl={data} styles={{ width: '80px', height: '80px', margin: 0 }} />
        }, */
        { key: 'title', label: 'Title' },
        {
            key: 'collection',
            label: 'collection',
            compute: rowData => {
                const { categories } = rowData;
                return categories;
            },
            renderer: data => (
                <div>
                    {data.map(
                        cat => cat && <div key={cat.id}>{cat.title}</div>
                    )}
                </div>
            )
        },
        {
            key: 'partCode',
            label: 'Part Code',
            compute: rowData => {
                const { parts } = rowData;
                return parts;
            },
            renderer: data => (
                <div>
                    {data.map((part, i) => (
                        <div key={`part-${i}`}>{part}</div>
                    ))}
                </div>
            )
        },
        {
            key: 'isGrindProduct',
            label: 'Grinds',
            compute: rowData => {
                const { isGrindProduct } = rowData;
                return isGrindProduct;
            },
            renderer: data => <div>{data ? 'Yes' : 'No'}</div>
        },
        {
            key: 'isPublished',
            label: 'Published',
            compute: rowData => {
                const { isPublished } = rowData;
                return isPublished;
            },
            renderer: data => (
                <div style={{ paddingLeft: 10 }}>
                    {data && <CheckCircle className={classes.icon} />}
                </div>
            ),
            styles: { align: 'justify' }
        }
    ];

    const [state, setState] = React.useState({
        search: '',
        filteredCollections: [],
        filteredStatuses: []
    });

    const [collectionList, setCollectionList] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const productLoading = useSelector(reduxState =>
        selectProductLoading(reduxState)
    );
    const collections = useSelector(reduxState =>
        selectCollections(reduxState)
    );

    useEffect(() => {
        if (collections.length > 0) {
            const cList = makeCollections(collections);
            setCollectionList(cList);
        }
    }, [collections]);

    const onAddProduct = () => {
        history.push('/products/create');
    };

    const onViewDetails = rowData => {
        const { uuid } = rowData;
        history.push(`/products/${uuid}`);
    };

    const onChange = type => event => {
        setState({ ...state, [type]: event.target.value, });
        setPage(0);
    };

    const removeFilter = filter => {
        const { filteredCollections, filteredStatuses } = state;
        if (filteredStatuses.some(status => status.value === filter)) {
            return setState({
                ...state,
                filteredStatuses: filteredStatuses.filter(
                    status => status.value !== filter
                )
            });
        }
        return setState({
            ...state,
            filteredCollections: filteredCollections.filter(
                col => col.value !== filter
            )
        });
    };

    const clearAllFilters = () => {
        setState({
            search: '',
            filteredCollections: [],
            filteredStatuses: []
        });
    };

    const filterProducts = () => {
        const { filteredCollections, filteredStatuses } = state;
        let results = [...products];

        // filter by status
        if (filteredStatuses.length > 0) {
            results = results.filter(p =>
                filteredStatuses.some(status => p[status.value])
            );
        }

        // filter by collection
        if (filteredCollections.length > 0) {
            results = results.filter(p =>
                filteredCollections.some(collection => {
                    const { categories } = p;
                    return categories.some(c => c.id === collection.value);
                })
            );
        }

        return results;
    };

    const searchProducts = productData => {
        const { search } = state;
        const fuseInstance = new Fuse(productData, fuseOpts);
        return fuseInstance.search(search);
    };

    const onRowClick = rowData => {
        onViewDetails(rowData);
    };

    const { search, filteredCollections, filteredStatuses } = state;

    const filteredTableData = filterProducts(products);
    const computedTableData =
        search.trim().length > 0
            ? searchProducts(filteredTableData)
            : filteredTableData;

    return (
        <React.Fragment>
            <div className={classes.addProductContainer}>
                {!readOnly && (
                    <Button
                        label="+ Add Product"
                        style={{
                            padding: '5px 27px'
                        }}
                        onClick={onAddProduct}
                    />
                )}
            </div>
            <ProductFilter
                onChange={onChange}
                searchFilter={search}
                collections={collectionList}
                filteredCollections={filteredCollections}
                statuses={statuses}
                filteredStatuses={filteredStatuses}
                numOfResults={computedTableData.length}
                removeFilter={removeFilter}
                clearAll={clearAllFilters}
            />
            <Table
                page={page}
                setPage={setPage}
                headerData={headerData}
                tableData={computedTableData}
                showHeader
                pagination
                loading={productLoading}
                onRowClick={onRowClick}
            />
        </React.Fragment>
    );
};

ProductsList.propTypes = {
    products: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,

    readOnly: PropTypes.bool.isRequired
};

export default withRouter(ProductsList);

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

const styles = makeStyles({
    paper: {
        padding: '10px 20px',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14
    },
    inputRoot: {
        marginLeft: 8,
        flex: 1
    },
    input: {
        padding: 0,
        fontSize: 14
    }
});

const SearchInput = props => {
    const classes = styles();
    const { placeholder, onChange, style, value, className } = props;
    const computedRootClass = className ? `${className} ${classes.paper}` : classes.paper;
    return (
        <Paper className={computedRootClass} style={style}>
            <SearchIcon className={classes.icon} />
            <InputBase
                value={value}
                classes={{
                    root: classes.inputRoot,
                    input: classes.input
                }}
                className={classes.input}
                placeholder={placeholder}
                onChange={onChange}
            />
        </Paper>
    );
};

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string
};

SearchInput.defaultProps = {
    placeholder: '',
    style: null,
    value: null,
    className: null
};

export default SearchInput;

/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import { refreshToken } from '@/state/auth/actions';
import { isTokenExpired } from './tokenUtils';
import { setAuthToken } from '@/state/auth/services';

import * as services from '@/state/auth/services';

const isOmittedRoute = (config = {}) => {
    const { url } = config;
    const omittedRoutes = [
        '/v1/auth/token/refresh',
        '/v1/version',
    ];

    return omittedRoutes.indexOf(url) > -1;
}

const requestHandler = async (config) => {
    const _loggedIn = services.getLocalStore('loggedIn');
    const loggedIn = _loggedIn === 'true';
    
    if (loggedIn && !isOmittedRoute(config)) {
        // check token validity 
        let token = localStorage.getItem('idToken');

        if (isTokenExpired(token)) {
            try {
                // console.log('TOKEN HAS EXPIRED - REFRESHING');
                const tokenData = await refreshToken();
                setAuthToken(tokenData);
                const { idToken } = tokenData;
                token = idToken;
            } catch (error) {
                // redirect to login here
                const isProd = process.env.NODE_ENV === 'production';
                const authUrl = isProd ? window.__env__.AUTH_URL : process.env.REACT_APP_AUTH_URL;
                // cheap secure redirect fix
                if (isProd && window.location.protocol === 'http:') {
                    window.location = `${authUrl}?returnUrl=https://${window.location.host}/callback`;
                } else {
                    window.location = `${authUrl}?returnUrl=${window.location.origin}/callback`;
                }
            }
        }

        const _config = { ...config };
        _config.headers.authorization = `Bearer ${token}`;
        return _config;
    }

    return config
}

export default (isProd) => {

    axios.defaults.baseURL = isProd ? window.__env__.BASE_URL : process.env.REACT_APP_BASE_URL;

    // set default headers
    axios.defaults.headers.common['Content-Type'] = 'applicaton/json';

    // set interceptor
    axios.interceptors.request.use(
        async config => requestHandler(config),
        error => Promise.reject(error)
    );
}

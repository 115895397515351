import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';

const styles = makeStyles({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    }
});

const VoucherCodeDetailsForm = props => {
    const classes = styles();
    const { code, loading, onChange } = props;

    const onAnyChange = (type, isNumber) => event => {
        if (type === 'startDate' || type === 'expiryDate') {
            onChange(type, event);
        } else {
            const { value } = event.target;

            // Explicity checking if numeric? Leaving this here for now
            if (isNumber) onChange(type, value.replace(/[^0-9]/gi, ''));
            else onChange(type, value);
        }
    };

    return (
        <Loader loading={loading || code === null}>
            <Grid className={classes.form} container spacing={2}>
                {/* <Grid item xs={12}>
                    <TextInput
                        label="Max Claims per user"
                        value={code.maximumRedemptionsPerUser}
                        onChange={onAnyChange('maximumRedemptionsPerUser', true)}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextInput
                        label="Max Claims"
                        value={code.maximumRedemptions}
                        onChange={onAnyChange('maximumRedemptions', true)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        label="Start Date"
                        value={moment(code.startDate)}
                        onChange={onAnyChange('startDate')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        label="Expiry Date"
                        value={moment(code.expiryDate)}
                        onChange={onAnyChange('expiryDate')}
                    />
                </Grid>
            </Grid>
        </Loader>
    );
};

VoucherCodeDetailsForm.propTypes = {
    code: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

VoucherCodeDetailsForm.defaultProps = {
    loading: false
};

export default VoucherCodeDetailsForm;

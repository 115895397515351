import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

import { selectUserUuid } from '@/state/auth/reducers';
import { selectUser } from '@/state/users/reducers';
import {
    selectPermissions,
    selectPermissionsSaveState
} from '@/state/permissions/reducers';

import * as modalTypes from '@/state/modal/types';
import * as permissionTypes from '@/state/permissions/types';

import Button from '@/components/Button/Button';
import Checkbox from '@/components/Checkbox/Checkbox';
import ScrollTable from '@/components/ScrollTable/ScrollTable';
import SelectInput from '@/components/TextInput/SelectInput';

import {
    permissionSet,
    mapPermissionsToSet,
    mapSetToPermissions
} from '@/constants/permissions';

const styles = makeStyles({
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    nameDisplay: {
        fontSize: '14px',
        marginRight: '4px',
        fontWeight: 'bold'
    },
    emailDisplay: {
        fontSize: '14px',
        fontStyle: 'italic'
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 100,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14
    },
    shortcutLabel: {
        fontSize: '10px',
        color: '#a7a7a7'
    },
    shortcutButtonsHolder: {
        display: 'flex',
        marginLeft: -8,
        marginRight: -8
    },
    shortcutButtons: {
        width: '100%',
        height: '25px',
        marginLeft: '7px',
        marginRight: '7px',
        padding: '0px',
        fontSize: '11px'
    },
    roleDescription: {
        fontSize: '10px',
        color: '5a5a5a'
    }
});

const permissionsDef = [
    {
        key: 'USER_PERMISSION',
        role: 'Edit user permission',
        desc: 'Allows a user to assign permissions to other users.',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'USER',
        role: 'User edit',
        desc:
            'Allows a user to edit all user information on the User Details screen.',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'PRODUCT',
        role: 'Products',
        desc:
            'Allows a user to create and edit products that then display on the website.',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'LOCATION',
        role: 'Locations',
        desc: 'Allows a user to create, edit and remove locations.',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'ORDER',
        role: 'Orders',
        desc:
            'Allows a user to modify some limited parts of an Order (such as Epicor re-submission).',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'PROMOTION',
        role: 'Promotions',
        desc:
            'Allows a user to create and edit vouchers & voucher codes, including assigning codes to users.',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'MOBILE_APP',
        role: 'Mobile App',
        desc:
            'Allows a user to send push notifications, and create and edit offer tiles.',
        showRead: true,
        showEdit: true,
    },
    //    { key: 'SHIPPING_LABEL', role: 'Shipping Labels', desc: 'N/A' }, //no edit capability yet, so no need to show it
    {
        key: 'SEGMENT',
        role: 'Segments',
        desc:
            'Allows a user to create, edit, and remove segments (used in the Promotions and Mobile App sections).',
        showRead: true,
        showEdit: true,
    },
    {
        key: 'BEANDRIVE',
        role: 'Bean drive',
        desc:
            'Allows a user full access to the bean drive portal admin account.',
        showRead: false,
        showEdit: true,
    }
    //    { key: 'TREND', role: 'Trends', desc: 'N/A' }, //no edit capability yet, so no need to show it
];

const roles = [
    { value: 'staff', label: 'Staff' },
    { value: 'customer', label: 'Customer' }
];

const UserPermissions = () => {
    const dispatch = useDispatch();
    const classes = styles();

    const selectedUser = useSelector(state => selectUser(state));
    const saving = useSelector(state => selectPermissionsSaveState(state));

    // Logic for setting read only - a user can have permission to edit users but not permissions!
    // Normal logic doesn't work because this is a modal and not it's own route
    const loggedInUserUuid = useSelector(state => selectUserUuid(state));
    const permissions = useSelector(state => selectPermissions(state));
    const loggedInPermissions = permissions[loggedInUserUuid];
    const hasEditPermission = loggedInPermissions
        ? loggedInPermissions.permissions.some(p => p === 'permission.edit')
        : false;

    const [values, setValues] = React.useState(permissionSet.none);

    const onSelectShortcut = name => () => {
        switch (name) {
            case 'customer':
                setValues(permissionSet.customer);
                break;

            case 'staff':
                setValues(permissionSet.none);
                break;

            case 'none':
                setValues(permissionSet.none);
                break;

            case 'all':
                setValues(permissionSet.all);
                break;

            case 'marketing':
                setValues(permissionSet.marketing);
                break;

            case 'customerService':
                setValues(permissionSet.customerService);
                break;

            default:
                setValues(permissionSet.customer);
                break;
        }
    };

    const handleChange = (name, permission) => event => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                [permission]: event.target.checked
            }
        });
        console.log('permission values changed to...', values);
    };

    const closeModal = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    };

    const onSave = () => {
        const { uuid } = selectedUser;

        const _permissions = mapSetToPermissions(values);
        const _roles = [values.ROLE];

        dispatch({ type: permissionTypes.PERMISSIONS_SAVE_STATE, state: true });
        dispatch({
            type: permissionTypes.UPDATE_USER_PERMISSIONS,
            uuid,
            permissions: _permissions,
            roles: _roles
        });
    };

    const computeTableData = (permissionList, permissionState) => {
        return permissionList.map(permission => {
            return {
                ...permission,
                read: permissionState[permission.key].read,
                write: permissionState[permission.key].write
            };
        });
    };

    const headerData = [
        { width: '25%', label: 'Role', select: data => data.role },
        {
            width: '55%',
            label: 'Role Description',
            render: data => (
                <Typography className={classes.roleDescription}>
                    {data.desc}
                </Typography>
            )
        },
        {
            width: '10%',
            label: 'Read',
            render: data => (
                data.showRead &&
                    <Checkbox
                        disabled={data.key}
                        checked={data.read}
                        onChange={handleChange(data.key, 'read')}
                    />
            )
        },
        {
            width: '10%',
            label: 'Edit',
            render: data => (
                data.showEdit &&
                    <Checkbox
                        disabled={(values.ROLE === 'customer') || !hasEditPermission}
                        checked={data.write}
                        onChange={handleChange(data.key, 'write')}
                    />
            )
            
        }
    ];

    const tableData = computeTableData(permissionsDef, values);

    // component did mount
    useEffect(() => {
        const { accessRoles, uuid } = selectedUser;
        const role = accessRoles[0];

        const _permissions = permissions[uuid];

        const mappedPermissions = mapPermissionsToSet(role, _permissions);

        setValues(mappedPermissions);
    }, [selectedUser, permissions[selectedUser.uuid]]);

    return (
        <React.Fragment>
            <Typography className={classes.title}>Permissions</Typography>
            <div style={{ display: 'flex' }}>
                <Typography className={classes.nameDisplay}>
                    {`${selectedUser.profile.firstName} ${selectedUser.profile.lastName}`}
                </Typography>
                <Typography className={classes.emailDisplay}>
                    ({selectedUser.profile.email})
                </Typography>
            </div>

            <Grid container>
                <Grid item xs={6}>
                    <SelectInput
                        label="Role"
                        value={values.ROLE}
                        options={roles}
                        name="role"
                        disabled={!hasEditPermission}
                        onChange={val => onSelectShortcut(val)()}
                    />
                </Grid>
            </Grid>

            <Typography className={classes.shortcutLabel}>Shortcuts</Typography>

            <div className={classes.shortcutButtonsHolder}>
                <Button
                    disabled={!hasEditPermission}
                    label="All"
                    className={classes.shortcutButtons}
                    onClick={onSelectShortcut('all')}
                />
                <Button
                    disabled={!hasEditPermission}
                    label="None"
                    className={classes.shortcutButtons}
                    onClick={onSelectShortcut('none')}
                />
                <Button
                    disabled={!hasEditPermission}
                    label="Marketing"
                    className={classes.shortcutButtons}
                    onClick={onSelectShortcut('marketing')}
                />
                <Button
                    disabled={!hasEditPermission}
                    label="Customer Service"
                    className={classes.shortcutButtons}
                    onClick={onSelectShortcut('customerService')}
                />
            </div>

            <ScrollTable
                height="300px"
                tableData={tableData}
                headerData={headerData}
            />

            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    onClick={closeModal}
                />
                <Button
                    className={classes.button}
                    label="Save"
                    loading={saving}
                    disabled={saving || !hasEditPermission}
                    onClick={onSave}
                />
            </div>
        </React.Fragment>
    );
};

UserPermissions.propTypes = {};

UserPermissions.defaultProps = {};

export default UserPermissions;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import Button from '@/components/Button/Button';

import NotificationForm from '@/components/MobileApp/NotificationForm';

import { selectSegmentsLoading } from '@/state/segments/reducers';

const styles = makeStyles({
    flexContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center'
    },
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    },
});

const SendPushNotification = props => {
    const classes = styles();
    const {
        onChange,
        onSendNotification,
        internalDescription,
        segmentUuid,
        title,
        body
    } = props;

    const segmentLoading = useSelector(state => selectSegmentsLoading(state));

    const isValid = segmentUuid.trim().length > 0
        && internalDescription.trim().length > 0
        && title.trim().length > 0
        && body.trim().length > 0;

    return (
        <Box
            rootClass={classes.root}
            title="Send push notification"
        >
            <Loader loading={segmentLoading}>
                <NotificationForm
                    internalDescription={internalDescription}
                    segmentUuid={segmentUuid}
                    title={title}
                    body={body}
                    onChange={onChange}
                />
                <div className={classes.flexContainer}>
                    <Button
                        onClick={onSendNotification}
                        label="Send Notification"
                        disabled={!isValid}
                    />
                </div>
            </Loader>
        </Box>
    );
};

SendPushNotification.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSendNotification: PropTypes.func.isRequired,
    internalDescription: PropTypes.string.isRequired,
    segmentUuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
};

export default SendPushNotification;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as types from '@/state/auth/types';

const Callback = props => {
    const { location, history, authSaga } = props;
    const params = new URLSearchParams(location.search);
    const idToken = params.get('idToken');
    const refreshToken = params.get('refreshToken');
    const expiresIn = params.get('expiresIn');

    authSaga({ idToken, refreshToken, expiresIn });

    history.replace('/users'); // this may need to move to the saga to ensure that the localstorage is updated


    return (
        <div>
            callback route
        </div>
    );
};

Callback.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,

    authSaga: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
    authSaga: (tokenData) => ({ type: types.AUTH_CALLBACK, ...tokenData })
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Callback));
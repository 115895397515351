import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import SubHeader from '@/components/SubHeader/SubHeader';
import Button from '@/components/Button/Button';

import SegmentData from '@/components/Segments/SegmentData';
import SegmentRule from '@/components/Segments/SegmentRule';
import SegmentResults from '@/components/Segments/SegmentResults';

import * as segmentTypes from '@/state/segments/types';
import { selectSegmentFields, selectSegmentSaving, selectSelectedSegment } from '@/state/segments/reducers';

const styles = theme => ({
    spacer: {
        marginTop: 60,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0px',
    },
    button: {
        width: 130
    }
});

class SegmentCreate extends Component {
    constructor(props) {
        super(props);
        const { getFields, clearResults } = props;

        clearResults();
        getFields();
    }

    state = {
        segment: {
        },
        rules: {
            constraints: 'all',
            field: '',
            operator: '',
            comparator: '',
        },
        errors: {}
    };

    componentDidUpdate(prevProps, prevState) {
        const { saving, selectedSegment, history } = this.props;
        if (!saving && 'uuid' in selectedSegment) {
            // save complete... redirect -- need to know uuid of new segment
            const { uuid } = selectedSegment;
            history.push(`/segments/${uuid}`);
        }
    }

    onChange = (type, value) => {
        const { segment, errors } = this.state;

        if(errors && Object.prototype.hasOwnProperty.call(errors, type)) {
            delete errors[type];
        }

        this.setState({
            segment: {
                ...segment,
                [type]: value,
                errors
            }
        });
    };

    onRuleChange = (type, value) => {
        const { rules } = this.state;
        this.setState({
            rules: {
                ...rules,
                [type]: value
            }
        })
    }

    onRuleClick = (ruleData) => {
        console.log(ruleData);
    }

    onAddRule = () => {
        const { segment, rules } = this.state;

        if (!('rules' in segment)) {
            segment.rules = [];
        }

        const { constraints, ...rest } = rules;
        const _rules = [...segment.rules];
        _rules.push(rest);

        this.setState({
            segment: {
                ...segment,
                rules: _rules
            }
        });
    }

    onRemoveRule = (uuid) => {
        const { segment } = this.state;
        const _rules = [...segment.rules];

        const rules = _rules
            .filter(r => r.field !== uuid)
            .map((r, i) => {
                return { ...r, id: i }
            });

        this.setState({
            segment: {
                ...segment,
                rules
            }
        });
    }

    onSaveAndRun = () => {
        const { createSegment } = this.props;
        const { segment, rules } = this.state;
        const { constraints } = rules;
        console.log('Save and Run the Segment', segment, constraints);

        if(!segment.name) {
            this.setState({
                errors: {
                    name: 'Name is required'
                }
            })
            return;
        }
        // save rule and execute the segment
        createSegment({ ...segment, constraints });
    };

    render() {
        const { classes, fields, saving, readOnly } = this.props;
        const { segment, rules, errors } = this.state;
        const disabled = Object.keys(segment).length > 0 && 'rules' in segment && segment.rules.length === 0;

        return (
            <React.Fragment>
                <SubHeader showAction={false} right={<div />} />
                <div className={classes.spacer} />

                <SegmentData
                    onChange={this.onChange}
                    segment={segment}
                    readOnly={readOnly}
                    errors={errors}
                />
                <SegmentRule
                    segment={segment}
                    fields={fields}
                    onChange={this.onRuleChange}
                    rules={rules}
                    onAddRule={this.onAddRule}
                    onRemoveRule={this.onRemoveRule}
                    readOnly={readOnly}
                    onRowClick={this.onRuleClick}
                />
                {
                    !readOnly &&
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} disabled={disabled} loading={saving} label='Save & Run' onClick={this.onSaveAndRun} />
                    </div>
                }
                <SegmentResults />
            </React.Fragment>
        );
    }
}

SegmentCreate.propTypes = {
    classes: PropTypes.object.isRequired,
    getFields: PropTypes.func.isRequired,
    createSegment: PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,

    fields: PropTypes.object.isRequired,
    selectedSegment: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,

};

const mapStateToProps = state => ({
    fields: selectSegmentFields(state),
    saving: selectSegmentSaving(state),
    selectedSegment: selectSelectedSegment(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getFields: () => ({ type: segmentTypes.GET_SEGMENT_FIELDS }),
    createSegment: segment => ({ type: segmentTypes.CREATE_SEGMENT, segment }),
    clearResults: () => ({ type: segmentTypes.CLEAR_SEGMENT_RESULTS }),

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SegmentCreate)));

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose;

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(sagaMiddleware),
        reduxDevTools
    )
);

sagaMiddleware.run(rootSaga);

export default store;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { Typography, RadioGroup } from '@material-ui/core';
import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import TextInput from '@/components/TextInput/TextInput';
import CheckBox from '@/components/Checkbox/Checkbox';

import { selectSelectedProduct } from '@/state/products/reducers';

const styles = makeStyles({
    container: {
        textAlign: 'center'
    },
    checkBox: {
        marginRight: 50
    }
});

const ProductMeta = props => {
    const classes = styles();
    const { readOnly, onUpdate, loading, isNew } = props;

    const [metaState, setMetaState] = React.useState({
        tags: '',
        searchTerms: '',
        isShippable: true,
        isDigital: false,
        isGiftCard: false,
        isCardTopUp: false
    });

    const product = useSelector(state => selectSelectedProduct(state));

    if (!isNew) {
        useEffect(() => {
            if (!loading && Object.keys(product).length > 0) {
                let { isShippable } = product;
                let { isDigital } = product;
                if(!isShippable && !isDigital) {
                    isDigital = false;
                    isShippable = true;
                    // set default in parent state
                    onUpdate({ isShippable: true, isDigital: false });
                }

                setMetaState({
                    tags: product.tags.join(),
                    searchTerms: product.searchTerms.join(),
                    isShippable,
                    isDigital,
                    isGiftCard: product.isGiftCard,
                    isCardTopUp: product.isCardTopUp
                });

            }
        }, [loading]);
    }

    const onChange = type => event => {
        setMetaState({ ...metaState, [type]: event.target.value });
        onUpdate({ [type]: event.target.value });
    };

    const onChecked = type => event => {
        if(type === 'isShippable') {
            onUpdate({ isShippable: true, isDigital: false });
            return setMetaState({ ...metaState, isShippable: true, isDigital: false })
        } if (type === 'isDigital') {
            onUpdate({ isShippable: false, isDigital: true });
            return setMetaState({ ...metaState, isShippable: false, isDigital: true })
        }

        setMetaState({ ...metaState, [type]: event.target.checked });
        onUpdate({ [type]: event.target.checked });
    };

    const {
        tags,
        searchTerms,
        isShippable,
        isDigital,
        isGiftCard,
        isCardTopUp
    } = metaState;

    return (
        <Box title="Product meta">
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Tags"
                            readOnly={readOnly}
                            value={tags}
                            helperText="Separate each tag by a comma"
                            onChange={onChange('tags')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Searchable Terms"
                            readOnly={readOnly}
                            value={searchTerms}
                            helperText="Seperate each search term by a comma"
                            onChange={onChange('searchTerms')}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <CheckBox
                            disabled={readOnly}
                            radioStyle
                            checked={isShippable}
                            className={classes.checkBox}
                            label="Physical"
                            onChange={onChecked('isShippable')}
                        />
                        <CheckBox
                            disabled={readOnly}
                            radioStyle
                            checked={isDigital}
                            className={classes.checkBox}
                            label="Digital"
                            onChange={onChecked('isDigital')}
                        />
                        <CheckBox
                            disabled={readOnly}
                            checked={isGiftCard}
                            className={classes.checkBox}
                            label="Digital Gift Card"
                            onChange={onChecked('isGiftCard')}
                        />
                        <CheckBox
                            disabled={readOnly}
                            checked={isCardTopUp}
                            className={classes.checkBox}
                            label="Card Top Up"
                            onChange={onChecked('isCardTopUp')}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductMeta.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductMeta.defaultProps = {
    isNew: false
};

export default ProductMeta;

import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as realtimeTypes from './types';

import * as userActions from '@/state/users/actions'
import * as userTypes from '@/state/users/types';
import { selectUser } from '@/state/users/reducers';

import { selectUserUuid } from '@/state/auth/reducers';
import { selectSelectedProduct } from '@/state/products/reducers';
import { selectOrder } from '@/state/orders/reducers';
import { selectAutomations, selectVouchers } from '@/state/promotions/reducers';
import { selectLocation } from '@/state/locations/reducers';
import { selectSelectedSegment } from '@/state/segments/reducers';

import * as permissionTypes from '@/state/permissions/types';
import * as permissionActions from '@/state/permissions/actions';

import * as productTypes from '@/state/products/types';
import * as productActions from '@/state/products/actions';

import * as partTypes from '@/state/parts/types';
import * as orderTypes from '@/state/orders/types';

import * as locationTypes from '@/state/locations/types';
import * as authTypes from '@/state/auth/types';

import * as promotionTypes from '@/state/promotions/types';

import * as snackTypes from '../snack/types';
import * as modalTypes from '../modal/types';

import * as segmentTypes from '@/state/segments/types';
import * as segmentActions from '@/state/segments/actions';

function* userDeleteSuccess(request) {
    // kick off a reload of user data for this particular user
    const { data, target } = request;
    const { uuid: userUuid } = data;
    const result = yield call(userActions.getUser, { userUuid })
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.ARCHIVE_USER_SUCCESS, user: result })
    }
}

function* userRestoreSuccess(request) {
    const { data, target } = request;
    const { uuid: userUuid } = data;
    const result = yield call(userActions.getUser, { userUuid })
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.RESTORE_USER_SUCCESS, user: result })
    }
}

function* userForceDeleteSuccess(request) {
    const { data, target, history } = request;
    const { uuid: userUuid } = data;
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        history.replace('/users');
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been deleted',
            open: true,
            props: { variant: 'success' }
        });
    }
}

function* userForceDeleteError(request) {
    const { data, target } = request;
    const { uuid: userUuid } = data;
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Could not delete user',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* userUpdateSuccess(request) {
    const { data, target } = request;
    console.info('DEBUG: function*userUpdateSuccess -> data, target', data, target);
    const { uuid: userUuid } = data;
    const result = yield call(userActions.getUser, { userUuid })
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.UPDATE_USER_SUCCESS, user: result })
    }
}

function* userUpdateError(request) {
    const { data, auth } = request;
    const currentUser = yield select(state => selectUserUuid(state));

    if (currentUser === auth) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* permissionsUpdateSuccess(request) {
    const { data, sourceUuid } = request;
    const { uuid: userUuid } = data;

    // The user has been updated - get their info and put into redux
    const result = yield call(userActions.getUser, { userUuid });
    const permissions = yield call(permissionActions.getUserPermissions, userUuid);
    yield put({ type: permissionTypes.UPDATE_USER_PERMISSIONS_SUCCESS, userUuid, permissions });

    // If the current user and source are the same - display success
    const currentUser = yield select(state => selectUserUuid(state));
    const sourceIsCurrentUser = sourceUuid === currentUser;
    if (sourceIsCurrentUser) {
        yield put({ type: permissionTypes.PERMISSIONS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Permissions have been saved successfully',
            open: true,
            props: { variant: 'success' }
        });
    }

    // If source is current user and selected user is still the same - update selected user
    const selectedUser = yield select(state => selectUser(state));
    if (sourceIsCurrentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.UPDATE_USER_SUCCESS, user: result });
    }
}

function* createProductSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    // get categories
    const categoriesResponse = yield call(productActions.getShopifyCategories);
    const categories = categoriesResponse.data;
    yield put({ type: productTypes.GET_SHOPIFY_COLLECTIONS_SUCCESS, collections: categories })

    // perform categories merge on data
    const mergedData = yield call(productActions.populateCategoriesForProducts, [data], categories);

    if (target && target === userUuid) {
        // this should account for updating individual items for a specific user
        yield put({ type: productTypes.CREATE_PRODUCT_SUCCESS_TARGET_UUID, product: mergedData[0] });
        yield put({ type: productTypes.PRODUCT_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Product created successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
    yield put({ type: productTypes.CREATE_PRODUCT_SUCCESS, product: mergedData[0] });

    const { productParts } = data;
    yield put({ type: partTypes.UPDATE_PARTS_LIST, parts: productParts });
}

function* updateProductSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    const selectedProduct = yield select(state => selectSelectedProduct(state));

    // get categories
    const categoriesResponse = yield call(productActions.getShopifyCategories);
    const categories = categoriesResponse.data;
    yield put({ type: productTypes.GET_SHOPIFY_COLLECTIONS_SUCCESS, collections: categories })

    // perform categories merge on data
    const mergedData = yield call(productActions.populateCategoriesForProducts, [data], categories);

    if (target && target === userUuid && selectedProduct.uuid === data.uuid) {
        // this should account for updating individual items for a specific user
        yield put({ type: productTypes.CREATE_PRODUCT_SUCCESS_TARGET_UUID, product: mergedData[0] });
        yield put({ type: productTypes.PRODUCT_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Product updated successfully',
            open: true,
            props: { variant: 'success' }
        });
    }

    yield put({ type: productTypes.UPDATE_PRODUCT_SUCCESS, product: mergedData[0] });

    const { productParts } = data;
    yield put({ type: partTypes.UPDATE_PARTS_LIST, parts: productParts });
}

function* deleteProductSuccess(request) {
    const { data } = request;
    yield put({ type: productTypes.DELETE_PRODUCT_SUCCESS, uuid: data });
    yield put({ type: productTypes.PRODUCT_SAVE_STATE, state: false });
}

function* deleteLocationSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));

    yield put({ type: locationTypes.DELETE_LOCATION_SUCCESS, uuid: data });

    if (target && target === userUuid) {
        yield put({ type: locationTypes.CREATE_LOCATION_SUCCESS_TARGET_UUID, location: data });
        yield put({ type: locationTypes.LOCATION_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Location deleted successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
}

function* updateLocationSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    const location = yield select(state => selectLocation(state));
    if (target && target === userUuid && data.uuid === location.uuid) {
        yield put({ type: locationTypes.CREATE_LOCATION_SUCCESS_TARGET_UUID, location: data });
        yield put({ type: locationTypes.LOCATION_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Location updated successfully',
            open: true,
            props: { variant: 'success' }
        });
    }

    yield put({ type: locationTypes.UPDATE_LOCATION_SUCCESS, location: data });
}

function* updateLocationError(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    if (target && target === userUuid) {
        yield put({ type: locationTypes.LOCATION_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* createLocationSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    if (target && target === userUuid) {
        yield put({ type: locationTypes.CREATE_LOCATION_SUCCESS_TARGET_UUID, location: data });
        yield put({ type: locationTypes.LOCATION_SAVE_STATE, state: false });
    }

    yield put({ type: locationTypes.CREATE_LOCATION_SUCCESS, location: data });
}

function* killUserSession(request) {
    const { target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    if (target && target === userUuid) {
        yield put({ type: authTypes.AUTH_LOGOUT });
    }
}

function* createVoucherSuccess(request) {
    const { data, sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);

    yield put({ type: promotionTypes.CREATE_VOUCHER_SUCCESS, voucher: data });

    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher successfully created',
            open: true,
            props: { variant: 'success' }
        });
    }
};

function* createVoucherError(request) {
    const { sourcedUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher has not been created',
            open: true,
            props: { variant: 'error' }
        });
    }
};

function* createVoucherCodeSuccess(request) {
    const { data, sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);

    // Should we update codes for all users with voucher selected?
    // Will this overwrite any potential changes

    if (loggedInUser === sourceUuid) {
        //   yield put({ type: promotionTypes.CREATE_VOUCHER_CODES_SUCCESS, voucher: data });
        yield put({ type: promotionTypes.SELECT_VOUCHER, uuid: data.uuid, page: 0, pageSize: 10, search: null });
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher code/s successfully generated',
            open: true,
            props: { variant: 'success' }
        });
    }
};

function* createVoucherCodeError(request) {
    const { sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);

    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher codes have not been created',
            open: true,
            props: { variant: 'error' }
        });
    }
};

function* updateVoucherCodeSuccess(request) {
    const { data, sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);

    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.UPDATE_VOUCHER_CODE_SUCCESS, voucher: data });
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher code successfully updated',
            open: true,
            props: { variant: 'success' }
        });
    }
};

function* updateVoucherCodeError(request) {
    const { sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher code has not been updated',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* deleteVoucherCodeSuccess(request) {
    const { data, sourceUuid } = request;

    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: promotionTypes.DELETE_VOUCHER_CODE_SUCCESS, voucher: data });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher code successfully removed',
            open: true,
            props: { variant: 'success' }
        });
    }
};

function* deleteVoucherCodeError(request) {
    const { sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher code has not been deleted',
            open: true,
            props: { variant: 'error' }
        });
    }
};

function* userUpdateProfileSuccess(request) {
    const { data, target } = request;
    const { uuid: userUuid } = data;
    const result = yield call(userActions.getUser, { userUuid });
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.UPDATE_USER_SUCCESS, user: result })
    }
}

function* userUpdatePreferencesSuccess(request) {
    const { data, target } = request;
    const { uuid: userUuid } = data;
    const result = yield call(userActions.getUser, { userUuid });
    const selectedUser = yield select(state => selectUser(state));
    const currentUser = yield select(state => selectUserUuid(state));

    if (target && target === currentUser && selectedUser.uuid === userUuid) {
        yield put({ type: userTypes.UPDATE_USER_SUCCESS, user: result })
    }
}

function* createOfferSuccess(request) {
    const { data } = request;
    yield put({ type: promotionTypes.CREATE_OFFER_SUCCESS, offer: data });
    yield put({ type: promotionTypes.OFFERS_SAVE_STATE, state: false });
}

function* updateOfferSuccess(request) {
    const { data } = request;
    yield put({ type: promotionTypes.UPDATE_OFFER_SUCCESS, offer: data });
    yield put({ type: promotionTypes.OFFERS_SAVE_STATE, state: false });
}

function* deleteOfferSuccess(request) {
    const { data } = request;
    yield put({ type: promotionTypes.DELETE_OFFER_SUCCESS, uuid: data.uuid });
    yield put({ type: promotionTypes.OFFERS_SAVE_STATE, state: false });
}

function* segmentCreateSuccess(request) {
    const { data, target } = request;

    const userUuid = yield select(state => selectUserUuid(state));

    const selectedSegment = yield select(selectSelectedSegment);

    // this stops uiloopback replacement if a user is creating and updating a segment on different pcs/tabs
    if (target && target === userUuid && Object.keys(selectedSegment).length === 0) {
        yield put({ type: segmentTypes.CREATE_SEGMENT_SUCCESS_TARGET_UUID, segment: data })
        yield put({ type: segmentTypes.CREATE_SEGMENT_SUCCESS, segment: data });
        yield put({ type: segmentTypes.SEGMENT_SAVING_STATE, state: false });
    }
}

function* segmentUpdateSuccess(request) {
    const { data, target } = request;

    const userUuid = yield select(state => selectUserUuid(state));
    const selectedSegment = yield select(selectSelectedSegment);

    if (target && target === userUuid && selectedSegment === data.uuid) {
        yield put({ type: segmentTypes.UPDATE_SEGMENT_SUCCESS_TARGET_UUID, segment: data });
        // execute the segment if it is the segment currnetly showing
        yield put({ type: segmentTypes.GET_SEGMENT_USERS, segmentUuid: data.uuid });
    }
    yield put({ type: segmentTypes.UPDATE_SEGMENT_SUCCESS, segment: data });
    yield put({ type: segmentTypes.SEGMENT_SAVING_STATE, state: false });
}

function* segmentUpdateError(request) {
    const { data, target } = request;

    const userUuid = yield select(state => selectUserUuid(state));
    const selectedSegment = yield select(selectSelectedSegment);

    if (target && target === userUuid && selectedSegment.uuid === data.uuid) {
        const content = data.errmsg || 'Error saving segment';
        yield put({
            type: snackTypes.SET_SNACK,
            content,
            open: true,
            props: { variant: 'error' }
        });
    }
    yield put({ type: segmentTypes.SEGMENT_SAVING_STATE, state: false });
}

function* segmentCreateError(request) {
    const { data, target } = request;

    const userUuid = yield select(state => selectUserUuid(state));
    const selectedSegment = yield select(selectSelectedSegment);

    if (target && target === userUuid && selectedSegment.uuid === data.uuid) {
        const content = data.errmsg || 'Error saving segment';
        yield put({
            type: snackTypes.SET_SNACK,
            content,
            open: true,
            props: { variant: 'error' }
        });
    }
    yield put({ type: segmentTypes.SEGMENT_SAVING_STATE, state: false });
}

function* deleteSegmentSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));

    if (target && target === userUuid) {
        yield put({ type: segmentTypes.SEGMENT_SAVING_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Segment has been successfully deleted',
            open: true,
            props: { variant: 'success' }
        });
    }

    yield put({ type: segmentTypes.DELETE_SEGMENT_SUCCESS, segment: data });
}

function* epicorSettingUpdateSuccess(request) {
    const { data } = request;
    yield put({ type: orderTypes.UPDATE_EPICOR_SETTING_SUCCESS, data });
    yield put({ type: orderTypes.EPICOR_SETTING_LOADING_STATE, state: false });
}

function* epicorSettingUpdateError(request) {
    yield put({ type: orderTypes.GET_EPICOR_SETTING });

    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === request.sourceUuid) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong',
            open: true,
            props: { variant: 'error' }
        });
    }

}

function* updateOrderSuccess(request) {
    const { data, target } = request;
    const userUuid = yield select(state => selectUserUuid(state));
    const selectedOrder = yield select(state => selectOrder(state));

    if (target && target === userUuid && selectedOrder.orderId === data.orderId) {
        // this should account for updating individual items for a specific user
        yield put({ type: orderTypes.UPDATE_ORDER_SUCCESS_TARGET_UUID, order: data });
        yield put({ type: orderTypes.ORDERS_LOADING_STATE, state: false });
    }

    yield put({ type: orderTypes.UPDATE_ORDER_SUCCESS, order: data });
}

function* updateVoucherSuccess(request) {
    const { data } = request;
    const { _id, __v, ...rest } = data;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === request.sourceUuid) {
        yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
        yield put({ type: promotionTypes.UPDATE_VOUCHER_SUCCESS_TARGET_UUID, voucher: rest });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Voucher updated successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
    const vouchers = yield select(selectVouchers);
    const updated = vouchers.map(voucher => {
        if (voucher.uuid === rest.uuid) {
            return rest;
        }
        return voucher;
    });
    yield put({ type: promotionTypes.UPDATE_VOUCHER_SUCCESS, vouchers: updated });
    yield put({ type: promotionTypes.VOUCHERS_SAVE_STATE, state: false });
}

function* createAutomationSuccess(request) {
    const { data } = request;
    const { _id, __v, ...rest } = data;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === request.sourceUuid) {
        yield put({ type: promotionTypes.AUTOMATIONS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Automation created successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
    yield put({ type: promotionTypes.CREATE_AUTOMATION_SUCCESS, automation: rest });
}

function* updateAutomationSuccess(request) {
    const { data, sourceUuid } = request;
    const { _id, __v, ...rest } = data;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.AUTOMATIONS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Automation updated successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
    const automations = yield select(selectAutomations);
    const updated = automations.map(automation => {
        if (automation.uuid === rest.uuid) {
            return rest;
        }
        return automation;
    });
    yield put({ type: promotionTypes.UPDATE_AUTOMATION_SUCCESS, automations: updated });
}

function* deleteAutomationSuccess(request) {
    const { data, sourceUuid } = request;
    const { uuid } = data;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({ type: promotionTypes.AUTOMATIONS_SAVE_STATE, state: false });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Automation removed successfully',
            open: true,
            props: { variant: 'success' }
        });
    }
    yield put({ type: promotionTypes.DELETE_AUTOMATION_SUCCESS, uuid });
}

function* createNotificationSegmentSuccess(request) {
    const { data, sourceUuid } = request;
    yield put({ type: segmentTypes.SEND_PUSH_NOTIFICATION_SUCCESS, notification: data });
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Push notification successfully sent to segment',
            open: true,
            props: { variant: 'success' }
        });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({
            type: segmentTypes.SEGMENT_LOADING_STATE,
            state: false
        });
    }
}

function* domainAddedToListSuccess(request) {
    const { data, sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'List has been successfully updated!',
            open: true,
            props: { variant: 'success' }
        });
        yield put({
            type: authTypes.AUTH_LOADING_STATE,
            state: false
        });
    }
}

function* domainAddedToListError(request) {
    const { data, sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong, list has not been updated',
            open: true,
            props: { variant: 'error' }
        });
        yield put({
            type: authTypes.AUTH_LOADING_STATE,
            state: false
        });
    }
}

function* partUpdateSuccess(request) {
    const { sourceUuid } = request;
    const loggedInUser = yield select(selectUserUuid);
    if (loggedInUser === sourceUuid) {
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Part has been successfully updated!',
            open: true,
            props: { variant: 'success' }
        });
    }
}

function* setHealthCheck(request) {
    yield put({
        type: 'UPDATE_SINGLE_HEALTH_CHECK',
        state: request.data
    })
}

export default [
    takeLatest(realtimeTypes.USER_DELETE_SUCCESS, userDeleteSuccess),
    takeLatest(realtimeTypes.USER_FORCE_DELETE_SUCCESS, userForceDeleteSuccess),
    takeLatest(realtimeTypes.USER_FORCE_DELETE_ERROR, userForceDeleteError),
    takeLatest(realtimeTypes.USER_RESTORE_SUCCESS, userRestoreSuccess),
    takeLatest(realtimeTypes.USER_UPDATE_SUCCESS, userUpdateSuccess),
    takeLatest(realtimeTypes.USER_UPDATE_ERROR, userUpdateError),

    takeLatest(realtimeTypes.USER_UPDATE_PROFILE_SUCCESS, userUpdateProfileSuccess),
    takeLatest(realtimeTypes.USER_UPDATE_PREFERENCES_SUCCESS, userUpdatePreferencesSuccess),

    takeLatest(realtimeTypes.PERMISSIONS_UPDATE_SUCCESS, permissionsUpdateSuccess),
    takeLatest(realtimeTypes.PRODUCT_CREATE_SUCCESS, createProductSuccess),
    takeLatest(realtimeTypes.PRODUCT_UPDATE_SUCCESS, updateProductSuccess),
    takeLatest(realtimeTypes.PRODUCT_DELETE_SUCCESS, deleteProductSuccess),
    takeLatest(realtimeTypes.LOCATION_DELETE_SUCCESS, deleteLocationSuccess),
    takeLatest(realtimeTypes.LOCATION_UPDATE_ERROR, updateLocationError),
    takeLatest(realtimeTypes.LOCATION_UPDATE_SUCCESS, updateLocationSuccess),
    takeLatest(realtimeTypes.LOCATION_CREATE_SUCCESS, createLocationSuccess),
    takeLatest(realtimeTypes.AUTHENTICATION_ACCOUNT_DISABLED, killUserSession),

    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CREATE_SUCCESS, createVoucherSuccess),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CREATE_ERROR, createVoucherError),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_CREATE_SUCCESS, createVoucherCodeSuccess),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_CREATE_ERROR, createVoucherCodeError),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_UPDATE_SUCCESS, updateVoucherCodeSuccess),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_ASSIGN_SUCCESS, updateVoucherCodeSuccess),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_UPDATE_ERROR, updateVoucherCodeError),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_ASSIGN_ERROR, updateVoucherCodeError),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_DELETE_SUCCESS, deleteVoucherCodeSuccess),
    takeLatest(realtimeTypes.PROMOTION_VOUCHER_CODE_DELETE_ERROR, deleteVoucherCodeError),

    takeLatest(realtimeTypes.PROMOTION_OFFER_CREATE_SUCCESS, createOfferSuccess),
    takeLatest(realtimeTypes.PROMOTION_OFFER_UPDATE_SUCCESS, updateOfferSuccess),
    takeLatest(realtimeTypes.PROMOTION_OFFER_DELETE_SUCCESS, deleteOfferSuccess),

    takeLatest(realtimeTypes.SEGMENT_CREATE_SUCCESS, segmentCreateSuccess),
    takeLatest(realtimeTypes.SEGMENT_UPDATE_SUCCESS, segmentUpdateSuccess),
    takeLatest(realtimeTypes.SEGMENT_UPDATE_ERROR, segmentUpdateError),
    takeLatest(realtimeTypes.SEGMENT_CREATE_ERROR, segmentCreateError),
    takeLatest(realtimeTypes.SEGMENT_DELETE_SUCCESS, deleteSegmentSuccess),
    takeLatest(realtimeTypes.ERP_UPDATE_SETTING_SUCCESS, epicorSettingUpdateSuccess),
    takeLatest(realtimeTypes.ERP_UPDATE_SETTING_ERROR, epicorSettingUpdateError),
    takeLatest(realtimeTypes.UPDATE_ORDER_SUCCESS, updateOrderSuccess),

    takeLatest(realtimeTypes.PROMOTION_VOUCHER_UPDATE_SUCCESS, updateVoucherSuccess),
    takeLatest(realtimeTypes.PROMOTION_AUTOMATION_CREATE_SUCCESS, createAutomationSuccess),
    takeLatest(realtimeTypes.PROMOTION_AUTOMATION_UPDATE_SUCCESS, updateAutomationSuccess),
    takeLatest(realtimeTypes.PROMOTION_AUTOMATION_DELETE_SUCCESS, deleteAutomationSuccess),
    takeLatest(realtimeTypes.NOTIFICATION_SEGMENT_CREATE_SUCCESS, createNotificationSegmentSuccess),
    takeLatest(realtimeTypes.AUTHENTICATION_DOMAIN_ADD_SUCCESS, domainAddedToListSuccess),
    takeLatest(realtimeTypes.AUTHENTICATION_DOMAIN_ADD_ERROR, domainAddedToListError),

    takeLatest(realtimeTypes.PART_UPDATE_SUCCESS, partUpdateSuccess),
    takeLatest([
        realtimeTypes.PRODUCT_HEALTH_SUCCESS,
        realtimeTypes.PERMISSIONS_HEALTH_SUCCESS,
        realtimeTypes.NOTIFICATION_HEALTH_SUCCESS,
        realtimeTypes.USER_HEALTH_SUCCESS,
        realtimeTypes.SHOPIFY_HEALTH_SUCCESS,
        realtimeTypes.AUTHENTICATION_HEALTH_SUCCESS,
        realtimeTypes.LOCATION_HEALTH_SUCCESS,
        realtimeTypes.PROMOTION_HEALTH_SUCCESS,
        realtimeTypes.LOYALTY_HEALTH_SUCCESS,
        realtimeTypes.ORDER_HEALTH_SUCCESS,
        realtimeTypes.SEGMENT_HEALTH_SUCCESS,
        realtimeTypes.EPICOR_HEALTH_SUCCESS,
    ], setHealthCheck)
];

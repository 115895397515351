export const GET_SEGMENTS = 'segments/GET_SEGMENTS';
export const GET_SEGMENTS_SUCCESS = 'segments/GET_SEGMENTS_SUCCESS';
export const GET_SEGMENTS_ERROR = 'segments/GET_SEGMENTS_ERROR';

export const GET_SEGMENT_DETAILS = 'segments/GET_SEGMENT_DETAILS';
export const GET_SEGMENT_DETAILS_SUCCESS = 'segments/GET_SEGMENT_DETAILS_SUCCESS';
export const GET_SEGMENT_DETAILS_ERROR = 'segments/GET_SEGMENT_DETAILS_ERROR';

export const GET_SEGMENT_USERS = 'segments/GET_SEGMENT_USERS';
export const GET_SEGMENT_USERS_SUCCESS = 'segments/GET_SEGMENT_USERS_SUCCESS';
export const GET_SEGMENT_USERS_ERROR = 'segments/GET_SEGMENT_USERS_ERROR';

export const SEND_PUSH_NOTIFICATION = 'segments/SEND_PUSH_NOTIFICATION';
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'segments/SEND_PUSH_NOTIFICATION_SUCCESS';
export const SEND_PUSH_NOTIFICATION_ERROR = 'segments/SEND_PUSH_NOTIFICATION_ERROR';

export const GET_SEGMENT_PUSH_NOTIFICATION_HISTORY = 'segments/GET_SEGMENT_PUSH_NOTIFICATION_HISTORY';
export const GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_SUCCESS = 'segments/GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_SUCCESS';
export const GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_ERROR = 'segments/GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_ERROR';
export const SEGMENT_HISTORY_LOADING_STATE = 'segments/SEGMENT_HISTORY_LOADING_STATE';

export const GET_SEGMENT_FIELDS = 'segments/GET_SEGMENT_FIELDS';
export const GET_SEGMENT_FIELDS_SUCCESS = 'segments/GET_SEGMENT_FIELDS_SUCCESS';
export const GET_SEGMENT_FIELDS_ERROR = 'segments/GET_SEGMENT_FIELDS_ERROR';

export const CREATE_SEGMENT = 'segments/CREATE_SEGMENT';
export const CREATE_SEGMENT_SUCCESS = 'segments/CREATE_SEGMENT_SUCCESS';
export const CREATE_SEGMENT_SUCCESS_TARGET_UUID = 'segments/CREATE_SEGMENT_SUCCESS_TARGET_UUID';
export const CREATE_SEGMENT_ERROR = 'segments/CREATE_SEGMENT_ERROR';

export const UPDATE_SEGMENT = 'segments/UPDATE_SEGMENT';
export const UPDATE_SEGMENT_SUCCESS = 'segments/UPDATE_SEGMENT_SUCCESS';
export const UPDATE_SEGMENT_SUCCESS_TARGET_UUID = 'segments/UPDATE_SEGMENT_SUCCESS_TARGET_UUID';
export const UPDATE_SEGMENT_ERROR = 'segments/UPDATE_SEGMENT_ERROR';

export const DELETE_SEGMENT = 'segments/DELETE_SEGMENT';
export const DELETE_SEGMENT_ERROR = 'segments/DELETE_SEGMENT_ERROR';
export const DELETE_SEGMENT_SUCCESS = 'segments/DELETE_SEGMENT_SUCCESS';

export const SET_SELECTED_SEGMENT = 'segments/SET_SELECTED_SEGMENT';

export const CLEAR_SEGMENT_RESULTS = 'segments/CLEAR_SEGMENT_RESULTS';

export const SEGMENT_LOADING_STATE = 'segments/SEGMENT_LOADING_STATE';
export const SEGMENT_SAVING_STATE = 'segments/SEGMENT_SAVING_STATE';
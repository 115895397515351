import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = makeStyles(theme => ({
    selectContainer: {
        maxHeight: 295,
        overflowY: 'auto',
        borderRadius: 2,
        border: 'solid 0.5px #d5d5d5',
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f7f7f7 !important'
        },
        '&::-webkit-scrollbar': {
            width: 3
        },
        '&::-webkit-scrollbar-thumb': {
            height: 174,
            borderRadius: 15,
            backgroundColor: '#a5a5a5',
            outline: '1px solid slategrey'
        }
    },
    listRoot: {
        backgroundColor: '#f7f7f7',
    },
    iconRoot: {
        minWidth: 0
    },
    listItemText: {
        fontSize: 14
    },
    checkboxRoot: {
        '& svg': {
            height: 14,
            width: 14
        }
    },
    checkboxChecked: {
        color: `${theme.palette.primary.light} !important`
    },
    subheader: {
        padding: '10px'
    }
}));

const SelectBox = props => {
    const classes = styles();
    const { options, onClick, selected, checkbox, rootStyle, subheader } = props;

    return (
        <div className={classes.selectContainer} style={rootStyle}>
            <List classes={{ root: classes.listRoot, subheader: classes.subheader }} subheader={subheader}>
                {
                    options && options.length > 0 &&
                    options
                        .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
                        .map(option => {
                            return (
                                <ListItem
                                    key={option.value}
                                    role={undefined}
                                    button
                                    onClick={() => onClick(option.value)}
                                >
                                    {
                                        checkbox !== false && (
                                            <ListItemIcon
                                                classes={{ root: classes.iconRoot }}
                                            >
                                                <Checkbox
                                                    edge="start"
                                                    checked={
                                                        selected.indexOf(option.value) !== -1
                                                    }
                                                    tabIndex={-1}
                                                    color="primary"
                                                    classes={{
                                                        root: classes.checkboxRoot,
                                                        checked: classes.checkboxChecked
                                                    }}
                                                />
                                            </ListItemIcon>
                                        )
                                    }
                                    <ListItemText
                                        primary={option.label}
                                        classes={{ primary: classes.listItemText }}
                                    />
                                </ListItem>
                            )
                        })
                }
            </List>
        </div>
    );
};

SelectBox.propTypes = {
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    checkbox: PropTypes.bool,
    onClick: PropTypes.func,
    rootStyle: PropTypes.object,
    subheader: PropTypes.string,
};

SelectBox.defaultProps = {
    checkbox: false,
    rootStyle: null,
    subheader: null,
    onClick: () => { }
};

export default SelectBox;

// Example function for setting state
// onSelectBoxChange = option => {
//   const { boxSelected } = this.state;
//   if (boxSelected.find(selected => selected === option)) {
//       return this.setState({
//           boxSelected: boxSelected.filter(selected => selected !== option)
//       });
//   }
//   return this.setState(prevState => ({
//       boxSelected: [...prevState.boxSelected, option]
//   }));
// };

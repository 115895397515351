import * as types from './types';
import * as orderTypes from '../orders/types';

// state definition
const initialState = {
    userLoyalty: {},
    orderLoyalty: {},
    loading: true
};

const loyaltyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USERS_LOYALTY_SUCCESS:
            return {
                ...state,
                userLoyalty: action.data
            };
        case types.GET_USERS_LOYALTY_ERROR:
            return {
                ...state,
                userLoyalty: {}
            };
        case orderTypes.SUBMIT_TO_GIVEX_SUCCESS:
        case types.GET_ORDER_LOYALTY_SUCCESS:
            return {
                ...state,
                orderLoyalty: action.data
            };
        case orderTypes.MARK_REWARD_AS_MANUALLY_PROCESSED_SUCCESS:
            return {
                ...state,
                orderLoyalty: {
                    ...state.orderLoyalty,
                    manuallyProcessed: true
                }
            };
        case types.LOYALTY_LOADING:
            return {
                ...state,
                loading: action.state
            };
        case types.GET_ORDER_LOYALTY_ERROR:
            return {
                ...state,
                orderLoyalty: {}
            };

        default:
            return state;
    }
};

export default loyaltyReducer;

export const selectUserLoyalty = state => state.loyalty.userLoyalty;
export const selectLoyaltyLoading = state => state.loyalty.loading;
export const selectOrderLoyalty = state => state.loyalty.orderLoyalty;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TabPanel from '@/components/TabPanel/TabPanel';

import ProductsList from '@/containers/ProductsList';
import PartsList from '@/containers/PartsList';

import * as productTypes from '@/state/products/types';
import * as partTypes from '@/state/parts/types';

import { selectProductLoading, selectProducts } from '@/state/products/reducers';
import { selectPartsLoading, selectParts } from '@/state/parts/reducers';

class Products extends Component {
    constructor(props) {
        super(props);

        const { getProducts, getParts } = props;
        getProducts();
        getParts();
    }

    render() {
        const { products, parts, readOnly } = this.props;

        return (
            <React.Fragment>
                <TabPanel value={0}>
                    <ProductsList products={products} readOnly={readOnly} />
                </TabPanel>
                <TabPanel value={1}>
                    <PartsList parts={parts} readOnly={readOnly} />
                </TabPanel>
            </React.Fragment>
        );
    }
}

Products.propTypes = {
    products: PropTypes.array.isRequired,
    parts: PropTypes.array.isRequired,

    getProducts: PropTypes.func.isRequired,
    getParts: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    products: selectProducts(state),
    parts: selectParts(state),
    productLoadingState: selectProductLoading(state),
    partLoadingState: selectPartsLoading(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getProducts: () => ({ type: productTypes.GET_PRODUCTS }),
    getParts: () => ({ type: partTypes.GET_PARTS }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Products);

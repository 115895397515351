// actions
import axios from 'axios';

export const getUsers = async (page, pageSize, search, permissions, status, sortBy, order) => {
    try {
        let queryString = `page=${page}&pageSize=${pageSize}`;
        if(search) {
            queryString += `&search=${encodeURIComponent(search)}`;
        }
        if(permissions) {
            queryString += `&permissions=${permissions}`;
        }
        if(status) {
            queryString += `&status=${status}`;
        }

        if(sortBy && order) {
            queryString += `&sortBy=${sortBy}&order=${order}`
        }

        const result = await axios.get(`/v1/user/all?${queryString}`);

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (request) => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/user/${userUuid}`);

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const updateUser = async (selectedUser, data) => {
    try {
        // form the user update object
        const user = { ...selectedUser };
        // check for addresses
        if ('addresses' in data) {
            const { addresses } = data;
            // ensure that 


            user.profile.addresses = addresses;
        }

        if ('profile' in data) {
            const { profile } = data;
            user.profile = { ...user.profile, ...profile };
        }

        if ('preferences' in data) {
            const { preferences } = data;
            const { birthday, optIn } = preferences;
            // format the object
            const { preferences: _p } = selectedUser;

            // check for notification subkey presence
            const emailA = birthday ? { ..._p.notifications.email, birthdayOffer: birthday === 'email' } : { ..._p.notifications.email };
            const emailB = optIn ? { ...emailA, marketing: optIn.email } : { ..._p.notifications.email, ...emailA };
            const postal = birthday ? { birthdayOffer: birthday === 'postal' } : { ..._p.notifications.postal };
            const grind = preferences.grind ? preferences.grind : user.preferences.grind;

            const payload = {
                ..._p,
                grind,
                notifications: {
                    ..._p.notifications,
                    email: emailB,
                    postal,
                    sms:emailB, // sms now uses the same acceptance as email
                }
            }

            user.preferences = { ...user.preferences, ...payload };
        }

        return user;

    } catch (error) {
        throw error;
    }
}

export const updateUserAddresses = async (selectedUser, data) => {
    try {
        // form the user update object
        const user = { ...selectedUser };
        // merge address data
        const { address, addressIndex } = data;
        // handle the update of defaults ...
        if (address !== null) {
            if (addressIndex !== undefined) {
                user.profile.addresses.splice(addressIndex, 1, address);
            } else {
                user.profile.addresses.push(address);
            }

            const { isDefault } = address;
            // now validate the defaults
            const result = user.profile.addresses.map((a, i) => {
                if (i !== addressIndex) {
                    // check default values
                    const _a = { ...a };
                    _a.isDefault = isDefault === _a.isDefault ? false : _a.isDefault;
                    return _a;
                }
                return a;
            });

            user.profile.addresses = result;

        } else {
            // remove address instead
            user.profile.addresses.splice(addressIndex, 1);
        }

        return user;

    } catch (error) {
        throw error;
    }
}

export const upsertUser = (userList, user) => {
    const _userList = [...userList];
    const idx = _userList.map(x => x.uuid).indexOf(user.uuid);
    if (idx > -1) {
        // update in place
        _userList.splice(idx, 1, user);
    } else {
        // insert
        _userList.push(user);
    }

    return _userList;
}

export const sendAppLink = async (request) => {
    const { phoneNumber, uuid } = request;
    try {
        const result = await axios.post('/v1/notifications/sms/app-link', { phoneNumber, uuid });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const SMSLogs = async (request) => {
    const { uuid } = request;
    try {
        const result = await axios.get('/v1/notifications/logs', { params: { userUuid: uuid, type: 'SMS_APP_LINK', order: -1 } });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const deletePendingUsers = async () => {
    try {
        const result = await axios.delete('/v1/user/scripts/delete-pending');

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const getLogs = async () => {
    try {
        const result = await axios.get('/v1/user/scripts/logs');

        return result.data;
    } catch (error) {
        throw error;
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@/components/Button/Button';

import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import TextInput from '@/components/TextInput/TextInput';

import { selectLoyaltyLoading } from '@/state/loyalty/reducers';
import { selectUser, selectUserLoading } from '@/state/users/reducers';

import Fom3D from '@/assets/images/fom-card-3d.png';

const styles = makeStyles(theme => ({
    qrCode: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fomCard: {
        position: 'relative',
        top: -15,
        height: 150,
        width: 150,
    }
}));

const UserLoyalty = props => {
    const { userLoyalty, readOnly, refreshLoyalty } = props;
    const classes = styles();
    const loyaltyLoadingState = useSelector(state => selectLoyaltyLoading(state));
    const userLoadingState = useSelector(state => selectUserLoading(state));
    const selectedUser = useSelector(state => selectUser(state));

    const loadingState = loyaltyLoadingState || userLoadingState;

    return (
        <Box title='Friend of Merlo'>
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={8}>
                        <Grid item xs={12}><TextInput label='FOM #' value={userLoyalty.fom} readOnly={readOnly} /></Grid>
                        <Grid item xs={6}><TextInput label='Current points' value={userLoyalty.pointsBalance} readOnly={readOnly} /></Grid>
                        <Grid item xs={6}><TextInput label='Current bean dollars' startAdornment='$ ' value={userLoyalty.currencyBalance} readOnly={readOnly} /></Grid>
                    </Grid>
                    <Grid item container xs={4} className={classes.qrCode}>
                        <img alt='fom-3d' src={Fom3D} className={classes.fomCard} />
                    </Grid>

                    <Grid item xs={12}>
                        <center><em>To do a card swap or edit the user`s loyalty points and bean dollars, please use Givex.</em></center>
                    </Grid>
                    <Grid item xs={4}>
                        <Button label='Refresh Friend of Merlo Details' onClick={() => refreshLoyalty(selectedUser.uuid)} />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

UserLoyalty.propTypes = {
    userLoyalty: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

export default UserLoyalty;
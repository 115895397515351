import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import Button from '@/components/Button/Button';

import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    flexContainer: {
        paddingTop: 16,
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    },
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center',
        marginTop: 25
    },
    actionButton: {
        width: 140,
        marginRight: 12
    }
});

// Dave's magnificent custom hook
// essentially prevState/prevProps in a pure component
const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

const ConfirmationModal = props => {
    const classes = styles();
    const dispatch = useDispatch();

    const {
        title,
        message,
        onConfirm,
        confirmButtonLabel,
        history,
        routeTo,
        saveStateSelector
    } = props;

    const closeModal = () => {
        dispatch({
            type: modalTypes.MODAL_SET_OPEN_STATE,
            state: false
        });
    };

    const saveState = useSelector(state => saveStateSelector(state));

    const prevSaving = usePrevious(saveState);

    // If the saving state was previously true and now false, close modal
    if (saveStateSelector !== null && prevSaving && !saveState) {
        closeModal();
        if (routeTo.trim().length > 0) {
            history.push(routeTo);
        }
    }

    return (
        <div className={classes.flexContainer}>
            {title && (
                <Typography className={classes.title}>{title}</Typography>
            )}
            <Typography align="center">{message}</Typography>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    disabled={saveState}
                    onClick={closeModal}
                />
                <Button
                    disabled={saveState}
                    loading={saveState}
                    className={classes.actionButton}
                    label={confirmButtonLabel}
                    onClick={onConfirm}
                />
            </div>
        </div>
    );
};

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    confirmButtonLabel: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    saveStateSelector: PropTypes.func,
    routeTo: PropTypes.string
};

ConfirmationModal.defaultProps = {
    title: '',
    confirmButtonLabel: 'Confirm',
    saveStateSelector: null,
    routeTo: ''
};

export default withRouter(ConfirmationModal);

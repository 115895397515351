import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserLoading } from '@/state/users/reducers';
import { selectUserUuid } from '@/state/auth/reducers';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    chipRow: {
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        fontSize: 14,
        color: '#000000',
        backgroundColor: '#eaeaea',
        textTransform: 'capitalize',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        color: '#077cc6',
        fontSize: 10,
        fontWeight: 'bold',
        cursor: 'pointer',
        userSelect: 'none',
        width: 40,
        height: 20,
    }

});

const PermissionChip = props => {
    const { selectedUser, readOnly } = props;
    const classes = styles();
    const dispatch = useDispatch();

    const loadingState = useSelector(state => selectUserLoading(state));
    const selectedUserUuid = useSelector(state => selectUserUuid(state));
    const { uuid } = selectedUser;

    const onEdit = () => {
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: modals.USER_PERMISSIONS_MODAL, props: {} });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    }

    const isOwnUser = selectedUserUuid === uuid;
    const showEdit = !isOwnUser;

    return (
        <div className={classes.chipRow}>
            {
                loadingState
                    ? <Chip className={classes.chip} label={<CircularProgress size={16} color='primary' />} />
                    : selectedUser.accessRoles.map((role, i) => <Chip key={`permission-chip-${i}`} className={classes.chip} label={role} />)
            }
            {
                showEdit &&
                <div role='presentation' className={classes.link} onClick={onEdit}>edit</div>
            }
        </div>
    )
};

PermissionChip.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    selectedUser: PropTypes.object.isRequired,
};

export default PermissionChip;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import moment from 'moment';

import TabPanel from '@/components/TabPanel/TabPanel';

import OrdersList from './OrdersList';

import { GET_UNSYNCED_GIVEX_ORDERS } from '@/state/orders/types';

const Orders = ({ readOnly }) => {
    const dispatch = useDispatch();

    const [tab, setTab] = useState(0);

    const onChange = index => {
        const cutoverDate =
        process.env.NODE_ENV === 'production'
            ? window.__env__.BEAN_DOLLAR_START_DATE
            : process.env.REACT_APP_BEAN_DOLLAR_START_DATE;
        
        dispatch({
            type: GET_UNSYNCED_GIVEX_ORDERS,
            currentPage: 0,
            pageSize: 100,
            loyaltyNo: true,
            dateTo: moment().unix(),
            dateFrom: moment(cutoverDate).unix()
        });

        setTab(index);
    };

    const epicorView = tab === 0;

    return (
        <>
            <TabPanel value={0} onChange={onChange}>
                <OrdersList readOnly={readOnly} epicorView={epicorView} />
            </TabPanel>
            <TabPanel value={1} onChange={onChange}>
                <OrdersList readOnly={readOnly} />
            </TabPanel>
        </>
    );
};

Orders.propTypes = {
    readOnly: PropTypes.bool.isRequired
};

export default Orders;

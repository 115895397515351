import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/styles';
import Button from '@/components/Button/Button';
import { Typography, Divider, IconButton, Grid } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Close from '@material-ui/icons/Close';
import TextInput from '@/components/TextInput/TextInput';
import * as types from '@/state/auth/types';
import { selectAuthLoadingState } from '@/state/auth/reducers';
import Box from '../Box/Box';
import { FixedSizeList } from 'react-window';
import { selectTabIndex } from '@/state/tabs/reducers';

const styles = theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20,
    },
    addDomainContainer: {
        display: 'flex',
        flex: 1,
        margin: 10
    },
    addButton: {
        width: '100%'
    },
    saveButton: {
        textAlign: 'end'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: 130,
        marginBottom: 10
    },
    label: {
        backgroundColor: 'white'
    }
});


class EmailDomainList extends Component {
    constructor(props) {
        super();

        this.state = {
            listInputValue: '',
            loading: true,
            tabIndex: 0,
            error: null,
            hasError: false,
            disabled: false
        }
    }

    onAddToList = () => {
        const { listInputValue } = this.state;
        const { onAdd, listType } = this.props;
        onAdd(listInputValue, listType);
        this.setState({
            listInputValue: ''
        })
    }

    onChange(e) {
        const valueIndex = this.props.list.indexOf(e.target.value);
        if(valueIndex !== -1) {
            return this.setState({
                listInputValue: e.target.value,
                error: 'Domain already exists',
                hasError: true,
                disabled: true
            })
        }

        return this.setState({
            listInputValue: e.target.value,
            error: null,
            hasError: false,
            disabled: false
        })

    }

    onListItemRemove(index) {
        const { listType, onItemRemove } = this.props;
        onItemRemove(index, listType)   
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.onAddToList()
        }
    }
    
    renderRow(props) {
        const { index, style, data, readOnly } = props;

        return (
            <ListItem button key={index} ContainerProps={{ style: style }} ContainerComponent="div">
                <ListItemText primary={data[index]} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.onListItemRemove(index)}>
                        {!readOnly &&
                            <Close />
                        }
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    render() {
        const { readOnly, classes, listType, authLoadingState, list } = this.props;
        const { listInputValue, error, hasError, disabled } = this.state;

        return (
            <React.Fragment>
                <Box title={`Email domain ${listType}`} rootClass={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <TextInput 
                            label='Add domain to list' 
                            value={listInputValue} 
                            onChange={(e) => this.onChange(e)} 
                            onKeyPress={(e) => this._handleKeyDown(e)} 
                            error={hasError}
                            helperText={error}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} style={{"alignSelf": 'center'}}>
                            <Button className={classes.addButton} loading={authLoadingState} label="Add Domain" disabled={listInputValue.length <= 0 || disabled} onClick={this.onAddToList} />
                        </Grid>
                    </Grid>
                    <Divider />
                    <FixedSizeList height={400} width='100%' itemSize={46} itemCount={list ? list.length : 0} itemData={list}>
                        {(props) => this.renderRow({ ...props, readOnly })}
                    </FixedSizeList>
                </Box>
            </React.Fragment>
        );
    }
}

EmailDomainList.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    authLoadingState: selectAuthLoadingState(state),
    tabIndex: selectTabIndex(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addToList: (list, values) => ({ type: types.DOMAIN_ADD, list, values }),
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    )(withStyles(styles)(EmailDomainList))
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


import { selectUserLoading } from '@/state/users/reducers';
import Loader from '@/components/Loader/Loader';

import FBIcon from '@/assets/images/btn-face.svg';
import GoogleIcon from '@/assets/images/btn-google.svg';
import InstaIcon from '@/assets/images/btn-insta.svg';
import AppleIcon from '@/assets/images/btn-apple.svg';
import { toTitleCase } from '@/utils/helpers';
import Datepicker from '../Datepicker/Datepicker';
import TextInput from '../TextInput/TextInput';
import Box from '../Box/Box';

const styles = makeStyles(theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    },
    userStatus: {
        width: 150,
        height: 30,
        // padding: '0px 55px',
        fontSize: 14
    },
    form: {
        marginBottom: theme.spacing(1)
    },
    emailInput: {
        fontSize: 14,
        padding: '8px 10px'
    },
    emailStatus: {
        fontSize: 11,
        fontWeight: 'bold',
        padding: '5px 18px',
        height: 'auto',
        color: '#fff',
        textTransform: 'uppercase',
    },
    emailPending: {
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#f9b145'
    },
    emailVerified: {
        backgroundColor: '#38d08a'
    },
    connectedVia: {
        fontSize: 11,
        color: '#a7a7a7',
        lineHeight: 1.18,
        marginBottom: 10
    },
    icon: {
        userSelect: 'none',
    },

}));



const PersonalDetails = props => {

    const classes = styles();
    const { user, onFormChange, readOnly, userErrors, setUserErrors } = props;

    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        mobileNumber: '',
        birthday: moment(),
        emailVerified: false,
        postcode: '',
        country: ''
    });

    const loadingState = useSelector(state => selectUserLoading(state));
    useEffect(() => {
        if (!loadingState && Object.keys(userErrors).length === 0) {
            const { profile, emailVerified } = user;
            const defaultAddress = profile.addresses.find(a => a.isDefault);

            let { postcode: _postcode, country: _country } = profile;

            if (defaultAddress) {
                _postcode = defaultAddress.postcode
                _country = defaultAddress.country
            }

            setValues({
                ...values,
                firstName: profile.firstName,
                lastName: profile.lastName,
                email: profile.email,
                phoneNumber: profile.phoneNumber,
                mobileNumber: profile.mobileNumber,
                birthday: moment(new Date(profile.birthday)),
                emailVerified: emailVerified.trim().length > 0,
                postcode: _postcode,
                country: toTitleCase(_country)
            })
        }
    }, [loadingState])

    const onValueChange = event => {
        if (!event) {
            return
        }

        if (moment.isMoment(event)) {
            const name = 'birthday';
            const value = event.startOf('day').utc(true).toISOString();
            setValues({ ...values, [name]: event });
            onFormChange({ currentTarget: { name, value } });
            const _errors = userErrors;

            if (!event.isValid()) {
                setUserErrors({
                    ...userErrors,
                    'profile.birthday': 'Invalid Date'
                })
            } else if (event.clone().add(16, 'years').isAfter(moment.now())) {
                setUserErrors({
                    ...userErrors,
                    'profile.birthday': 'Date of birth needs to be at least 16 years of age'
                })
            } else if (_errors['profile.birthday']) {
                delete _errors['profile.birthday'];
                setUserErrors(_errors);
            }
        } else {
            const { target } = event;
            const { name, value } = target;
            const _errors = userErrors;
            if (_errors[`profile.${name}`]) {
                delete _errors[`profile.${name}`];
                setUserErrors(_errors);
            }

            if (name === 'mobileNumber') {
                let countryCode = 'AU';
                if (values.country.toLocaleLowerCase() === 'australia' || !values.country) {
                    countryCode = 'AU';

                } else if (
                    values.country.toLocaleLowerCase() === 'new zealand'
                ) {
                    countryCode = 'NZ';
                }

                const parsedNumber = parsePhoneNumber(value, { regionCode: countryCode });
                const number = parsedNumber.valid
                    ? parsedNumber.number.international.replace(/[^\d+]+/g, '')
                    : value;

                const isValid = (parsedNumber.valid && number.match(/^\+(642|614)/));


                setValues({
                    ...values,
                    mobileNumber: number
                });

                if (!isValid && value) {
                    setUserErrors({
                        ...userErrors,
                        'profile.mobileNumber': `Please enter a valid ${countryCode} mobile number.`
                    })
                }
            } else {
                setValues({ ...values, [name]: value });
            }

            onFormChange(event);
        }
    }

    const isEmailVerified = () => {
        const { emailVerified } = values;
        const label = emailVerified ? 'verified' : 'pending';
        const classBase = classes.emailStatus;
        const className = emailVerified ? `${classBase} ${classes.emailVerified}` : `${classBase} ${classes.emailPending}`;
        return <Chip className={className} label={label} />
    }

    const statusLabel = () => {
        let status = <CircularProgress size={24} />
        if (!loadingState) {
            const { endedDate, emailVerified } = user;
            const active = endedDate.trim().length === 0;
            const pending = active && emailVerified.trim().length === 0;
            const archived = endedDate.trim().length > 0;

            const activeState = active && pending ? 'Pending' : 'Active';
            status = archived ? 'Archived' : activeState;
        }
        return status;
    }

    const getErrors = (field) => {
        const _field = `profile.${field}`
        if (userErrors[_field]) {
            return userErrors[_field];
        }
        return false;
    }

    return (
        <Box
            rootClass={classes.root}
            title="Personal Details"
            toolbarAction={<Chip className={classes.userStatus} label={statusLabel()} />}
        >
            <Loader loading={loadingState}>
                <Grid className={classes.form} container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="First name"
                            value={values.firstName}
                            name="firstName"
                            onChange={onValueChange}
                            readOnly={readOnly}
                            error={!!getErrors('firstName')}
                            helperText={getErrors('firstName') || ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Last name"
                            value={values.lastName}
                            name="lastName"
                            onChange={onValueChange}
                            readOnly={readOnly}
                            error={!!getErrors('lastName')}
                            helperText={getErrors('lastName') || ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Email address"
                            inputRootClass={classes.emailInput}
                            value={values.email}
                            endAdornment={isEmailVerified()}
                            name="email"
                            onChange={onValueChange}
                            readOnly={readOnly}
                            error={!!getErrors('email')}
                            helperText={getErrors('email') ? 'Email must be unique' : ''}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Phone number"
                            value={values.phoneNumber}
                            name="phoneNumber"
                            onChange={onValueChange}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Mobile number"
                            value={values.mobileNumber}
                            name="mobileNumber"
                            onChange={onValueChange}
                            readOnly={readOnly}
                            error={getErrors('mobileNumber')}
                            helperText={getErrors('mobileNumber') ? userErrors['profile.mobileNumber'] : ''}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Datepicker
                            label="Birthday"
                            name="birthday"
                            onChange={onValueChange}
                            value={values.birthday}
                            readOnly={readOnly}
                            maxDate={moment().subtract(16, 'years').toDate()}
                            error={!!getErrors('birthday')}
                            helperText={getErrors('birthday')}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            label="Postcode"
                            name="postcode"
                            value={values.postcode}
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Country"
                            name="country"
                            value={values.country}
                            readOnly
                        />
                    </Grid>

                </Grid>
            </Loader>
        </Box>
    );
};

PersonalDetails.propTypes = {
    user: PropTypes.object,
    onFormChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    userErrors: PropTypes.object
};

PersonalDetails.defaultProps = {
    user: null,
    userErrors: {}
};

export default PersonalDetails;

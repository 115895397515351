
export const GET_PARTS = 'parts/GET_PARTS';
export const GET_PARTS_SUCCESS = 'parts/GET_PARTS_SUCCESS';
export const GET_PARTS_ERROR = 'parts/GET_PARTS_ERROR';

export const GET_PART_DETAIL = 'parts/GET_PART_DETAIL';
export const GET_PART_DETAIL_SUCCESS = 'parts/GET_PART_DETAIL_SUCCESS';
export const GET_PART_DETAIL_ERROR = 'parts/GET_PART_DETAIL_ERROR';

export const UPDATE_PART = 'parts/UPDATE_PART';
export const UPDATE_PART_SUCCESS = 'parts/UPDATE_PART_SUCCESS';
export const UPDATE_PART_ERROR = 'parts/UPDATE_PART_ERROR';

export const UPDATE_PARTS_LIST = 'parts/UPDATE_PARTS';

export const REMOVE_PART_FROM_PRODUCT = 'parts/REMOVE_PART_FROM_PRODUCT';

export const PART_SAVING = 'parts/PARTS_SAVING';
export const PARTS_LOADING_STATE = 'parts/PARTS_LOADING_STATE';
import axios from 'axios';
import { uploadImageToCloud } from '@/state/images/actions';

export const getOffers = async () => {
    try {
        const result = await axios.get(
            '/v1/promotions/offers?page=1&limit=999999999&status=all'
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const formatOfferPayload = async offer => {
    try {
        const { image, ...rest } = offer;
        const payload = {...rest};
        if (payload.text2) delete payload.text2; // old field we removed in 1.23
        if (payload.text3) delete payload.text3; // old field we removed in 1.23
        if (payload.linkText) delete payload.linkText; // old field we removed in 1.23
        if (payload.active) delete payload.active; // calculated field, ignore if present
        if (payload.startAt) delete payload.startAt; // calculated field, ignore if present
        if (payload.endAt) delete payload.endAt; // calculated field, ignore if present

        console.log('offer payload -> ', payload);

        if ('image' in offer) {
            const {
                image: { isNew, src }
            } = offer;
            if (isNew) {
                const result = await uploadImageToCloud(src);
                const { data } = result;
                const { publicUrl } = data;
                payload.imageUrl = publicUrl;
            }
        }

        return payload;
    } catch (error) {
        throw error;
    }
};

export const createOffer = async offer => {
    try {
        // delete some props we don't use anymore or that are calculated and not stored.
        // while we phase out the old app, some of these are intentionally still served from the getOffers endpoint,
        // but the create/update no longer use them.
        const offerPayload = {...offer};
        if (offerPayload.text2) delete offerPayload.text2; // old field we removed in 1.23
        if (offerPayload.text3) delete offerPayload.text3; // old field we removed in 1.23
        if (offerPayload.linkText) delete offerPayload.linkText; // old field we removed in 1.23
        if (offerPayload.active) delete offerPayload.active; // calculated field, ignore if present
        if (offerPayload.startAt) delete offerPayload.startAt; // calculated field, ignore if present
        if (offerPayload.endAt) delete offerPayload.endAt; // calculated field, ignore if present
        const result = await axios.post('/v1/promotions/offers', offerPayload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const updateOffer = async offer => {
    const { uuid } = offer;

    // delete some props we don't use anymore or that are calculated and not stored.
    // while we phase out the old app, some of these are intentionally still served from the getOffers endpoint,
    // but the create/update no longer use them.
    const offerPayload = {...offer};
    if (offerPayload.text2) delete offerPayload.text2; // old field we removed in 1.23
    if (offerPayload.text3) delete offerPayload.text3; // old field we removed in 1.23
    if (offerPayload.linkText) delete offerPayload.linkText; // old field we removed in 1.23
    if (offerPayload.active) delete offerPayload.active; // calculated field, ignore if present
    if (offerPayload.startAt) delete offerPayload.startAt; // calculated field, ignore if present
    if (offerPayload.endAt) delete offerPayload.endAt; // calculated field, ignore if present

    try {
        const result = await axios.put(`/v1/promotions/offers/${uuid}`, offerPayload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const deleteOffer = async uuid => {
    try {
        const result = await axios.delete(`/v1/promotions/offers/${uuid}`);
        return result.data;
    } catch (e) {
        throw e;
    }
};

export const getUserVouchers = async request => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/promotions/vouchers/${userUuid}/all`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getVouchers = async () => {
    try {
        const result = await axios.get(
            '/v1/promotions/vouchers?pageSize=99999'
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getRedemptions = async () => {
    try {
        const result = await axios.get('/v1/promotions/vouchers/redemptions');
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createVoucher = async payload => {
    try {
        const result = await axios.post('/v1/promotions/vouchers/', payload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const updateVoucher = async voucher => {
    const { uuid: voucherUuid } = voucher;
    try {
        const result = await axios.put(
            `/v1/promotions/vouchers/${voucherUuid}`,
            voucher
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createVoucherCodes = async request => {
    try {
        const { voucherUuid, voucher } = request;
        const result = await axios.post(
            `/v1/promotions/vouchers/${voucherUuid}/codes`,
            voucher
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const notifyUserOfCode = async request => {
    try {
        const { voucherUuid, codeUuid } = request;
        const result = await axios.post(
            `/v1/promotions/vouchers/${voucherUuid}/codes/${codeUuid}/notify`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const generatePdfOfCode = async (voucherUuid, codeUuid, title) => {
    try {
        const result = await axios.get(
            `/v1/promotions/vouchers/${voucherUuid}/codes/${codeUuid}/pdf`
        );

        const link = result.data.data.base64Pdf;

        const downloadLink = document.createElement('a');
        const fileName = `${title} - ${codeUuid}.pdf`;

        downloadLink.href = link;
        downloadLink.download = fileName;
        downloadLink.click();
    } catch (error) {
        throw error;
    }
};

export const createVoucherCodesForSegment = async request => {
    try {
        const { voucherUuid, segmentUuid, voucher } = request;
        const result = await axios.post(
            `/v1/promotions/vouchers/${voucherUuid}/segments/${segmentUuid}`,
            voucher
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getVoucherCodes = async request => {
    try {
        const { voucherUuid, page, pageSize, search } = request;

        let url = `/v1/promotions/vouchers/${voucherUuid}/codes?page=${page}&pageSize=${pageSize}`;

        if (search) {
            url += `&search=${search}`
        }

        const result = await axios.get(url);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getAutomations = async () => {
    try {
        const result = await axios.get('/v1/promotions/automations');
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createAutomation = async automation => {
    try {
        const result = await axios.post(
            '/v1/promotions/automations',
            automation
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const updateAutomation = async (uuid, automation) => {
    try {
        const result = await axios.put(
            `/v1/promotions/automations/${uuid}`,
            automation
        );
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const deleteAutomation = async uuid => {
    try {
        const result = await axios.delete(`/v1/promotions/automations/${uuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const updateVoucherCode = async (voucherUuid, payload) => {
    try {
        const { code } = payload;
        const result = await axios.put(
            `/v1/promotions/vouchers/${voucherUuid}/codes/${code}`,
            payload
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const assignVoucherCode = async (voucherUuid, payload) => {
    try {
        const { code } = payload;
        const result = await axios.patch(
            `/v1/promotions/vouchers/${voucherUuid}/codes/${code}/assign`,
            payload
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const deleteVoucherCode = async (voucherUuid, codeUuid) => {
    try {
        const result = await axios.delete(
            `/v1/promotions/vouchers/${voucherUuid}/codes/${codeUuid}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const syncMerloCloudWithKlaviyo = async () => {
    try {
        const result = await axios.get(
            '/v1/promotions/settings/sync'
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

// shopify promotions
export const getShopifyPromotions = async () => {
    try {
        const result = await axios.get(
            '/v1/promotions/shopify/list'
        );
        console.info('DEBUG: getShopifyPromotions -> result', result);
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const updateShopifyPromotion = async (data) => {
    const { uuid, ...rest } = data;
    try {
        const result = await axios.put(`/v1/promotions/shopify/${uuid}`, rest);
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const createShopifyPromotion = async (data) => {
    try {
        const result = await axios.post('/v1/promotions/shopify', {
            ...data
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const deleteShopifyPromotion = async (uuid) => {
    try {
        const result = await axios.delete(`/v1/promotions/shopify/${uuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
}

export default getUserVouchers;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import moment from 'moment';
import Button from '@/components/Button/Button';

const styles = (theme) => ({
    container: {
        position: 'fixed',
        width: 'calc(100% - 200px)',
        left: 200,
        top: 150,
        backgroundColor: '#e6e6e6',
        display: 'flex',
        alignItems: 'center',
        height: 60,
        paddingRight: 15,
        paddingLeft: 15,
        zIndex: 11,

    },
    lastUpdated: {
        display: 'flex',
        fontSize: 11,
        color: '#a7a7a7',
        marginRight: 24,
    },
    lastUpdatedLabel: {
        color: '#5a5a5a',
        fontSize: 11,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        paddingRight: 5
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    left: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
});

const SubHeader = props => {
    const { classes, lastUpdated, onSave, readOnly, disabled, saving, showAction, right, left, buttonLabel } = props;

    return (
        <div className={classes.container}>
            <div className={classes.buttonContainer}>
                {
                    left &&
                    <div className={classes.left}>{left}</div>
                }
                {
                    right
                    || lastUpdated && (
                        <div className={classes.lastUpdated}>
                            <div className={classes.lastUpdatedLabel}>last updated</div>
                            {moment(new Date(lastUpdated)).format('h:mmA DD/MM/YYYY')}
                        </div>
                    )
                }

                {
                    showAction &&
                    <Button
                        label={buttonLabel}
                        style={{
                            width: 85,
                            height: 35,
                            padding: 5
                        }}
                        onClick={onSave}
                        disabled={readOnly || disabled}
                        loading={saving}
                    />
                }
            </div>
        </div>
    );
};

SubHeader.propTypes = {
    onSave: PropTypes.func,
    lastUpdated: PropTypes.string,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    saving: PropTypes.bool,
    showAction: PropTypes.bool,
    left: PropTypes.node,
    right: PropTypes.node,
    buttonLabel: PropTypes.string
};

SubHeader.defaultProps = {
    onSave: () => { },
    lastUpdated: null,
    readOnly: false,
    disabled: false,
    saving: false,
    showAction: true,
    left: null,
    right: null,
    buttonLabel: 'Save'
}

export default withStyles(styles)(SubHeader);

// padding: '5px 27px'
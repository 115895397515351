import * as types from './types';
import * as actions from './actions';

const initialState = {
    users: false,
    products: false,
    locations: false,
    orders: false,
    promotions: false,
    mobileApp: false,
    shipping: false,
    segments: false,
    trends: false,
    help: false,
    settings: false,
    env: 'development'
};

const featureFlagReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FEATURE_FLAG:
            return {
                ...state,
                [action.feature]: action.state,
            };

        case types.SET_FEATURE_ENV:
            return {
                ...actions.flagsForEnv(action.env),
                env: action.env,
            };

        default:
            return state;
    }
}

export default featureFlagReducer;

export const selectFeatureState = state => state.featureFlag;
export const selectEnv = state => state.featureFlag.env;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Arrow from '@material-ui/icons/ArrowDropUp';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';
import Button from '@/components/Button/Button';

import { selectLoyaltyLoading } from '@/state/loyalty/reducers';
import * as types from '@/state/orders/types';
import { selectOrder } from '@/state/orders/reducers';

const useStyles = makeStyles({
    buttonContainer: {
        marginTop: 10,
        display: 'flex'
    },
    notSubmitted: {
        fontSize: 11,
        color: '#a7a7a7'
    },
    button: {
        '&:not(:last-child)': {
            marginRight: 8
        }
    },
    moreDetails: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1
    },
    moreDetailsIcon: {
        transform: ({ moreDetails }) =>
            moreDetails.expanded ? 'rotateZ(0deg)' : 'rotateZ(180deg)'
    },
    moreDetailsContainer: {
        height: ({ moreDetails }) => (moreDetails.expanded ? 600 : 0),
        paddingTop: ({ moreDetails }) => (moreDetails.expanded ? 20 : 0),
        overflow: 'hidden'
    }
});

const LoyaltyDetails = ({
    fom,
    accruedPoints,
    pointsUsed,
    fomCreditSpent,
    readOnly,
    orderDate,
    transactionId,
    processedAt,
    request,
    response,
    manuallyProcessed,
    orderId,
    fomCurrency,
    loyaltySkus
}) => {
    const dispatch = useDispatch();

    const [orderData, setOrderData] = useState({
        fom: '',
        accruedPoints: '',
        pointsUsed: '',
        creditUsed: '',
        transactionId: '',
        processedAt: '',
        fomCurrency: ''
    });

    const [moreDetails, setMoreDetails] = useState({
        expanded: false,
        response: '',
        request: ''
    });

    const loadingState = useSelector(state => selectLoyaltyLoading(state));
    const selectedOrder = useSelector(state => selectOrder(state));

    const { loyaltyRewardsSynced, loyaltyRewardsRejected } = selectedOrder || {};

    const classes = useStyles({ moreDetails });

    useEffect(() => {
        if (!loadingState) {
            setOrderData({
                fom,
                accruedPoints,
                pointsUsed,
                fomCreditSpent,
                transactionId,
                processedAt,
                fomCurrency
            });

            setMoreDetails(d => ({ ...d, response, request }));
        }
    }, [loadingState]);

    const isProd = process.env.NODE_ENV === 'production';
    const cutoverDate = isProd
        ? window.__env__.BEAN_DOLLAR_START_DATE
        : process.env.REACT_APP_BEAN_DOLLAR_START_DATE;

    const beforeLoyaltyChange = moment(orderDate).isBefore(moment(cutoverDate));
    const creditLabel = `${beforeLoyaltyChange ? 'Credit' : 'Bean dollars'} USED in this order`;

    const submitted = loyaltyRewardsSynced || Boolean(orderData.processedAt);

    const onMoreDetails = () => {
        setMoreDetails(d => ({ ...d, expanded: !moreDetails.expanded }));
    };

    const onResubmit = () => {
        dispatch({ type: types.SUBMIT_TO_GIVEX, orderId });
    };

    const onManuallyProcessed = () => {
        dispatch({ type: types.MARK_REWARD_AS_MANUALLY_PROCESSED, orderId });
    };

    let computedTransactionId =
        submitted && !orderData.transactionId ? 'N/A' : orderData.transactionId;

    if (manuallyProcessed) {
        computedTransactionId = 'Manually Processed';
    }

    const computedProcessedDate = orderData.processedAt
        ? moment(orderData.processedAt)
        : '';

    const accruedBeanDollars = orderData.fomCurrency
        ? (Math.round(orderData.fomCurrency * 100) / 100).toFixed(2)
        : '0.00';

    const spentBeanDollars = orderData.fomCreditSpent
        ? (Math.round(orderData.fomCreditSpent * 100) / 100).toFixed(2)
        : '0.00';

    const isGuestOrder = !fom;

    return (
        <Box title="Givex Details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            value={orderData.fom}
                            label="Friends of Merlo card number"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label={creditLabel}
                            value={`$${spentBeanDollars}`}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {!beforeLoyaltyChange && !isGuestOrder && (
                        <Grid item xs={4}>
                            <TextInput
                                value={computedTransactionId}
                                label="Givex transaction ID"
                                readOnly
                            />
                        </Grid>
                    )}
                    {!beforeLoyaltyChange && !isGuestOrder && (
                        <Grid item xs={6}>
                            <Datepicker
                                label="Date of Givex transaction"
                                value={computedProcessedDate}
                                readOnly
                            />
                        </Grid>
                    )}
                    {!beforeLoyaltyChange && (
                        <Grid item xs={6}>
                            <TextInput
                                startAdornment="$"
                                value={accruedBeanDollars}
                                label={`Bean dollars ${isGuestOrder ? 'eligible' : 'EARNED'} from this order`}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )}
                </Grid>

                {beforeLoyaltyChange && (
                    <Typography className={classes.notSubmitted}>
                        This order was made before the switch to Bean Dollar
                        rewards ({cutoverDate}).
                    </Typography>
                )}

                {(!beforeLoyaltyChange && !submitted && !manuallyProcessed && !loyaltyRewardsRejected) && (
                    <Typography className={classes.notSubmitted}>
                        This order has not been submitted.
                    </Typography>
                )}

                <div className={classes.buttonContainer}>
                    {!beforeLoyaltyChange && !isGuestOrder && !loyaltyRewardsRejected && (
                        <>
                            <Button
                                disabled={
                                    readOnly || submitted || manuallyProcessed
                                }
                                label="Resubmit to Givex"
                                onClick={onResubmit}
                                className={classes.button}
                            />
                            <Button
                                disabled={readOnly || submitted}
                                label="Mark as manually processed"
                                onClick={onManuallyProcessed}
                                className={classes.button}
                            />
                        </>
                    )}

                    {!beforeLoyaltyChange && (
                        <>
                            <div
                                role="presentation"
                                className={classes.moreDetails}
                                onClick={onMoreDetails}
                            >
                                View more details{' '}
                                <Arrow className={classes.moreDetailsIcon} />
                            </div>

                        </>
                    )}

                </div>


                <div className={classes.moreDetailsContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                label="Givex Topup Request"
                                value={moreDetails.request}
                                multiline
                                rows={15}
                                rowsMax={15}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label="Givex Topup Response"
                                value={moreDetails.response}
                                multiline
                                rows={15}
                                rowsMax={15}
                            />
                        </Grid>
                    </Grid>
                </div>

            </Loader>
        </Box>
    );
};

LoyaltyDetails.propTypes = {
    fom: PropTypes.string,
    accruedPoints: PropTypes.number,
    pointsUsed: PropTypes.number,
    fomCreditSpent: PropTypes.number,
    readOnly: PropTypes.bool.isRequired,
    orderDate: PropTypes.string,
    transactionId: PropTypes.string,
    processedAt: PropTypes.string,
    request: PropTypes.string,
    response: PropTypes.string,
    manuallyProcessed: PropTypes.bool,
    orderId: PropTypes.string,
    loyaltyRewardsSynced: PropTypes.bool,
    loyaltySkus: PropTypes.array
};

LoyaltyDetails.defaultProps = {
    fom: '',
    accruedPoints: 0,
    pointsUsed: 0,
    fomCreditSpent: 0,
    transactionId: '',
    processedAt: '',
    request: '',
    response: '',
    manuallyProcessed: false,
    orderId: '',
    loyaltyRewardsSynced: false,
    orderDate: '',
    loyaltySkus: []
};

export default LoyaltyDetails;

export const permissionSet = {
    'customer': {
        USER_PERMISSION: { read: false, write: false, },
        USER: { read: false, write: false, },
        PRODUCT: { read: false, write: false, },
        LOCATION: { read: false, write: false, },
        ORDER: { read: false, write: false, },
        PROMOTION: { read: false, write: false, },
        MOBILE_APP: { read: false, write: false, },
        SHIPPING_LABEL: { read: false, write: false, },
        SEGMENT: { read: false, write: false, },
        TREND: { read: false, write: false, },
        BEANDRIVE: { read: false, write: false },
        ROLE: 'customer',
    },
    'all': {
        USER_PERMISSION: { read: true, write: true, },
        USER: { read: true, write: true, },
        PRODUCT: { read: true, write: true, },
        LOCATION: { read: true, write: true, },
        ORDER: { read: true, write: true, },
        PROMOTION: { read: true, write: true, },
        MOBILE_APP: { read: true, write: true, },
        SHIPPING_LABEL: { read: true, write: true, },
        SEGMENT: { read: true, write: true, },
        TREND: { read: true, write: true, },
        BEANDRIVE: { read: true, write: true },
        ROLE: 'staff',
    },
    'none': {
        USER_PERMISSION: { read: true, write: false, },
        USER: { read: true, write: false, },
        PRODUCT: { read: true, write: false, },
        LOCATION: { read: true, write: false, },
        ORDER: { read: true, write: false, },
        PROMOTION: { read: true, write: false, },
        MOBILE_APP: { read: true, write: false, },
        SHIPPING_LABEL: { read: true, write: false, },
        SEGMENT: { read: true, write: false, },
        TREND: { read: true, write: false, },
        BEANDRIVE: { read: true, write: false },
        ROLE: 'staff',
    },
    'marketing': {
        USER_PERMISSION: { read: true, write: true, },
        USER: { read: true, write: false, },
        PRODUCT: { read: true, write: true, },
        LOCATION: { read: true, write: false, },
        ORDER: { read: true, write: true, },
        PROMOTION: { read: true, write: false, },
        MOBILE_APP: { read: true, write: true, },
        SHIPPING_LABEL: { read: true, write: false, },
        SEGMENT: { read: true, write: true, },
        TREND: { read: true, write: false, },
        BEANDRIVE: { read: true, write: false },
        ROLE: 'staff',
    },
    'customerService': {
        USER_PERMISSION: { read: true, write: false, },
        USER: { read: true, write: true, },
        PRODUCT: { read: true, write: false, },
        LOCATION: { read: true, write: true, },
        ORDER: { read: true, write: false, },
        PROMOTION: { read: true, write: true, },
        MOBILE_APP: { read: true, write: false, },
        SHIPPING_LABEL: { read: true, write: true, },
        SEGMENT: { read: true, write: false, },
        TREND: { read: true, write: true, },
        BEANDRIVE: { read: true, write: false },
        ROLE: 'staff',
    }
};

const permissionKeys = {
    'permission.edit': 'USER_PERMISSION',
    'user.edit': 'USER',
    'product.edit': 'PRODUCT',
    'location.edit': 'LOCATION',
    'order.edit': 'ORDER',
    'promotion.edit': 'PROMOTION',
    'notification.edit': 'MOBILE_APP',
    'shipping.edit': 'SHIPPING_LABEL',
    'segment.edit': 'SEGMENT',
    'trend.edit': 'TREND',
    'beandrive.edit': 'BEANDRIVE'
}

export const mapPermissionsToSet = (role, permissionData) => {
    // accept the permissions from api and map to the above format
    // role = staff -> all read is enabled
    // role = customer -> all read and write is disabled
    if (role === 'customer' || !permissionData) return permissionSet.customer;

    // map the permissions and return set object
    const result = {
        USER_PERMISSION: { read: true, write: false, },
        USER: { read: true, write: false, },
        PRODUCT: { read: true, write: false, },
        LOCATION: { read: true, write: false, },
        ORDER: { read: true, write: false, },
        PROMOTION: { read: true, write: false, },
        MOBILE_APP: { read: true, write: false, },
        SHIPPING_LABEL: { read: true, write: false, },
        SEGMENT: { read: true, write: false, },
        TREND: { read: true, write: false, },
        BEANDRIVE: { read: true, write: false },
        ROLE: 'staff',
    }

    const { permissions } = permissionData;

    const partialPermissions = permissions.reduce((acc, permission) => {
        if (permission in permissionKeys) {
            const write = permission.match(/.*\.edit$/gi)
            acc[permissionKeys[permission]] = { read: true, write }
        }
        return acc;
    }, {})
    return { ...result, ...partialPermissions };
}

export const mapSetToPermissions = (permissionsData) => {
    // accept the permissions set and map to the appropriate permissions api format
    const _permissions = [];
    Object.keys(permissionsData).forEach(p => {
        const _p = permissionsData[p];
        if (_p.write) {
            const key = Object.keys(permissionKeys).find(k => permissionKeys[k] === p);
            _permissions.push(key);
        }
    })

    // eslint-disable-next-line no-console
    console.log('mapSetToPermissions -> ', _permissions);

    return _permissions;
}

export default permissionSet;
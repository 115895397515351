import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '@material-ui/core/Switch';

import * as types from '@/state/orders/types';

import {
    selectRewardSetting,
    selectRewardSettingLoading
} from '@/state/orders/reducers';

const AccrueRewardSetting = ({ readOnly }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: types.GET_REWARD_SETTING });
    }, [dispatch]);

    const onChange = () => {
        dispatch({ type: types.UPDATE_REWARD_SETTING, value: enabled });
    };

    const loading = useSelector(state => selectRewardSettingLoading(state));
    const enabled = useSelector(state => selectRewardSetting(state));

    return (
        <Switch
            checked={enabled}
            onChange={onChange}
            color="primary"
            disabled={loading || readOnly}
        />
    );
};

AccrueRewardSetting.propTypes = {
    readOnly: PropTypes.bool.isRequired
};

export default AccrueRewardSetting;

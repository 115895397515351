import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';

const toolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    titleContainer: {
        flex: '0 0 auto'
    },
    title: {
        fontSize: 20,
        lineHeight: 1.2
    },
    actionContainer: {
        flexGrow: 1,
        textAlign: 'end'
    },
    btnMargin: {
        margin: '0px 8px',
        padding: '5px 10px !important'
    }
}));

const TableToolbar = props => {
    const classes = toolbarStyles();
    const { title, menuActions } = props;
    return (
        <Toolbar className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>{title}</Typography>
            </div>
            {menuActions.length > 0 && (
                <div className={classes.actionContainer}>
                    {menuActions.map((action, idx) => (
                        <Button
                            key={idx}
                            className={classes.btnMargin}
                            label={action.label}
                            onClick={action.func}
                        />
                    ))}
                </div>
            )}
        </Toolbar>
    );
};

TableToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    menuActions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            func: PropTypes.func
        })
    )
};

TableToolbar.defaultProps = {
    menuActions: []
};

export default TableToolbar;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';

import TextInput from '@/components/TextInput/TextInput';


const styles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    labelRoot: {
        fontSize: 14,
        lineHeight: '1.3',
        color: theme.palette.label.primary
    },
    containedLabelRoot: {
        margin: 0,
        fontSize: 14,
        lineHeight: '1.3',
        color: theme.palette.label.primary
    },
    inputRoot: {
        fontSize: 14,
        padding: '12px 10px',
        '& fieldset': {
            borderColor: theme.palette.label.primary
        }
    },
    inputEl: {
        padding: 0
    },
    iconButton: {
        outline: '0 !important'
    },
    inputError: {
        color: 'red',
        '& svg': {
            color: 'red'
        }
    },
    inputDisabled: {
        color: `${theme.palette.label.disabled} !important`,
        '& fieldset': {
            borderColor: `${theme.palette.label.disabled} !important`
        }
    },
    inputFocused: {
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    paperRoot: {
        padding: '10px 20px',
        borderRadius: 2
    }
}));

const Datepicker = props => {
    const classes = styles();
    const {
        label,
        style,
        onChange,
        required,
        error,
        value,
        name,
        disableFuture,
        disablePast,
        disabled,
        readOnly,
        variant,
        minDate,
        maxDate,
        placeholder
    } = props;

    const outlinedPicker = (
        <KeyboardDatePicker
            className={classes.root}
            style={style}
            label={label}
            onChange={onChange}
            required={required}
            error={error}
            value={value}
            name={name}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            format="DD/MM/YYYY"
            fullWidth
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot,
                    disabled: classes.inputDisabled
                }
            }}
            inputVariant="outlined"
            InputProps={{
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputEl,
                    error: classes.inputError,
                    disabled: classes.inputDisabled,
                    focused: classes.inputFocused
                }
            }}
            InputAdornmentProps={{
                className: error ? classes.inputError : ''
            }}
            FormHelperTextProps={{
                error,
                classes: {
                    root: classes.inputError
                }
            }}
            KeyboardButtonProps={{
                size: 'small',
                className: classes.iconButton
            }}
            {...props}
        />
    );

    const containedPicker = (
        <React.Fragment>
            <InputLabel className={classes.containedLabelRoot} shrink>
                {label}
            </InputLabel>
            <Paper className={classes.paperRoot}>
                <KeyboardDatePicker
                    style={style}
                    onChange={onChange}
                    required={required}
                    error={error}
                    value={value}
                    name={name}
                    disabled={disabled}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholder={placeholder}
                    format="DD/MM/YYYY"
                    fullWidth
                    InputAdornmentProps={{
                        className: error ? classes.inputError : ''
                    }}
                    KeyboardButtonProps={{
                        size: 'small',
                        className: classes.iconButton
                    }}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
            </Paper>
        </React.Fragment>
    );

    if (readOnly) {
        return (
            <TextInput
                label={label}
                style={style}
                onChange={onChange}
                required={required}
                error={error}
                value={value ? value.format('DD/MM/YYYY') : ''}
                name={name}
                readOnly={readOnly}
                variant={variant}
            />
        );
    }

    return variant === 'outlined' ? outlinedPicker : containedPicker;
};

Datepicker.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
    ]),
    name: PropTypes.string,
    required: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'contained']),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    // minDate: PropTypes.instanceOf(moment),
    // maxDate: PropTypes.instanceOf(moment)
};

Datepicker.defaultProps = {
    label: 'Datepicker',
    style: null,
    error: false,
    value: null,
    name: 'Datepicker',
    required: false,
    disableFuture: false,
    disablePast: false,
    disabled: false,
    readOnly: false,
    onChange: null,
    variant: 'outlined',
    // placeholder: moment().format('DD/MM/YYYY'),
    placeholder: 'DD/MM/YYYY',
};

export default Datepicker;

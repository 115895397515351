import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
    root: {
        margin: `${theme.spacing(1)}px 0px`
    },
    charCountHelper: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.label.primary
    },
    labelRoot: {
        fontSize: 14,
        lineHeight: '1.3',
        color: theme.palette.label.primary,
        backgroundColor: 'white'
    },
    inputBorder: {
        '& fieldset': {
            borderColor: theme.palette.label.primary
        }
    },
    inputRoot: {
        fontSize: 14,
        padding: '12px 10px',
        '& fieldset': {
            borderColor: theme.palette.label.primary
        }
    },
    inputEl: {
        padding: 0
    },
    inputError: {
        color: 'red'
    },
    inputDisabled: {
        color: `${theme.palette.label.disabled} !important`,
        '& fieldset': {
            borderColor: `${theme.palette.label.disabled} !important`
        }
    },
    inputFocused: {
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    valid: {
        color: '#000'
    }
}));

const TextInput = props => {
    const classes = styles();
    const {
        label,
        value,
        required,
        error,
        disabled,
        maxLength,
        showMax,
        helperText,
        onChange,
        multiline,
        rows,
        startAdornment,
        endAdornment,
        inputRootClass,
        name,
        readOnly,
        rowsMax,
        placeholder,
        type,
        inputRef,
        onKeyPress,
        onKeyUp,
        onBlur
    } = props;

    const computedHelper = showMax && maxLength ? `${maxLength - value.length}` : helperText;
    const helperClass = value ? `${classes.valid} ${classes.charCountHelper}` : classes.charCountHelper;
    const computedHelperClass = showMax ? helperClass : '';

    return (
        <TextField
            label={label}
            classes={{
                root: classes.root
            }}
            InputLabelProps={{
                classes: {
                    root: classes.labelRoot,
                    disabled: classes.inputDisabled
                },
                shrink: true,
            }}
            inputRef={inputRef}
            name={name}
            fullWidth
            value={value}
            type={type}
            variant="outlined"
            margin="dense"
            error={error}
            disabled={disabled}
            required={required}
            helperText={computedHelper}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            rowsMax={rowsMax}
            onBlur={onBlur}
            InputProps={{
                classes: {
                    root: inputRootClass
                        ? `${inputRootClass} ${classes.inputBorder}`
                        : `${classes.inputRoot}`,
                    input: classes.inputEl,
                    error: classes.inputError,
                    disabled: classes.inputDisabled,
                    focused: classes.inputFocused,
                },
                readOnly,
                endAdornment,
                startAdornment,
                inputProps: {
                    maxLength,
                },
            }}
            FormHelperTextProps={{
                classes: {
                    root: computedHelperClass
                }
            }}
        />
    );
};




TextInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    showMax: PropTypes.bool,
    helperText: PropTypes.string,
    onChange: PropTypes.func,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    inputRootClass: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    inputRef: PropTypes.object,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func
};

TextInput.defaultProps = {
    label: 'Input Label',
    value: '',
    required: false,
    error: false,
    disabled: false,
    readOnly: false,
    showMax: false,
    helperText: '',
    maxLength: null,
    onChange: null,
    multiline: false,
    rows: 3,
    rowsMax: 3,
    startAdornment: null,
    endAdornment: null,
    inputRootClass: null,
    name: '',
    placeholder: '',
    type: 'text',
    inputRef: null,
    onKeyPress: null,
    onKeyUp: null,
    onBlur: null
};

export default TextInput;

import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';

import SelectInput from '@material-ui/core/Select/SelectInput';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import TextInput from '@/components/TextInput/TextInput';
import Box from '@/components/Box/Box';

import { selectLocationsLoading } from '@/state/locations/reducers';
import Loader from '@/components/Loader/Loader';
import SelectBox from '../SelectBox/SelectBox';

const styles = makeStyles({
    divider: {
        margin: '10px -20px'
    },
    state: {
        width: '100%'
    }
});

const stateOptions = [
    'ACT',
    'NSW',
    'NT',
    'QLD',
    'SA',
    'VIC',
    'WA',
]

const LocationDetails = props => {
    const classes = styles();
    const {
        location,
        onChange,
        onAddressChange,
        google,
        errors,
        readOnly,
        updateLatLng
    } = props;

    const [detailState, setDetailState] = React.useState({
        title: '',
        description: '',
        email: '',
        phone: '',
        website: '',
        fax: '',
        facebook: '',
        instagram: '',
        twitter: '',
        addressFormatted: '',
        address1: '',
        address2: '',
        suburb: '',
        city: '',
        postcode: '',
        state: '',
        country: '',
        // bodyHtml: ''
    });

    const loadingState = useSelector(state => selectLocationsLoading(state));
    useEffect(() => {
        if (!loadingState) {
            const {
                title,
                description,
                email,
                phone,
                website,
                fax,
                addressFormatted,
                links,
                address: {
                    address1,
                    address2,
                    suburb,
                    city,
                    postcode,
                    state,
                    country
                }
            } = location;


            setDetailState({
                title,
                description,
                email,
                phone,
                website,
                fax,
                addressFormatted,
                address1,
                address2,
                suburb,
                city,
                postcode,
                state,
                country,
                ...links
                // bodyHtml
            });
        }
    }, [loadingState]);

    const onFieldChange = type => event => {
        setDetailState({ ...detailState, [type]: event.target.value });
        onChange(type, event.target.value);
    };

    const onAddressChanged = type => event => {
        setDetailState({ ...detailState, [type]: event.target.value });
        if (typeof(onAddressChange) == 'function') onAddressChange(type, event.target.value);
    }
    
    return (
        <Box title="Location Details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Title"
                            value={detailState.title}
                            onChange={onFieldChange('title')}
                            error={Boolean(errors.title)}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Email"
                            value={detailState.email}
                            readOnly={readOnly}
                            onChange={onFieldChange('email')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Phone Number"
                            value={detailState.phone}
                            onChange={onFieldChange('phone')}
                            readOnly={readOnly}
                            error={Boolean(errors.phone)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Website"
                            value={detailState.website}
                            readOnly={readOnly}
                            onChange={onFieldChange('website')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Fax"
                            value={detailState.fax}
                            readOnly={readOnly}
                            onChange={onFieldChange('fax')}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Facebook"
                            value={detailState.facebook}
                            readOnly={readOnly}
                            onChange={onFieldChange('facebook')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Instagram"
                            value={detailState.instagram}
                            readOnly={readOnly}
                            onChange={onFieldChange('instagram')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Twitter"
                            value={detailState.twitter}
                            readOnly={readOnly}
                            onChange={onFieldChange('twitter')}
                        />
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Address 1"
                            value={detailState.address1}
                            readOnly={readOnly}
                            onChange={onAddressChanged('address1')}
                            error={Boolean(errors['address.address1'])}
                            helperText={errors['address.address1'] ? errors['address.address1'][0] : ''}
                            onBlur={updateLatLng}
                        />
                        <TextInput
                            label="Address 2"
                            value={detailState.address2}
                            readOnly={readOnly}
                            onChange={onAddressChanged('address2')}
                            error={Boolean(errors['address.address2'])}
                            helperText={errors['address.address2'] ? errors['address.address2'][0] : ''}
                            onBlur={updateLatLng}
                        />
                        <TextInput
                            label="Suburb"
                            value={detailState.suburb}
                            readOnly={readOnly}
                            onChange={onAddressChanged('suburb')}
                            error={Boolean(errors['address.suburb'])}
                            helperText={errors['address.suburb'] ? errors['address.suburb'][0] : ''}
                            onBlur={updateLatLng}
                        />
                        <TextInput
                            label="City"
                            value={detailState.city}
                            readOnly={readOnly}
                            onChange={onAddressChanged('city')}
                            error={Boolean(errors['address.city'])}
                            helperText={errors['address.city'] ? errors['address.city'][0] : ''}
                            onBlur={updateLatLng}
                        />
                        <TextInput
                            label="Postcode"
                            value={detailState.postcode}
                            readOnly={readOnly}
                            onChange={onAddressChanged('postcode')}
                            error={Boolean(errors['address.postcode'])}
                            helperText={errors['address.postcode'] ? errors['address.postcode'][0] : ''}
                            type="number"
                            onBlur={updateLatLng}
                        />
                        <TextField
                            value={detailState.state}
                            onChange={onAddressChanged('state')}
                            label="State"
                            disabled={readOnly}
                            error={Boolean(errors['address.state'])}
                            helperText={errors['address.state'] ? errors['address.state'][0] : ''}
                            variant="outlined"
                            select
                            className={classes.state}
                            onBlur={updateLatLng}
                        >
                            {stateOptions.map(state => (
                                <MenuItem value={state} key={state}>
                                    <em>{state}</em>
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextInput
                            label="Country"
                            value={detailState.country}
                            readOnly={readOnly}
                            onChange={onAddressChanged('country')}
                            error={Boolean(errors['address.country'])}
                            helperText={errors['address.country'] ? errors['address.country'][0] : ''}
                            onBlur={updateLatLng}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

LocationDetails.propTypes = {
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    updateLatLng: PropTypes.func.isRequired
};

export default LocationDetails;

import * as types from './types';
import * as permissionTypes from '../permissions/types';

import { upsertUser } from './actions';
// import { combineReducers } from 'redux';
// state definition
const initialState = {
    users: [],
    meta: {},
    selectedUser: {},
    loading: true,
    errors: {}
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                meta: action.meta,
                errors: {}
            };

        case types.USERS_LOADING_STATE:
            return {
                ...state,
                loading: action.state
            };

        case types.UPDATE_USER_SUCCESS:
        case types.GET_USER_SUCCESS:
        case types.ARCHIVE_USER_SUCCESS:
        case types.RESTORE_USER_SUCCESS:
        case types.UPDATE_USER_ADDRESS_SUCCESS:
        case types.DELETE_USER_ADDRESS_SUCCESS:
        case types.CREATE_USER_ADDRESS_SUCCESS:
        case permissionTypes.UPDATE_USER_ROLE_SUCCESS:
            return {
                ...state,
                selectedUser: action.user,
                errors: {}
            };

        case types.UPDATE_USER_ERROR:
            return {
                ...state,
                errors: action.error,
            }

        default:
            return state;
    }
};

export default usersReducer

export const selectUsers = state => state.users.users;
export const selectUser = state => state.users.selectedUser;
export const selectUserLoading = state => state.users.loading;
export const selectUserPermissions = state => state.users.userPermissions;
export const selectUsersMeta = state => state.users.meta;
export const selectUsersErrors = state => state.users.errors;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

import {
    selectProductLoading,
    selectSelectedProduct,
    selectProductSaving
} from '@/state/products/reducers';

import * as types from '@/state/products/types';
import * as partTypes from '@/state/parts/types';

import SubHeader from '@/components/SubHeader/SubHeader';
import ProductData from '@/components/ProductDetails/ProductData';
import ProductImages from '@/components/ProductDetails/ProductImages';
import ProductCollection from '@/components/ProductDetails/ProductCollection';
import ProductTastingNotes from '@/components/ProductDetails/ProductTastingNotes';
import ProductGrindOptions from '@/components/ProductDetails/ProductGrindOptions';
import ProductMeta from '@/components/ProductDetails/ProductMeta';
import ProductQuiz from '@/components/ProductDetails/ProductQuiz';
import ProductLinkedParts from '@/components/ProductDetails/ProductLinkedParts';
import ProductViewShopify from '@/components/ProductDetails/ProductViewShopify';
import Button from '@/components/Button/Button';
import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';



const styles = theme => ({
    spacer: {
        marginTop: 60
    },
    radioGroup: {
        flexDirection: 'row'
    },
    divider: {
        marginTop: 15,
        marginBottom: 20,
        marginRight: -20,
        marginLeft: -20
    },
    label: {
        fontSize: 11,
        color: theme.palette.label.primary
    },
    deleteContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

class ProductDetails extends Component {
    constructor(props) {
        super(props);
        const uuid = this.getIdFromRoute(props);
        props.getProduct(uuid);
        props.getParts();
    }

    // Empty state - children will update anything that changes
    state = {
        saveDisabled: true
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { saveDisabled } = this.state;
        const { loading, saving } = this.props;
        // Conditional checks to stop unnecessary re-renders from
        // slowing the page down
        return (
            loading !== nextProps.loading ||
            saving !== nextProps.saving ||
            (saveDisabled && !nextState.saveDisabled)
        );
    }

    getIdFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { uuid } = params;
        return uuid;
    };

    onSave = () => {
        // pass the data to the saga for parsing and uploading
        const uuid = this.getIdFromRoute(this.props);
        const { updateProduct } = this.props;
        const { saveDisabled, ...rest } = this.state;
        updateProduct(rest, uuid);
    };

    onObjectUpdate = type => data => {
        this.setState(prevState => ({
            [type]: {
                ...prevState[type],
                ...data
            },
            saveDisabled: false
        }));
    };

    onDelete = () => {
        // Modal things
        const { setModalComponent, openModal, product } = this.props;
        setModalComponent(modals.DELETE_LOCATION_MODAL, {
            message: `Are you sure you want to delete '${product.title}'?`,
            confirmMessage: 'Delete',
            onConfirm: this.onConfirmDelete,
        });
        openModal();
    };

    onConfirmDelete = () => {
        const { deleteProduct, history } = this.props;
        const uuid = this.getIdFromRoute(this.props);
        deleteProduct(uuid, history);
    };

    onArrayUpdate = type => data => {
        this.setState({
            [type]: [...data],
            saveDisabled: false
        });
    };

    render() {
        const { saveDisabled } = this.state;
        const { classes, readOnly, saving, loading, product } = this.props;

        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSave}
                    saving={saving}
                    lastUpdate={moment().toISOString()}
                    readOnly={readOnly}
                    disabled={saveDisabled || readOnly}
                />
                <div className={classes.spacer} />

                {
                    !readOnly &&
                    <div className={classes.deleteContainer}>
                        <Button
                            label="Delete Product"
                            onClick={this.onDelete}
                            loading={loading}
                        />
                    </div>
                }

                <ProductData
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('details')}
                    loading={loading}
                />
                {
                    // <ProductImages
                    //     readOnly={readOnly}
                    //     onUpdate={this.onArrayUpdate('images')}
                    //     loading={loading}
                    // />
                }
                <ProductCollection
                    readOnly={readOnly}
                    onUpdate={this.onArrayUpdate('categories')}
                    onObjectUpdate={this.onObjectUpdate('details')}
                    loading={loading}
                />
                <ProductTastingNotes
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('tastingNotes')}
                    loading={loading}
                />
                <ProductQuiz
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('quizTags')}
                    loading={loading}
                />
                <ProductGrindOptions
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('grinds')}
                    loading={loading}
                />
                <ProductMeta
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('meta')}
                    loading={loading}
                />
                <ProductLinkedParts
                    readOnly={readOnly}
                    onUpdate={this.onArrayUpdate('parts')}
                    loading={loading}
                />
                {
                    !readOnly &&
                    <ProductViewShopify
                        product={product}
                    />
                }
            </React.Fragment>
        );
    }
}

ProductDetails.propTypes = {
    product: PropTypes.object.isRequired,

    history: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,

    getProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    getParts: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    product: selectSelectedProduct(state),
    saving: selectProductSaving(state),
    loading: selectProductLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProduct: productUuid => ({
                type: types.GET_PRODUCT_DETAIL,
                uuid: productUuid
            }),
            getParts: () => ({ type: partTypes.GET_PARTS }),
            updateProduct: (productData, uuid) => ({
                type: types.UPDATE_PRODUCT,
                productData,
                uuid
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            deleteProduct: (productUuid, history) => ({
                type: types.DELETE_PRODUCT,
                uuid: productUuid,
                history
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ProductDetails));

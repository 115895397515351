import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250
            // width: '20%'
        }
    },
    anchorOrigin: {
        vertical: 65,
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    getContentAnchorEl: null
};

const styles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        boxShadow: '0 2px 4px 0px rgba(0, 0, 0, 0.2)'
    },
    selectRoot: {
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        padding: '20px 50px 20px 17px',
        '&:focus': {
            backgroundColor: `${theme.palette.background.paper} !important`
        }
    },
    inputRootClosed: {
        '& svg': {
            right: 15
        }
    },
    inputRootOpen: {
        '& svg': {
            right: 15,
            transform: 'rotate(180deg)'
        }
    },
    menuItemText: {
        fontSize: 12
    },
    menuItem: {
        backgroundColor: `${theme.palette.background.paper} !important`
    },
    checkboxRoot: {
        '& svg': {
            height: 19,
            width: 19
        }
    },
    checkboxChecked: {
        color: `${theme.palette.primary.light} !important`
    }
}));

const SelectWithCheckbox = props => {
    const classes = styles();
    const [pressed, onPressed] = React.useState();
    const { label, options, selected, onChange, style, className } = props;

    const computedRootClass = className ? `${classes.formControl} ${className}` : classes.formControl;

    const inputRootClass = pressed
        ? classes.inputRootOpen
        : classes.inputRootClosed;

    return (
        <FormControl className={computedRootClass} style={style}>
            <Select
                multiple
                displayEmpty
                disableUnderline
                value={selected}
                onChange={onChange}
                MenuProps={MenuProps}
                onOpen={() => onPressed(!pressed)}
                onClose={() => onPressed(!pressed)}
                classes={{
                    root: classes.selectRoot
                }}
                input={
                    <Input
                        classes={{
                            root: inputRootClass
                        }}
                    />
                }
                renderValue={value => {
                    switch (value.length) {
                        case 0:
                            return <span style={{ color: '#5c5c5c' }}>{label}</span>;
                        case 1:
                        case 2:
                            return value.map(v => v.label).join(', ');
                        default:
                            return `${value[0].label}, ${value[1].label}, ...`;
                    }
                }}
            >
                {
                    options.map((option, i) => (
                        <MenuItem
                            key={`checkbox-select-${i}`}
                            value={option}
                            classes={{
                                root: classes.menuItem
                            }}
                        >
                            <Checkbox
                                classes={{
                                    root: classes.checkboxRoot,
                                    checked: classes.checkboxChecked
                                }}
                                color="primary"
                                checked={selected.map(x => x.value).indexOf(option.value) > -1}
                            />
                            <ListItemText
                                classes={{ primary: classes.menuItemText }}
                                primary={option.label}
                            />
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

SelectWithCheckbox.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    selected: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
};

SelectWithCheckbox.defaultProps = {
    label: 'Select with Checboxes!',
    options: [{ label: 'Option1', value: 'opt1' }, { label: 'Option2', value: 'opt2' }],
    selected: [],
    style: null,
    className: null
};

export default SelectWithCheckbox;

import React from 'react';
import PropTypes from 'prop-types';
import * as snackTypes from '../../state/snack/types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@/components/Button/Button';

const ImagePicker = props => {
    const { label, onImageLoad, setSnack } = props;

    const inputRef = React.createRef();

    const handleFileChange = event => {
        const { files } = event.target;
        if (files && files[0]) {             
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.JPG|\.JPEG|\.PNG|\.GIF)$/i;
            if (!allowedExtensions.exec(files[0].name)) {
                return setSnack('error', 'Image must have a file type of jpg, jpeg, png or gif');
            }

            const reader = new FileReader();

            reader.onloadstart = e => { 
                console.log(e.target);
            }

            reader.onload = e => {
                onImageLoad(e.target.result);
            }

            reader.readAsDataURL(files[0]);
        }
    }

    const handleFileOpen = () => {
        inputRef.current.click();
    }

    return (
        <React.Fragment>
            <input
                ref={inputRef}
                id='image-picker'
                type='file'
                accept='image/*'
                capture='camera'
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Button
                label={label}
                onClick={handleFileOpen}
                {...props}
            />
        </React.Fragment>
    );
};

ImagePicker.propTypes = {
    label: PropTypes.string,
    onImageLoad: PropTypes.func.isRequired,
};

ImagePicker.defaultProps = {
    label: 'Load Image'
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setSnack: (level, message) => ({
                type: snackTypes.SET_SNACK,
                content: message,
                open: true,
                props: { variant: level }
            }),
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(ImagePicker);
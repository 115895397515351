import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import ReactRte from 'react-rte';
import TextInput from '@/components/TextInput/TextInput';
import Loader from '@/components/Loader/Loader';
import Datepicker from '@/components/Datepicker/Datepicker';
import SelectInput from '@/components/TextInput/SelectInput';
import AddSku from '@/components/Vouchers/AddSku';
import Checkbox from '../Checkbox/Checkbox';

const styles = makeStyles({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    },
    divider: {
        width: 'calc(100% + 40px)',
        marginLeft: '-20px'
    },
    textEditor: {
        'font-family': 'inherit',
        'font-size': 'inherit'
    }
});

const VoucherDetailsForm = props => {
    const classes = styles();
    const { voucher, loading, onChange, onAddSku, onRemoveSku, readOnly } = props;

    const onAnyChange = (type, isNumber) => event => {
        // Form can be readonly - don't do anything in this case
        if (onChange === null || onChange === undefined) return;
        if (type === 'deleteExpiredCodes') {
            onChange(type, event.target.checked);
        } else if(type === 'createdAt' || type === 'status') {
            onChange(type, event.target.value);
        } else {
            const { value } = event.target;

            // Explicity checking if numeric? Leaving this here for now
            if (isNumber) onChange(type, value.replace(/[^0-9]/gi, ''));
            else onChange(type, value);
        }
    };

    const showSkus = () => {
        if (voucher.SKU && onAddSku === null) {
            if (voucher.SKU.length > 0) return true;
            return false;
        }
        return true;
    };

    const [rteState, setRte] = React.useState(ReactRte.createEmptyValue());

    useEffect(() => {
        if (!loading) {
            setRte(ReactRte.createValueFromString(voucher.emailBody, 'html'));
        }
    }, [loading]);

    const onRteChange = type => event => {
        setRte(event);
        const html = event.toString('html');
        onChange(type, html);
    };

    const status =
        voucher.status
            ? voucher.status.replace(/^\w/, c => c.toUpperCase())
            : '';

    return (
        <Loader loading={loading || voucher === null}>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        label="Title *"
                        value={voucher.title}
                        name="title"
                        readOnly={readOnly}
                        onChange={onAnyChange('title')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="Description *"
                        value={voucher.description}
                        name="description"
                        multiline
                        rows={4}
                        readOnly={readOnly}
                        onChange={onAnyChange('description')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="Email Subject"
                        value={voucher.emailSubject}
                        name="emailSubject"
                        readOnly={readOnly}
                        onChange={onAnyChange('emailSubject')}
                        placeholder="New Merlo Voucher!"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactRte
                        label="Email Body"
                        value={rteState}
                        onChange={onRteChange('emailBody')}
                        placeholder="Email Body"
                        readOnly={readOnly}
                        className={classes.textEditor}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="POS Instructions"
                        value={voucher.internalReference}
                        name="internalReference"
                        multiline
                        readOnly={readOnly}
                        rows={4}
                        onChange={onAnyChange('internalReference')}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextInput
                        readOnly
                        label="Status"
                        value={status}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        readOnly
                        label="Date created"
                        value={moment(voucher.createdAt)}
                        name="createdAt"
                        onChange={onAnyChange('createdAt')}
                    />
                </Grid>
                {/* <Grid item xs={4}>
                    <TextInput
                        label="Default max claims per user"
                        value={voucher.defaultNumberOfRedemptionsPerUser}
                        name="maxRedemptions"
                        readOnly={readOnly}
                        onChange={onAnyChange(
                            'defaultNumberOfRedemptionsPerUser',
                            true
                        )}
                    />
                </Grid> */}
                <Grid item xs={4}>
                    <TextInput
                        label="Default max claims"
                        value={voucher.defaultNumberOfRedemptions}
                        name="defaultNumberOfRedemptions"
                        readOnly={readOnly}
                        onChange={onAnyChange(
                            'defaultNumberOfRedemptions',
                            true
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        label="Default expiry in days *"
                        value={voucher.defaultExpiry}
                        name="defaultExpiry"
                        readOnly={readOnly}
                        onChange={onAnyChange('defaultExpiry', true)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        label="Delete expired voucher codes"
                        // value={voucher.deleteExpiredCodes}
                        checked={voucher.deleteExpiredCodes}
                        name="deleteExpiredCodes"
                        readOnly={readOnly}
                        onChange={onAnyChange('deleteExpiredCodes')}
                    />
                </Grid>
                {!readOnly && (
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                        <AddSku
                            skus={voucher.SKU}
                            onSubmit={onAddSku}
                            onRemove={onRemoveSku}
                        />
                    </Grid>
                )}
            </Grid>
        </Loader>
    );
};

VoucherDetailsForm.propTypes = {
    voucher: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onAddSku: PropTypes.func,
    onRemoveSku: PropTypes.func
};

VoucherDetailsForm.defaultProps = {
    onChange: null,
    onAddSku: null,
    onRemoveSku: PropTypes.func
};

export default VoucherDetailsForm;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Close from '@material-ui/icons/Close';

import Radio from '@/components/RadioBox/RadioBox';
import Select from '@/components/TextInput/SelectInput';
import TextInput from '@/components/TextInput/TextInput';

import Button from '@/components/Button/Button';
import Box from '@/components/Box/Box';
import Table from '@/components/Table/Table';
import Loader from '@/components/Loader/Loader';

import { selectSegmentsLoading } from '@/state/segments/reducers';
import Datepicker from '../Datepicker/Datepicker';
import moment from 'moment';
import is from 'is_js';

const styles = makeStyles(theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    },
    radioRow: {
        display: 'flex',
        alignItems: 'center'
    },
    ruleText: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#a7a7a7',
        textTransform: 'uppercase'
    },
    selectText: {
        fontSize: 12,
        fontStyle: 'italic',
        fontWeight: 300,
        color: '#5a5a5a',
        padding: '5px 10px 5px 0px'
    },
    addRuleContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    button: {
        margin: '20px 0px',
        width: 130
    },
    tableContainer: {
        margin: '0px -20px -30px'
    }
}));

const filterTypes = {
    string: [
        { label: 'contains', value: 'contains' },
        { label: 'starts with', value: 'starts with' },
        { label: 'ends with', value: 'ends with' },
        { label: 'equals', value: 'equals' },
        { label: 'not equal', value: 'not equal' },
        { label: 'does not contain', value: 'does not contain' }
    ],
    number: [
        { label: '>', value: '>' },
        { label: '>=', value: '>=' },
        { label: '<', value: '<' },
        { label: '<=', value: '<=' },
        { label: '=', value: '=' },
        { label: '!=', value: '!=' },
        { label: 'Is Empty', value: 'Is Empty' },
    ],
    booleanNumber: [
        { label: '=', value: '=' },
        { label: '!=', value: '!=' },
    ],
    booleanString: [
        { label: 'contains', value: 'contains' },
        { label: 'does not contain', value: 'does not contain' },
    ],
    booleanString2: [
        { label: 'equals', value: 'equals' },
        { label: 'does not equal', value: 'does not equal' }
    ],
    birthdayToday: [
        { label: 'equals', value: 'equals' },
    ],
    birthdayRange: [
        { label: 'during', value: 'during' },
        // { label: 'before', value: 'before' },
        // { label: 'after', value: 'after' }
    ],
    orderedWithinLastYear: [
        { label: 'within', value: 'within' },
        // { label: 'before', value: 'before' },
        // { label: 'after', value: 'after' }
    ]
}

const SegmentRule = props => {
    const { fields, segment, rules, onChange, onAddRule, onRemoveRule, readOnly, onRowClick } = props;

    const classes = styles();

    const loading = useSelector(state => selectSegmentsLoading(state));

    const fieldsPresent = Object.keys(fields).length > 0;


    const handleRadioChange = (event) => {
        onChange('constraints', event.target.value)
    }

    const onSelect = (type, inputType = null) => value => {
        onChange(type, value, inputType);
    };

    const onClick = rowData => {
        onRowClick(rowData);
    };



    const _selectedField = rules.field.trim().length > 0;
    const selectedField = _selectedField ? fields[rules.field] : null;
    const orderedFields = Object.values(fields).sort((a, b) => {
        return a.label.localeCompare(b.label)
    })
    const fieldSelectData = fieldsPresent ? orderedFields : [''];

    const operatorSelector = _selectedField ? selectedField.filterType : null;
    const operatorSelectData = operatorSelector ? [...filterTypes[operatorSelector]] : [''];
    if (selectedField) {
        const { dataKey } = selectedField;
        if (dataKey === 'data.currencyBalance' || dataKey === 'data.pointsBalance') {
            operatorSelectData.pop();
        }
    }

    const getComparatorInput = data => {
        if (data && 'inputType' in data) {
            const { inputType } = data;
            switch (inputType) {
                case 'text':
                    return <TextInput label='Compared with'
                        onChange={(e) => onSelect('comparator')(e.target.value)}
                        value={rules.comparator}
                    />

                case 'text-date':
                    if (rules.operator.toLowerCase() === 'is empty') {
                        return <Select label='Compared with'
                            value={''}
                            onChange={onSelect('comparator')}
                            disabled
                        />
                    }

                    return <Datepicker label='Compared with'
                        onChange={(e) => {
                            onSelect('comparator')(moment.isMoment(e) ? e.format("DD/MM/YYYY") : null)
                        }}
                        value={is.empty(rules.comparator) ? null : moment(rules.comparator, 'DD/MM/YYYY')}
                    />
                case 'text-postcode':
                    return <TextInput label='Compared with'
                        onChange={(e) => onSelect('comparator')(e.target.value)}
                        value={rules.comparator}
                    />

                case 'number':
                    return <TextInput label='Compared with' type='number'
                        onChange={(e) => onSelect('comparator')(e.target.value)}
                        value={rules.comparator}
                    />

                case 'select':
                    return <Select label='Compared with'
                        value={rules.comparator}
                        options={selectedField.selectOps}
                        onChange={onSelect('comparator')}
                        placeholder='- Please select -'
                    />

                default:
                    return <TextInput label='Compared with'
                        onChange={(e) => onSelect('comparator')(e.target.value)}
                        value={rules.comparator}
                    />;
            }
        }

        return <TextInput label='Compared with'
            onChange={(e) => onSelect('comparator')(e.target.value)}
            value={rules.comparator}
        />;
    }

    const headerData = [
        { key: 'id', visible: false },
        {
            key: 'field',
            label: 'Field',
            compute: (rowData) => rowData.field,
            renderer: (data) => {
                return fields && data in fields ? fields[data].label : ''
            },
            sortable: false
        },
        { key: 'operator', label: 'Operator', sortable: false },
        { key: 'comparator', label: 'Compare With', sortable: false },
        {
            key: 'remove',
            label: 'remove',
            compute: (rowData) => rowData,
            renderer: (data) => !readOnly && <Close onClick={(e) => { e.stopPropagation(); onRemoveRule(data) }} />,
            headerStyle: { align: 'right', size: 'small' },
            styles: { align: 'right' },
            sortable: false,
            showLabel: false
        },
    ]

    // allow disabled to be bypassed if the operator is set to 'is empty'
    let disabled = Object.values(rules).some(i => typeof i === 'string' && i.trim().length === 0);
    if (rules.operator.toLowerCase() === 'is empty') { disabled = false; }

    return (
        <Box
            rootClass={classes.root}
            title='Rules'
        >
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <RadioGroup
                            value={rules.constraints}
                            onChange={!readOnly ? handleRadioChange : undefined}
                            className={classes.radioGroup}
                        >
                            <div className={classes.radioRow}>
                                <div style={{ width: 80 }}>
                                    <Radio small label='All' value='all' />
                                </div>
                                <span className={classes.ruleText}>all rules must be true for a user to be included</span>
                            </div>
                            <div className={classes.radioRow}>
                                <div style={{ width: 80 }}>
                                    <Radio small label='Any' value='any' />
                                </div>
                                <span className={classes.ruleText}>at least 1 of these must be true</span>
                            </div>
                        </RadioGroup>
                    </Grid>
                    {
                        !readOnly &&
                        <React.Fragment>
                            <Grid item xs={4}>
                                <Select
                                    label='Field'
                                    value={rules.field}
                                    options={fieldSelectData}
                                    onChange={onSelect('field')}
                                    placeholder='- Please select -'
                                />
                                <div className={classes.selectText}>
                                    {selectedField && selectedField.description}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    label='Operator'
                                    value={rules.operator}
                                    options={operatorSelectData}
                                    onChange={onSelect('operator')}
                                    placeholder='- Please select -'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    getComparatorInput(selectedField)
                                }
                            </Grid>
                            <div className={classes.addRuleContainer}>
                                <Button className={classes.button} label='Add rule' onClick={onAddRule} disabled={disabled} />
                            </div>
                        </React.Fragment>
                    }
                </Grid>
                <div className={classes.tableContainer}>
                    <Table
                        headerData={headerData}
                        tableData={segment.rules || []}
                        onRowClick={onClick}
                        loading={false}
                        showEmptyRows={false}
                        elevation={0}
                        defaultSortKey='id'
                        defaultSortDir='asc'
                    />
                </div>
            </Loader>
        </Box>
    );
};

SegmentRule.propTypes = {
    segment: PropTypes.object,
    rules: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onAddRule: PropTypes.func.isRequired,
    onRemoveRule: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

SegmentRule.defaultProps = {
    segment: null,
    readOnly: false
}

export default SegmentRule;
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import * as types from '@/state/auth/types';
import * as services from '@/state/auth/services';
import { selectUserUuid, selectAuthTimeStamp } from '@/state/auth/reducers';

import * as permissionTypes from '@/state/permissions/types';
import { selectPermissions } from '@/state/permissions/reducers';

class AuthGuardian extends Component {

    state = {initialized: false}

    async componentDidMount() {
        // console.log('mounted');
        await this.checkSecure();
        this.loggedInCheck();
        this.tokenCheck();
        this.permissionsCheck();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.initialized === false) {
            // console.log('in auth guardian');
            await this.checkSecure();
            this.loggedInCheck();
            this.tokenCheck();
            this.permissionsCheck();
        }
    }

    checkSecure = async () => {
        return new Promise(resolve => {
            // cheap redirect to https
            const isProd = process.env.NODE_ENV === 'production';

            if (isProd && window.location.protocol === 'http:') {
                window.location.protocol = 'https:';
            }
            resolve();
        })
    }

    tokenCheck = () => {
        const { userUuid, extractUserUuid } = this.props;
        // see if token exists in localstorage first off
        const idToken = services.getLocalStore('idToken');
        const refreshToken = services.getLocalStore('refreshToken');

        if (idToken === null || refreshToken === null) {
            this.redirectToAuthPortal();
        } else {
            // if userUuid is not already present in redux, extract is from the token
            if (userUuid.trim().length === 0) {
                extractUserUuid(idToken);
            }
        }


    }

    loggedInCheck = () => {
        const _loggedIn = services.getLocalStore('loggedIn');
        const loggedIn = _loggedIn === 'true';
        if (!loggedIn) { this.redirectToAuthPortal(); }
    }

    permissionsCheck = () => {
        const { userUuid, getUserPermissions, permissions, requiredPermissions } = this.props;
        if (userUuid.trim().length > 0) {
            // check for presence of permissions
            if (Object.keys(permissions).length > 0) {
                // check logged in user is in the list
                if (!(userUuid in permissions)) {
                    getUserPermissions(userUuid);
                } else {
                    // Don't allow a user to view a page without required permissions
                    if (requiredPermissions) {
                        let authorized = requiredPermissions.every(p => permissions[userUuid].permissions.includes(p));
                        if (!authorized) {
                            window.location = window.location.origin;
                            return;
                        }
                    }
                    // Done
                    this.setState({initialized: true});
                }
            } else {
                getUserPermissions(userUuid);
            }
        }
    }

    redirectToAuthPortal = () => {
        const isProd = process.env.NODE_ENV === 'production'
        const authUrl = isProd ? window.__env__.AUTH_URL : process.env.REACT_APP_AUTH_URL;
        if (isProd && window.location.protocol === 'http:') {
            window.location = `${authUrl}?returnUrl=https://${window.location.host}/callback`;
        } else {
            window.location = `${authUrl}?returnUrl=${window.location.origin}/callback`;
        }
    }

    render() {
        return this.state.initialized ?
            this.props.children :
            null;
    }
}

AuthGuardian.propTypes = {

};

const mapStateToProps = state => ({
    userUuid: selectUserUuid(state),
    permissions: selectPermissions(state),
    authTimeStamp: selectAuthTimeStamp(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    refreshToken: (refreshToken) => ({ type: types.REFRESH_TOKEN, refreshToken }),
    extractUserUuid: (idToken) => ({ type: types.AUTH_GET_USERUUID, idToken }),
    getUserPermissions: (userUuid) => ({ type: permissionTypes.GET_USER_PERMISSIONS, userUuid }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuardian);
export const GET_PRODUCTS = 'products/GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'products/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'products/GET_PRODUCTS_ERROR';

export const GET_PRODUCT_DETAIL = 'products/GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'products/GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_ERROR = 'products/GET_PRODUCT_DETAIL_ERROR';

export const GET_PARTS = 'products/GET_PARTS';
export const GET_PARTS_SUCCESS = 'products/GET_PARTS_SUCCESS';
export const GET_PARTS_ERROR = 'products/GET_PARTS_ERROR';

export const GET_PART_DETAIL = 'products/GET_PART_DETAIL';
export const GET_PART_DETAIL_SUCCESS = 'products/GET_PART_DETAIL_SUCCESS';
export const GET_PART_DETAIL_ERROR = 'products/GET_PART_DETAIL_ERROR';

export const GET_SHOPIFY_COLLECTIONS = 'products/GET_SHOPIFY_COLLECTIONS';
export const GET_SHOPIFY_COLLECTIONS_SUCCESS = 'products/GET_SHOPIFY_COLLECTIONS_SUCCESS';
export const GET_SHOPIFY_COLLECTIONS_ERROR = 'products/GET_SHOPIFY_COLLECTIONS_ERROR';

export const UPDATE_PRODUCT = 'products/UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'products/UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'products/UPDATE_PRODUCT_ERROR';

export const CREATE_PRODUCT = 'products/CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'products/CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_SUCCESS_TARGET_UUID = 'products/CREATE_PRODUCT_SUCCESS_TARGET_UUID';
export const CREATE_PRODUCT_ERROR = 'products/CREATE_PRODUCT_ERROR';

export const DELETE_PRODUCT = 'products/DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'products/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'products/DELETE_PRODUCT_ERROR';

export const PRODUCTS_LOADING_STATE = 'products/PRODUCTS_LOADING_STATE';
export const PRODUCT_SAVE_STATE = 'products/PRODUCT_SAVE_STATE';

export const UPDATE_PART_LIST = 'products/UPDATE_PART_LIST';
export const UPDATE_PART_LIST_ORDER = 'products/UPDATE_PART_LIST_ORDER';
export const MERGE_PRODUCT_PART_LIST = 'products/MERGE_PRODUCT_PART_LIST';
export const REMOVE_FROM_PRODUCT_PART_LIST = 'products/REMOVE_FROM_PRODUCT_PART_LIST';

export const CLEAR_SELECTED_PRODUCT = 'products/CLEAR_SELECTED_PRODUCT';
import * as types from './types';

const initialState = {
    open: false,
    component: null,
    props: {},
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MODAL_SET_COMPONENT:
            return {
                ...state,
                component: action.component,
                props: action.props,
            };
        case types.MODAL_SET_OPEN_STATE:
            return {
                ...state,
                open: action.state
            }
        
        default:
            return state;
    }
}

export default modalReducer;

export const selectModalState = state => state.modals.open;
export const selectModalComponent = state => state.modals.component;
export const selectModalProps = state => state.modals.props;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Box from '@/components/Box/Box';

import { selectLocationsLoading } from '@/state/locations/reducers';
import Loader from '@/components/Loader/Loader';

const styles = makeStyles(theme => ({
}));


const LocationOptions = props => {
    const classes = styles();
    const { location, onChange, readOnly} = props;

    const [switchState, setSwitchState] = React.useState({
        isBeanRetailer: false,
        isCoffeeRetailer: false,
        isAlcoholLicensed: false,
        isMerloOwned: false,
        hasFreeWifi: false,
    })

    const loadingState = useSelector(state => selectLocationsLoading(state));
    useEffect(() => {
        if (!loadingState) {
            const { isBeanRetailer, isCoffeeRetailer, isAlcoholLicensed, isMerloOwned, hasFreeWifi } = location;
            setSwitchState({
                isBeanRetailer,
                isCoffeeRetailer,
                isAlcoholLicensed,
                isMerloOwned,
                hasFreeWifi,
            });
        }
    }, [loadingState]);

    const handleChange = type => event => {
        setSwitchState({ ...switchState, [type]: event.target.checked });
        onChange(type, event.target.checked);
    }

    return (
        <Box title='Options'>
            <Loader loading={loadingState}>
                <FormGroup row>
                    <FormControlLabel
                        control={<Switch color='primary' checked={switchState.isBeanRetailer} onChange={handleChange('isBeanRetailer')} value='isBeanRetailer' />}
                        label='Sells Beans'
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        control={<Switch color='primary' checked={switchState.isCoffeeRetailer} onChange={handleChange('isCoffeeRetailer')} value='isCoffeeRetailer' />}
                        label='Sells Wet Coffee'
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        control={<Switch color='primary' checked={switchState.isAlcoholLicensed} onChange={handleChange('isAlcoholLicensed')} value='isAlcoholLicensed' />}
                        label='Has Alcohol Licence'
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        control={<Switch color='primary' checked={switchState.isMerloOwned} onChange={handleChange('isMerloOwned')} value='isMerloOwned' />}
                        label='Merlo Owned'
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        control={<Switch color='primary' checked={switchState.hasFreeWifi} onChange={handleChange('hasFreeWifi')} value='hasFreeWifi' />}
                        label='Has free WiFi'
                        disabled={readOnly}
                    />
                </FormGroup>
            </Loader>
        </Box>
    );
};

LocationOptions.propTypes = {
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default LocationOptions;
import React from 'react';
import * as modalTypes from '@/constants/modals';
import UserPermissionsModal from '@/containers/Modals/UserPermissions';
import AddVoucher from '@/containers/Modals/AddVoucher';
import GenerateVoucherCodes from '@/containers/Modals/GenerateVoucherCodes';
import AssignVoucherCode from '@/containers/Modals/AssignVoucherCode';
import EditVoucherCode from '@/containers/Modals/EditVoucherCode';
import NotifyVoucherCode from '@/containers/Modals/NotifyVoucherCode';
import ViewRedemption from '@/containers/Modals/ViewRedemption';
import EditAutomation from '@/containers/Modals/EditAutomation';
import NotificationConfirm from '@/containers/Modals/NotificationConfirm';
import ResetPassword from '@/containers/Modals/ResetPassword'
import AddressDetails from '@/containers/Modals/AddressDetails';
import PromptNumber from '@/containers/Modals/PromptNumber';
import PromptBoolean from '@/containers/Modals/PromptBoolean';
import AddPart from '@/containers/Modals/AddPart';
import AddPartsToProduct from '@/containers/Modals/AddPartsToProduct';
import RemovePartFromProduct from '@/containers/Modals/RemovePartFromProduct';
import RemoveProductFromPart from '@/containers/Modals/RemoveProductFromPart';
import DeleteLocationModal from '@/containers/Modals/DeleteLocationModal';
import ArchiveUserModal from '@/containers/Modals/ArchiveUserModal';
import SegmentExportCSV from '@/containers/Modals/SegmentExportCSV';
import ShopifyPromotion from '@/containers/Modals/ShopifyPromotion';

import ConfirmationModal from '@/containers/Modals/ConfirmationModal';

export const getModalFromType = (type) => {
    switch (type) {
        case modalTypes.USER_PERMISSIONS_MODAL:
            return <UserPermissionsModal />

        case modalTypes.NOTIFICATION_CONFIRM_MODAL:
            return <NotificationConfirm />

        case modalTypes.PROMOTIONS_ADD_VOUCHER_MODAL:
            return <AddVoucher />

        case modalTypes.PROMOTIONS_GENERATE_VOUCHER_CODES_MODAL:
            return <GenerateVoucherCodes />

        case modalTypes.PROMOTIONS_ASSIGN_VOUCHER_CODE_MODAL:
            return <AssignVoucherCode />

        case modalTypes.PROMOTIONS_NOTIFY_VOUCHER_CODE_MODAL:
            return <NotifyVoucherCode />

        case modalTypes.PROMOTIONS_EDIT_VOUCHER_CODE_MODAL:
            return <EditVoucherCode />

        case modalTypes.PROMOTIONS_VIEW_REDEMPTION_MODAL:
            return <ViewRedemption />

        case modalTypes.PROMOTIONS_EDIT_AUTOMATION_MODAL:
            return <EditAutomation />

        case modalTypes.PRODUCTS_ADD_PARTS_MODAL:
            return <AddPart />

        case modalTypes.ADD_PARTS_TO_PRODUCT_MODAL:
            return <AddPartsToProduct />

        case modalTypes.REMOVE_PART_FROM_PRODUCT:
            return <RemovePartFromProduct />

        case modalTypes.REMOVE_PRODUCT_FROM_PART:
            return <RemoveProductFromPart />

        case modalTypes.RESET_PASSWORD_MODAL:
            return <ResetPassword />;

        case modalTypes.ADDRESS_DETAILS_MODAL:
            return <AddressDetails />

        case modalTypes.PROMPT_NUMBER_MODAL:
            return <PromptNumber />

        case modalTypes.PROMPT_BOOLEAN_MODAL:
            return <PromptBoolean />
        
        case modalTypes.DELETE_LOCATION_MODAL:
            return <DeleteLocationModal />

        case modalTypes.ARCHIVE_USER_MODAL:
            return <ArchiveUserModal />

        case modalTypes.CONFIRMATION_MODAL:
            return <ConfirmationModal />

        case modalTypes.SEGMENT_EXPORT_CSV:
            return <SegmentExportCSV />

        case modalTypes.SHOPIFY_PROMOTION_MODAL:
            return <ShopifyPromotion />
        
        default:
            return <div />;
    }
}

export default getModalFromType;
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@/components/Button/Button';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';

import * as modalTypes from '@/state/modal/types';
import * as modals from '@/constants/modals';

const styles = makeStyles(theme => ({
    linkedProductRow: {
        height: 100,
        backgroundColor: '#fafafa',
        padding: 10,
        cursor: 'pointer',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center'
    },
    titleColumn: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
    },
    column: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center'
    }
}));



const LinkedProduct = props => {
    const classes = styles();
    const dispatch = useDispatch();

    const { product, history, onUpdate, part } = props;

    const onRowClick = () => {
        const { uuid } = product;
        history.push(`/products/${uuid}`);
    }

    const removeProductRef = useRef();
    removeProductRef.current = (data) => {
        onUpdate(data);
    };

    const onRemoveFromProduct = (e, productData) => {
        e.stopPropagation();
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: modals.REMOVE_PRODUCT_FROM_PART, props: { part, product: productData, ref: removeProductRef } });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    }

    const hasImage = product.images.length > 0;

    return (
        <div role='presentation' onClick={onRowClick} className={classes.linkedProductRow}>
            {
                // <ImagePlaceholder
                //     styles={{ height: 80, width: 80 }}
                //     imageUrl={hasImage ? product.images[0].src : null}
                // />
            }
            <div style={{ marginLeft: 10 }} className={classes.titleColumn} >{product.title}</div>
            <div className={classes.column}>{product.primaryCollection}</div>
            <div className={classes.column}>{product.categories.length > 0 && product.categories.map(c => c.title)}</div>
            <div className={classes.column}><LinkIcon color='primary' /></div>
            {
                // <div className={classes.column}><Button label='Remove' onClick={(e) => { onRemoveFromProduct(e, product) }} /></div>
            }
        </div>
    );
};

LinkedProduct.propTypes = {
    part: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default withRouter(LinkedProduct);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import SubHeader from '@/components/SubHeader/SubHeader';
import Button from '@/components/Button/Button';

import SegmentData from '@/components/Segments/SegmentData';
import SegmentRule from '@/components/Segments/SegmentRule';
import SegmentResults from '@/components/Segments/SegmentResults';

import * as segmentTypes from '@/state/segments/types';
import * as segmentActions from '@/state/segments/actions';
import { selectSegmentFields, selectSelectedSegment, selectSegmentSaving } from '@/state/segments/reducers';
import moment from 'moment';

const styles = theme => ({
    spacer: {
        marginTop: 60,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0px',
    },
    button: {
        width: 130
    }
});

class SegmentDetails extends Component {
    constructor(props) {
        super(props);
        const uuid = this.getUuidFromRoute(props);
        const { getSegmentUsers, getSegment, getFields, clearResults } = props;

        clearResults();
        getFields();
        getSegment(uuid);
        getSegmentUsers(uuid);
    }

    state = {
        segment: {},
        rules: {
            constraints: 'all',
            field: '',
            operator: '',
            comparator: '',
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { segmentData, saving, getSegmentUsers } = this.props;
        if (prevProps.segmentData !== segmentData) {
            const { constraints, ...rest } = segmentData;
            this.setState(prevState => ({ //eslint-disable-line
                segment: {
                    ...rest,
                    rules: rest.rules.map((r, i) => ({ ...r, id: i }))
                },
                rules: {
                    ...prevState.rules,
                    constraints
                }
            }));
        };

        if (!saving && prevProps.saving) {
            // save complete... execute the query
            const uuid = this.getUuidFromRoute(this.props);
            getSegmentUsers(uuid);
        };
    }

    onChange = (type, value) => {
        const { segment } = this.state;

        this.setState({
            segment: {
                ...segment,
                [type]: value
            }
        });
    };

    onRuleChange = (type, value) => {
        const { rules } = this.state;
        if (value.toLowerCase() === 'is empty') {
            return this.setState({
                rules: {
                    ...rules,
                    [type]: value,
                    comparator: ''
                }
            })
        }

        this.setState({
            rules: {
                ...rules,
                [type]: value,
            }
        })
    }

    onRuleClick = (ruleData) => {
        this.setState(prevState => ({
            rules: {
                ...prevState.rules,
                ...ruleData
            }
        }));
    }

    onAddRule = () => {
        const { segment, rules } = this.state;

        if (!('rules' in segment)) {
            segment.rules = [];
        }

        const { constraints, ...rest } = rules;
        const _rules = [...segment.rules];
        _rules.push({ ...rest, id: _rules.length });

        this.setState(prevState => ({
            segment: {
                ...segment,
                rules: _rules
            },
            rules: {
                ...prevState.rules,
                field: '',
                operator: '',
                comparator: '',
            }
        }));
    }

    onRemoveRule = (rowData) => {
        const { segment } = this.state;
        const _rules = [...segment.rules];

        const rules = _rules
            .filter(r => r.id !== rowData.id)
            .map((r, i) => {
                return { ...r, id: i }
            });

        this.setState({
            segment: {
                ...segment,
                rules
            }
        });
    }

    getUuidFromRoute = props => {
        const { match: { params } } = props;
        const { uuid } = params;
        return uuid;
    };

    onSaveAndRun = async () => {
        const { updateSegment } = this.props;
        const { segment, rules } = this.state;
        const { constraints } = rules;
        const uuid = this.getUuidFromRoute(this.props);
        console.log('Save and Run the Segment', segment);

        // save rule and execute the segment
        updateSegment({ ...segment, uuid, constraints });
    };

    render() {
        const { classes, fields, saving, readOnly } = this.props;
        const { segment, rules } = this.state;
        const disabled = Object.keys(segment).length > 0 && 'rules' in segment && segment.rules.length === 0;
        return (
            <React.Fragment>
                <SubHeader showAction={false} right={<div />} />
                <div className={classes.spacer} />

                <SegmentData
                    segment={segment}
                    onChange={this.onChange}
                    readOnly={readOnly}
                />
                <SegmentRule
                    segment={segment}
                    fields={fields}
                    onChange={this.onRuleChange}
                    rules={rules}
                    onAddRule={this.onAddRule}
                    onRemoveRule={this.onRemoveRule}
                    readOnly={readOnly}
                    onRowClick={this.onRuleClick}
                />
                {
                    !readOnly &&
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} disabled={disabled} loading={saving} label='Save & Run' onClick={this.onSaveAndRun} />
                    </div>
                }
                <SegmentResults />
            </React.Fragment>
        );
    }
}

SegmentDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    getSegmentUsers: PropTypes.func.isRequired,
    getSegment: PropTypes.func.isRequired,
    getFields: PropTypes.func.isRequired,
    updateSegment: PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,

    fields: PropTypes.object.isRequired,
    segmentData: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,

};

const mapStateToProps = state => ({
    fields: selectSegmentFields(state),
    segmentData: selectSelectedSegment(state),
    saving: selectSegmentSaving(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getSegmentUsers: segmentUuid => ({ type: segmentTypes.GET_SEGMENT_USERS, segmentUuid }),
    getSegment: segmentUuid => ({ type: segmentTypes.GET_SEGMENT_DETAILS, segmentUuid }),
    getFields: () => ({ type: segmentTypes.GET_SEGMENT_FIELDS }),
    updateSegment: segment => ({ type: segmentTypes.UPDATE_SEGMENT, segment }),
    clearResults: () => ({ type: segmentTypes.CLEAR_SEGMENT_RESULTS }),
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SegmentDetails)));

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';

import { selectOrdersLoading } from '@/state/orders/reducers';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';

const OrderDetails = props => {
    const { createdAt, updatedAt, source, readOnly, name, subscription, noteAttributes, subscriptionCount } = props;

    console.log(props)

    const [orderData, setOrderData] = React.useState({
        createdAt: moment(),
        updatedAt: moment(),
        source: '',
        name: ''
    });

    const loadingState = useSelector(state => selectOrdersLoading(state));

    useEffect(() => {
        if (!loadingState) {
            setOrderData({
                createdAt: moment(new Date(createdAt)),
                updatedAt: moment(new Date(updatedAt)),
                source,
                name
            });
        }
    }, [loadingState]);

    // subscriptionCount is new, the old way was to look for the order_count so keep that for old orders.
    let subscriptionCountValue = subscriptionCount;
    if (!subscriptionCountValue) {
        const orderCount = noteAttributes.find(attr => attr.name === 'Order_Count');
        subscriptionCountValue = orderCount ? orderCount.value : 0; 
    }

    
    return (
        <Box title="Order details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput value={orderData.name} label="ID" readOnly />
                    </Grid>
                    <Grid item xs={6}>
                        <Datepicker
                            value={orderData.createdAt}
                            label="Created date"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Datepicker
                            value={orderData.createdAt}
                            label="Payment date"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Datepicker
                            value={orderData.updatedAt}
                            label="Last updated"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            value={orderData.source}
                            label="Source"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            value={subscription ? 'Yes' : 'No'}
                            label="Subscription"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            value={subscriptionCountValue || 0}
                            label="Subscription Count"
                            readOnly
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

OrderDetails.propTypes = {
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    source: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    name: PropTypes.string,
    subscription: PropTypes.bool,
    subscriptionCount: PropTypes.number,
    noteAttributes: PropTypes.array,
};

OrderDetails.defaultProps = {
    createdAt: null,
    updatedAt: null,
    source: null,
    name: '',
    subscription: false,
    subscriptionCount: 0,
    noteAttributes: []
};

export default OrderDetails;

import moment from 'moment';
import jwtDecode from 'jwt-decode';

export const isTokenExpired = token => {
    if (token === null || token.trim().length === 0) return true;

    const decodedToken = jwtDecode(token);
    const { exp } = decodedToken;

    const expData = moment.unix(exp);
    if (expData.isBefore(moment())) {
        return true;
    }

    return false;
};

export default isTokenExpired;

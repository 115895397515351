import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';

import { withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';

import Button from '../components/Button/Button';
import Table from '../components/Table/Table';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';
import * as promotionTypes from '@/state/promotions/types';

import {
    selectAutomationsLoading,
    selectAutomationSaveState
} from '@/state/promotions/reducers';

const styles = () => ({
    addAutomationContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
});

class AutomationList extends React.Component {
    getHeaderData = () => {
        const { segments, vouchers } = this.props;

        return [
            { key: 'title', label: 'Title' },
            {
                key: 'nextRunDate',
                label: 'Next Run Date',
                compute: data => data,
                renderer: data => {
                    if (
                        data.nextRunDate !== null &&
                        data.nextRunDate !== '' &&
                        data.active
                    )
                        return moment(data.nextRunDate).format('DD/MM/YYYY');
                    return '';
                }
            },
            {
                key: 'lastRunDate',
                label: 'Last Run Date',
                compute: data => data.lastRunDate,
                renderer: data => {
                    if (data !== null && data !== '')
                        return moment(data).format('DD/MM/YYYY');
                    return '';
                }
            },
            {
                key: 'segmentUuid',
                label: 'Linked Segment',
                compute: data =>
                    segments.find(segment => segment.uuid === data.segmentUuid),
                renderer: data => {
                    if (data !== undefined) return data.name;
                    return 'Couldn\'t find segment';
                },
                sortData: (a,b,order) => {
                    const aSegment = segments.find(segment => segment.uuid === a.segmentUuid)
                    const bSegment = segments.find(segment => segment.uuid === b.segmentUuid)
                    if(!aSegment) {
                        return 1
                    }
                    if(!bSegment) {
                        return -1
                    }
                    if(order === 'desc') {
                        return bSegment.name.localeCompare(aSegment.name)
                    }
                    return aSegment.name.localeCompare(bSegment.name)
                }
            },
            {
                key: 'voucherUuid',
                label: 'Linked Voucher',
                compute: data =>
                    vouchers.find(voucher => voucher.uuid === data.voucherUuid),
                renderer: data => {
                    if (data !== undefined) return data.title;
                    return 'Couldn\'t find voucher';
                },
                sortData: (a,b,order) => {
                    const aVoucher = vouchers.find(voucher => voucher.uuid === a.voucherUuid)
                    const bVoucher = vouchers.find(voucher => voucher.uuid === b.voucherUuid)
                    if(!aVoucher) {
                        return 1
                    }
                    if(!bVoucher) {
                        return -1
                    }

                    if(order === 'desc') {
                        return bVoucher.title.localeCompare(aVoucher.title)
                    }
                    return aVoucher.title.localeCompare(bVoucher.title)
                }
            },
            {
                key: 'active',
                label: 'Status',
                compute: data => (data.active ? 'Active' : 'Inactive'),
                renderer: data => <Chip label={data} />
            }
        ];
    };

    menuActions = () => [
        {
            label: 'Edit',
            func: rowData => this.onEditAutomation(rowData.uuid)
        },
        {
            label: 'Delete',
            func: rowData => this.onDeleteAutomation(rowData)
        }
    ];

    onCreateAutomation = () => {
        const {
            setModalComponent,
            setSelectedAutomation,
            openModal
        } = this.props;
        setSelectedAutomation(null);
        setModalComponent(modals.PROMOTIONS_EDIT_AUTOMATION_MODAL);
        openModal();
    };

    onEditAutomation = automationUuid => {
        const {
            setSelectedAutomation,
            setModalComponent,
            openModal
        } = this.props;
        setSelectedAutomation(automationUuid);
        setModalComponent(modals.PROMOTIONS_EDIT_AUTOMATION_MODAL);
        openModal();
    };

    onConfirmDelete = automation => {
        const { deleteAutomation } = this.props;
        const { uuid } = automation;
        deleteAutomation(uuid);
    };

    onDeleteAutomation = automation => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.CONFIRMATION_MODAL, {
            message: `Are you sure you want to delete ${automation.title}?`,
            confirmMessage: 'Delete',
            saveStateSelector: selectAutomationSaveState,
            onConfirm: () => this.onConfirmDelete(automation)
        });
        openModal();
    };

    render() {
        const { automations, classes, readOnly } = this.props;

        return (
            <React.Fragment>
                <div className={classes.addAutomationContainer}>
                    { !readOnly &&
                        <Button
                            label="+ Create automation"
                            style={{
                                padding: '5px 27px'
                            }}
                            onClick={this.onCreateAutomation}
                        />
                    }
                </div>
                <Table
                    headerData={this.getHeaderData()}
                    menuActions={!readOnly ? this.menuActions() : null}
                    tableData={automations}
                    showHeader
                    loading={false}
                />
            </React.Fragment>
        );
    }
}

AutomationList.propTypes = {
    automations: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    vouchers: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    automationsLoadingState: selectAutomationsLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setSelectedAutomation: automationUuid => ({
                type: promotionTypes.SELECT_AUTOMATION,
                automationUuid
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            deleteAutomation: automationUuid => ({
                type: promotionTypes.DELETE_AUTOMATION,
                automationUuid
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AutomationList));

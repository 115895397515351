import React from 'react';
import PropTypes from 'prop-types';

const StatusChip = props => {
    return (
        <div>
            
        </div>
    );
};

StatusChip.propTypes = {
    
};

export default StatusChip;
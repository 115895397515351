import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';
import { makeStyles } from '@material-ui/styles';
import LinkIcon from '@material-ui/icons/Link';

import Table from '@/components/Table/Table';
import Button from '@/components/Button/Button';
import PartsFilter from '@/components/Filters/PartsFilter';

import { selectParts, selectPartsLoading } from '@/state/parts/reducers';

import * as modalTypes from '@/state/modal/types';
import * as modals from '@/constants/modals';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['partCode', 'erpTitle', 'optionTitle', 'price']
};

const styles = makeStyles(theme => ({
    addProductContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
   
}));

const headerData = [
    { key: 'partCode', label: 'SKU / PART' },
    { key: 'erpTitle', label: 'ERP TITLE' },
    { key: 'optionTitle', label: 'OPTION TITLE' },
    {
        key: 'price',
        label: 'PRICE',
        compute: (rowData) => rowData.price || 0,
        renderer: (data) => <div>$ {data.toFixed(2)}</div>
    },
    {
        key: 'checkboxState',
        label: 'LINKED PRODUCT',
        compute: (rowData) => {
            const { linkedProduct } = rowData;
            return linkedProduct;
        },
        renderer: (data) => data ? <LinkIcon color='primary' /> : <div styles={{ width: '80px', height: '80px', margin: 0 }}>-</div>,
        styles: { align: 'center' },
        checkBoxDisabled: (rowData) => {
            const { linkedProduct } = rowData;
            return linkedProduct !== null;
        },
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(a) && !compute(b) ? 1 : -1
                : !compute(a) && compute(b) ? 1 : -1;
        },
    },
    {
        key: 'reward',
        label: 'Bean dollar %',
        compute: ({ reward }) => (reward ? reward.percentage : 0),
        renderer: data => <div>{`${data}%`}</div>,
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(b) - compute(a)
                : compute(a) - compute(b);
        },
        styles: { align: 'justify' }
    }
]


const PartsList = props => {
    const { history } = props;
    const classes = styles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);

    const parts = useSelector(state => selectParts(state));
    const partsLoading = useSelector(state => selectPartsLoading(state));

    const [selectedParts, setSelectedParts] = React.useState({});
    const [state, setState] = React.useState({
        search: '',
        showOrphans: true
    })

    // const rowActions = (rowData) => {
    //     return [
    //         { label: 'Add to a product', func: () => { console.log(rowData) } },
    //     ]
    // }

    const onAddSelectedParts = () => {
        // open modal with selected parts
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: modals.ADD_PARTS_TO_PRODUCT_MODAL, props: { selectedParts } });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    };

    const onRowClick = (rowData) => {
        const { partCode } = rowData;
        history.push(`/parts/${partCode}`);
    };

    const onChecked = rowData => {
        // deselect and select
        const data = { ...selectedParts };
        const { partCode } = rowData;
        if (partCode in data) {
            // delete
            delete data[partCode];
        } else {
            data[partCode] = rowData;
        }

        setSelectedParts({ ...data });
    };

    const onChange = type => event => {
        setState({ ...state, [type]: event.target.value });
        setPage(0);
    };

    const onSwitchChange = type => event => {
        setState({ ...state, [type]: event.target.checked });
        setPage(0);
    }

    const searchParts = (data) => {
        const { search } = state;
        const fuseInstance = new Fuse(data, fuseOpts);
        return fuseInstance.search(search);
    }

    const filterData = data => {
        const { showOrphans } = state;

        if (showOrphans) {
            return data.filter(d => !d.linkedProduct);
        }

        return data;
    }

    const { search } = state;
    const filteredData = filterData(parts);
    const computedTableData = search.trim().length > 0 ? searchParts(filteredData) : filteredData;


    return (
        <React.Fragment>
            <div className={classes.addProductContainer}>
                <Button
                    label="+ Add selected parts to product"
                    style={{
                        padding: '5px 27px'
                    }}
                    onClick={onAddSelectedParts}
                    disabled={Object.keys(selectedParts).length === 0}
                />
            </div>

            <PartsFilter
                onChange={onChange}
                searchFilter={search}
                showOrphans={state.showOrphans}
                onSwitchChange={onSwitchChange}
            />
            <Table
                page={page}
                setPage={setPage}
                headerData={headerData}
                tableData={computedTableData}
                showHeader
                pagination
                loading={partsLoading}
                onRowClick={onRowClick}
                checkBox
                checkBoxData={selectedParts}
                onChecked={onChecked}
                checkBoxId='partCode'
            />
        </React.Fragment>
    );
};

PartsList.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(PartsList);
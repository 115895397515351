import axios from 'axios';

export const api = async (url, method, payload) => {
    try {
        const result = await axios({
            url,
            method,
            data: payload,
        });

        return result.data;

    } catch (error) {
        throw error;
    }
};

export const healthCheck = async () => {
    try {
        const result = await axios.get('/v1/service-health');

        return result.data;

    } catch (error) {
        throw error;
    }
};

export default api;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import SearchInput from '@/components/SearchInput/SearchInput';
import Table from '../components/Table/Table';
import { selectRedemptionsLoading } from '@/state/promotions/reducers';

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'description', 'userDisplay']
};

const headerData = [
    {
        key: 'date',
        label: 'Date',
        compute: rowData => rowData.date,
        renderer: data => data && moment(data).format('DD/MM/YYYY')
    },
    { key: 'title', label: 'Title' },
    { key: 'assignedName', label: 'Assigned' },
    { key: 'posId', label: 'POS ID' },
    {
        key: 'status',
        label: 'Status',
        compute: data => data.status.replace(/^\w/, c => c.toUpperCase()),
        renderer: data => <Chip label={data} />
    }
];

const styles = () => ({
    addPromoContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
});

class RedemptionList extends Component {
    state = {
        search: '',
        currentPage: 0
    };

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    onChange = type => event => {
        this.setState({
            [type]: event.target.value,
            currentPage: 0,
        });
    };

    searchRedemptions = redemptions => {
        const { search } = this.state;
        const fuseInstance = new Fuse(redemptions, fuseOpts);
        return fuseInstance.search(search);
    };

    onViewDetails = rowData => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.PROMOTIONS_VIEW_REDEMPTION_MODAL, {
            redemption: rowData
        });
        openModal();
    };

    render() {
        const { search, currentPage } = this.state;
        const { redemptions, redemptionsLoadingState } = this.props;
        const filtered =
            search.trim().length > 0
                ? this.searchRedemptions(redemptions)
                : redemptions;

        return (
            <React.Fragment>
                <SearchInput
                    style={{ height: 59 }}
                    value={search}
                    placeholder="Search by Title, Description or User"
                    onChange={this.onChange('search')}
                    searchFilter={search}
                />

                <Table
                    page={currentPage}
                    setPage={this.setPage}
                    headerData={headerData}
                    tableData={filtered}
                    showHeader
                    pagination
                    loading={redemptionsLoadingState}
                    onRowClick={this.onViewDetails}
                />
            </React.Fragment>
        );
    }
}

RedemptionList.propTypes = {
    redemptions: PropTypes.array.isRequired,
    redemptionsLoadingState: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    redemptionsLoadingState: selectRedemptionsLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(RedemptionList))
);

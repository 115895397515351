import * as types from './types';
import * as actions from './actions';

const initialState = {
    products: [],
    productsMeta: {},
    selectedProduct: {},
    selectedProductParts: {},
    collections: [],
    loadingState: true,
    saveState: false,
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.products,
                productsMeta: action.meta,
            };

        case types.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                selectedProduct: action.product,
            };

        case types.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: actions.upsertProducts(state.products, action.product),
            };

        case types.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: actions.upsertProducts(state.products, action.product),
            };

        case types.CREATE_PRODUCT_SUCCESS_TARGET_UUID:
            return {
                ...state,
                selectedProduct: action.product,
            };

        case types.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: actions.removeProduct(state.products, action.uuid),
            }

        case types.GET_SHOPIFY_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collections: action.collections
            };

        case types.PRODUCTS_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.PRODUCT_SAVE_STATE:
            return {
                ...state,
                saveState: action.state
            };

        case types.UPDATE_PART_LIST:
            return {
                ...state,
                selectedProductParts: action.parts,
            };

        case types.UPDATE_PART_LIST_ORDER:
            return {
                ...state,
                selectedProductParts: action.parts,
                selectedProduct: actions.updateParts(state.selectedProduct, action.parts)
            };

        case types.MERGE_PRODUCT_PART_LIST:
            return {
                ...state,
                selectedProductParts: {},
                selectedProduct: actions.mergeParts(state.selectedProduct, action.parts)
            };
        
        case types.REMOVE_FROM_PRODUCT_PART_LIST:
            return {
                ...state,
                selectSelectedProductParts: {},
                selectedProduct: actions.removeParts(state.selectedProduct, action.parts)
            };

        case types.CLEAR_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: {},
                selectedProductParts: {}
            }

        default:
            return state;
    }
}

export default productsReducer;

export const selectProducts = state => state.products.products;
export const selectSelectedProduct = state => state.products.selectedProduct;
export const selectCollections = state => state.products.collections;
export const selectProductLoading = state => state.products.loadingState;
export const selectProductSaving = state => state.products.saveState;
export const selectSelectedProductParts = state => state.products.selectedProductParts;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Switch from '@material-ui/core/Switch';
import * as types from '@/state/orders/types';
import { selectEpicorSettingLoading, selectEpicorSetting } from '@/state/orders/reducers';



class EpicorUpload extends Component {

    constructor(props) {
        super();

        props.getUploadSetting();
    }

    onChange = () => {
        const { updateSetting, setting } = this.props;

        updateSetting(!setting.value)
    }
    
    render() {
        const { readOnly, loadingState, setting } = this.props;

        return (
            <Switch
                checked={setting.value}
                onChange={this.onChange}
                color="primary"
                disabled={loadingState || readOnly}
            />
        );
    }
};

EpicorUpload.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    getUploadSetting: PropTypes.func.isRequired,
    updateSetting: PropTypes.func.isRequired,
    setting: PropTypes.object,
    loadingState: PropTypes.bool,
};

EpicorUpload.defaultProps = {
    setting: {
        value: false
    },
    loadingState: true.value
}

const mapStateToProps = state => ({
    loadingState: selectEpicorSettingLoading(state),
    setting: selectEpicorSetting(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getUploadSetting: () => ({ type: types.GET_EPICOR_SETTING }),
    updateSetting: (value) => ({ type: types.UPDATE_EPICOR_SETTING, key: 'upload', value })
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EpicorUpload));

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button/Button';

import * as modalTypes from '@/state/modal/types';
import * as productTypes from '@/state/products/types';

import * as partTypes from '@/state/parts/types';

const styles = makeStyles({
    title: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 1.2,
        marginTop: 14,
        marginBottom: 14,
    },
    buttonsContainer: {
        textAlign: 'center',
        marginTop: 40,
    },
    actionButton: {
        width: '140px',
        margin: '12px',
    }
});

const RemoveProductFromPart = props => {
    const { product, part } = props;
    const classes = styles();
    const dispatch = useDispatch();

    const onNo = () => {
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: '' });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    }

    const onYes = () => {
        dispatch({ type: partTypes.REMOVE_PART_FROM_PRODUCT, product });
        props.fwdRef.current(product);
        onNo();
    }

    const name = product.title;

    return (
        <div>
            <Typography className={classes.title}>Are you sure you want to unlink this part from {name} ?</Typography>
            <div className={classes.buttonsContainer}>
                <Button className={classes.actionButton}
                    label='No'
                    variant={null} style={{ color: '#077abe' }}
                    onClick={onNo}
                />
                <Button className={classes.actionButton}
                    label='Yes'
                    onClick={onYes}
                />
            </div>
        </div>
    );
};

RemoveProductFromPart.propTypes = {
    product: PropTypes.object,
    part: PropTypes.object,
    fwdRef: PropTypes.object
};

RemoveProductFromPart.defaultProps = {
    product: {},
    part: {},
    fwdRef: {}
};

export default RemoveProductFromPart;
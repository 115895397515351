import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBox from '@material-ui/core/Checkbox';

import TableToolbar from './TableToolbar';
import PopupMenu from '../PopupMenu/PopupMenu';
import CustomTableHead from './TableHead';
import PaginationActions from './PaginationActions';
import * as helpers from './helpers';


const tableStyles = makeStyles(theme => ({
    tableHeadRow: {
        backgroundColor: '#f2f2f2'
    },
    tableHeadLabel: {
        fontSize: 8,
        lineHeight: 1.13,
        color: 'black',
        textTransform: 'uppercase'
    },
    root: {
        borderRadius: 2,
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    tableRow: {
        backgroundColor: '#fff',
        cursor: 'pointer'
    },
    tableCell: styles => ({
        ...styles,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '350px'
    }),
    menuCell: {
        textAlign: 'right'
    },
    checkBoxCell: {
        textAlign: 'center',
        paddingRight: 0,
        paddingLeft: 0,
        height: 71
    },
    loading: {
        textAlign: 'center'
    },
    noData: {
        textAlign: 'center',
    }
}));


// the header is used to detemine what columns to render in the table
// it contains the data keys from the table data set... this way we can pass the full data object into the row

const CustomTable = props => {
    const {
        page, setPage,
        tableData, noDataLabel, headerData, menuActions, toolbarData,
        pagination, showHeader, loading, referenceData, styles, elevation,
        showEmptyRows, rowsPerPageOptions, onRowClick,
        checkBox, onChecked, checkBoxData, checkBoxId,
        readOnly, defaultSortDir, defaultSortKey, pageChanged, count, rowsPerPageChanged,
        serverSidePagination, defaultRowsPerPage, onSort, rowStyle, searching, serverSideSorting
    } = props;

    const hasHeader = Boolean(headerData);
    const classes = tableStyles(styles);
    // const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage || rowsPerPageOptions[0]);
    const [order, setOrder] = React.useState(defaultSortDir);

    const _useHeader = hasHeader && headerData.length > 0 ? headerData[0].key : '';
    const _useDefault = defaultSortKey !== null ? defaultSortKey : _useHeader;

    const [orderBy, setOrderBy] = React.useState(_useDefault);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    const headWithMenu = menuActions
        ? [...headerData, { key: 'menu', numeric: false, label: '' }]
        : headerData;

    const headWithCheckBox = checkBox
        ? [{ key: 'checkbox', label: '', sortable: false }, ...headWithMenu]
        : headWithMenu;

    const extraColumns = readOnly
        ? headerData
        : headWithCheckBox;

    const onChangePage = (e, newPage) => {
        setPage(newPage);

        if(pageChanged) {
            pageChanged(newPage);
        }
    };

    const onChangeRowsPerPage = e => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);

        if(rowsPerPageChanged) {
            rowsPerPageChanged(Number(e.target.value));
            setPage(0);
        }
    };

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        const newOrder = isDesc ? 'asc' : 'desc';
        setOrder(newOrder);
        setOrderBy(property);
        onSort({ newOrder, property });
        setPage(0);
    };

    const preventPropagation = e => {
        e.stopPropagation();
    }

    // resets page to 0 if searching
    useEffect(() => {
        setPage(0);
    }, [searching]);

    const sortedTableData = helpers.stableSort(tableData, helpers.headerAwareGetSorting(order, orderBy, headerData));

    let paginatedTableData = [];
    
    if(serverSidePagination && !serverSideSorting) {
        paginatedTableData = sortedTableData;
    } else if (serverSideSorting) { 
        paginatedTableData = tableData;
    } else {
        paginatedTableData = pagination ? sortedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedTableData;
    }

    return (
        <Paper elevation={elevation} className={classes.root}>
            {
                Object.keys(toolbarData).length > 0 &&
                <TableToolbar {...toolbarData} />
            }
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    {
                        showHeader &&
                        <CustomTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headerData={extraColumns}
                            checkBox
                            menuActions
                            readOnly={readOnly}
                        />
                    }
                    {
                        // eslint-disable-next-line no-nested-ternary
                        !loading ?
                            tableData.length > 0 ?
                                <TableBody>
                                    {
                                        paginatedTableData
                                            .map((row, idx) => {
                                                const _menuActions = typeof menuActions === 'function' ? menuActions(row) : menuActions;
                                                const isChecked = checkBox && row[checkBoxId] in checkBoxData;
                                                const cbIdx = headerData.findIndex(h => 'checkBoxDisabled' in h);
                                                const checkBoxDisabled = cbIdx > -1 ? headerData[cbIdx].checkBoxDisabled(row) : false;

                                                return (
                                                    <TableRow
                                                        key={idx}
                                                        hover
                                                        classes={{
                                                            root: classes.tableRow,
                                                            hover: classes.tableRowHover
                                                        }}
                                                        onClick={() => onRowClick(row)}
                                                        style={rowStyle(row)}
                                                    >
                                                        {
                                                            checkBox &&
                                                            <TableCell
                                                                component="td"
                                                                scope="row"
                                                                className={classes.checkBoxCell}
                                                            >
                                                                {
                                                                    !checkBoxDisabled &&
                                                                    <CheckBox
                                                                        onChange={() => { onChecked(row) }}
                                                                        onClick={e => preventPropagation(e)}
                                                                        checked={isChecked}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            </TableCell>
                                                        }
                                                        {
                                                            headerData.map((hd, i) => {
                                                                let cellData;
                                                                if ('compute' in hd) {
                                                                    // evalute the computed method for rendering the cell
                                                                    const computedCellData = hd.compute({ ...row, referenceData });
                                                                    cellData = hd.renderer(computedCellData);
                                                                } else {
                                                                    cellData = helpers.deepValue(row, hd.key);
                                                                }
                                                                let cellStyle = {};
                                                                if ('styles' in hd) {
                                                                    cellStyle = hd.styles;
                                                                }

                                                                let showColumn = true;
                                                                if ('visible' in hd) {
                                                                    showColumn = hd.visible;
                                                                }

                                                                return (
                                                                    showColumn === true &&
                                                                    <TableCell
                                                                        component="td"
                                                                        scope="row"
                                                                        key={i}
                                                                        classes={{ root: classes.tableCell }}
                                                                        {...cellStyle}
                                                                    >
                                                                        {cellData}
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            !readOnly && menuActions !== null && menuActions.length > 0 &&
                                                            <TableCell
                                                                component="td"
                                                                scope="row"
                                                                className={classes.menuCell}
                                                            >
                                                                <PopupMenu
                                                                    data={row}
                                                                    menuActions={_menuActions}
                                                                />
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                )
                                            }
                                            )}

                                    {
                                        emptyRows > 0 && showEmptyRows &&
                                        <TableRow style={{ height: 48 * emptyRows }}>
                                            <TableCell colSpan={12} />
                                        </TableRow>
                                    }
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={100} style={{ height: 48 * showEmptyRows ? emptyRows : 1 }} className={classes.noData}>{noDataLabel}</TableCell>
                                    </TableRow>
                                </TableBody>
                            :
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={100} style={{ height: 48 * showEmptyRows ? emptyRows : 1 }} className={classes.loading}><CircularProgress /></TableCell>
                                </TableRow>
                            </TableBody>
                    }

                    {
                        pagination &&
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    colSpan={12}
                                    count={count || tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page'
                                        },
                                        native: true
                                    }}
                                    onChangePage={onChangePage}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                    ActionsComponent={PaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </div>
        </Paper>
    );
};

CustomTable.propTypes = {
    page: PropTypes.number,
    setPage: PropTypes.func,
    tableData: PropTypes.array.isRequired,
    // headerData: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         key: PropTypes.string,
    //         label: PropTypes.string
    //     })
    // ),
    headerData: PropTypes.array,
    showHeader: PropTypes.bool,
    menuActions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    toolbarData: PropTypes.object,
    pagination: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    referenceData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styles: PropTypes.object,
    noDataLabel: PropTypes.string,
    elevation: PropTypes.number,
    showEmptyRows: PropTypes.bool,
    rowsPerPageOptions: PropTypes.array,
    onRowClick: PropTypes.func,
    checkBox: PropTypes.bool,
    onChecked: PropTypes.func,
    checkBoxData: PropTypes.object,
    checkBoxId: PropTypes.string,
    readOnly: PropTypes.bool,
    scrollable: PropTypes.bool,
    defaultSortKey: PropTypes.string,
    defaultSortDir: PropTypes.oneOf(['asc', 'desc']),
    pageChanged: PropTypes.func,
    count: PropTypes.number,
    rowsPerPageChanged: PropTypes.func,
    serverSidePagination: PropTypes.bool,
    defaultRowsPerPage: PropTypes.number,
    onSort: PropTypes.func,
    rowStyle: PropTypes.func,
    searching: PropTypes.bool,
    serverSideSorting: PropTypes.bool
};

CustomTable.defaultProps = {
    page: 0,
    setPage: () => { },
    elevation: 1,
    headerData: [],
    menuActions: null,
    toolbarData: {},
    pagination: false,
    showHeader: true,
    referenceData: {},
    styles: {},
    noDataLabel: 'No data to display',
    showEmptyRows: true,
    rowsPerPageOptions: [10, 25, 50, 100, 200],
    onRowClick: () => { },
    checkBox: false,
    onChecked: () => { },
    checkBoxData: {},
    checkBoxId: '',
    readOnly: false,
    scrollable: false,
    defaultSortKey: null,
    defaultSortDir: 'asc',
    pageChanged: null,
    count: null,
    rowsPerPageChanged: null,
    serverSidePagination: false,
    defaultRowsPerPage: null,
    onSort: () => { },
    rowStyle: () => {},
    searching: false,
    serverSideSorting: false
};

export default CustomTable;

import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import Route404 from '@/containers/Route404';
import Callback from '@/containers/Callback';
import Routes from '@/constants/routes';
import AuthenticatedRoute from '@/components/AuthenticatedRoute/AuthenticatedRoute';

import { selectFeatureState } from '@/state/featureFlag/reducers';

import SocketContext from '@/system/SocketContext';
import Sockets from '@/lib/Sockets';

const RouteGuardian = () => {
    const features = useSelector(state => selectFeatureState(state));
    const [routeList, setRouteList] = useState(Routes(features));

    const io = new Sockets();

    return (
        <SocketContext.Provider value={io}>
            <Switch>
                <Redirect exact from="/" to="/users" />
                <Route path="/callback" component={Callback} />
                {routeList && routeList.map(route => (
                    <AuthenticatedRoute
                        key="pageRoute"
                        requiredPermissions={route.requiredPermissions}
                        {...route}
                        component={route.component}
                    />
                ))}
                <Route component={Route404} />
            </Switch>
        </SocketContext.Provider>
    );
};

export default RouteGuardian;

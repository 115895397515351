import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import Button from '../components/Button/Button';
import { getBeanDriveJWT } from '@/state/auth/actions';
import { SET_SNACK } from '@/state/snack/types';
import { selectPermissions } from '@/state/permissions/reducers';
import { selectUserUuid } from '@/state/auth/reducers';


const BeanDrivePromo = (props) => {
    const isProd = process.env.NODE_ENV === 'production';
    const beanDriveUrl = isProd
        ? window.__env__.BEAN_DRIVE_SSO
        : process.env.REACT_APP_BEAN_DRIVE_SSO;
    const dispatch = useDispatch();
    const permissions = useSelector(state => selectPermissions(state));
    const userUuid = useSelector(state => selectUserUuid(state));

    const getJWT = async () => {
        try {
            const { data } = await getBeanDriveJWT()
            return data.token
        } catch (error) {
            console.error('LOG ~ file: BeanDrivePromo.js:24 ~ getJWT ~ error', error)
            dispatch({
                type: SET_SNACK,
                content: 'Failed to get authenticated bean drive link, please refresh to try again',
                open: true,
                props: { variant: 'error' }
            });
        }
    }

    const onClick = async () => {

        const token = await getJWT()

        console.log('isProd', isProd)
        console.log('beanDriveUrl', beanDriveUrl)
        console.log('window.__env__.BEAN_DRIVE_SSO', window.__env__.BEAN_DRIVE_SSO)
        console.log('process.env.REACT_APP_BEAN_DRIVE_SSO', process.env.REACT_APP_BEAN_DRIVE_SSO)

        window.open(`${beanDriveUrl}?token=${encodeURIComponent(token)}`, '_blank');
    }

    const hasAccess = permissions[userUuid].permissions.find(permission => ['beandrive.edit'].includes(permission))

    return (
        <React.Fragment>
            <Typography variant='h4' gutterBottom>
            Merlo Bean Drive Portal
            </Typography>

            {(hasAccess && <>
                <Typography gutterBottom>
                To access the Merlo Bean Drive portal, click the button below. You will be automatically logged in as an administrator.
                </Typography>
                <Button
                    label="BEAN DRIVE PORTAL"
                    onClick={onClick}
                />
            </>)}

            {(!hasAccess && <>
                <Typography gutterBottom>
                You do not have the necessary access permission for the Bean Drive portal. Please ask IT to upgrade your account if you need access.
                </Typography>
            </>)}

            



        </React.Fragment>
    );
};

BeanDrivePromo.propTypes = {
};

export default BeanDrivePromo;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as promotionTypes from '@/state/promotions/types';
import * as segmentTypes from '@/state/segments/types';

import { selectSegments } from '@/state/segments/reducers';
import { selectOffers } from '@/state/promotions/reducers';

import TabPanel from '@/components/TabPanel/TabPanel';
import OfferTileList from '@/containers/OfferTileList';
import PushNotificationList from '@/containers/PushNotificationList';

class MobileApp extends Component {
    constructor(props) {
        super(props);
        const { getSegments, getOffers, getNotificationHistory } = props;
        getSegments();
        getOffers();
        getNotificationHistory();
    }

    render() {
        const { offers, segments, readOnly } = this.props;
        return (
            <React.Fragment>
                <TabPanel value={0}>
                    <PushNotificationList segments={segments} readOnly={readOnly} />
                </TabPanel>
                <TabPanel value={1}>
                    <OfferTileList offers={offers} readOnly={readOnly} />
                </TabPanel>
            </React.Fragment>
        );
    }
}

MobileApp.propTypes = {
    getSegments: PropTypes.func.isRequired,
    getOffers: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    offers: PropTypes.array.isRequired,
    getNotificationHistory: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    segments: selectSegments(state),
    offers: selectOffers(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOffers: () => ({ type: promotionTypes.GET_OFFERS }),
            getSegments: () => ({ type: segmentTypes.GET_SEGMENTS }),
            getNotificationHistory: () => ({ type: segmentTypes.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY }),
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileApp);

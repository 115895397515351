import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const styles = makeStyles({
    labelRoot: {
        userSelect: 'none'
    },
    small: {
        '& svg': {
            height: 16,
            width: 16
        }
    },
    label: {
        fontSize: 14,
        fontWeight: 300
    }
});

const Checkbox = props => {
    const classes = styles();
    const { label, radioStyle, small, checked, onChange, value, disabled, className } = props;

    const rootClass = small ? `${classes.labelRoot} ${classes.small}` : classes.labelRoot;
    const labelClass = small ? classes.label : '';

    const square = 
        <MuiCheckbox checked={checked} 
            color="primary" onChange={onChange} value={value} 
            disabled={disabled}
        />;

    const circle = (
        <MuiCheckbox
            color="primary"
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={checked}
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    );

    return (
        <FormControlLabel
            label={label}
            control={radioStyle ? circle : square}
            classes={{
                root: rootClass,
                label: labelClass
            }}
            className={className}
        />
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    radioStyle: PropTypes.bool,
    small: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string
};

Checkbox.defaultProps = {
    label: null,
    radioStyle: false,
    small: true,
    checked: false,
    value: '',
    disabled: false,
    onChange: () => { },
    className: '',
};

export default Checkbox;

import axios from 'axios';

export const uploadImageToCloud = async imageData => {
    try {
        // could be nice to add a file name here...
        const response = await axios.post('/v1/uploads', {
            file: imageData
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export default uploadImageToCloud;
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import MuiColorPicker from 'material-ui-color-picker';

const ColorPicker = props => {

    const { label, value, onChange, readOnly } = props;

    const onColorSelect = (colorData) => {
        onChange({ color: colorData });
    }

    const styles = makeStyles(theme => ({
        root: {
            margin: `${theme.spacing(1)}px 0px`
        },
        charCountHelper: {
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.label.primary
        },
        labelRoot: {
            fontSize: 14,
            lineHeight: '1.3',
            color: theme.palette.label.primary
        },
        inputBorder: {
            '& fieldset': {
                borderColor: theme.palette.label.primary
            }
        },
        inputRoot: {
            cursor: 'pointer',
            fontSize: 14,
            padding: '12px 10px',
            '& fieldset': {
                borderColor: theme.palette.label.primary
            }
        },
        inputEl: {
            padding: 0,
            cursor: 'pointer',
            backgroundColor: value && value.color || '#fff'
        },
        inputError: {
            color: 'red'
        },
        inputDisabled: {
            color: `${theme.palette.label.disabled} !important`,
            '& fieldset': {
                borderColor: `${theme.palette.label.disabled} !important`
            }
        },
        inputFocused: {
            '& fieldset': {
                borderWidth: '1px !important'
            }
        },
        valid: {
            color: '#000'
        }
    }));

    const classes = styles();
    const computedHelperClass = value
        ? `${classes.valid} ${classes.charCountHelper}`
        : classes.charCountHelper;

    return (
        <MuiColorPicker
            name='color'
            defaultValue='#1F73C6'
            value={value && value.color || ''}
            onChange={onColorSelect}

            label={label}
            TextFieldProps={{
                autoComplete: 'off',
                fullWidth: true,
                variant: 'outlined',
                classes: {
                    root: classes.root
                },
                InputLabelProps: {
                    shrink: true,
                    classes: {
                        root: classes.labelRoot,
                        disabled: classes.inputDisabled
                    }
                },
                InputProps: {
                    style: { color: value && value.color },
                    readOnly,
                    classes: {
                        root: `${classes.inputRoot}`,
                        input: `${classes.inputEl} `,
                        error: classes.inputError,
                        disabled: classes.inputDisabled,
                        focused: classes.inputFocused,
                    },
                },
                FormHelperTextProps: {
                    classes: {
                        root: computedHelperClass
                    }
                }
            }}
        />

    )
}

ColorPicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
}

ColorPicker.defaultProps = {
    label: '',
    readOnly: false,
    value: null,
}


export default ColorPicker;

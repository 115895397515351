// Turn an array of json objects into a CSV
export const jsonToCsv = jsonArray => {
    if (jsonArray.length === 0) {
        return '';
    }
    const keys = Object.keys(jsonArray[0]);

    const replacer = (key, value) => {
        if (value == null) {
            return '';
        }
        return value;
    };

    let csv = jsonArray.map(row => {
        return keys.map(key => JSON.stringify(row[key], replacer)).join(',');
    });

    csv.unshift(keys.join(','));
    csv = csv.join('\r\n');

    return csv;
};

// Download a file
export const download = (data, filename, type) => {
    const file = new Blob([data], { type });
    if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else {
        // Others
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};

export function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export const toTitleCase = (phrase) => {
    return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';

import Fuse from 'fuse.js';

import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import Button from '@/components/Button/Button';
import Table from '@/components/Table/Table';
import SearchInput from '@/components/SearchInput/SearchInput';

import * as modalTypes from '@/state/modal/types';
import * as userTypes from '@/state/users/types';
import * as promoTypes from '@/state/promotions/types';
import {
    selectUserLoading,
    selectUsers,
    selectUsersMeta
} from '@/state/users/reducers';
import { selectVouchersSaveState } from '@/state/promotions/reducers';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    }
});

const fuseOpts = {
    shouldSort: false,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['profile.email', 'profile.firstName', 'profile.lastName']
};

const AssignVoucherCode = props => {
    const classes = styles();
    const [values, setValues] = React.useState({
        search: '',
        page: 0,
        pageSize: 5
    });

    const {
        closeModal,
        userLoadingState,
        code,
        assignVoucherCode,
        selectedVoucher,
        getUsers,
        users,
        meta,
        vouchersSaveState
    } = props;

    useEffect(() => {
        getUsers(values.page, values.pageSize, null, 'active');
    }, []);

    const onHandleChange = value => {
        setValues({
            ...values,
            search: value.target.value,
            page: 0
        });

        // reset page to 0 on search
        getUsers(0, values.pageSize, value.target.value, 'active');
    };

    const onSubmit = userData => {
        const {
            redemptions,
            userDisplay,
            referenceData,
            createdAt,
            updatedAt,
            userEmail,
            ...rest
        } = code;
        const { userUuid, userName } = userData;
        const newCode = { ...rest, userUuid, userName };
        assignVoucherCode(selectedVoucher.uuid, newCode);
    };

    const headerData = [
        {
            key: 'profile.firstName',
            label: 'Name',
            compute: data =>
                `${data.profile.firstName} ${data.profile.lastName}`,
            renderer: data => data
        },
        { key: 'profile.email', label: 'Email' },
        {
            key: 'status',
            label: 'Status',
            compute: rowData => {
                const { endedDate, emailVerified } = rowData;
                const active = endedDate.trim().length === 0;
                const pending = active && emailVerified.trim().length === 0;
                const archived = endedDate.trim().length > 0;

                const activeState = active && pending ? 'Pending' : 'Active';

                return archived ? 'Archived' : activeState;
            },
            renderer: data => <Chip label={data} />
        },
        {
            key: 'fom',
            label: '',
            compute: data => ({
                userUuid: data.uuid,
                userName: `${data.profile.firstName} ${data.profile.lastName}`
            }),
            renderer: data => (
                <Button
                    label="Assign"
                    loading={vouchersSaveState}
                    disabled={vouchersSaveState}
                    onClick={() => onSubmit(data)}
                />
            )
        }
    ];

    const onChangePage = page => {
        setValues({
            ...values,
            page
        });
        getUsers(page, values.pageSize, values.search, 'active');
    };

    return (
        <React.Fragment>
            <Typography className={classes.title}>
                Select a user to assign this voucher to
            </Typography>

            <SearchInput
                style={{ height: 59 }}
                value={values.search}
                placeholder="Search by Name or Email"
                onChange={onHandleChange}
                searchFilter={values.search}
            />

            <Table
                headerData={headerData}
                tableData={users}
                showHeader
                pagination
                loading={userLoadingState}
                rowsPerPageOptions={[5]}
                count={Number(meta.count)}
                pageChanged={onChangePage}
                elevation={0}
                serverSidePagination
                showEmptyRows={false}
                page={values.page}
                searching={values.search !== '' ? true : false}
            />

            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    disabled={vouchersSaveState}
                    loading={vouchersSaveState}
                    onClick={closeModal}
                />
            </div>
        </React.Fragment>
    );
};

AssignVoucherCode.propTypes = {
    selectedVoucher: PropTypes.object.isRequired,
    code: PropTypes.object.isRequired,
    assignVoucherCode: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    userLoadingState: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    selectedVoucher: state.promotions.selectedVoucher,
    users: selectUsers(state),
    meta: selectUsersMeta(state),
    userLoadingState: selectUserLoading(state),
    vouchersSaveState: selectVouchersSaveState(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers: (page, pageSize, search, status) => ({
                type: userTypes.GET_USERS,
                page,
                pageSize,
                search,
                status
            }),
            assignVoucherCode: (uuid, payload) => ({
                type: promoTypes.ASSIGN_VOUCHER_CODE,
                uuid,
                payload
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignVoucherCode);

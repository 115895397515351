import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            light: '#077cc6',
            main: '#0079c1'
        },
        background: {
            default: '#dadada',
            dark: '#131821'
        },
        label: {
            primary: '#a7a7a7',
            disabled: '#dadada'
        }
    },
    // overrides: {
    //     PrivateRadioButtonIcon: {
    //         '&:layer': {
    //             transform: 'scale(1.8)'
    //         }

    //     }
    // }
});

import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/Button/Button';
import AutomationForm from '../../components/Automations/AutomationForm';

import * as modalTypes from '@/state/modal/types';
import * as promotionTypes from '@/state/promotions/types';

import {
    selectSelectedAutomation,
    selectAutomationSaveState
} from '@/state/promotions/reducers';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: 140,
        marginTop: 12,
        marginRight: 12
    }
});

const EditAutomation = props => {
    const classes = styles();
    const {
        selectedAutomation,
        closeModal,
        createAutomation,
        updateAutomation,
        automationSaveState
    } = props;

    const isNew =
        selectedAutomation === null || selectedAutomation === undefined;

    const [values, setValues] = React.useState({
        uuid: '',
        title: '',
        repeatTime: 'Weekly',
        segmentUuid: '',
        voucherUuid: '',
        notes: '',
        active: false,
        sendEmailOnGeneration: false,
        nextRunDate: moment(),
        lastRunDate: null
    });

    useEffect(() => {
        if (selectedAutomation !== null && selectedAutomation !== undefined) {
            setValues({
                uuid: selectedAutomation.uuid,
                title: selectedAutomation.title,
                repeatTime: selectedAutomation.repeatTime,
                segmentUuid: selectedAutomation.segmentUuid,
                voucherUuid: selectedAutomation.voucherUuid,
                notes: selectedAutomation.notes,
                active: selectedAutomation.active,
                sendEmailOnGeneration: selectedAutomation.sendEmailOnGeneration,
                nextRunDate: selectedAutomation.nextRunDate
                    ? moment(selectedAutomation.nextRunDate)
                    : moment(),
                lastRunDate:
                    selectedAutomation.lastRunDate !== null &&
                    selectedAutomation.lastRunDate !== ''
                        ? moment(selectedAutomation.lastRunDate)
                        : null
            });
        }
    }, [selectedAutomation]);

    const onChange = (key, value) => {
        setValues(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const onSubmit = () => {
        const { uuid, lastRunDate, nextRunDate, ...remainingValues } = values;
        if (isNew) {
            createAutomation({
                nextRunDate: nextRunDate.toISOString(),
                ...remainingValues
            });
        } else {
            updateAutomation(uuid, {
                nextRunDate: nextRunDate.toISOString(),
                ...remainingValues
            });
        }
    };

    const canSubmit =
        values.title.trim().length > 0 &&
        values.segmentUuid.trim().length > 0 &&
        values.voucherUuid.trim().length > 0;

    return (
        <Fragment>
            <Typography className={classes.title}>
                {isNew ? 'Create Automation' : 'Edit Automation'}
            </Typography>
            <AutomationForm
                isNew={isNew}
                automation={values}
                onChange={onChange}
            />
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    disabled={automationSaveState}
                    style={{ color: '#077ABE' }}
                    onClick={closeModal}
                />
                <Button
                    className={classes.actionButton}
                    label={isNew ? 'Create' : 'Save'}
                    loading={automationSaveState}
                    disabled={!canSubmit || automationSaveState}
                    onClick={onSubmit}
                />
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    automationSaveState: selectAutomationSaveState(state),
    selectedAutomation: selectSelectedAutomation(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            createAutomation: automation => ({
                type: promotionTypes.CREATE_AUTOMATION,
                automation
            }),
            updateAutomation: (automationUuid, automation) => ({
                type: promotionTypes.UPDATE_AUTOMATION,
                automationUuid,
                automation
            })
        },
        dispatch
    );

EditAutomation.propTypes = {
    selectedAutomation: PropTypes.object
};

EditAutomation.defaultProps = {
    selectedAutomation: null
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAutomation);

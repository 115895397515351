import { call, put, take, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';

import * as snackTypes from '../snack/types';

import { selectAutomations } from '@/state/promotions/reducers';

// common
function* getOffers() {
    try {
        yield put({ type: types.OFFERS_LOADING_STATE, state: true });
        const offers = yield call(actions.getOffers);
        yield put({ type: types.GET_OFFERS_SUCCESS, offers });
        yield put({ type: types.OFFERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_OFFERS_ERROR, error });
        yield put({ type: types.OFFERS_LOADING_STATE, state: false });
    }
}

function* selectOffer(action) {
    try {
        // Get all vouchers
        yield put({ type: types.OFFERS_LOADING_STATE, state: true });
        const offers = yield call(actions.getOffers);
        yield put({ type: types.GET_OFFERS_SUCCESS, offers });

        // Select the offer we were initially after
        const offer = offers.data.find(item => item.uuid === action.uuid);
        if (offer != null) {
            yield put({ type: types.SELECT_OFFER_SUCCESS, offer });
        } else {
            yield put({
                type: types.SELECT_OFFER_ERROR,
                error: 'No offer with matching UUID'
            });
        }
        yield put({ type: types.OFFERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.SELECT_OFFER_ERROR, error });
        yield put({ type: types.OFFERS_LOADING_STATE, state: false });
    }
}

function* createOffer(action) {
    try {
        yield put({ type: types.OFFERS_SAVE_STATE, state: true });
        const offer = yield call(actions.formatOfferPayload, action.offer);
        yield call(actions.createOffer, offer);
        yield put({ type: types.CREATE_OFFER_SUCCESS });
    } catch (error) {
        yield put({ type: types.CREATE_OFFER_ERROR, error });
        yield put({ type: types.OFFERS_SAVE_STATE, state: false });
    }
}

function* updateOffer(action) {
    try {
        yield put({ type: types.OFFERS_SAVE_STATE, state: true });
        const offer = yield call(actions.formatOfferPayload, action.offer);
        yield call(actions.updateOffer, offer);
    } catch (error) {
        yield put({ type: types.UPDATE_OFFER_ERROR, error });
        yield put({ type: types.OFFERS_SAVE_STATE, state: false });
    }
}

function* deleteOffer(action) {
    try {
        yield put({ type: types.OFFERS_SAVE_STATE, state: true });
        yield call(actions.deleteOffer, action.uuid);
    } catch (error) {
        yield put({ type: types.DELETE_OFFER_ERROR, error });
        yield put({ type: types.OFFERS_SAVE_STATE, state: false });
    }
}

function* getRedemptions() {
    try {
        yield put({ type: types.REDEMPTIONS_LOADING_STATE, state: true });
        const result = yield call(actions.getRedemptions);
        const { data } = result;
        yield put({ type: types.GET_REDEMPTIONS_SUCCESS, redemptions: data });
        yield put({ type: types.REDEMPTIONS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_REDEMPTIONS_ERROR, error });
        yield put({ type: types.REDEMPTIONS_LOADING_STATE, state: false });
    }
}

function* getUserVouchers(userUuid) {
    try {
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: true });
        const vouchers = yield call(actions.getUserVouchers, userUuid);
        const { data } = vouchers;
        yield put({ type: types.GET_USERS_VOUCHERS_SUCCESS, vouchers: data });
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_USERS_VOUCHERS_ERROR, error });
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    }
}

function* getVouchers() {
    let vouchers;

    try {
        // Get Vouchers
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: true });
        vouchers = yield call(actions.getVouchers);
        yield put({ type: types.GET_VOUCHERS_SUCCESS, vouchers });
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_USERS_VOUCHERS_ERROR, error });
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    }
}

function* selectVoucher(action) {
    try {
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: true });

        // Get all vouchers
        const vouchers = yield call(actions.getVouchers);
        yield put({ type: types.GET_VOUCHERS_SUCCESS, vouchers });
        const voucher = vouchers.data.find(item => item.uuid === action.uuid);

        // Get all codes for voucher
        const codes = yield call(actions.getVoucherCodes, { voucherUuid: action.uuid, page: action.page, pageSize: action.pageSize, search: action.search });
        const voucherWithCodes = { ...voucher, voucherCodes: codes.data, meta: codes.meta};

        yield put({
            type: types.SELECT_VOUCHER_SUCCESS,
            voucher: voucherWithCodes
        });

        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.SELECT_VOUCHER_ERROR, error });
        yield put({ type: types.VOUCHERS_LOADING_STATE, state: false });
    }
}

function* createVoucher(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.createVoucher, action.data);
    } catch (error) {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error creating the voucher',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* updateVoucher(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.updateVoucher, action.voucher);
    } catch (error) {
        yield put({ type: types.UPDATE_VOUCHER_ERROR, error });
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error updating the voucher',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* createVoucherCodes(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.createVoucherCodes, action);
    } catch (error) {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error creating the voucher codes',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* createVoucherCodesForSegment(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.createVoucherCodesForSegment, action);
    } catch (error) {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error creating the voucher codes',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* updateVoucherCode(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.updateVoucherCode, action.uuid, action.payload);
    } catch (error) {
        yield put({ type: types.UPDATE_VOUCHER_CODE_ERROR });
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error updating the voucher code',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* assignVoucherCode(action) {
    try {
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
        yield call(actions.assignVoucherCode, action.uuid, action.payload);
    } catch (error) {
        yield put({ type: types.UPDATE_VOUCHER_CODE_ERROR });
        yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error updating the voucher code',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* notifyUserOfCode(action) {
    try {
        yield call(actions.notifyUserOfCode, action);
    } catch (error) {
        yield put({ type: types.PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION_ERROR });
        yield put({
          type: snackTypes.SET_SNACK,
          content: 'There was an error sending the notification',
          open: true,
          props: { variant: 'error' }
        });
    }
}

function* getAutomations() {
    try {
        yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: true });
        const result = yield call(actions.getAutomations);
        yield put({ type: types.GET_AUTOMATIONS_SUCCESS, automations: result.data });
        yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_AUTOMATIONS_ERROR, error });
        yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: false });
    }
}

function* selectAutomation(action) {
  const { automationUuid } = action;
  try {
    yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: true });
    if (automationUuid === null) {
      yield put({ type: types.SELECT_AUTOMATION_SUCCESS, automation: null });
      yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: false });
    } else {
      const automations = yield select(state => selectAutomations(state));
      const selectedAutomation = automations.find(automation => automation.uuid === automationUuid);
      yield put({ type: types.SELECT_AUTOMATION_SUCCESS, automation: selectedAutomation });
      yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: false });
    }
  } catch (error) {
    yield put({ type: types.SELECT_AUTOMATION_ERROR, error });
    yield put({ type: types.AUTOMATIONS_LOADING_STATE, state: false });
  }
}

function* createAutomation(action) {
  try {
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: true });
    yield call(actions.createAutomation, action.automation);
  } catch (error) {
    yield put({ type: types.CREATE_AUTOMATION_ERROR, error });
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: false });
  }
}

function* updateAutomation(action) {
  try {
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: true });
    yield call(actions.updateAutomation, action.automationUuid, action.automation);
  } catch (error) {
    yield put({ type: types.UPDATE_AUTOMATION_ERROR, error });
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: false });
  }
}

function* deleteAutomation(action) {
  try {
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: true });
    yield call(actions.deleteAutomation, action.automationUuid);
  } catch (error) {
    yield put({ type: types.DELETE_AUTOMATION_ERROR, error });
    yield put({ type: types.AUTOMATIONS_SAVE_STATE, state: false });
  }
}

function* deleteVoucherCode(action) {
  try {
    yield put({ type: types.VOUCHERS_SAVE_STATE, state: true });
    yield call(actions.deleteVoucherCode, action.voucherUuid, action.codeUuid);
  } catch (error) {
    yield put({ type: types.VOUCHERS_SAVE_STATE, state: false });
    yield put({ type: types.DELETE_VOUCHER_CODE_ERROR, error });
  }
}

function* getShopifyPromotions() {
    try {
        const result = yield call(actions.getShopifyPromotions);
        yield put({ type: types.GET_SHOPIFY_PROMOTIONS_SUCCESS, promotions: result.data });
    } catch (error) {
        yield put({ type: types.GET_SHOPIFY_PROMOTIONS_ERROR, error });    
    }
}

function* createShopifyPromotion(action) {
    console.info('DEBUG: function*createShopifyPromotion -> action', action);
    try {
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: true });
        const result = yield call(actions.createShopifyPromotion, action.data);
        yield put({ type: types.CREATE_SHOPIFY_PROMOTION_SUCCESS, promotion: result.data });
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: false });
    } catch (error) {
        yield put({ type: types.CREATE_SHOPIFY_PROMOTION_ERROR, error });    
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: false });
    }
}

function* updateShopifyPromotion(action) {
    try {
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: true });
        const result = yield call(actions.updateShopifyPromotion, action.data);
        yield put({ type: types.UPDATE_SHOPIFY_PROMOTION_SUCCESS, promotion: result.data });
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: false });
    } catch (error) {
        yield put({ type: types.UPDATE_SHOPIFY_PROMOTION_ERROR, error });  
        yield put({ type: types.SHOPIFY_PROMOTION_SAVE_STATE, state: false });
    }
}

function* deleteShopifyPromotion(action) {
    try {
        const result = yield call(actions.deleteShopifyPromotion, action.uuid);
        yield put({ type: types.DELETE_SHOPIFY_PROMOTION_SUCCESS, uuid: result.data.uuid });
    } catch (error) {
        yield put({ type: types.DELETE_SHOPIFY_PROMOTION_ERROR, error });    
    }
}

// flow
export default [
    takeLatest(types.GET_OFFERS, getOffers),
    takeLatest(types.SELECT_OFFER, selectOffer),
    takeLatest(types.CREATE_OFFER, createOffer),
    takeLatest(types.UPDATE_OFFER, updateOffer),
    takeLatest(types.GET_REDEMPTIONS, getRedemptions),
    takeLatest(types.GET_USERS_VOUCHERS, getUserVouchers),
    takeLatest(types.GET_VOUCHERS, getVouchers),
    takeLatest(types.CREATE_VOUCHER, createVoucher),
    takeLatest(types.UPDATE_VOUCHER, updateVoucher),
    takeLatest(types.SELECT_VOUCHER, selectVoucher),
    takeLatest(types.CREATE_VOUCHER_CODES, createVoucherCodes),
    takeLatest(types.UPDATE_VOUCHER_CODE, updateVoucherCode),
    takeLatest(types.ASSIGN_VOUCHER_CODE, assignVoucherCode),
    takeLatest(types.CREATE_SEGMENT_VOUCHER_CODES, createVoucherCodesForSegment),
    takeLatest(types.PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION, notifyUserOfCode),
    takeLatest(types.GET_AUTOMATIONS, getAutomations),
    takeLatest(types.DELETE_OFFER, deleteOffer),
    takeLatest(types.CREATE_AUTOMATION, createAutomation),
    takeLatest(types.SELECT_AUTOMATION, selectAutomation),
    takeLatest(types.UPDATE_AUTOMATION, updateAutomation),
    takeLatest(types.DELETE_AUTOMATION, deleteAutomation),
    takeLatest(types.DELETE_VOUCHER_CODE, deleteVoucherCode),
    
    takeLatest(types.GET_SHOPIFY_PROMOTIONS, getShopifyPromotions),
    takeLatest(types.CREATE_SHOPIFY_PROMOTION, createShopifyPromotion),
    takeLatest(types.UPDATE_SHOPIFY_PROMOTION, updateShopifyPromotion),
    takeLatest(types.DELETE_SHOPIFY_PROMOTION, deleteShopifyPromotion)
];

import { all } from 'redux-saga/effects';

import userSagas from './users/sagas';
import authSagas from './auth/sagas';
import locationSagas from './locations/sagas';
import promotionSagas from './promotions/sagas';
import loyaltySagas from './loyalty/sagas';
import orderSagas from './orders/sagas';
import productSagas from './products/sagas';
import partSagas from './parts/sagas';
import realtimeSagas from './realtime/sagas';
import permissionSagas from './permissions/sagas';
import segmentSagas from './segments/sagas';

export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...userSagas,
        ...locationSagas,
        ...promotionSagas,
        ...loyaltySagas,
        ...orderSagas,
        ...productSagas,
        ...partSagas,
        ...realtimeSagas,
        ...permissionSagas,
        ...segmentSagas
    ]);
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import RadioBox from '@/components/RadioBox/RadioBox';
import TextInput from '@/components/TextInput/TextInput';
import SubHeader from '@/components/SubHeader/SubHeader';
import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';

import LinkedProduct from '@/components/LinkedProduct/LinkedProduct';

import * as productTypes from '@/state/products/types';
import { selectProductSaving } from '@/state/products/reducers';

import * as types from '@/state/parts/types';
import {
    selectSelectedPart,
    selectPartsLoading,
    selectPartSaving
} from '@/state/parts/reducers';
import PartRewards from '@/components/PartDetails/PartRewards';
// import { updateProduct } from '@/state/products/actions';

const styles = theme => ({
    spacer: {
        marginTop: 60
    },
    radioGroup: {
        flexDirection: 'row'
    },
    divider: {
        marginTop: 15,
        marginBottom: 20,
        marginRight: -20,
        marginLeft: -20
    },
    label: {
        fontSize: 11,
        color: theme.palette.label.primary
    }
});

class PartDetails extends Component {
    constructor(props) {
        super(props);

        const partCode = this.getPartCodeFromRoute(props);
        props.getPart(partCode);
    }

    state = {
        taxable: 'no',
        inStock: 'yes',
        reward: 0,
        errors: []
    };

    componentDidUpdate(prevProps, prevState) {
        const { selectedPart, partsLoading } = this.props;
        console.log('LOG ~ file: PartDetails.js ~ line 70 ~ PartDetails ~ componentDidUpdate ~ selectedPart', selectedPart)
        if (!partsLoading && prevProps.partsLoading) {
            const {
                isTaxable,
                inStock,
                optionTitle,
                reward: currentReward
            } = selectedPart;
            /*eslint-disable*/

            this.setState({
                optionTitle: optionTitle,
                taxable: isTaxable ? 'yes' : 'no',
                inStock: inStock ? 'yes' : 'no',
                reward: currentReward ? currentReward.percentage : 0
            });
        }
    }

    getPartCodeFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { partcode } = params;
        return partcode;
    };

    handleRadioChange = type => event => {
        this.setState({
            [type]: event.target.value
        });
    };

    onChange = type => event => {
        this.setState({
            [type]: event.target.value
        });
    };

    onProductUnlink = type => product => {
        const { selectedPart } = this.props;
        const updatedProduct = { ...product };
        updatedProduct.parts = updatedProduct.parts.filter(
            p => p !== selectedPart.partCode
        );

        this.setState({
            removeProductFromPart: true
            // [type]: {
            //     uuid: updatedProduct.uuid,
            //     parts: updatedProduct.parts,
            // }
        });
    };

    onSave = () => {
        const { updatePart, selectedPart } = this.props;
        const { inStock, optionTitle, reward } = this.state;
        const { partCode } = selectedPart;
        const _inStock = inStock === 'yes' ? true : false;
        // console.log(this.state);
        // if (linkedProduct) {
        //     updateProduct(linkedProduct.uuid, { parts: linkedProduct.parts });
        // }
        updatePart(partCode, { inStock: _inStock, optionTitle, reward });
    };

    setErrors = (error) => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...error
            }
        })
    }

    render() {
        const {
            selectedPart,
            partsLoading,
            classes,
            readOnly,
            saving,
            productSaving
        } = this.props;


        const { linkedProduct, rewardHistory } = selectedPart;

        const { taxable, inStock, optionTitle, reward, errors } = this.state;

        const disabled = readOnly || Object.keys(errors).length > 0

        return (
            <React.Fragment>
                <SubHeader
                    onSave={() => {
                        this.onSave();
                    }}
                    lastUpdated={moment(selectedPart.updatedAt).toISOString()}
                    saving={productSaving || saving}
                    disabled={disabled}
                />
                <div className={classes.spacer} />
                <Box title="Part Details">
                    <Loader loading={partsLoading}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Option Title"
                                    value={optionTitle}
                                    onChange={this.onChange('optionTitle')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    In Stock?
                                </Typography>
                                <RadioGroup
                                    value={inStock}
                                    onChange={
                                        readOnly
                                            ? undefined
                                            : this.handleRadioChange('inStock')
                                    }
                                    className={classes.radioGroup}
                                >
                                    <RadioBox small label="Yes" value="yes" />
                                    <RadioBox small label="No" value="no" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Epicor Part Title"
                                    value={selectedPart.erpTitle}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="SKU / Part Code"
                                    value={selectedPart.partCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Tax Code"
                                    value={selectedPart.taxCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.label}>
                                            Taxable?
                                        </Typography>
                                        <RadioGroup
                                            value={taxable}
                                            className={classes.radioGroup}
                                        >
                                            <RadioBox
                                                small
                                                label="Yes"
                                                value="yes"
                                            />
                                            <RadioBox
                                                small
                                                label="No"
                                                value="no"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Stock In Epicor"
                                    value={selectedPart.stock}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Unit of measurement"
                                    value={selectedPart.unitOfMeasureCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Weight"
                                    value={selectedPart.weight}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    readOnly={readOnly}
                                    label="Price"
                                    value={`\$${Number(selectedPart.price).toFixed(2)}`}
                                />
                            </Grid>
                            <PartRewards
                                reward={reward}
                                rewardHistory={rewardHistory}
                                onRewardChange={this.onChange}
                                readOnly={readOnly}
                                setErrors={this.setErrors}
                                errors={errors}
                            />
                        </Grid>
                    </Loader>
                </Box>
                <Box title="Linked Product">
                    <Loader loading={partsLoading}>
                        {linkedProduct ? (
                            <LinkedProduct
                                part={selectedPart}
                                product={linkedProduct}
                                onUpdate={this.onProductUnlink('linkedProduct')}
                            />
                        ) : (
                                <Typography>
                                    No products linked to this part
                                </Typography>
                            )}
                    </Loader>
                </Box>
            </React.Fragment>
        );
    }
}

PartDetails.propTypes = {
    selectedPart: PropTypes.object,
    getPart: PropTypes.func.isRequired,
    updatePart: PropTypes.func.isRequired,

    partsLoading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    productSaving: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired
};

PartDetails.defaultProps = {
    selectedPart: {}
};

const mapStateToProps = state => ({
    selectedPart: selectSelectedPart(state),
    partsLoading: selectPartsLoading(state),
    saving: selectPartSaving(state),
    productSaving: selectProductSaving(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getPart: partCode => ({ type: types.GET_PART_DETAIL, partCode }),
            updatePart: (partCode, data) => ({
                type: types.UPDATE_PART,
                partCode,
                data
            })
            //  updateProduct: (uuid, productData) => ({ type: productTypes.UPDATE_PRODUCT, uuid, productData })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PartDetails));

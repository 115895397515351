import * as types from './types';

// state definition
const initialState = {
    offers: [],
    vouchers: [],
    userVouchers: [],
    redemptions: [],
    automations: [],
    offersLoading: true,
    vouchersLoading: true,
    automationsLoading: true,
    redemptionsLoading: true,
    offersSaveState: false,
    vouchersSaveState: false,
    automationSaveSate: false,
    selectedVoucher: {},
    selectedOffer: {},
    selectedAutomation: null,

    shopifyPromotions: [],
    selectedPromotion: {},
    promotionSaveState: false,
};

const vouchersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.offers.data
            };

        case types.UPDATE_OFFER_SUCCESS:
        case types.CREATE_OFFER_SUCCESS:
        case types.SELECT_OFFER_SUCCESS:
            return {
                ...state,
                selectedOffer: action.offer
            };

        case types.DELETE_OFFER_SUCCESS:
            return {
                ...state,
                offers: state.offers.filter(offer => offer.uuid !== action.uuid)
            };

        case types.GET_USERS_VOUCHERS_SUCCESS:
            return {
                ...state,
                userVouchers: action.vouchers
            };

        case types.GET_VOUCHERS_SUCCESS:
            return {
                ...state,
                vouchers: action.vouchers.data
            };

        case types.CREATE_VOUCHER_SUCCESS:
            return {
                ...state,
                vouchers: [...state.vouchers, action.voucher]
            };

        case types.UPDATE_VOUCHER_SUCCESS:
            return {
                ...state,
                vouchers: action.vouchers
            };

        case types.CREATE_VOUCHER_CODES_SUCCESS:
        case types.UPDATE_VOUCHER_CODE_SUCCESS:
        case types.DELETE_VOUCHER_CODE_SUCCESS:
        case types.UPDATE_VOUCHER_SUCCESS_TARGET_UUID:
        case types.SELECT_VOUCHER_SUCCESS:
            return {
                ...state,
                selectedVoucher: action.voucher
            };

        case types.GET_REDEMPTIONS_SUCCESS:
            return {
                ...state,
                redemptions: action.redemptions
            };

        case types.VOUCHERS_LOADING_STATE:
            return {
                ...state,
                vouchersLoading: action.state
            };

        case types.AUTOMATIONS_LOADING_STATE:
            return {
                ...state,
                automationsLoading: action.state
            };

        case types.REDEMPTIONS_LOADING_STATE:
            return {
                ...state,
                redemptionsLoading: action.state
            };

        case types.OFFERS_SAVE_STATE:
            return {
                ...state,
                offersSaveState: action.state
            };

        case types.VOUCHERS_SAVE_STATE:
            return {
                ...state,
                vouchersSaveState: action.state
            };

        case types.AUTOMATIONS_SAVE_STATE:
            return {
                ...state,
                automationSaveSate: action.state
            };

        case types.OFFERS_LOADING_STATE:
            return {
                ...state,
                offersLoading: action.state
            };

        case types.GET_AUTOMATIONS_SUCCESS:
            return {
                ...state,
                automations: action.automations
            };

        case types.SELECT_AUTOMATION_SUCCESS:
            return {
                ...state,
                selectedAutomation: action.automation
            };

        case types.CREATE_AUTOMATION_SUCCESS:
            return {
                ...state,
                automations: [...state.automations, action.automation]
            };

        case types.UPDATE_AUTOMATION_SUCCESS:
            return {
                ...state,
                automations: action.automations
            };

        case types.DELETE_AUTOMATION_SUCCESS:
            return {
                ...state,
                automations: state.automations.filter(automation => automation.uuid !== action.uuid)
            }

        case types.GET_SHOPIFY_PROMOTIONS_SUCCESS:
            return {
                ...state,
                shopifyPromotions: action.promotions
            };

        case types.CREATE_SHOPIFY_PROMOTION_SUCCESS:
        case types.UPDATE_SHOPIFY_PROMOTION_SUCCESS:
            return {
                ...state,
                shopifyPromotions: upsertSingle(state.shopifyPromotions, action.promotion)
            };
        
        case types.DELETE_SHOPIFY_PROMOTION_SUCCESS:
            console.log(action);
            return {
                ...state,
                shopifyPromotions: state.shopifyPromotions.filter(p => p.uuid !== action.uuid)
            };
        
        case types.SET_SELECTED_SHOPIFY_PROMOTION:
            return {
                ...state,
                selectedPromotion: action.uuid
            };
        
        case types.SHOPIFY_PROMOTION_SAVE_STATE:
            return {
                ...state,
                promotionSaveState: action.state
            };
        
        default:
            return state;
    }
};

export default vouchersReducer;

export const selectVouchers = state => state.promotions.vouchers;
export const selectUserVouchers = state => {
    const userUuid = state.users.selectedUser.uuid;
    const userVouchers = [...state.promotions.userVouchers];
    const filteredVouchers = [];
    userVouchers.reduce((acc, cur) => {
        const { voucherCodes: vc, ...rest } = cur;
        acc.push({
            ...rest,
            voucherCodes: vc.filter(c => c.userUuid === userUuid)
        });
        return acc;
    }, filteredVouchers);
    return filteredVouchers;
};

export const selectOffers = state => state.promotions.offers;
export const selectSelectedVoucher = state => state.promotions.selectedVoucher;
export const selectRedemptions = state => state.promotions.redemptions;
export const selectAutomations = state => {
    return state.promotions.automations.filter(automation => automation.showOnList != false)
};

export const selectOffersLoading = state => state.promotions.offersLoading;
export const selectVouchersLoading = state => state.promotions.vouchersLoading;
export const selectAutomationsLoading = state =>
    state.promotions.automationsLoading;
export const selectRedemptionsLoading = state =>
    state.promotions.redemptionsLoading;
export const selectOffersSaveState = state => state.promotions.offersSaveState;
export const selectSelectedOffer = state => state.promotions.selectedOffer;
export const selectVouchersSaveState = state =>
    state.promotions.vouchersSaveState;
export const selectSelectedAutomation = state =>
    state.promotions.selectedAutomation;
export const selectAutomationSaveState = state =>
    state.promotions.automationSaveSate;

// shopify promotions
export const selectSelectedPromotion = state => state.promotions.selectedPromotion;
export const selectShopifyPromotions = state => state.promotions.shopifyPromotions;
export const selectPromotionSaveState = state => state.promotions.promotionSaveState;

// requires a uuid as the identifier
const upsertSingle = (state, element) => {
    const currentState = [...state];
    
    // set all other elements to false prior to upsert
    if (element.active) {
        currentState.forEach(p => p.active = false);
    }

    const idx = currentState.findIndex(e => e.uuid === element.uuid);
    if (idx > -1) {
        currentState.splice(idx, 1, element);
    } else {
        currentState.push(element);
    }
    return currentState;
}
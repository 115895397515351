import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Fuse from 'fuse.js';
import { makeStyles } from '@material-ui/styles';

import ProductFilter from '@/components/Filters/ProductFilter';
import Table from '@/components/Table/Table';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import Button from '@/components/Button/Button';

import { selectProductLoading, selectProducts, selectProductSaving } from '@/state/products/reducers';

import * as modalTypes from '@/state/modal/types';
import * as productTypes from '@/state/products/types';

const fuseOpts = {
    shouldSort: false,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'parts', 'categories.title']
};

const headerData = [
    {
        key: 'image',
        label: '',
        sortable: false,
        compute: (rowData) => {
            const { images } = rowData;
            return images.length > 0 ? images[0].src : null;
        },
        renderer: (data) => <ImagePlaceholder imageUrl={data} styles={{ width: '40px', height: '40px', margin: 0 }} />
    },
    { key: 'title', label: 'Title' },
    {
        key: 'collection',
        label: 'collection',
        compute: (rowData) => {
            const { categories } = rowData;
            return categories;
        },
        renderer: (data) => <div>{data.map(cat => <div key={cat.id}>{cat.title}</div>)}</div>
    },
    {
        key: 'partCode',
        label: 'Part Code',
        compute: (rowData) => {
            const { parts } = rowData;
            return parts;
        },
        renderer: (data) => <div>{data.map((part, i) => <div key={`part-${i}`}>{part}</div>)}</div>
    },
    {
        key: 'isGrindProduct',
        label: 'Grinds',
        compute: (rowData) => {
            const { isGrindProduct } = rowData;
            return isGrindProduct;
        },
        renderer: (data) => <div>{data ? 'Yes' : 'No'}</div>
    },

]

const styles = makeStyles(theme => ({
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 140,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14,
    },
}));

// hook
const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

const AddPartsToProduct = props => {
    const classes = styles();
    const dispatch = useDispatch()

    const productSaving = useSelector(reduxState => selectProductSaving(reduxState));
    const productLoading = useSelector(reduxState => selectProductLoading(reduxState));
    const products = useSelector(reduxState => selectProducts(reduxState));

    const [search, setSearch] = React.useState('');
    const [selectedProduct, setSelectedProduct] = React.useState({});

    const onChange = type => event => {
        setSearch(event.target.value);
    }

    const onChecked = rowData => {
        // deselect and select
        const { uuid } = rowData;

        setSelectedProduct({ [uuid]: rowData });
    };

    const onCancel = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
    }

    const searchProducts = () => {
        const fuseInstance = new Fuse(products, fuseOpts);
        return fuseInstance.search(search);
    }

    const onAdd = () => {
        // get uuid of selected product
        const _productUuid = Object.keys(selectedProduct)[0];
        const { selectedParts } = props;

        const _parts = Object.keys(selectedParts);

        const _product = selectedProduct[_productUuid];
        const product = { parts: [...new Set([..._product.parts, ..._parts])] };

        // add list of selected parts to the product
        dispatch({ type: productTypes.UPDATE_PRODUCT, uuid: _productUuid, productData: product });
    }

    const tableData = search.trim().length > 0 ? searchProducts(products) : products;

    const prevSaving = usePrevious(productSaving);
    useEffect(() => {
        if (!productSaving && prevSaving) {
            onCancel();
        }
    }, [productSaving]);
    // get list of products
    // display parts
    // display list
    // select one product 
    return (
        <React.Fragment>
            <div className={classes.title}>Add Parts To Product</div>

            <ProductFilter
                onChange={onChange}
                searchFilter={search}
                searchOnly
            />
            <Table
                headerData={headerData}
                tableData={tableData}
                pagination
                loading={productLoading}
                checkBox
                showHeader={false}
                checkBoxData={selectedProduct}
                onChecked={onChecked}
                onRowClick={onChecked}
                checkBoxId='uuid'
                rowsPerPageOptions={[5]}
                styles={{ paddingRight: 0 }}
            />
            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant='outlined' label='Cancel' onClick={onCancel} disabled={productSaving} />
                <Button className={classes.button} label='+ Add To Product' onClick={onAdd} loading={productSaving} />
            </div>

        </React.Fragment>
    );
};

AddPartsToProduct.propTypes = {
    selectedParts: PropTypes.object,
};

AddPartsToProduct.defaultProps = {
    selectedParts: {}
};

export default AddPartsToProduct;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';

import * as shippingActions from '@/state/shipping/actions';
import { selectOrderEpicorData, selectOrdersLoading } from '@/state/orders/reducers';
import moment from 'moment';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px'
  },
  flexItem: {
    flex: 1, // Each item takes up an equal amount of space
  },
  lastItem: {
    flex: '0 0 auto', // The last item (icon link) should take up its required space only
  },
}));

const OrderShipping = props => {
  const { orderId } = props;
  const epicorOrderData = useSelector(state => selectOrderEpicorData(state));
  const classes = styles();
  const orderLoadingState = useSelector(state => selectOrdersLoading(state));
  const [shippingData, setShippingData] = useState({})
  const [loading, setLoading] = useState(true)

  const loadingState = loading || orderLoadingState;

  const getShippingLabels = async () => {
    setLoading(true)
    try {
      const data = await shippingActions.getShippingLabels(orderId || -1, 0, 1);
      if (data.bookings.length > 0) {
        setShippingData(data.bookings[0]);
      } else {
        setShippingData({});
      }
    } catch (error) {
      setShippingData({});
      throw error
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    // wait for orderLoadingState since the old order Id and epicor Id are still in state until that request is done
    if (orderId && !orderLoadingState) {
      getShippingLabels()
    } else {
      setShippingData({});
      setLoading(false)
    }

    return () => {
      setShippingData({})
    }
  }, [orderId, orderLoadingState])

  return (
    <Box title='Shippit Details'>
      <Loader loading={loadingState}>
        <div className={classes.flexContainer}>
          <div className={classes.flexItem}>
            <TextInput label='Courier Name' value={shippingData.courierName || ''} readOnly />
          </div>
          <div className={classes.flexItem}>
            <TextInput label='Tracking Number' value={shippingData.trackingNumber || ''} readOnly />
          </div>
          <div className={classes.flexItem}>
            <TextInput label='Current state' value={shippingData.currentState || ''} readOnly />
          </div>
          <div className={classes.flexItem}>
            <TextInput label='Booking time' value={shippingData.booked ? moment.unix(shippingData.booked).format('DD/MM/YYYY') : ''} readOnly />
          </div>
          <div className={classes.lastItem}>
            <a href={shippingData.trackingUrl} target="_blank" rel="noreferrer"><LaunchIcon /></a>
          </div>
        </div>
      </Loader>
    </Box>
  );
};

OrderShipping.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default OrderShipping;
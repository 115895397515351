import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

import {
    selectProductSaving,
    selectSelectedProduct
} from '@/state/products/reducers';
import * as types from '@/state/products/types';
import * as partTypes from '@/state/parts/types';

import SubHeader from '@/components/SubHeader/SubHeader';
import ProductData from '@/components/ProductDetails/ProductData';
// import ProductImages from '@/components/ProductDetails/ProductImages'; //no more images here, they manage them in shopify
import ProductCollection from '@/components/ProductDetails/ProductCollection';
import ProductTastingNotes from '@/components/ProductDetails/ProductTastingNotes';
import ProductGrindOptions from '@/components/ProductDetails/ProductGrindOptions';
import ProductMeta from '@/components/ProductDetails/ProductMeta';
import ProductQuiz from '@/components/ProductDetails/ProductQuiz';
import ProductLinkedParts from '@/components/ProductDetails/ProductLinkedParts';

const styles = theme => ({
    spacer: {
        marginTop: 60
    },
    radioGroup: {
        flexDirection: 'row'
    },
    divider: {
        marginTop: 15,
        marginBottom: 20,
        marginRight: -20,
        marginLeft: -20
    },
    label: {
        fontSize: 11,
        color: theme.palette.label.primary
    }
});

class ProductCreate extends Component {
    constructor(props) {
        super(props);

        props.clearSelectedProduct();
        props.getCollections();
        props.getParts();
    }

    // Empty state - children will update anything that changes
    state = {
        saveDisabled: true,
        details: {
            title: ''
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { saveDisabled, details } = this.state;
        const { saving, readOnly } = this.props;
        // Conditional checks to stop unnecessary re-renders from
        // slowing the page down
        return (
            readOnly ||
            ((saveDisabled && !nextState.saveDisabled) ||
                saving !== nextProps.saving ||
                (details.title.trim().length === 0 &&
                    nextState.details.title.trim().length > 0) ||
                (details.title.trim().length > 0 &&
                    nextState.details.title.trim().length === 0))
        );
    }

    componentDidUpdate(prevProps) {
        const { saving, selectedProduct, history } = this.props;
        if (!saving && prevProps.saving) {
            // saving occurred
            if ('uuid' in selectedProduct) {
                // redirect to
                const { uuid } = selectedProduct;
                history.push(`/products/${uuid}`);
            }
        }
    }

    onSave = () => {
        // pass the data to the saga for parsing and uploading
        const { createProduct } = this.props;

        const {
            isShippable,
            isGrindProduct,
            saveDisabled,
            meta,
            ...rest
        } = this.state;
        console.log(
            '🚀 ~ file: ProductCreate.js ~ line 100 ~ ProductCreate ~ rest',
            rest
        );

        createProduct({
            ...rest,
            meta: {
                ...meta,
                isShippable: isShippable || true,
                isGrindProduct: isGrindProduct || false
            }
        });
    };

    onObjectUpdate = type => data => {
        this.setState(prevState => ({
            [type]: {
                ...prevState[type],
                ...data
            },
            saveDisabled: false
        }));
    };

    onArrayUpdate = type => data => {
        this.setState({
            [type]: [...data],
            saveDisabled: false
        });
    };

    render() {
        const { classes, readOnly, saving } = this.props;
        const { saveDisabled, details } = this.state;

        const hasRequiredField =
            details && 'title' in details && details.title.trim().length > 0;

        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSave}
                    saving={saving}
                    lastUpdate={moment().toISOString()}
                    disabled={saveDisabled || !hasRequiredField || readOnly}
                />
                <div className={classes.spacer} />
                <ProductData
                    isNew
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('details')}
                    loading={false}
                />
                {/* <ProductImages
                    isNew
                    readOnly={readOnly}
                    onUpdate={this.onArrayUpdate('images')}
                    loading={false}
                /> */}
                {/* <ProductCollection
                    isNew
                    readOnly={readOnly}
                    onUpdate={this.onArrayUpdate('categories')}
                    onObjectUpdate={this.onObjectUpdate('categories')}
                    loading={false}
                />
                <ProductTastingNotes
                    isNew
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('tastingNotes')}
                    loading={false}
                />
                <ProductGrindOptions
                    isNew
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('grinds')}
                    loading={false}
                />
                <ProductMeta
                    readOnly={readOnly}
                    onUpdate={this.onObjectUpdate('meta')}
                    loading={false}
                />
                <ProductLinkedParts
                    readOnly={readOnly}
                    onUpdate={this.onArrayUpdate('parts')}
                    loading={false}
                /> */}
            </React.Fragment>
        );
    }
}

ProductCreate.propTypes = {
    classes: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired,

    selectedProduct: PropTypes.object.isRequired,

    createProduct: PropTypes.func.isRequired,
    getCollections: PropTypes.func.isRequired,
    clearSelectedProduct: PropTypes.func.isRequired,
    getParts: PropTypes.func.isRequired,

    readOnly: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    saving: selectProductSaving(state),
    selectedProduct: selectSelectedProduct(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createProduct: productData => ({
                type: types.CREATE_PRODUCT,
                productData
            }),
            getCollections: () => ({ type: types.GET_SHOPIFY_COLLECTIONS }),
            getParts: () => ({ type: partTypes.GET_PARTS }),
            clearSelectedProduct: () => ({ type: types.CLEAR_SELECTED_PRODUCT })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(ProductCreate))
);

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles({
    paper: {
        borderRadius: 2,
        padding: 20,
        marginTop: 20
    },
    toolbar: {
        minHeight: 0,
        marginBottom: 20
    },
    title: {
        flex: 1,
        fontSize: 20,
        lineHeight: 1.2
    },
    toolbarAction: {
        display: 'flex',
        alignItems: 'center'
    }
});

const Box = props => {
    const classes = styles();
    const {
        rootClass,
        toolbarClass,
        title,
        children,
        toolbarAction,
        className,
        readOnly,
        noToolbar
    } = props;
    const computedRoot = rootClass || classes.paper;
    const toolbarRoot = toolbarClass || classes.toolbar;

    return (
        <Paper className={computedRoot}>
            {!noToolbar && (
                <Toolbar className={toolbarRoot} disableGutters>
                    <Typography className={classes.title}>{title}</Typography>
                    {!readOnly && (
                        <div className={classes.toolbarAction}>
                            {toolbarAction}
                        </div>
                    )}
                </Toolbar>
            )}
            <div className={className}>{children}</div>
        </Paper>
    );
};

Box.propTypes = {
    rootClass: PropTypes.string,
    toolbarClass: PropTypes.string,
    title: PropTypes.string,
    toolbarAction: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    noToolbar: PropTypes.bool
};

Box.defaultProps = {
    rootClass: null,
    toolbarClass: null,
    title: null,
    toolbarAction: null,
    children: null,
    className: '',
    readOnly: false,
    noToolbar: false
};

export default Box;

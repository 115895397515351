import { call, put, takeLatest, takeEvery, takeLeading } from 'redux-saga/effects';

import * as types from './types';
import * as actions from './actions';

function* getUserPermissions(request) {
    const { userUuid } = request;
    try {
        const permissions = yield call(actions.getUserPermissions, userUuid);
        yield put({ type: types.GET_USER_PERMISSIONS_SUCCESS, userUuid, permissions });
    } catch (error) {
        yield put({ type: types.GET_USER_PERMISSIONS_ERROR, error });
    }
}

function* updateUserPermissions(request) {
    const { uuid: userUuid, permissions, roles } = request;
    try {
        const result = yield call(actions.updateUserPermissions, { userUuid, permissions, roles });
    } catch (error) {
        yield console.log(error);
    }
}

export default [
    takeLeading(types.GET_USER_PERMISSIONS, getUserPermissions),
    takeLatest(types.UPDATE_USER_PERMISSIONS, updateUserPermissions),
]
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import TextInput from '@/components/TextInput/TextInput';

import IconAcidities from '@/assets/images/icon-acidities.svg';
import IconAromas from '@/assets/images/icon-aromas.svg';
import IconBodies from '@/assets/images/icon-bodies.svg';
import IconFinishes from '@/assets/images/icon-finishes.svg';
import IconFlavours from '@/assets/images/icon-flavours.svg';

import { selectSelectedProduct } from '@/state/products/reducers';

const styles = makeStyles({
    container: {
        textAlign: 'center'
    },
    icon: {
        padding: '0px 5px'
    },
    aromasIcon: {
        padding: '0px 8px'
    }
});

const ProductTastingNotes = props => {
    const classes = styles();
    const { readOnly, onUpdate, loading, isNew } = props;
    const [tastingNotesState, setTastingNotesState] = React.useState({
        flavours: '',
        aromas: '',
        finishes: '',
        acidities: '',
        bodies: ''
    });

    const product = useSelector(state => selectSelectedProduct(state));
    const { tastingNotes } = product;

    if (!isNew) {
        useEffect(() => {
            if (!loading && tastingNotes !== undefined) {
                const {
                    flavours,
                    aromas,
                    finishes,
                    acidities,
                    bodies
                } = tastingNotes;
                
                setTastingNotesState({
                    flavours:
                        flavours.length > 0 ? flavours.join(', ') : flavours[0],
                    aromas: aromas.length > 0 ? aromas.join(', ') : aromas[0],
                    finishes:
                        finishes.length > 0 ? finishes.join(', ') : finishes[0],
                    acidities:
                        acidities.length > 0
                            ? acidities.join(', ')
                            : acidities[0],
                    bodies: bodies.length > 0 ? bodies.join(', ') : bodies[0]
                    
                });
            }
        }, [loading]);
    }

    const onChange = type => event => {
        const updatedTastingNotes = {
            ...tastingNotesState,
            [type]: event.target.value
        };
        setTastingNotesState(updatedTastingNotes);
        onUpdate(updatedTastingNotes);
    };


    const { 
        flavours,
        aromas,
        finishes,
        acidities,
        bodies } = tastingNotesState;

    return (
        <Box title="Tasting Notes" className={classes.container}>
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <p>Leave all fields blank to hide this section on the product's detail page.</p>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label="Flavours"
                            readOnly={readOnly}
                            value={flavours}
                            onChange={onChange('flavours')}
                            startAdornment={
                                <img
                                    className={classes.icon}
                                    src={IconFlavours}
                                    alt="..."
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label="Aromas"
                            readOnly={readOnly}
                            value={aromas}
                            onChange={onChange('aromas')}
                            startAdornment={
                                <img
                                    className={classes.aromasIcon}
                                    src={IconAromas}
                                    alt="..."
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label="Finishes"
                            readOnly={readOnly}
                            value={finishes}
                            onChange={onChange('finishes')}
                            startAdornment={
                                <img
                                    className={classes.icon}
                                    src={IconFinishes}
                                    alt="..."
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label="Acidities"
                            readOnly={readOnly}
                            value={acidities}
                            onChange={onChange('acidities')}
                            startAdornment={
                                <img
                                    className={classes.icon}
                                    src={IconAcidities}
                                    alt="..."
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label="Bodies"
                            readOnly={readOnly}
                            value={bodies}
                            onChange={onChange('bodies')}
                            startAdornment={
                                <img
                                    className={classes.icon}
                                    src={IconBodies}
                                    alt="..."
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductTastingNotes.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductTastingNotes.defaultProps = {
    isNew: false
};

export default ProductTastingNotes;

import * as types from './types';

const initialState = {
    selectedTab: 0
}

const tabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TABS_SET_TAB_INDEX:
            return {
                selectedTab: action.tabId,
            }
        default:
            return state;
    }
}

export default tabsReducer;

export const selectTabIndex = state => state.tabs.selectedTab;
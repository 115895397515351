import axios from 'axios';

const {CancelToken} = axios;
let cancel = null;
export const getOrder = async request => {
    const { orderId } = request;
    try {
        const result = await axios.get(`/v1/orders/${orderId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getAllOrders = async (
    page,
    pageSize,
    search,
    source,
    dateFrom,
    dateTo,
    showUploadedToEpicor,
    showNotUploadedToEpicor,
    showLoyaltyUploaded,
    showLoyaltyNotUploaded,
    sortBy,
    order
) => {
    try {
        let query = `page=${page}&limit=${pageSize}`;

        if (search) {
            query += `&search=${encodeURIComponent(search)}`;
        }

        if (source) {
            query += `&source=${source}`;
        }

        if (dateFrom) {
            query += `&dateFrom=${dateFrom}`;
        }

        if (dateTo) {
            query += `&dateTo=${dateTo}`;
        }

        if (showUploadedToEpicor) {
            query += `&showUploadedToEpicor=${showUploadedToEpicor}`;
        }

        if (showNotUploadedToEpicor) {
            query += `&showNotUploadedToEpicor=${showNotUploadedToEpicor}`;
        }

        if (showLoyaltyUploaded) {
            query += `&showLoyaltyUploaded=${showLoyaltyUploaded}`;
        }

        if (showLoyaltyNotUploaded) {
            query += `&showLoyaltyNotUploaded=${showLoyaltyNotUploaded}`;
        }

        if (sortBy) {
            query += `&sortBy=${sortBy}`;
        }

        if (order) {
            query += `&order=${order}`;
        }

        if(cancel && search) { // cancel previous requests when searching
            cancel();
        }

        const result = await axios.get(`/v1/orders?${query}`, {
            cancelToken: new CancelToken((c) => {
                cancel = c
            })
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUserOrders = async request => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/orders/history/${userUuid}?limit=9999&page=0`);

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const submitToEpicor = async request => {
    const { order } = request;
    try {
        const result = await axios.post('/v1/orders/epicor', order);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getEpicorRawData = async request => {
    console.info('TCL: request', request);
    const { orderId } = request;
    try {
        const result = await axios.get(`/v1/orders/raw/${orderId}`)
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getEpicorUploadSetting = async request => {
    try {
        const result = await axios.get('/v1/epicor/settings/upload')
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const updateEpicorSetting = async request => {
    try {
        const result = await axios.put(`/v1/epicor/settings/${request.key}`, {
            value: request.value
        })
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getEpicorConnectionDetails = async () => {
    try {
        const result = await axios.get('/v1/epicor/connection-details')
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const updateOrder = async (request) => {
    try {
        const { orderId, payload } = request;

        const result = await axios.put(`/v1/orders/${orderId}`, { ...payload })
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const getRewardSetting = async () => {
    const result = await axios.get('/v1/loyalty/settings?key=allowRewardTransactions');
    return result.data;
};

export const toggleRewardSetting = async () => {
    const result = await axios.patch('/v1/loyalty/settings/allowRewardTransactions');
    return result.data;
}

export const manuallyProcessRewards = async (orderId) => {
    const result = await axios.patch(`/v1/orders/manually-process-rewards/${orderId}`);
    return result.data;
};

export const processRewards = async (orderId) => {
    const result = await axios.post(`/v1/loyalty/orders/${orderId}/rewards`);
    return result.data;
};

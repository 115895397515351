import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// import Button from '@/components/Button/Button';
import Table from '@/components/Table/Table';

import { selectOrdersLoading } from '@/state/orders/reducers';

const headerData = [
    {
        key: 'createdAt',
        label: 'Date',
        compute: rowData => moment(rowData.createdAt),
        renderer: data => moment(new Date(data)).format('DD/MM/YYYY hh:mm:ss A')
    },
    { key: 'name', label: 'ID' },
    {
        key: 'total',
        label: 'Total',
        compute: rowData => rowData.totals[0].total,
        renderer: data => `$ ${data}`,
        sortData: (a, b, order, compute) => {
            /* eslint-disable-next-line */
            return order === 'desc'
                ? compute(b) - compute(a)
                : compute(a) - compute(b);
        }
    },
    {
        key: 'totalReward',
        label: 'Bean dollars',
        compute: ({ beanDollars }) =>
            Number(beanDollars.toFixed(2)) || Number('0.00'),
        renderer: data => `$${data.toFixed(2)}`,
        sortData: (a, b, order, compute) => {
            /* eslint-disable-next-line */
            return order === 'desc'
                ? compute(b) - compute(a)
                : compute(a) - compute(b);
        }
    }
];

const OrderHistory = props => {
    const { orders, history } = props;
    const [page, setPage] = useState(0)
    const ordersLoading = useSelector(state => selectOrdersLoading(state));

    const onRowClick = rowData => {
        history.push(`/orders/${rowData.orderId}`);
    };

    return (
        <Table
            headerData={headerData}
            tableData={orders}
            toolbarData={{ title: 'Order History' }}
            loading={ordersLoading}
            noDataLabel="No order history"
            pagination
            onRowClick={onRowClick}
            defaultSortKey="createdAt"
            defaultSortDir="desc"
            page={page}
            setPage={setPage}
        />
    );
};

OrderHistory.propTypes = {
    orders: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(OrderHistory);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import { useSelector } from 'react-redux';

import TextInput from '@/components/TextInput/TextInput';
import Box from '@/components/Box/Box';

// import { selectSelectedSegment } from '@/state/segments/reducers';

const styles = makeStyles(theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    },
}))

const SegmentData = props => {
    const { onChange, segment, readOnly, errors } = props;

    const classes = styles();
    // const segmentDetails = useSelector(state => selectSelectedSegment(state));
    const onUpdate = type => event => {
        onChange(type, event.target.value);
    };

    const getError = name => {
        if(errors && Object.prototype.hasOwnProperty.call(errors, name)) {
            return errors[name];
        }

        return null;
    }

    return (
        <React.Fragment>
            <Box
                rootClass={classes.root}
                title='Segment Details'
            >
                <TextInput label='Name' readOnly={readOnly} value={segment.name} required={true} onChange={onUpdate('name')} error={getError('name') ? true : false} helperText={getError('name')} />
                <TextInput label='Description' readOnly={readOnly} multiline value={segment.description} onChange={onUpdate('description')} />
            </Box>
        </React.Fragment>
    );
};

SegmentData.propTypes = {
    onChange: PropTypes.func.isRequired,
    segment: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
};

SegmentData.defaultProps = {
    segment: null
}

export default SegmentData;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';

import { GoogleApiWrapper } from 'google-maps-react';

import SubHeader from '@/components/SubHeader/SubHeader';
import StatusChip from '@/components/Locations/StatusChip';

import Details from '@/components/Locations/Details';
import ImageUpload from '@/components/Locations/ImageUpload';
import Options from '@/components/Locations/Options';

import * as locationTypes from '@/state/locations/types';

import { selectLocationSaveState, selectLocation } from '@/state/locations/reducers';

import { validateLocation } from '@/constants/validation';

const styles = theme => ({
    addUserContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    chipsContainer: {
        flex: 1
    },
    chip: {
        margin: theme.spacing(1)
    },
    spacer: {
        marginTop: 60
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px'
    },
    button: {
        fontSize: 14,
        width: 125,
        padding: '5px 0px'
    }
});

class LocationAdd extends Component {
    constructor(props) {
        super(props);

        props.setLocationLoading(false);
    }

    state = {
        location: {
            image: {
                src: null,
                isNew: false
            },
            title: '',
            description: '',
            bodyHtml: '',
            email: '',
            phone: '',
            website: '',
            fax: '',
            links: {
                facebook: '',
                twitter: '',
                instagram: '',
            },
            addressFormatted: '',
            address: {
                address1: '',
                address2: '',
                suburb: '',
                city: '',
                postcode: '',
                state: '',
                country: ''
            },
            isBeanRetailer: false,
            isCoffeeRetailer: false,
            isAlcoholLicensed: false,
            isMerloOwned: false,
            hasFreeWifi: false,
            lat: -25.34449,
            lng: 134.035431,
        },
        isNew: true,
        errors: {}
    };

    componentDidUpdate = (prevProps) => {
        const { locationSaveState, selectedLocation, history } = this.props;
        if (!locationSaveState && prevProps.locationSaveState) {
            // saving occurred
            if ('uuid' in selectedLocation) {
                // redirect to
                const { uuid } = selectedLocation;
                history.push(`/locations/${uuid}`);
            }
        }
    }

    onChange = (target, data) => {
        this.setState(prevState => {
            return {
                location: {
                    ...prevState.location,
                    [target]: data
                }
            };
        });
        this.setState({ [target]: data });
    };
        

    onAddressChange = (target, data) => {
        this.setState((prevState) => {
            return {
                location: {
                    ...prevState.location,
                    address: {
                        ...prevState.location.address,
                        [target]: data
                    }
                }
            }
            
        });
    }

    onBodyHtmlChange = e => {
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                bodyHtml: e
            }
        }));
    };

    onSave = () => {
        const { location } = this.state;

        console.log('state -> ', this.state);

        const {
            facebook, instagram, twitter,
            address,
            title, lat, lng,
            ...rest
        } = location;

        const { createLocation } = this.props;


        const titleValidation =
            title !== undefined ? title : '';
        const latValidation = lat !== undefined ? lat : 0;
        const lngValidation = lng !== undefined ? lng : 0;

        const {
            address1,
            address2,
            suburb,
            city,
            postcode,
            state,
            country
        } = address;

        const addressFormatted = `${address1} ${address2} ${suburb} ${city} ${postcode} ${state} ${country}`;

        const newErrors = validateLocation(
            titleValidation,
            latValidation,
            lngValidation,
            address
        );
        console.log('LOG ~ file: LocationAdd.js ~ line 180 ~ LocationAdd ~ newErrors', newErrors, address)

        if (newErrors !== undefined) {
            this.setState({
                errors: newErrors
            });
        } else {
            // Clear errors
            this.setState({
                errors: {}
            });

            // Build payload and send it off
            const payload = {
                ...rest,
                ...location,
                addressFormatted,
                links: {
                    facebook,
                    instagram,
                    twitter
                }
            };

            console.log('payload 1 -> ', payload);

            delete payload.facebook;
            delete payload.instagram;
            delete payload.twitter;

            console.log('payload 2 -> ', payload);

            createLocation(payload);
        }
    };

    render() {
        const { classes, google, locationSaveState } = this.props;
        const { location } = this.state;
        const { errors } = this.state;

        return (
            <React.Fragment>
                <SubHeader saving={locationSaveState} onSave={this.onSave} />
                <div className={classes.spacer} />

                <div className={classes.actions}>
                    <div className={classes.chipsContainer}>
                        <StatusChip selectedLocation={location} />
                    </div>
                </div>
                <ImageUpload
                    location={location}
                    onChange={this.onChange}
                />
                <Details
                    google={google}
                    location={location}
                    onChange={this.onChange}
                    onAddressChange={this.onAddressChange}
                    onBodyHtmlChange={this.onBodyHtmlChange}
                    errors={errors}
                />
                <Options location={location} onChange={this.onChange} />
                
            </React.Fragment>
        );
    }
}

LocationAdd.propTypes = {
    classes: PropTypes.object.isRequired,
    setLocationLoading: PropTypes.func.isRequired,
    google: PropTypes.object.isRequired,
    createLocation: PropTypes.func.isRequired,
    locationSaveState: PropTypes.bool.isRequired,
    selectedLocation: PropTypes.object,
    history: PropTypes.object.isRequired,
};

LocationAdd.defaultProps = {
    selectedLocation: {}
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setLocationLoading: state => ({
                type: locationTypes.LOCATIONS_LOADING_STATE,
                state
            }),
            createLocation: locationData => ({
                type: locationTypes.CREATE_LOCATION,
                locationData
            })
        },
        dispatch
    );

const mapStateToProps = state => ({
    locationSaveState: selectLocationSaveState(state),
    selectedLocation: selectLocation(state),
    apiKey:
        process.env.NODE_ENV === 'production'
            ? window.__env__.GOOGLE_MAPS_API_KEY
            : process.env.REACT_APP_GOOGLE_MAPS_API_KEY
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        GoogleApiWrapper(props => {
            return { apiKey: props.apiKey };
        })(LocationAdd)
    )
);

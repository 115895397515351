import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import TextInput from '@/components/TextInput/TextInput';
import SelectInput from '@/components/TextInput/SelectInput';

import { selectSegments } from '@/state/segments/reducers';

const NotificationForm = props => {
    const { internalDescription, segmentUuid, title, body, onChange } = props;

    const segments = useSelector(state => selectSegments(state));
    const segmentOptions = segments.map(seg => ({
        value: seg.uuid,
        label: seg.name
    }));

    const onAnyChange = type => event => {
        if (onChange !== null) {
            onChange(type, event);
        }
    };

    const readOnly = onChange === null;

    const selectedSegment = segments.find(seg => seg.uuid === segmentUuid);
    const segmentLabel = selectedSegment ? selectedSegment.name : '';

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    label='Internal description'
                    value={internalDescription}
                    onChange={onAnyChange('internalDescription')}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    label="Select segment"
                    options={segmentOptions}
                    value={readOnly ? segmentLabel : segmentUuid}
                    onChange={onAnyChange('segment')}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="Push notification title"
                    value={title}
                    onChange={onAnyChange('title')}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="Push notification message"
                    value={body}
                    onChange={onAnyChange('body')}
                    readOnly={readOnly}
                    multiline
                    rowsMax={3}
                />
            </Grid>
        </Grid>

    );
};

NotificationForm.propTypes = {
    internalDescription: PropTypes.string.isRequired,
    segmentUuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

NotificationForm.defaultProps = {
    onChange: null,
};

export default NotificationForm;

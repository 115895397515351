import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@/components/Button/Button';
import TextInput from '@/components/TextInput/TextInput';
import SelectInput from '@/components/TextInput/SelectInput';
import Datepicker from '@/components/Datepicker/Datepicker';
import Checkbox from '@/components/Checkbox/Checkbox';

import * as modalTypes from '@/state/modal/types';
import * as promotionTypes from '@/state/promotions/types';
import * as segmentTypes from '@/state/segments/types';

import { selectSegments, selectSegmentUsers } from '@/state/segments/reducers';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    secondConfirmationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 16
    },
    secondConfirmationButtons: {
        textAlign: 'center',
        marginTop: 20
    }
});

const GenerateVoucherCodes = props => {
    const classes = styles();
    const { closeModal, selectedVoucher, getSegmentUsers, vouchersSaveState } = props;
    const [values, setValues] = React.useState({
        count: '',
        segment: '',
        claims: selectedVoucher.defaultNumberOfRedemptions,
        redemptions: selectedVoucher.defaultNumberOfRedemptionsPerUser,
        startDate: moment(),
        expiryDate: moment().add(selectedVoucher.defaultExpiry, 'days'),
        sendEmail: false,
        secondConfirm: false
    });

    // Get segments then map them for use in dropdown
    const segments = useSelector(state => selectSegments(state));
    const segmentOptions = segments.map(seg => ({
        value: seg.uuid,
        label: seg.name
    }));

    // If a segment is selected - this is the number of codes to generate
    const segmentUsersLength = useSelector(state => selectSegmentUsers(state))
        .length;

    const onHandleChange = (key, isNumber) => event => {
        // Error handling for datepickers
        const value = event.target === undefined ? event : event.target.value;

        if (isNumber) {
            setValues({ ...values, [key]: value.replace(/[^0-9]/gi, '') });
        } else {
            setValues({ ...values, [key]: value });
        }
    };

    // Get the number of segment users to display
    // in generate code field
    const onSelectChange = value => {
        if (value !== '') {
            getSegmentUsers(value);
        }
        setValues({ ...values, segment: value });
    };

    const onToggleChange = () => {
        setValues({ ...values, sendEmail: !values.sendEmail });
    };

    const onSubmit = () => {
        const { createVoucherCodes, createVoucherCodesForSegment } = props;
        const {
            count,
            startDate,
            expiryDate,
            redemptions,
            claims,
            segment,
            sendEmail
        } = values;

        const payload = {
            count,
            startDate: startDate.utc(),
            expiry: expiryDate.diff(startDate, 'days'),
            maximumRedemptionsPerUser: Number(redemptions),
            maximumRedemptions: Number(claims)
        };

        if (segment.trim().length > 0) {
            createVoucherCodesForSegment(selectedVoucher.uuid, segment, {
                ...payload,
                count: segmentUsersLength,
                sendEmailOnGeneration: sendEmail
            });
        } else {
            createVoucherCodes(selectedVoucher.uuid, payload);
        }
    };

    const handleSubmit = () => {
        if (values.segment.trim().length > 0 && values.sendEmail) {
            setValues({ ...values, secondConfirm: true });
        } else onSubmit();
    };

    const startDate = moment(values.startDate);
    const endDate = moment(values.expiryDate);
    const daysDiff = endDate.diff(startDate, 'days');
    const codesToGenerate =
        values.segment !== '' ? segmentUsersLength : values.count;

    const isDisabled =
        values.segment.trim().length > 0 && segmentUsersLength > 0
            ? false
            : values.count === '' ||
              values.count <= 0

    const firstConfirmation = (
        <React.Fragment>
            <Typography className={classes.title}>
                Generate codes for this voucher
            </Typography>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label="Segment (optional)"
                        options={[
                            { value: '', label: 'None' },
                            ...segmentOptions
                        ]}
                        value={values.segment}
                        onChange={value => onSelectChange(value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        label="Number of codes to generate"
                        value={codesToGenerate}
                        name="title"
                        disabled={values.segment !== ''}
                        onChange={onHandleChange('count', true)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Datepicker
                        label="Start Date"
                        value={startDate}
                        name="createdAt"
                        maxDate={endDate}
                        onChange={onHandleChange('startDate')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Datepicker
                        label="End Date"
                        value={endDate}
                        name="createdAt"
                        minDate={startDate}
                        onChange={onHandleChange('expiryDate')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        readOnly
                        disabled
                        label="Expiry in days"
                        value={daysDiff}
                        name="expiry"
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <TextInput
                        label="Max claims per user for each code"
                        value={values.redemptions}
                        type="number"
                        name="title"
                        onChange={onHandleChange('redemptions')}
                    />
                </Grid> */}
                <Grid item xs={6}>
                    <TextInput
                        label="Total max claims for each code"
                        value={values.claims}
                        name="title"
                        type="number"
                        onChange={onHandleChange('claims')}
                    />
                </Grid>
                {values.segment.trim().length > 0 && (
                    <Grid className={classes.flex} item xs={12}>
                        <Checkbox
                            checked={values.sendEmail}
                            label="Send email on generation"
                            onChange={onToggleChange}
                        />
                    </Grid>
                )}
            </Grid>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    disabled={vouchersSaveState}
                    loading={vouchersSaveState}
                    onClick={closeModal}
                />
                <Button
                    className={classes.actionButton}
                    label="Generate codes"
                    loading={vouchersSaveState}
                    disabled={isDisabled || vouchersSaveState}
                    onClick={handleSubmit}
                />
            </div>
        </React.Fragment>
    );

    const secondConfirmation = (
        <div className={classes.secondConfirmationContainer}>
            <Typography align="center">{`You are about to generate and email codes to ${codesToGenerate} people.`}</Typography>
            <Typography align="center">
                Are you sure you wish to continue?
            </Typography>
            <div className={classes.secondConfirmationButtons}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant="outlined"
                    style={{ color: '#077abe' }}
                    onClick={() =>
                        setValues({ ...values, secondConfirm: false })
                    }
                />
                <Button
                    className={classes.actionButton}
                    label="Confirm"
                    disabled={false}
                    onClick={onSubmit}
                />
            </div>
        </div>
    );

    return values.secondConfirm ? secondConfirmation : firstConfirmation;
};

GenerateVoucherCodes.propTypes = {
    closeModal: PropTypes.func.isRequired,
    createVoucherCodes: PropTypes.func.isRequired,
    selectedVoucher: PropTypes.object.isRequired,
    getSegmentUsers: PropTypes.func.isRequired,
    createVoucherCodesForSegment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    selectedVoucher: state.promotions.selectedVoucher,
    vouchersSaveState: state.promotions.vouchersSaveState
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getSegmentUsers: segmentUuid => ({
                type: segmentTypes.GET_SEGMENT_USERS,
                segmentUuid
            }),
            createVoucherCodes: (voucherUuid, voucher) => ({
                type: promotionTypes.CREATE_VOUCHER_CODES,
                voucherUuid,
                voucher
            }),
            createVoucherCodesForSegment: (
                voucherUuid,
                segmentUuid,
                voucher
            ) => ({
                type: promotionTypes.CREATE_SEGMENT_VOUCHER_CODES,
                voucherUuid,
                segmentUuid,
                voucher
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerateVoucherCodes);

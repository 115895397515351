import axios from 'axios';

export const getUserPermissions = async (userUuid) => {
    try {
        const result = await axios.get(`/v1/permissions/${userUuid}`);

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const updateUserPermissions = async (request) => {
    console.info('DEBUG: updateUserPermissions -> request', request);

    const { userUuid, roles, permissions } = request;
    try {
        const result = await axios.put(`/v1/permissions/${userUuid}`, {
            permissions,
            roles
        })

        return result.data;

    } catch (error) {
        throw error;
    }
}

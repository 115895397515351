import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
    container: {
        display: 'block',
        justifyContent: 'center',
        margin: '0px 0px 20px 0px',
    }
}));

const Trends = props => {
    const classes = styles();

    return (
        <div className={classes.container}>

            <h2>Revenue</h2>

            <iframe title='Revenue 30 days' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=f8de42fc-82ec-48af-b6a9-050c87c5cd0d&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />



            <iframe title='Revenue per month' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=db42d92a-46ba-40df-82a8-a5a4f2731ebe&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />


            <iframe title='Revenue per year' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=6d42c2ba-d103-4e5e-8247-59541c690ec4&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <h2>Orders</h2>

            <iframe title='Average spend per order' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=534f87ca-0c4b-48f1-b3d6-141fa55aac7a&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Orders Placed' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=cc0324c9-e9a6-490d-8c14-d2bbfb854e7e&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Orders by Delivery State (AU)' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=a20ec1fb-6712-4465-9873-98a93db23bda&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Company Orders' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=fa2ce049-57e6-4e8d-a834-40117d4c2880&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Number of items in orders - AVG' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=f409528b-6013-41a5-8ef5-74cf90890feb&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Number of items in orders - Counts' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=e0f3b6e7-4479-49ad-a36f-2393b99e4024&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />


            <h2>Memberships</h2>

            <iframe title='User Registrations' style={{ border: 'none' }}
                width="100%"
                height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=7a345539-1de3-44f3-a7a7-30779f2c6a7b&tenant=3a83be70-2810-40ed-ae8a-32e5f4eb354c" />

            <iframe title='Mailgun Statistics'
                style={{ border: 'none' }}
                width="100%" height="480"
                frameBorder="0"
                src="https://charts.mongodb.com/charts-merlo-prod-tksza/embed/charts?id=bf0c3eb2-8a3c-4186-bc87-fe15294ef0d2&theme=light" />


            {/* <h2>Google Analytics</h2>
            <p>Blended between the old analytics account and the new one, where possible.</p>

            <iframe title='Google Analytics' style={{ border: 'none' }} 
            width="100%" 
            height="600" 
            frameBorder="0"
            src="https://datastudio.google.com/embed/reporting/1WsuV8Xqzt37IirOzpMjNvo-Cy4Lt4RR7/page/muiw" allowFullScreen /> */}

        </div>
    );
};

Trends.propTypes = {

};

export default Trends;
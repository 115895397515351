import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import TextInput from '@/components/TextInput/TextInput';
import Checkbox from '@/components/Checkbox/Checkbox';
import Button from '@/components/Button/Button';
import DateTimePicker from '@/components/Datepicker/DateTimePicker';

import * as modalTypes from '@/state/modal/types';
import * as promotionTypes from '@/state/promotions/types';

import { selectPromotionSaveState } from '@/state/promotions/reducers';

const styles = {
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 100,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14,
    },
};

const ShopifyPromotion = props => {
    console.info('DEBUG: props', props);
    const { classes } = props;

    const dispatch = useDispatch();
    // const selectedPromotion = useSelector(selectSelectedPromotion);
    const { uuid, code, startDate, endDate, variant, active } = props;
    const saveState = useSelector(selectPromotionSaveState);

    const isEdit = uuid ? true : false;
    const title = `${isEdit ? 'Update' : 'Create'} Shopify Promotion`;

    const [promoState, setPromoState] = useState({
        uuid: '',
        code: '',
        variant: '',
        startDate: null,
        endDate: null,
        active: false
    });

    useEffect(() => {
        if (isEdit) {
            setPromoState({ uuid, code, startDate: moment(startDate), endDate: moment(endDate), variant, active })
        }
    }, []);

    const onChange = key => event => {
        switch (key) {
            case 'startDate':
            case 'endDate':
                setPromoState({
                    ...promoState,
                    [key]: event
                });
                break;
            case 'active':
                setPromoState({
                    ...promoState,
                    [key]: event.target.checked
                });
                break;
            default:
                setPromoState({
                    ...promoState,
                    [key]: event.target.value
                });
                break;
        }
    }

    const onCancel = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    }

    const onSave = () => {
        const action = !isEdit
            ? promotionTypes.CREATE_SHOPIFY_PROMOTION
            : promotionTypes.UPDATE_SHOPIFY_PROMOTION;

        dispatch({ type: action, data: { ...promoState, startDate: promoState.startDate.toISOString(), endDate: promoState.endDate.toISOString() } });
        onCancel();
    }

    const disabled = () => {
        const { uuid, active, startDate, endDate, ...rest } = promoState;
        const validStringKeys = Object.values(rest).every(p => p.trim().length > 0);
        const validDateKeys = startDate instanceof moment && endDate instanceof moment;
        return !(validStringKeys && validDateKeys);
    }

    console.log(promoState);

    return (
        <React.Fragment>
            <div className={classes.title}>{title}</div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput label='Code' onChange={onChange('code')} value={promoState.code} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput label='Variant' onChange={onChange('variant')} value={promoState.variant} />

                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker
                        label='Start Date'
                        value={promoState.startDate}
                        onChange={onChange('startDate')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker
                        label='End Date'
                        value={promoState.endDate}
                        onChange={onChange('endDate')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox label='Set as active' onChange={onChange('active')} checked={promoState.active} />
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant='outlined' label='Cancel' onClick={onCancel} disabled={saveState} />
                    <Button className={classes.button} label='Save' onClick={onSave} loading={saveState} disabled={disabled()} />
                </div>
            </Grid>
        </React.Fragment>
    );
};

ShopifyPromotion.propTypes = {

};

export default withStyles(styles)(ShopifyPromotion);
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TextInput from '@/components/TextInput/TextInput';
import Button from '@/components/Button/Button';

const styles = makeStyles({
    addSkuTitle: {
        margin: '20px 0px',
        fontSize: 20,
        lineHeight: 1.2
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        marginLeft: 10
    },
    skuContainer: {
        margin: '10px 0px'
    },
    skuBox: {
        display: 'inline-block',
        marginRight: 10,
        padding: 10,
        backgroundColor: '#f2f2f2',
        borderRadius: 4
    },
    icon: {
        fontSize: 17
    }
});

const AddSku = props => {
    const [text, setSku] = useState('');

    const { skus, onSubmit, onRemove, readOnly } = props;
    const classes = styles();

    const handleSubmit = () => {
        onSubmit(text.replace(',', ''));
        setSku('');
    };

    const handleKeyPress = e => {
        const { key } = e;
        if (key === ',' || key === 'Enter') handleSubmit();
    };

    const readOnlyTitle = (
        <Typography className={classes.addSkuTitle}>SKUs</Typography>
    );

    const title = (
        <Typography className={classes.addSkuTitle}>Add SKUs</Typography>
    );

    return (
        <>
            {readOnly ? readOnlyTitle : title}
            {!readOnly && (
                <div className={classes.flexContainer}>
                    <TextInput
                        label="SKU"
                        value={text}
                        onChange={e => setSku(e.target.value)}
                        onKeyUp={handleKeyPress}
                        readOnly={readOnly}
                    />
                    <Button
                        className={classes.button}
                        label="Add"
                        onClick={handleSubmit}
                        disabled={readOnly || text === ''}
                    />
                </div>
            )}
            <div className={classes.skuContainer}>
                {skus.map((sku, idx) => (
                    <Typography key={`sku-${idx}`} className={classes.skuBox}>
                        {sku}
                        {!readOnly && (
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                onClick={() => onRemove(sku)}
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>
                        )}
                    </Typography>
                ))}
            </div>
        </>
    );
};

AddSku.propTypes = {
    skus: PropTypes.array,
    readOnly: PropTypes.bool,
    onSubmit: PropTypes.func,
    onRemove: PropTypes.func
};

AddSku.defaultProps = {
    skus: [],
    readOnly: false,
    onSubmit: null,
    onRemove: null
};

export default AddSku;

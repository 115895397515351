import * as types from './types';

const initialState = {
    loading: false,
    permissions: {},
    saveState: false
};

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PERMISSIONS_SAVE_STATE:
            return {
                ...state,
                saveState: action.state
            };

        case types.GET_USER_PERMISSIONS_SUCCESS:
        case types.UPDATE_USER_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    [action.userUuid]: action.permissions[0]
                }
            };

        default:
            return state;
    }
};

export default permissionsReducer;

export const selectPermissions = state => state.permissions.permissions;
export const selectPermissionsLoading = state => state.permissions.loading;
export const selectPermissionsSaveState = state => state.permissions.saveState;

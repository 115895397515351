import validate from 'validate.js';

// Constraints
const password = {
    password: {
        presence: true,
        length: {
            minimum: 6,
            message: 'must be at least 6 characters'
        },
        format: {
            pattern: '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{6,20})',
            message:
                '^Password length must be between 6 and 20 and must have at least 1 lower case character, 1 upper case character, 1 number and 1 symbol'
        }
    }
};

const confirmPassword = {
    confirm: {
        presence: {
            message: '^You must confirm your password.',
            allowEmpty: false
        },
        equality: {
            attribute: 'password',
            message: '^Your passwords do not match.'
        }
    }
};

const location = {
    title: {
        presence: {
            message: '^You must enter a title.',
            allowEmpty: false
        }
    },
    lat: {
        presence: {
            message: '^You must specify a latitude',
            allowEmpty: false
        },
        // format: {
        //     message: '^A latitude can only include 0-9',
        //     flags: 'U',
        //     pattern: '[0-9]+'
        // }
    },
    lng: {
        presence: {
            message: '^You must specify a longitude',
            flags: 'i',
            allowEmpty: false
        },
        // format: {
        //     message: '^A longitude can only include 0-9',
        //     flags: 'U',
        //     pattern: '(\\-?\\d+(\\.\\d+)?).\\s*(\\-?\\d+(\\.\\d+)?)$'
        // }
    },
    'address.address1': {
        presence: {
            message: '^You must specify an Address',
            allowEmpty: false
        },
    },
    'address.address2': {
        presence: {
            allowEmpty: true
        },
    },
    'address.suburb': {
        presence: {
            message: '^You must specify a Suburb',
            allowEmpty: false
        },
    }, 
    'address.state': {
        presence: {
            message: '^You must specify a State',
            allowEmpty: false
        },
    }, 
    'address.postcode': {
        presence: {
            message: '^You must specify a Postcode',
            allowEmpty: false
        }
    }, 
    'address.city': {
        presence: {
            message: '^You must specify a City',
            allowEmpty: true
        }
    }, 
    'address.country': {
        presence: {
            message: '^You must specify a Country',
            allowEmpty: false
        },
    },
};

// Helpers
export const validatePassword = value => {
    const error = validate({ password: value }, password);
    return error;
};

export const validateConfirmPassword = (pw, confirm) => {
    const error = validate({ password: pw, confirm }, confirmPassword);
    return error;
};

export const validateLocation = (title, lat, lng, address) => {
    const error = validate({ title, lat, lng, address }, location);
    return error;
};

export default validatePassword;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as segmentTypes from '@/state/segments/types';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import SendPushNotification from '@/components/MobileApp/SendPushNotification';
import Table from '@/components/Table/Table';
import TextInput from '@/components/TextInput/TextInput';

import {
    selectSegmentPushNotificationHistory,
    selectSegmentHistoryLoading
} from '@/state/segments/reducers';

class PushNotificationList extends React.Component {
    constructor(props) {
        super(props);
        const { getNotificationHistory } = props;
        getNotificationHistory();
    }

    state = {
        internalDescription: '',
        title: '',
        body: '',
        segment: {},
        currentPage: 0
    };

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    onChange = (type, event) => {
        const { segments } = this.props;
        if (type === 'segment') {
            const segment = segments.find(seg => seg.uuid === event);
            return this.setState({
                segment,
                currentPage: 0
            });
        }

        return this.setState({
            [type]: event.target.value,
            currentPage: 0,
        });
    };

    onSendNotification = () => {
        const { internalDescription, segment, title, body } = this.state;
        const { setModalComponent, openModal } = this.props;
        const notification = {
            internalDescription,
            segmentUuid: segment.uuid,
            title,
            body
        };

        // process the segment and return the result count
        setModalComponent(modals.NOTIFICATION_CONFIRM_MODAL, {
            notification,
            onConfirmNotification: this.onConfirmNotification,
        });
        openModal();

    };

    onConfirmNotification = () => {
        const { internalDescription, title, body, segment } = this.state;
        const { submitPushNotification, setLoadingState } = this.props;
        const notification = {
            internalDescription,
            title,
            body
        };
        const { uuid } = segment;
        
        setLoadingState(true)
        submitPushNotification(uuid, notification);
    };

    headerData = () => {
        const { segments } = this.props;
        return [
            {
                key: 'createdAt',
                label: 'Date',
                compute: data => data.createdAt,
                renderer: date => moment(date).format('DD/MM/YYYY'),
                sortData: (a, b, order, compute) => {
                    if(order === 'asc') {
                        const aDate = moment(a.createdAt).valueOf();
                        const bDate = moment(b.createdAt).valueOf();
                        return aDate - bDate;
                    } 
                    const aDate = moment(a.createdAt).valueOf();
                    const bDate = moment(b.createdAt).valueOf();
                    return bDate - aDate;
                    
                }
            },
            {
                key: 'internalDescription',
                label: 'Title'
            },
            {
                key: 'message',
                label: 'Message'
            },
            {
                key: 'segmentUuid',
                label: 'Segment',
                compute: rowData => {
                    const { segmentUuid } = rowData;
                    const segment = segments.find(seg => {
                        return seg.uuid === segmentUuid;
                    });
                    if (segment !== undefined) return segment.name;
                    return '-';
                },
                renderer: segmentUuid => segmentUuid,
                sortData: (a, b, order) => {
                    const segmentA = segments.find(seg => seg.uuid === a.segmentUuid);
                    const segmentB = segments.find(seg => seg.uuid === b.segmentUuid);
                    const aName = segmentA !== undefined ? segmentA.name : '-';
                    const bName = segmentB !== undefined ? segmentB.name : '-';
                    return order === 'asc' ? bName.localeCompare(aName) : aName.localeCompare(bName);
                }
            }
        ];
    };

    render() {
        const { internalDescription, segment, title, body, currentPage } = this.state;
        const { historyLoading, notificationHistory, readOnly } = this.props;

        const segmentUuid = segment.uuid || '';

        return (
            <React.Fragment>
                { !readOnly && 
                    <SendPushNotification
                        onChange={this.onChange}
                        onSendNotification={this.onSendNotification}
                        internalDescription={internalDescription}
                        segmentUuid={segmentUuid}
                        title={title}
                        body={body}
                    />
                }
                <Table
                    headerData={this.headerData()}
                    page={currentPage}
                    setPage={this.setPage}
                    loading={historyLoading}  
                    tableData={notificationHistory}
                    defaultSortKey='createdAt'
                    defaultSortDir='desc'
                    pagination
                />
                <div style={{ marginBottom: 40 }} />
            </React.Fragment>
        );
    }
}

PushNotificationList.propTypes = {
    setModalComponent: PropTypes.func.isRequired,
    submitPushNotification: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    notificationHistory: PropTypes.array.isRequired,
    getNotificationHistory: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    historyLoading: selectSegmentHistoryLoading(state),
    notificationHistory: selectSegmentPushNotificationHistory(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            }),
            submitPushNotification: (segmentUuid, notification) => ({
                type: segmentTypes.SEND_PUSH_NOTIFICATION,
                segmentUuid,
                notification
            }),
            getNotificationHistory: () => ({
                type: segmentTypes.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY
            }),
            setLoadingState: boolean => ({
                type: segmentTypes.SEGMENT_LOADING_STATE,
                state: boolean
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PushNotificationList)
);

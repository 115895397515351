// these are the message types that come from the API
export const USER_RESTORE_SUCCESS = 'USER_RESTORE_SUCCESS';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_FORCE_DELETE_SUCCESS = 'USER_FORCE_DELETE_SUCCESS';
export const USER_FORCE_DELETE_ERROR = 'USER_FORCE_DELETE_ERROR';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PREFERENCES_SUCCESS = 'USER_UPDATE_PREFERENCES_SUCCESS';

export const PERMISSIONS_UPDATE_SUCCESS = 'PERMISSIONS_UPDATE_SUCCESS';

export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';

export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS';
export const LOCATION_UPDATE_ERROR = 'LOCATION_UPDATE_ERROR';
export const LOCATION_CREATE_SUCCESS = 'LOCATION_CREATE_SUCCESS';

export const AUTHENTICATION_ACCOUNT_DISABLED = 'AUTHENTICATION_ACCOUNT_DISABLED';

export const PROMOTION_VOUCHER_CREATE_SUCCESS = 'PROMOTION_VOUCHER_CREATE_SUCCESS';
export const PROMOTION_VOUCHER_CREATE_ERROR = 'PROMOTION_VOUCHER_CREATE_ERROR';

export const PROMOTION_VOUCHER_CODE_CREATE_SUCCESS = 'PROMOTION_VOUCHER_CODE_CREATE_SUCCESS';
export const PROMOTION_VOUCHER_CODE_UPDATE_SUCCESS = 'PROMOTION_VOUCHER_CODE_UPDATE_SUCCESS';
export const PROMOTION_VOUCHER_CODE_ASSIGN_SUCCESS = 'PROMOTION_VOUCHER_CODE_ASSIGN_SUCCESS';
export const PROMOTION_VOUCHER_CODE_DELETE_SUCCESS = 'PROMOTION_VOUCHER_CODE_DELETE_SUCCESS';
export const PROMOTION_VOUCHER_CODE_CREATE_ERROR = 'PROMOTION_VOUCHER_CODE_CREATE_ERROR';
export const PROMOTION_VOUCHER_CODE_UPDATE_ERROR = 'PROMOTION_VOUCHER_CODE_UPDATE_ERROR';
export const PROMOTION_VOUCHER_CODE_DELETE_ERROR = 'PROMOTION_VOUCHER_CODE_DELETE_ERROR';
export const PROMOTION_VOUCHER_CODE_ASSIGN_ERROR = 'PROMOTION_VOUCHER_CODE_ASSIGN_ERROR';

export const PROMOTION_OFFER_CREATE_SUCCESS = 'PROMOTION_OFFER_CREATE_SUCCESS';
export const PROMOTION_OFFER_UPDATE_SUCCESS = 'PROMOTION_OFFER_UPDATE_SUCCESS';

export const PROMOTION_OFFER_DELETE_SUCCESS = 'PROMOTION_OFFER_DELETE_SUCCESS';
export const PROMOTION_VOUCHER_UPDATE_SUCCESS = 'PROMOTION_VOUCHER_UPDATE_SUCCESS';

export const SEGMENT_CREATE_SUCCESS = 'SEGMENT_CREATE_SUCCESS';
export const SEGMENT_CREATE_ERROR = 'SEGMENT_CREATE_ERROR';

export const SEGMENT_UPDATE_SUCCESS = 'SEGMENT_UPDATE_SUCCESS';
export const SEGMENT_UPDATE_ERROR = 'SEGMENT_UPDATE_ERROR';

export const SEGMENT_DELETE_SUCCESS = 'SEGMENT_DELETE_SUCCESS';
export const SEGMENT_DELETE_ERROR = 'SEGMENT_DELETE_ERROR';

export const PROMOTION_AUTOMATION_CREATE_SUCCESS = 'PROMOTION_AUTOMATION_CREATE_SUCCESS';
export const PROMOTION_AUTOMATION_UPDATE_SUCCESS = 'PROMOTION_AUTOMATION_UPDATE_SUCCESS';
export const PROMOTION_AUTOMATION_DELETE_SUCCESS = 'PROMOTION_AUTOMATION_DELETE_SUCCESS';

export const ERP_UPDATE_SETTING_SUCCESS = 'ERP_UPDATE_SETTING_SUCCESS';
export const ERP_UPDATE_SETTING_ERROR = 'ERP_UPDATE_SETTING_ERROR';


export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';

export const NOTIFICATION_SEGMENT_CREATE_SUCCESS = 'NOTIFICATION_SEGMENT_CREATE_SUCCESS';

export const AUTHENTICATION_DOMAIN_ADD_SUCCESS = 'AUTHENTICATION_DOMAIN_ADD_SUCCESS';
export const AUTHENTICATION_DOMAIN_ADD_ERROR = 'AUTHENTICATION_DOMAIN_ADD_ERROR';

export const PART_UPDATE_SUCCESS = 'PART_UPDATE_SUCCESS';

export const PRODUCT_HEALTH_SUCCESS = "PRODUCT_HEALTH_SUCCESS"
export const NOTIFICATION_HEALTH_SUCCESS = "NOTIFICATION_HEALTH_SUCCESS"
export const USER_HEALTH_SUCCESS = "USER_HEALTH_SUCCESS"
export const SHOPIFY_HEALTH_SUCCESS = "SHOPIFY_HEALTH_SUCCESS"
export const AUTHENTICATION_HEALTH_SUCCESS = "AUTHENTICATION_HEALTH_SUCCESS"
export const LOCATION_HEALTH_SUCCESS = "LOCATION_HEALTH_SUCCESS"
export const PROMOTION_HEALTH_SUCCESS = "PROMOTION_HEALTH_SUCCESS"
export const LOYALTY_HEALTH_SUCCESS = "LOYALTY_HEALTH_SUCCESS"
export const ORDER_HEALTH_SUCCESS = "ORDER_HEALTH_SUCCESS"
export const PERMISSIONS_HEALTH_SUCCESS = "PERMISSIONS_HEALTH_SUCCESS"
export const SEGMENT_HEALTH_SUCCESS = "SEGMENT_HEALTH_SUCCESS"
export const EPICOR_HEALTH_SUCCESS = "EPICOR_HEALTH_SUCCESS"


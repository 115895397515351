export const GET_USER_PERMISSIONS = 'permissions/GET_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_SUCCESS = 'permissions/GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'permissions/GET_USER_PERMISSIONS_ERROR';

export const UPDATE_USER_PERMISSIONS = 'permissions/UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'permissions/UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_ERROR = 'permissions/UPDATE_USER_PERMISSIONS_ERROR';

export const UPDATE_USER_ROLE = 'permissions/UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_SUCCESS = 'permissions/UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_ERROR = 'permissions/UPDATE_USER_ROLE_ERROR';

export const PERMISSIONS_SAVE_STATE = 'permissions/PERMISSIONS_SAVE_STATE';

import axios from 'axios';

export const getShippingLabels = async (search, page, limit, sortBy, order, showDeliveredYes, showDeliveredNo, filter, cancelToken) => {
    try {
        let query = `page=${page}&limit=${limit}`;

        if(filter) {
            query += `&filter=${encodeURIComponent(filter)}`;
        }

        if(search) {
            query += `&search=${encodeURIComponent(search)}`;
        }

        if(sortBy) {
            query += `&sortBy=${sortBy}`;
        }

        if(order) {
            query += `&order=${order}`;
        }

        if(showDeliveredYes) {
            query += `&showDeliveredYes=${showDeliveredYes}`;
        }

        if(showDeliveredNo) {
            query += `&showDeliveredNo=${showDeliveredNo}`;
        }

        const result = await axios.get(`/v1/epicor/shippit/bookings?${query}`, {
            cancelToken
        });

        return result.data;
    } catch (error) {
        throw error;
    }
};
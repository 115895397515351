import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';
import * as promotionTypes from '@/state/promotions/types';

import Button from '../components/Button/Button';
import Table from '../components/Table/Table';

const styles = {
    addPromotion: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
};

const ShopifyPromo = props => {
    const dispatch = useDispatch();

    const { promotions, readOnly, classes } = props;

    const getHeaderData = () => {
        return [
            { key: 'code', label: 'Code' },
            { key: 'variant', label: 'Variant' },
            { key: 'startDate', label: 'Start Date', compute: data => data.startDate, renderer: data => moment(data).format('DD/MM/YYYY') },
            { key: 'endDate', label: 'End Date', compute: data => data.endDate, renderer: data => moment(data).format('DD/MM/YYYY') },
            {
                key: 'active',
                label: 'Status',
                compute: data => (data.active ? 'Active' : 'Inactive'),
                renderer: data => <Chip label={data} />
            }
        ];
    };

    const menuActions = (row) => {
        return [
            {
                label: 'Delete',
                func: rowData => onDeletePromotion(rowData)
            }
        ]
    }

    const onDeletePromotion = async (promotion) => {
        const { uuid } = promotion;
        await dispatch({
            type: promotionTypes.DELETE_SHOPIFY_PROMOTION,
            uuid
        });
    }

    const onAddPromotion = async () => {
        await dispatch({
            type: modalTypes.MODAL_SET_COMPONENT,
            component: modals.SHOPIFY_PROMOTION_MODAL,
        });
        await dispatch({
            type: modalTypes.MODAL_SET_OPEN_STATE,
            state: true
        })
    }

    const onUpdatePromotion = async (promotion) => {
        await dispatch({
            type: modalTypes.MODAL_SET_COMPONENT,
            component: modals.SHOPIFY_PROMOTION_MODAL,
            props: promotion
        });
        await dispatch({
            type: modalTypes.MODAL_SET_OPEN_STATE,
            state: true
        })
    }

    return (
        <React.Fragment>

            <div className={classes.addPromotion}>
                {!readOnly &&
                    <Button
                        label="+ Add promotion"
                        style={{
                            padding: '5px 27px'
                        }}
                        onClick={onAddPromotion}
                    />
                }
            </div>

            <Table
                headerData={getHeaderData()}
                menuActions={!readOnly ? menuActions() : null}
                tableData={promotions}
                showHeader
                loading={false}
                pagination
                onRowClick={onUpdatePromotion}
            />

        </React.Fragment>
    );
};

ShopifyPromo.propTypes = {
};

export default withStyles(styles)(ShopifyPromo);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Fuse from 'fuse.js';
import Chip from '@material-ui/core/Chip';
import Button from '@/components/Button/Button';

import * as locationTypes from '@/state/locations/types';
import { selectLocations, selectLocationsLoading } from '@/state/locations/reducers';

import Table from '@/components/Table/Table';
import LocationFilter from '@/components/Filters/LocationFilter';

const styles = () => ({
    addLocationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 0px'
    }

});

const fuseOpts = {
    shouldSort: true,
    tokenize: false,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'address.address1', 'address.address2', 'address.suburb', 'address.postcode', 'address.city', 'address.state']
};

const headerData = [
    { key: 'title', label: 'Store Name' },
    { key: 'addressFormatted', label: 'Address' },
    {
        key: 'attributes',
        label: '',
        compute: (rowData) => {
            const attributes = [];
            // needs unique keys
            if (rowData.isMerloOwned) { attributes.push('Merlo Owned') };
            if (rowData.isBeanRetailer) { attributes.push('Sells Beans') };
            if (rowData.isCoffeeRetailer) { attributes.push('Wet Coffee') };
            if (rowData.isAlcoholLicensed) { attributes.push('Alcohol') };
            if (rowData.hasFreeWifi) { attributes.push('Free WiFi') };

            return attributes;
        },
        renderer: (data) => data.map((label, i) => <Chip key={`attribute-${i}`} size='small' label={label} />)
    },

]


const attributes = [
    { label: 'Wet Coffee', value: 'isCoffeeRetailer' },
    { label: 'Merlo Owned', value: 'isMerloOwned' },
    { label: 'Sells Beans', value: 'isBeanRetailer' },
    { label: 'Alcohol', value: 'isAlcoholLicensed' },
    { label: 'Free WiFi', value: 'hasFreeWifi' }
];


class Locations extends Component {
    constructor(props) {
        super(props);

        props.getLocations();
    }

    state = {
        search: '',
        filteredAttributes: [],
        currentPage: 0
    }

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    onChange = type => event => {
        this.setState({
            [type]: event.target.value,
            currentPage: 0,
        });
    }

    searchLocations = locations => {
        const { search } = this.state;
        const fuseInstance = new Fuse(locations, fuseOpts);
        return fuseInstance.search(search);
    };

    filterLocations = locations => {
        const { filteredAttributes } = this.state;

        if (filteredAttributes.length > 0) {
            return locations
                .filter(location =>
                    filteredAttributes.some(status =>
                        status.value in location && location[status.value] === true
                    )
                )
        }

        return locations;
    };

    clearAllFilters = () => {
        this.setState({
            search: '',
            filteredAttributes: [],
            currentPage: 0,
        });
    };

    removeFilter = filter => {
        return this.setState(prevState => ({
            filteredAttributes: prevState.filteredAttributes.filter(attribute => attribute.value !== filter),
            currentPage: 0,
        }));
    };

    onAddLocation = () => {
        const { history } = this.props;
        history.push('/locations/add');
    }

    onViewDetails = (rowData) => {
        const { history } = this.props;
        const { uuid } = rowData;
        history.push(`/locations/${uuid}`);
    }

    onRowClick = (rowData) => {
        this.onViewDetails(rowData)
    }

    render() {
        const { classes, locations, locationsLoadingState, readOnly } = this.props;
        const { search, filteredAttributes, currentPage } = this.state;

        const filteredTableData = this.filterLocations(locations);
        const computedTableData = search.trim().length > 0 ? this.searchLocations(filteredTableData) : filteredTableData;

        return (
            <React.Fragment>
                { !readOnly &&
                    <div className={classes.addLocationContainer}>
                        <Button
                            label="+ Add location"
                            style={{
                                padding: '5px 27px'
                            }}
                            onClick={this.onAddLocation}
                        />
                    </div>
                }
                <LocationFilter
                    onChange={this.onChange}
                    searchFilter={search}
                    attributes={attributes}
                    filteredAttributes={filteredAttributes}
                    numOfResults={computedTableData.length}
                    removeFilter={this.removeFilter}
                    clearAll={this.clearAllFilters}
                />
                <Table
                    page={currentPage}
                    setPage={this.setPage}
                    pagination
                    tableData={computedTableData}
                    headerData={headerData}
                    showHeader
                    loading={locationsLoadingState}
                    styles={{
                        fontSize: '11px'
                    }}
                    onRowClick={this.onRowClick}
                />
            </React.Fragment>
        );
    }
}

Locations.propTypes = {
    classes: PropTypes.object.isRequired,
    getLocations: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired,
    locationsLoadingState: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getLocations: () => ({ type: locationTypes.GET_LOCATIONS })
}, dispatch);

const mapStateToProps = state => ({
    locations: selectLocations(state),
    locationsLoadingState: selectLocationsLoading(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Locations)));

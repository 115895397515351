import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as actions from './actions';
import * as types from './types';

import * as snackTypes from '@/state/snack/types';

import history from '../../utils/history';
// extract the userUuid, accessRoles and fetch permissions 

// evaluate accessRoles between token and profile
// function* extractTokenData(idToken) {
//     const userUuid = yield call(actions.getUuidFromToken, idToken);
// }

function* extractUserUuid(idToken) {
    const tokenData = yield call(actions.getDataFromToken, idToken);
    const { userUuid } = tokenData;
    yield put({ type: types.AUTH_SUCCESS, userUuid });
}

function* handleAuth(tokenData) {
    console.info('DEBUG: function*handleAuth -> tokenData', tokenData);
    yield put({ type: types.AUTH_CALLBACK_STATE, state: true });
    yield call(services.setAuthToken, tokenData);
    yield extractUserUuid(tokenData);
}

function* handleLogout() {
    yield call(services.clearAuthToken);
    history.replace('/');
}

function* refreshToken(requestData) {
    try {
        const tokenData = yield call(actions.refreshToken, requestData.refreshToken);
        yield call(services.setAuthToken, tokenData);
    } catch (error) {
        yield put({ type: types.REFRESH_TOKEN_ERROR, error });
    }
}

function* resetPassword(userUuid, password) {
    try {
        yield put({ type: types.AUTH_LOADING_STATE, state: true });
        yield call(actions.resetPassword, userUuid, password);

        yield put({ type: types.RESET_PASSWORD_SUCCESS });
        yield put({ type: types.AUTH_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.RESET_PASSWORD_ERROR, error });
        yield put({ type: types.AUTH_LOADING_STATE, state: false });
    }
}

function* resendEmailVerification(request) {
    const { email } = request;
    try {
        yield call(actions.resendVerificationEmail, email);
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Email verification has been resent',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        console.log('email verification send error :', error);
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'There was a problem sending email verification',
            open: true,
            props: { variant: 'error' }
        });

    }
}

function* addDomainToList(request) {
    const { list, values } = request;
    try {
        yield put({
            type: types.AUTH_LOADING_STATE,
            state: true
        });
        yield call(actions.addDomainToList, list, values);
    } catch (error) {
        console.log('email verification send error :', error);
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong, list has not been updated',
            open: true,
            props: { variant: 'error' }
        });
        yield put({
            type: types.AUTH_LOADING_STATE,
            state: false
        });
    }
}

// flow
export default [
    takeLatest(types.AUTH_CALLBACK, handleAuth),
    takeLatest(types.REFRESH_TOKEN, refreshToken),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.AUTH_LOGOUT, handleLogout),
    takeLatest(types.AUTH_GET_USERUUID, extractUserUuid),
    takeLatest(types.RESEND_EMAIL_VERIFICATION, resendEmailVerification),
    takeLatest(types.DOMAIN_ADD, addDomainToList),
];
import axios from 'axios';
import moment from 'moment-timezone';

export const getSegmentDetails = async payload => {
    const { uuid } = payload;
    try {
        const result = await axios.get(`/v1/segments/${uuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getSegments = async () => {
    try {
        const result = await axios.get('/v1/segments');
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getSegmentUsers = async payload => {
    try {
        // need to send the client timezone to the api
        const tz = moment.tz.guess();
        console.info('DEBUG: tz', tz);
        const { segmentUuid } = payload;
        const result = await axios.get(`/v1/segments/exec/${segmentUuid}?tz=${tz}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getSegmentCsv = async payload => {
  try {
      const { segmentUuid, optionalFields } = payload;

      const tz = moment.tz.guess();
      const response = await axios.post(`/v1/segments/${segmentUuid}/csv`, { tz, optionalFields }, { responseType: 'stream' });
      return response.data;

  } catch (error) {
      throw error;
  }
}

export const sendPushNotification = async (segmentUuid, notification) => {
    console.info('DEBUG: sendPushNotification -> segmentUuid, notification', segmentUuid, notification);
    try {
        const result = await axios.post(`/v1/notifications/segments/${segmentUuid}`,
            notification
        );
        console.info('DEBUG: sendPushNotification -> result', result);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const segmentPushNotificationHistory = async () => {
    try {
        const result = await axios.get('/v1/notifications/segments/history?page=1&pageSize=999999999');
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getSegmentFields = async () => {
    try {
        const result = await axios.get('/v1/segments/fields');
        return result.data;

    } catch (error) {
        throw error;
    }
}

export const createSegment = async (segmentData) => {
    const { uuid, ...rest } = segmentData;

    try {
        const result = await axios.post('/v1/segments/create', {
            ...rest
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const updateSegment = async (segmentData) => {
    console.info('DEBUG: updateSegment -> segmentData', segmentData);
    const { segment } = segmentData;
    const { uuid, ...rest } = segment;
    try {
        const result = await axios.put(`/v1/segments/update/${uuid}`, {
            ...rest
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const upsertSegment = (currentSegments, newSegment) => {
    console.info('DEBUG: upsertSegment -> newSegment', newSegment);
    console.info('DEBUG: upsertSegment -> currentSegments', currentSegments);
    const segments = [...currentSegments];
    const idx = segments.findIndex(s => s.uuid === newSegment.uuid);
    if (idx > -1) {
        segments.splice(idx, 1, newSegment);
    } else {
        segments.push(newSegment);
    }

    return segments;
}


export const deleteSegment = (segment) => {
    try {
        const { uuid } = segment;
        const result = axios.delete(`/v1/segments/delete/${uuid}`);

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const removeSegmentFromList = (currentSegments, segment) => {
    const { uuid } = segment;
    const segments = [...currentSegments];
    const idx = segments.findIndex(seg => seg.uuid === uuid);
    segments.splice(idx, 1);
    return segments;
}
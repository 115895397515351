import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button/Button';

import VoucherCodeDetailsForm from '../../components/Vouchers/VoucherCodeDetailsForm';

import * as modalTypes from '@/state/modal/types';
import * as types from '@/state/promotions/types';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    }
});

const EditVoucherCode = props => {
    const classes = styles();
    const { voucherUuid, code, editVoucherCode, closeModal, vouchersSaveState } = props;

    const [values, setValues] = React.useState(code);

    const onHandleChange = (key, value) => {
        setValues(prevState => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const submit = () => {
        const {
            redemptions,
            userDisplay,
            updatedAt,
            createdAt,
            userEmail,
            ...rest
        } = values;
        editVoucherCode(voucherUuid, { ...rest });
    };

    return (
        <React.Fragment>
            <Typography className={classes.title}>Edit Voucher Code</Typography>
            <VoucherCodeDetailsForm
                code={values}
                onChange={onHandleChange}
                loading={false}
            />
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    disabled={vouchersSaveState}
                    loading={vouchersSaveState}
                    onClick={closeModal}
                />
                <Button
                    className={classes.actionButton}
                    label="Save"
                    loading={vouchersSaveState}
                    disabled={vouchersSaveState}
                    onClick={submit}
                />
            </div>
        </React.Fragment>
    );
};

EditVoucherCode.propTypes = {
    code: PropTypes.object.isRequired,
    editVoucherCode: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    voucherUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  vouchersSaveState: state.promotions.vouchersSaveState
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            editVoucherCode: (uuid, payload) => ({
                type: types.UPDATE_VOUCHER_CODE,
                uuid,
                payload
            }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditVoucherCode);

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import AuthGuardian from '@/system/AuthGuardian';
import AppShell from '@/containers/AppShell';
import { selectAuthCallbackState, selectUserUuid } from '@/state/auth/reducers';
import ScrollToTop from '@/components/ScrollToTop/ScrollToTop';
import { selectPermissions } from '@/state/permissions/reducers';

import Loader from '@/components/Loader/Loader';
import * as types from '@/state/tabs/types';

// validate permissions against the routes here and pass in a read only flag

const AuthenticatedRoute = props => {
    const { component: Component, permissions: routePermissions, requiredPermissions, dependencies: routeDependencies, ...rest } = props;
    const authLoadingState = useSelector(state => selectAuthCallbackState(state));
    const permissions = useSelector(state => selectPermissions(state));
    const userUuid = useSelector(state => selectUserUuid(state));

    const hasEditPermission = permissions[userUuid] ? routePermissions.some(p => permissions[userUuid].permissions.indexOf(p) > -1) : false;

    // Fire off loading state before getting to the route
    const dispatch = useDispatch();
    routeDependencies.forEach(dep => dispatch({ type: dep, state: true }));

    // 
    const { location } = rest;
    const tabIndex = location.state ? parseInt(location.state) : 0;
    dispatch({ type: types.TABS_SET_TAB_INDEX, tabId: tabIndex });

    return <Route exact {...rest} render={(routeProps) => {
        return (
            <Loader loading={authLoadingState}>
                <AuthGuardian requiredPermissions={requiredPermissions}>
                    <ScrollToTop />
                    <AppShell>
                        <Component {...routeProps} readOnly={!hasEditPermission} />
                    </AppShell>
                </AuthGuardian>
            </Loader>
        )
    }} />
}

AuthenticatedRoute.propTypes = {
    component: PropTypes.any.isRequired,
    permissions: PropTypes.array.isRequired,
    dependencies: PropTypes.array,
};

AuthenticatedRoute.defaultProps = {
    dependencies: []
}

export default AuthenticatedRoute;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Fuse from 'fuse.js';

import Button from '../components/Button/Button';
import Table from '../components/Table/Table';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

import * as types from '@/state/promotions/types';
import { selectVouchersLoading } from '@/state/promotions/reducers';
import SearchInput from '@/components/SearchInput/SearchInput';

const headerData = [
    {
        key: 'createdAt',
        label: 'Date',
        compute: rowData => rowData.createdAt,
        renderer: data => data && moment(data).format('DD/MM/YYYY')
    },
    { key: 'title', label: 'Title' },
    { key: 'description', label: 'Description' },
    {
        key: 'claims',
        label: 'Claims',
        compute: data => data.totalClaims,
        renderer: data => data,
        sortData: (a,b,order) => {
            if(order === 'desc') {
                return Number(b.totalClaims) - Number(a.totalClaims)
            }
            return Number(a.totalClaims) - Number(b.totalClaims)
        }
    },
    {
        key: 'status',
        label: 'Status',
        compute: data => data.status,
        renderer: data =>
            data !== 'live' ? <Chip label="Inactive" /> : <Chip label="Live" />
    }
];

const styles = () => ({
    addPromoContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    }
});

const fuseOpts = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'description']
};

class VoucherList extends Component {
    state = {
        search: '',
        currentPage: 0
    };

    setPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    onChange = type => event => {
        this.setState({
            [type]: event.target.value,
            currentPage: 0,
        });
    };

    onViewDetails = rowData => {
        const { history } = this.props;
        const { uuid } = rowData;
        history.push(`/promotions/vouchers/${uuid}`);
    };

    onAddPromo = () => {
        const { setModalComponent, openModal } = this.props;
        setModalComponent(modals.PROMOTIONS_ADD_VOUCHER_MODAL, {});
        openModal(true);
    };

    menuActions = row => {
        if (row.status !== 'live') {
            return [
                {
                    label: 'View details',
                    func: rowData => this.onViewDetails(rowData)
                }
                // {
                //     label: 'Activate',
                //     func: rowData => this.onViewDetails(rowData)
                // },
                // {
                //     label: 'Download All Print',
                //     func: rowData => this.onViewDetails(rowData)
                // }
            ];
        }
        return [
            {
                label: 'View details',
                func: rowData => this.onViewDetails(rowData)
            }
            // {
            //     label: 'Download All Print',
            //     func: rowData => this.onViewDetails(rowData)
            // }
        ];
    };

    searchVouchers = vouchers => {
        const { search } = this.state;
        const fuseInstance = new Fuse(vouchers, fuseOpts);
        return fuseInstance.search(search);
    };

    render() {
        const { search, currentPage } = this.state;
        const { classes, vouchers, vouchersLoadingState, readOnly } = this.props;
        console.log('TCL: VoucherList -> render -> readOnly', readOnly)

        const filtered =
            search.trim().length > 0 ? this.searchVouchers(vouchers) : vouchers;

        return (
            <React.Fragment>
                <div className={classes.addPromoContainer}>
                    { !readOnly &&
                        <Button
                            label="+ Create voucher"
                            style={{
                                padding: '5px 27px'
                            }}
                            onClick={this.onAddPromo}
                        />
                    }
                </div>
                <SearchInput
                    style={{ height: 59 }}
                    value={search}
                    placeholder="Search by Title or Description"
                    onChange={this.onChange('search')}
                    searchFilter={search}
                />

                <Table
                    page={currentPage}
                    setPage={this.setPage}
                    headerData={headerData}
                    tableData={filtered}
                    showHeader
                    menuActions={row => this.menuActions(row)}
                    pagination
                    loading={vouchersLoadingState}
                    onRowClick={this.onViewDetails}
                    defaultSortDir='desc'
                />
            </React.Fragment>
        );
    }
}

VoucherList.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    vouchers: PropTypes.array.isRequired,
    vouchersLoadingState: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    setModalComponent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    vouchersLoadingState: selectVouchersLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateVoucherStatus: (voucherUuid, newStatus) => ({
                type: types.UPDATE_VOUCHER,
                voucherUuid,
                data: { status: newStatus }
            }),
            openModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: true
            }),
            setModalComponent: (component, props) => ({
                type: modalTypes.MODAL_SET_COMPONENT,
                component,
                props
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(VoucherList))
);

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button/Button';

import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    flexContainer: {
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    },
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center',
        marginTop: 25
    },
    actionButton: {
        width: 140,
        marginRight: 12
    }
});

const ConfirmationModal = props => {
    const classes = styles();
    const {
        title,
        message,
        closeModal,
        onConfirm,
        confirmMessage,
    } = props;

    const [loadingState, setLoadingState] = useState(false);

    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current && loadingState === false) {
            closeModal();
        } else {
            didMountRef.current = true;
        }
    }, [loadingState]);

    const onClick = () => {
        setLoadingState(true);
        onConfirm();
    }

    return (
        <div className={classes.flexContainer}>
            {title && (
                <Typography className={classes.title}>{title}</Typography>
            )}
            <Typography align="center">{message}</Typography>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    onClick={closeModal}
                />
                <Button
                    disabled={loadingState}
                    loading={loadingState}
                    className={classes.actionButton}
                    label={confirmMessage}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    confirmMessage: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
    title: '',
    confirmMessage: 'Confirm'
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(ConfirmationModal)
);

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Arrow from '@material-ui/icons/ArrowDropUp';

import ReactRte from 'react-rte';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import SelectInput from '@/components/TextInput/SelectInput';
import TextInput from '@/components/TextInput/TextInput';
import ColorPicker from '@/components/ColorPicker/ColorPicker';

import { selectSelectedProduct } from '@/state/products/reducers';

const ProductData = ({ readOnly, onUpdate, loading, isNew }) => {
    const product = useSelector(state => selectSelectedProduct(state));

    const [rteState, setRte] = useState(ReactRte.createEmptyValue());

    const [details, setDetails] = useState({
        title: '',
        subTitle: '',
        description: '',
        shortDescription: '',
        type: '',
        theme: { color: '' },
        isPublished: false
    });

    if (!isNew) {
        useEffect(() => {
            if (!loading) {
                setDetails({
                    title: product.title || '',
                    subTitle: product.subTitle || '',
                    description: product.description || '',
                    shortDescription: product.shortDescription || '',
                    type: product.type || '',
                    theme: product.theme || { color: '' },
                    isPublished: product.isPublished || false,
                });
                setRte(
                    ReactRte.createValueFromString(product.description, 'html')
                );
            }
        }, [loading, product]);
    }

    const onEventTargetChange = type => event => {
        setDetails({ ...details, [type]: event.target.value });
        onUpdate({ [type]: event.target.value });
    };

    const onEventChange = type => event => {
        setDetails({ ...details, [type]: event });
        onUpdate({ [type]: event });
    };

    const onSwitchChange = type => event => {
        setDetails({ ...details, [type]: event.target.checked });
        onUpdate({ [type]: event.target.checked });
    };

    const onRteChange = type => event => {
        setRte(event);
        const html = event.toString('html');
        setDetails({ ...details, [type]: html });
        onUpdate({ [type]: html });
    };

    const {
        title,
        subTitle,
        shortDescription,
        type,
        theme,
        isPublished
    } = details;

    const toolbarAction = () => {
        return (
            <Grid
                component="label"
                container
                justify="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item>Unpublished</Grid>
                <Grid item>
                    <Switch
                        disabled={readOnly}
                        checked={isPublished}
                        onChange={onSwitchChange('isPublished')}
                        color="primary"
                    />
                </Grid>
                <Grid item>Published</Grid>
            </Grid>
        );
    };

    return (
        <Box
            title="Product Details"
            toolbarAction={isNew ? null : toolbarAction()}
        >
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Title"
                            value={title}
                            onChange={onEventTargetChange('title')}
                            readOnly={readOnly}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Sub-Title"
                            value={subTitle}
                            onChange={onEventTargetChange('subTitle')}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {
                        // <Grid item xs={12}>
                        //     <ReactRte
                        //         value={rteState}
                        //         onChange={onRteChange('description')}
                        //         placeholder="Product description"
                        //         readOnly={readOnly}
                        //     />
                        // </Grid>
                    }
                    <Grid item xs={12}>
                        <TextInput
                            label="Short Description"
                            value={shortDescription}
                            onChange={onEventTargetChange('shortDescription')}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label="Type"
                            value={type}
                            options={[
                                { label: 'Normal', value: 'Normal' },
                                { label: 'Members Only', value: 'Members Only' }
                            ]}
                            onChange={onEventChange('type')}
                            readOnly={readOnly}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <ColorPicker
                            label="Theme Color"
                            onChange={onEventChange('theme')}
                            readOnly={readOnly}
                            value={theme}
                        /> */}
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductData.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductData.defaultProps = {
    isNew: false
};

export default ProductData;

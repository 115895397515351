import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import * as snackTypes from '../snack/types';

function* getLocations() {
    try {
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: true });
        const response = yield call(actions.getLocations);
        const { locations, meta } = response.data;
        yield put({ type: types.GET_LOCATIONS_SUCCESS, locations, meta });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_LOCATIONS_ERROR, error });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    }
}

function* getMerloLocations() {
    try {
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: true });
        const response = yield call(actions.getMerloLocations);
        // const { locations, meta } = response.data;
        yield put({
            type: types.GET_MERLO_LOCATIONS_SUCCESS,
            locations: response.data
        });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_MERLO_LOCATIONS_ERROR, error });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    }
}

function* getLocation(locationUuid) {
    try {
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: true });
        const response = yield call(actions.getLocation, locationUuid);
        const location = response.data;
        yield put({ type: types.GET_LOCATION_SUCCESS, location });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_LOCATION_ERROR, error });
        yield put({ type: types.LOCATIONS_LOADING_STATE, state: false });
    }
}

function* createLocation(request) {
    try {
        yield put({ type: types.LOCATION_SAVE_STATE, state: true });
        const { locationData } = request;
        const payload = yield call(actions.formatLocationPayload, locationData);
        yield call(actions.createLocation, payload);
    } catch (error) {
        yield put({ type: types.CREATE_LOCATION_ERROR, error });
        yield put({ type: types.LOCATION_SAVE_STATE, state: false });
    }
}

function* updateLocation(request) {
    try {
        yield put({ type: types.LOCATION_SAVE_STATE, state: true });
        const { locationData } = request;
        const payload = yield call(actions.formatLocationPayload, locationData);
        yield call(actions.updateLocation, payload);
    } catch (error) {
        yield put({ type: types.UPDATE_LOCATION_ERROR, error });
        yield put({ type: types.LOCATION_SAVE_STATE, state: false });
        console.log('TCL: function*updateLocation -> error.response.data', error.response)
        const errorMessage = error.response ? error.response.data.message : 'Something went wrong';
        yield put({
            type: snackTypes.SET_SNACK,
            content: errorMessage,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* deleteLocation(locationUuid) {
    try {
        yield put({ type: types.LOCATION_SAVE_STATE, state: true });
        yield call(actions.deleteLocation, locationUuid);
    } catch (error) {
        yield put({ type: types.DELETE_LOCATION_ERROR, error });
        yield put({ type: types.LOCATION_SAVE_STATE, state: false });
    }
}

export default [
    takeLatest(types.GET_LOCATIONS, getLocations),
    takeLatest(types.GET_LOCATION, getLocation),
    takeLatest(types.GET_MERLO_LOCATIONS, getMerloLocations),
    takeLatest(types.CREATE_LOCATION, createLocation),
    takeLatest(types.UPDATE_LOCATION, updateLocation),
    takeLatest(types.DELETE_LOCATION, deleteLocation),
];

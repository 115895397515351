import { call, put, takeLatest } from 'redux-saga/effects';

import * as productActions from '@/state/products/actions';
import * as actions from './actions';
import * as types from './types';

// parts

function* getParts() {
    try {
        yield put({ type: types.PARTS_LOADING_STATE, state: true });
        const response = yield call(actions.getParts);
        const { data, meta } = response;
        yield put({ type: types.GET_PARTS_SUCCESS, parts: data, meta });
        yield put({ type: types.PARTS_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_PARTS_ERROR, error });
        yield put({ type: types.PARTS_LOADING_STATE, state: false });
    }
}

function* getPart(request) {
    try {
        yield put({ type: types.PARTS_LOADING_STATE, state: true });
        // get part
        const response = yield call(actions.getPart, request);
        const { data } = response;
        // get categories
        const categoriesResponse = yield call(productActions.getShopifyCategories);
        const categories = categoriesResponse.data;

        // // manage the linked categories
        const { linkedProduct } = data;
        // const products = yield linkedProducts.map(p => JSON.parse(p));
        // perform categories merge on data
        if (linkedProduct !== null) {
            const mergedData = yield call(productActions.populateCategoriesForProducts, [linkedProduct], categories);
            [data.linkedProduct] = mergedData;
        } 

        yield put({ type: types.GET_PART_DETAIL_SUCCESS, part: data });
        yield put({ type: types.PARTS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_PART_DETAIL_ERROR, error });
        yield put({ type: types.PARTS_LOADING_STATE, state: false });
    }
}

function* updatePart(request) {
    try {
        yield put({ type: types.PART_SAVING, state: true });

        const result = yield call(actions.updatePart, request);
        console.info('DEBUG: function*updatePart -> result', result);

        yield put({ type: types.UPDATE_PART_SUCCESS});
        yield put({ type: types.PART_SAVING, state: false });
    } catch (error) {
        yield put({ type: types.UPDATE_PART_ERROR, error });
        yield put({ type: types.PART_SAVING, state: false });
    }
}

// flow
export default [
    takeLatest(types.GET_PARTS, getParts),
    takeLatest(types.GET_PART_DETAIL, getPart),
    takeLatest(types.UPDATE_PART, updatePart),
];

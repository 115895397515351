import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import MainMenu from '@/components/NavMenu/MainMenu';
import Header from '@/components/Header/Header';
import Modal from '@/components/Modal/Modal';
import PageContainer from '@/components/PageContainer/PageContainer';
import Realtime from '@/components/Realtime/Realtime';
import Snack from '@/components/Snack/SnackWrapper';

import SocketContext from '@/system/SocketContext';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
});

const AppShell = props => {
    const { classes, children } = props;

    return (
        <div className={classes.container}>
            <CssBaseline />
            <Header />
            <MainMenu />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <PageContainer>
                    {children}
                </PageContainer>
                <Modal />
            </MuiPickersUtilsProvider>
            <SocketContext.Consumer>
              {socket => <Realtime io={socket} />}
            </SocketContext.Consumer>
            <Snack />
        </div>
    );
}

AppShell.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};

export default withStyles(styles)(AppShell);

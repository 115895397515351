import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button/Button';
import TextInput from '@/components/TextInput/TextInput';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';


const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom:14,
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px',
    }
});


const PromptNumber = props => {

    const { initialValue, closeModal, title, submitLabel } = props

    const [values, setValues] = React.useState({
        count: initialValue == null ? '' : String(initialValue),
    });

    const onHandleChange = value => {

        setValues({count: value.target.value.replace(/[^0-9]/gi, '')});
        
    }
    

    const submit = () => {
        const { submitAction } = props;
        const { voucher } = values;
        submitAction(voucher);
        closeModal()
    }

    const classes = styles();
    
    return (
        <div>
            <Typography className={classes.title}>{title}</Typography>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        label='How many?'
                        value={values.count}
                        name='title'
                        onChange={onHandleChange}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                <Button className={classes.actionButton} 
                    label='Cancel' 
                    variant={null} style={{color:'#077abe'}}
                    onClick={closeModal}
                />
                <Button className={classes.actionButton} 
                    label={submitLabel} 
                    onClick={submit}
                />
            </div>
        </div>
    );
};

PromptNumber.propTypes = {
    closeModal: PropTypes.func.isRequired,

    // Customisable fields
    title: PropTypes.string.isRequired,
    submitLabel: PropTypes.string,
    submitAction: PropTypes.func.isRequired,
    initialValue: PropTypes.number,
};

PromptNumber.defaultProps = {
    submitLabel: 'Submit',
    initialValue: null,
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => bindActionCreators({
    closeModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PromptNumber);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '@/state/auth/actions';

import * as types from '@/state/auth/types';

import EmailDomainList from '@/components/EmailDomainList/EmailDomainList';
import { selectAuthLoadingState, selectWhitelist, selectBlacklist } from '@/state/auth/reducers';
import Button from '@/components/Button/Button';
import { Grid } from '@material-ui/core';
import SubHeader from '@/components/SubHeader/SubHeader';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    spacer: {
        marginTop: 60,
    },
});

class OptionsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lists: null,
            blacklist: [],
            whitelist: [],
            loading: true
        }
    }

    async componentDidMount() {
        const lists = await actions.getDomainLists();
        this.setState({
            whitelist: lists.data.whitelist,
            loading: false
        });
        this.setState({
            blacklist: lists.data.blacklist
        });
    }

    onAdd = (value, listType) => {
        if(listType === 'whitelist') {
            this.state.whitelist.unshift(value);
            const index = this.state.blacklist.indexOf(value);
            if(index !== -1) {
                this.state.blacklist.splice(index, 1);
            }
        } else {
            this.state.blacklist.unshift(value);
            const index = this.state.whitelist.indexOf(value);
            if(index !== -1) {
                this.state.whitelist.splice(index, 1);
            }
        }

        const whitelist = [...this.state.whitelist];
        const blacklist = [...this.state.blacklist];

        this.setState({
            whitelist,
            blacklist,
        })
        
    }

    onItemRemove = (index, listType) => {
        if(listType === 'whitelist') {
            this.state.whitelist.splice(index, 1);
        } else {
            this.state.blacklist.splice(index, 1);
        }  

        const whitelist = [...this.state.whitelist];
        const blacklist = [...this.state.blacklist];

        this.setState({
            whitelist,
            blacklist,
        })
    }

    onSave = () => {
        const { addToList } = this.props;
        const { whitelist, blacklist } = this.state;
        addToList('whitelist', whitelist);
        addToList('blacklist', blacklist);
    }

    render() {
        const { readOnly, authLoadingState, classes } = this.props;
        const { blacklist, whitelist, loading } = this.state;
        const _blacklist = blacklist.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
        const _whitelist = whitelist.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSave}
                    readOnly={authLoadingState || loading}
                />
                <div className={classes.spacer} />
                <EmailDomainList readOnly={readOnly} listType="blacklist" list={_blacklist} onAdd={this.onAdd} onItemRemove={this.onItemRemove} />
                <EmailDomainList readOnly={readOnly} listType="whitelist" list={_whitelist} onAdd={this.onAdd} onItemRemove={this.onItemRemove} />
            </React.Fragment>
        );
    }
}

OptionsContainer.propTypes = {
};

const mapStateToProps = state => ({
    authLoadingState: selectAuthLoadingState(state),
    whitelist: selectWhitelist(state),
    blacklist: selectBlacklist(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addToList: (list, values) => ({ type: types.DOMAIN_ADD, list, values }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptionsContainer));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SubHeader from '@/components/SubHeader/SubHeader';
import Box from '@/components/Box/Box';
import Button from '@/components/Button/Button';
import OfferImageUpload from '@/components/Offers/OfferImageUpload';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import OfferTileDetailsForm from '@/components/Offers/OfferTileDetailsForm';
import OfferTileScheduleForm from '@/components/Offers/OfferTileScheduleForm';

import {
    selectSelectedOffer,
    selectOffersSaveState
} from '@/state/promotions/reducers';
import * as promotionTypes from '@/state/promotions/types';

const styles = {
    marginTop: {
        marginTop: 60
    },
    marginBottom: {
        marginBottom: 60
    },
    title: {
        fontSize: 10,
        color: '#a7a7a7',
        letterSpacing: 0.16,
        textTransform: 'uppercase'
    },
    text1: {
        fontSize: 16,
        lettingSpacing: 0.26
    },
    cta: {
        height: 50,
        width: '100%',
        fontSize: 18,
        letterSpacing: 0.11
    },
    flexContainer: {
        display: 'flex',
        width: '100%'
    },
    imageUploadContainer: {
        width: 300,
        maxWidth: 300,
        minWidth: 300
    },
    detailContainer: {
        width: '100%',
        marginLeft: 20,
    },
    exampleContainer: {
        width: 'calc(100% - 261px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    example: {
        minWidth: 336,
        maxWidth: '100%'
    },
    exampleContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 10,
        '& p': {
            maxWidth: 220,
            wordBreak: 'break-word'
        }
    }
};

class OfferTileCreate extends Component {
    state = {
        image: {
            src: null,
            isNew: true
        },
        text1: '',
        linkUrl: '',
        startDate: moment().format(),
        endDate: moment()
            .days(30)
            .format(),
        errors: {}
    };

    componentDidUpdate(prevProps) {
        const { offerSaveState, selectedOffer, history } = this.props;
        if (!offerSaveState && prevProps.offerSaveState) {
            // saving occurred
            if ('uuid' in selectedOffer) {
                // redirect to
                const { uuid } = selectedOffer;
                history.push(`/mobile-app/offer-tiles/${uuid}`);
            }
        }
    }

    onImageChange = image => {
        this.setState({
            image
        });
    };

    onChange = data => {
        return this.setState(prevState => ({
            ...prevState,
            ...data
        }), () => {
            // validate CTA
            if ('linkUrl' in data) {
                this.validateCTA(data.linkUrl)
            }
        });
    };

    validateCTA = cta => {
        const regex = /^(https:\/\/)[^\s(["<,>]*\.[^\s[",><]*/ig;
        const regTest = new RegExp(regex);
        const test = regTest.test(cta);

        const { errors } = this.state;
        if (!test) {
            // set an error state for this field
            this.setState(prevState => ({
                ...prevState,
                errors: {
                    imageUrl: 'Image must start with https:// and be a valid url'
                }
            }));
        } else if (test && 'imageUrl' in errors) {
            this.setState(prevState => ({
                ...prevState,
                errors: {}
            }));
        }
    }


    onSaveTile = () => {
        const { createOffer } = this.props;
        const { errors, ...rest } = this.state;
        createOffer(rest);
    };

    render() {
        const { classes, offerSaveState } = this.props;
        const { text1, linkUrl, image, errors } = this.state;

        const saveDisabled =
            text1 === '' ||
            linkUrl === '' ||
            image.src === null;

        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSaveTile}
                    saving={offerSaveState}
                    disabled={offerSaveState || saveDisabled}
                />
                <div className={classes.marginTop} />
                <div className={classes.flexContainer}>
                    <div className={classes.imageUploadContainer}>
                        <OfferImageUpload
                            loading={false}
                            offer={this.state}
                            onChange={this.onImageChange}
                        />
                    </div>
                    <div className={classes.detailContainer}>
                        <Box title="Offer title details">
                            <OfferTileDetailsForm
                                loading={false}
                                offerTile={this.state}
                                onChange={this.onChange}
                                errors={errors}
                            />
                        </Box>
                        <Box title="Schedule">
                            <OfferTileScheduleForm
                                loading={false}
                                offerTile={this.state}
                                onChange={this.onChange}
                            />
                        </Box>
                    </div>
                </div>
                
                <div className={classes.marginBottom} />
            </React.Fragment>
        );
    }
}

OfferTileCreate.propTypes = {
    classes: PropTypes.object.isRequired,
    createOffer: PropTypes.func.isRequired,
    offerSaveState: PropTypes.bool.isRequired,
    selectedOffer: PropTypes.object,
    history: PropTypes.object.isRequired
};

OfferTileCreate.defaultProps = {
    selectedOffer: {}
};

const mapStateToProps = state => ({
    offerSaveState: selectOffersSaveState(state),
    selectedOffer: selectSelectedOffer(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createOffer: offer => ({
                type: promotionTypes.CREATE_OFFER,
                offer
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(OfferTileCreate))
);

import * as types from './types';

// state definition
const initialState = {
    authLoadingState: false,
    userUuid: '',
    timeStamp: null,
    loadingState: false,
    version: '',
    whitelist: [],
    blacklist: []
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.AUTH_SUCCESS:
            return {
                ...state,
                userUuid: action.userUuid,
                authLoadingState: false,
                timeStamp: new Date().toISOString()
            };

        case types.AUTH_CALLBACK_STATE:
            return {
                ...state,
                authLoadingState: action.state
            };

        case types.AUTH_TIMESTAMP:
            return {
                ...state,
                timeStamp: new Date().toISOString()
            };

        case types.GET_VERSION_SUCCESS:
            return {
                ...state,
                version: action.version
            };

        case types.UPDATE_WHITELIST_SUCCESS:
            return {
                ...state,
                whitelist: action.values
            };

        case types.UPDATE_BLACKLIST_SUCCESS:
            return {
                ...state,
                blacklist: action.values
            };

        default:
            return state;
    }
}

export default usersReducer;

export const selectAuthLoadingState = state => state.auth.loadingState;
export const selectUserUuid = state => state.auth.userUuid;
export const selectAuthCallbackState = state => state.auth.authLoadingState;
export const selectAuthTimeStamp = state => state.auth.timeStamp;
export const selectVersion = state => state.auth.version; //old way, not used anymore
export const selectWhitelist = state => state.auth.whitelist;
export const selectBlacklist = state => state.auth.blacklist;
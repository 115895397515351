import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { makeStyles } from '@material-ui/styles';
import Table from '@/components/Table/Table';
import Button from '@/components/Button/Button';
import PartsFilter from '@/components/Filters/PartsFilter';

import * as modalTypes from '@/state/modal/types';

import { selectParts, selectPartsLoading } from '@/state/parts/reducers';
import { selectSelectedProductParts } from '@/state/products/reducers';

import * as productTypes from '@/state/products/types';

const fuseOpts = {
    shouldSort: false,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['partCode', 'erpTitle', 'optionTitle', 'price']
};

const headerData = [
    { key: 'partCode', label: 'SKU / PART' },
    { key: 'erpTitle', label: 'ERP TITLE' },
    { key: 'optionTitle', label: 'OPTION TITLE' },
    { key: 'price', label: 'PRICE' },
];

const styles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 140,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14,
    },
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    table: {
        padding: '10px 10px'
    }
}));

const AddPart = props => {
    const { product } = props;

    const classes = styles();
    const dispatch = useDispatch();

    const parts = useSelector(state => selectParts(state));
    const partsLoading = useSelector(state => selectPartsLoading(state));
    const productParts = useSelector(state => selectSelectedProductParts(state));

    const [selectedParts, setSelectedParts] = React.useState({});
    const [state, setState] = React.useState({
        search: '',
    });
    const [allParts, setAllParts] = React.useState([]);

    const onChecked = rowData => {
        // deselect and select
        const data = { ...selectedParts };
        const { partCode } = rowData;

        if (partCode in data) {
            // delete
            delete data[partCode];
        } else {
            data[partCode] = rowData;
        }

        dispatch({ type: productTypes.UPDATE_PART_LIST, parts: data });
    };

    const onChange = type => event => {
        setState({ ...state, [type]: event.target.value });
    };

    const onCancel = () => {
        dispatch({ type: productTypes.UPDATE_PART_LIST, parts: {} })
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
    }

    const onAdd = () => {
        dispatch({ type: productTypes.MERGE_PRODUCT_PART_LIST, parts: productParts })
        props.fwdRef.current(); // make call to parent to update state
        onCancel();
    }

    useEffect(() => {
        setSelectedParts(productParts)
    }, [productParts]);

    useEffect(() => {
        // perform initial filter of all parts that arent associated with a product
        const _parts = parts.filter(p => p.linkedProduct === null);
        const { parts: passedProductParts } = product;
        setAllParts(passedProductParts
            ? _parts.filter(p => passedProductParts.indexOf(p.partCode) === -1)
            : _parts
        );
    }, [parts]);

    const searchParts = (data) => {
        const { search } = state;
        const fuseInstance = new Fuse(data, fuseOpts);
        return fuseInstance.search(search);
    }

    const { search } = state;
    const computedTableData = search.trim().length > 0 ? searchParts(allParts) : allParts;
    
    return (
        <React.Fragment>
            <div className={classes.title}>Add Parts</div>

            <PartsFilter
                onChange={onChange}
                searchFilter={search}
                showSwitch={false}
            />
            <Table
                headerData={headerData}
                tableData={computedTableData}
                showHeader={false}
                pagination
                loading={partsLoading}
                checkBox
                checkBoxData={selectedParts}
                onChecked={onChecked}
                onRowClick={onChecked}
                rowsPerPageOptions={[6]}
                checkBoxId='partCode'
                styles={classes.tableWrapper}
            />

            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant='outlined' label='Cancel' onClick={onCancel} disabled={partsLoading} />
                <Button className={classes.button} label='+ Add Selected' disabled={Object.keys(selectedParts).length === 0} onClick={onAdd} loading={partsLoading} />
            </div>
        </React.Fragment>
    );
};

AddPart.propTypes = {
    product: PropTypes.object,
    fwdRef: PropTypes.object
};

AddPart.defaultProps = {
    product: {},
    fwdRef: {}
}

export default AddPart;
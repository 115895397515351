// actions
import axios from 'axios';
import decode from 'jwt-decode';

import { getLocalStore } from './services';

export const refreshToken = async () => {
    // set api auth in header... this should be done via a global setting
    try {
        const token = getLocalStore('refreshToken');
        const result = await axios.post('/v1/auth/token/refresh',
            { refreshToken: token },
        );

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const resetPassword = async (request) => {
    const { password, userUuid } = request;
    try {
        const result = await axios.post(`/v1/auth/password/reset/${userUuid}`, {
            password,
        });

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getDataFromToken = async (request) => {
    const { idToken } = request;
    try {
        const decodedToken = await decode(idToken);
        const { userUuid, accessRoles } = decodedToken;

        return { userUuid, accessRoles };

    } catch (error) {
        throw error;
    }
}

export const resendVerificationEmail = async (email) => {
    console.info('DEBUG: resendVerificationEmail -> request', email);
    try {
        const result = await axios.post('/v1/auth/email/resend', {
            email
        });

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const addDomainToList = async (list, values) => {
    try {
        const result = await axios.post('/v1/auth/domains', {
            list,
            values
        });

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getDomainLists = async () => {
    try {
        const result = await axios.get('/v1/auth/domains');

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getVersion = async () => {
    try {
        const result = await axios.get('/v1/version');

        return result.data;

    } catch (error) {
        throw error;
    }
}

export const getBeanDriveJWT = async () => {
    try {
        const result = await axios.get('/v1/auth/beandrive/token');

        return result.data;

    } catch (error) {
        throw error;
    }
}
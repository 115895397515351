import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import { selectModalState, selectModalComponent, selectModalProps } from '@/state/modal/reducers';
import * as modalTypes from '@/state/modal/types';
import { getModalFromType } from '@/components/Modal/modalLookup';

const useStyles = makeStyles(theme => ({
    paper: {
        width: 784,
        minHeight: 200,
        backgroundColor: '#ffffff',
        // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
        padding: theme.spacing(2, 4, 4),
        borderRadius: '2px',
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const getModal = (component, componentProps, ref) => {
    return React.cloneElement(getModalFromType(component), { ...componentProps, fwdRef: ref });
}

const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

const Modal = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector(state => selectModalState(state));
    const component = useSelector(state => selectModalComponent(state));
    const modalProps = useSelector(state => selectModalProps(state));

    const prevOpen = usePrevious(open);

    useEffect(() => {
        if (prevOpen === true && !open) {
            dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
        }
    }, [open])

    const handleClose = () => dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });

    const RefModal = React.forwardRef((_props, ref) => {
        if (component) {
            return getModal(component, _props, ref);
        }

        return <div />
    })

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.modalContainer}
            maxWidth='md'
        >
            <div className={classes.paper}>
                <RefModal {...modalProps} />
            </div>
        </Dialog>
    );
};

Modal.propTypes = {

};

export default Modal;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import Box from '../Box/Box';
import Button from '../Button/Button';
import AddressBox from '../AddressBox/AddressBox';

import { selectUserLoading } from '@/state/users/reducers';
import Loader from '@/components/Loader/Loader';

import * as modalTypes from '@/state/modal/types';
import * as modals from '@/constants/modals';

import * as userTypes from '@/state/users/types';

const styles = makeStyles({
    root: {
        borderRadius: 2,
        padding: '15px 20px 32px 20px',
        marginTop: 20
    }
});

const UserAddresses = props => {
    const classes = styles();
    const dispatch = useDispatch();

    const { user, onFormAddressChange, readOnly } = props;

    const [addresses, setAddresses] = React.useState([]);

    const loadingState = useSelector(state => selectUserLoading(state));
    useEffect(() => {
        if (!loadingState) {
            const { profile } = user;
            const { addresses: _addresses } = profile;
            setAddresses(_addresses);
        }
    }, [loadingState]);

    const updateAddress = (addressIndex, data) => {
        const updatedAddress = { ...addresses[addressIndex], ...data };

        dispatch({ type: userTypes.UPDATE_USER_ADDRESS, data: { address: updatedAddress, addressIndex } });

        // merge
        const _addresses = [...addresses];
        _addresses.splice(addressIndex, 1, updatedAddress);

        const { isDefault } = data;
        // update all other records to toggle 
        const result = _addresses.map((a, i) => {
            if (i !== addressIndex) {
                // check default values
                const _a = { ...a };
                _a.isDefault = isDefault === _a.isDefault ? false : _a.isDefault;
                return _a;
            }
            return a;
        });

        // update state
        // setAddresses(result);

        // update parent state
        // onFormAddressChange(result);
    }

    const deleteAddress = (addressIndex) => {
        dispatch({ type: userTypes.DELETE_USER_ADDRESS, data: { address: addresses[addressIndex], addressIndex } })
    }

    const onAddAddress = () => {
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: modals.ADDRESS_DETAILS_MODAL, props: { 
            edit: false, title: 'Add New Address', actionType: userTypes.CREATE_USER_ADDRESS 
        } });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    }

    return (
        <Box
            rootClass={classes.root}
            title="Addresses"
            toolbarAction={<Button label="+ Add a new address" onClick={onAddAddress} />}
            readOnly={readOnly}
        >
            <Loader loading={loadingState}>
                <Grid container spacing={4}>
                    {
                        addresses.map((address, i) => {
                            // this needs to use the shopify ID to makes its updates... if address doesnt have it - it needs to add that
                            return (
                                <Grid key={`${address.title}-${i}`} item xs={6}>
                                    <AddressBox
                                        address={address}
                                        addressIndex={i}
                                        updateAddress={updateAddress}
                                        deleteAddress={deleteAddress}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Loader>
        </Box>
    );
};

UserAddresses.propTypes = {
    user: PropTypes.object,
    onFormAddressChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

UserAddresses.defaultProps = {
    user: null,
};

export default UserAddresses;

import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = props => {
    const { loading, children } = props;
    const styles = {
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        loading
            ? <div style={styles}><CircularProgress /></div>
            : [children]
    );
};

Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default Loader;
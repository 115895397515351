// import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import envConfig from '@/utils/envConfig';
import axiosConfig from '@/utils/axiosConfig';

import RouteGuardian from '@/system/RouteGuardian';
import * as authTypes from '@/state/auth/types';
import * as authActions from '@/state/auth/actions';

import * as featureTypes from '@/state/featureFlag/types';

const App = () => {
    const dispatch = useDispatch();
    const [initialized, setInitialized] = React.useState(false);
    
    useEffect(() => {
        const setupEnv = async () => {
            const result = await envConfig();
            axiosConfig(result.isProd);
            dispatch({
                type: featureTypes.SET_FEATURE_ENV,
                env: result.merloEnv
            });
            // get the version
            try {
                const version = await authActions.getVersion();
                dispatch({
                    type: authTypes.GET_VERSION_SUCCESS,
                    version: version.data.version
                })
            } catch (error) {
                // do nothing
            }
            setInitialized(true);
        };

        setupEnv();
    }, []);

    

    return initialized
        ? <RouteGuardian />
        : <div>waiting for something....</div>;
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import SearchInput from '@/components/SearchInput/SearchInput';



const ProductFilter = props => {
    const {
        onChange,
        searchFilter,
        onSwitchChange,
        showOrphans,
        showSwitch
    } = props;

    const styles = makeStyles({
        flexContainer: {
            display: 'flex',
            height: 58.75,
        },
        search: {
            flexBasis: showSwitch ? '100%' : '100%',
        },
        switchContainer: {
            flexBasis: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth:240, maxWidth:240
        }
    });

    const classes = styles();

    return (
        <React.Fragment>
            <div className={classes.flexContainer}>

                <SearchInput
                    className={classes.search}
                    placeholder="Search by SKU, ERP title, option title"
                    value={searchFilter}
                    onChange={onChange('search')}
                />
                {
                    showSwitch &&
                    <div className={classes.switchContainer} >
                        <Grid component="label" container justify="center" alignItems="center" spacing={1}>
                            <Grid item>All Parts</Grid>
                            <Grid item>
                                <Switch
                                    checked={showOrphans}
                                    onChange={onSwitchChange('showOrphans')}
                                    color='primary'
                                />
                            </Grid>
                            <Grid item>Unlinked</Grid>
                        </Grid>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

ProductFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSwitchChange: PropTypes.func,
    searchFilter: PropTypes.string.isRequired,
    showOrphans: PropTypes.bool,
    showSwitch: PropTypes.bool,
};

ProductFilter.defaultProps = {
    onSwitchChange: () => { },
    showOrphans: true,
    showSwitch: true,
};

export default ProductFilter;

import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Button from '@/components/Button/Button';

import Table from '@/components/Table/Table';

import { selectVouchersLoading } from '@/state/promotions/reducers';
import { selectUser } from '@/state/users/reducers';
import { generatePdfOfCode } from '@/state/promotions/actions';


const UserVouchers = props => {

    const [buttonLoading, setButtonLoading] = useState([]);

    const voucherHeaderData = [
        {
            key: 'date',
            label: 'Date',
            compute: (rowData) => {
                return rowData.expiryDate;
            },
            renderer: (data) => data && `Expires: ${moment(data).format('DD/MM/YYYY HH:mm')}`
        },
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        {
            key: 'SKU',
            label: 'Code',
            compute: (rowData) => {
                return rowData.code;
            },
            renderer: (data) => data
        },
        {
            key: 'status',
            label: 'Status',
            compute: (rowData) => {
                const { status } = rowData;
                return status.charAt(0).toUpperCase() + status.slice(1);
            },
            renderer: (data) => <Chip label={data} />
        },
        {
            key: 'download',
            label: 'Download',
            compute: (rowData) => {
                return rowData;
            },
            renderer: (data) => {
                const loading = buttonLoading.includes(`button-${data.code}-loading`);
                return <Button label={'Download PDF'} loading={loading} onClick={(e) => onClick(data.uuid, data.code, data.title)}  />
            }
        },
    ]

    const { vouchers, history } = props;
    const promotionLoading = useSelector(state => selectVouchersLoading(state));
    const user = useSelector(state => selectUser(state));

    const onViewVoucherDetails = (rowData) => {
        const { uuid } = rowData;
        history.push(`/promotions/vouchers/${uuid}`);
    }

    const onClick = async (voucherUuid, code, title) => {
        try {
            setButtonLoading([...buttonLoading, `button-${code}-loading`]);
            await generatePdfOfCode(voucherUuid, code, title);
            const buttons = buttonLoading.filter(buttons => {
                return !(buttons === `button-${code}-loading`)
            })
            setButtonLoading(buttons);
        } catch (error) {
            throw error;
        }
    }

    const tableData = vouchers.reduce((prev, curr) => {
        const codes = curr.voucherCodes.map(code => {
            return {...code, title: curr.title, uuid: curr.uuid }
        });
        prev.push(...codes);
        return prev;
    }, []);

    return (
        <Table
            headerData={voucherHeaderData}
            tableData={tableData}
            toolbarData={{ title: 'Vouchers' }}
            showHeader={false}
            loading={promotionLoading}
            referenceData={user}
            // onRowClick={onViewVoucherDetails}
        />
    );
};

UserVouchers.propTypes = {
    vouchers: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
};


export default withRouter(UserVouchers);
export const GET_LOCATIONS = 'locations/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'locations/GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'locations/GET_LOCATIONS_ERROR';

export const GET_MERLO_LOCATIONS = 'locations/GET_MERLO_LOCATIONS';
export const GET_MERLO_LOCATIONS_SUCCESS = 'locations/GET_MERLO_LOCATIONS_SUCCESS';
export const GET_MERLO_LOCATIONS_ERROR = 'locations/GET_MERLO_LOCATIONS_ERROR';

export const GET_LOCATION = 'locations/GET_LOCATION';
export const GET_LOCATION_SUCCESS = 'locations/GET_LOCATION_SUCCESS';
export const GET_LOCATION_ERROR = 'locations/GET_LOCATION_ERROR';

export const CREATE_LOCATION = 'locations/CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'locations/CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'locations/CREATE_LOCATION_ERROR';

export const UPDATE_LOCATION = 'locations/UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'locations/UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'locations/UPDATE_LOCATION_ERROR';

export const DELETE_LOCATION = 'locations/DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'locations/DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'locations/DELETE_LOCATION_ERROR';

export const LOCATIONS_LOADING_STATE = 'locations/LOCATIONS_LOADING_STATE';
export const LOCATION_SAVE_STATE = 'locations/LOCATION_SAVE_STATE';

export const CREATE_LOCATION_SUCCESS_TARGET_UUID = 'locations/CREATE_LOCATION_SUCCESS_TARGET_UUID';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';
import SelectInput from '@/components/TextInput/SelectInput';
import Checkbox from '@/components/Checkbox/Checkbox';

import { selectSegments } from '@/state/segments/reducers';
import { selectVouchers } from '@/state/promotions/reducers';

// Helper function for creating dropdown options
const createOptions = (array, label, value) =>
    array.reduce((acc, cur) => {
        acc.push({ label: cur[label], value: cur[value] });
        return acc;
    }, []);

const styles = makeStyles({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    }
});

const AutomationForm = ({ automation, onChange }) => {
    const classes = styles();

    const segments = useSelector(state => selectSegments(state));
    const segmentOptions = createOptions(segments, 'name', 'uuid');

    const vouchers = useSelector(state => selectVouchers(state));
    const voucherOptions = createOptions(vouchers, 'title', 'uuid');

    const readOnly = onChange === null || onChange === undefined;

    const handleCheckbox = type => event => {
        onChange(type, event.target.checked);
    };

    const handleText = type => event => {
        onChange(type, event.target.value);
    };

    const handleSelectOrDate = type => event => {
        onChange(type, event);
    };

    return (
        <Fragment>
            <Checkbox
                checked={automation.active}
                label="Make active"
                onChange={handleCheckbox('active')}
            />
            <Checkbox
                checked={automation.sendEmailOnGeneration}
                label="Send email on generation"
                onChange={handleCheckbox('sendEmailOnGeneration')}
            />

            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        label="Title"
                        value={automation.title}
                        readOnly={readOnly}
                        onChange={handleText('title')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        readOnly={readOnly}
                        label="Next run date"
                        value={automation.nextRunDate}
                        minDate={moment()}
                        onChange={handleSelectOrDate('nextRunDate')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        readOnly
                        label="Last run date"
                        value={automation.lastRunDate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput
                        disabled={readOnly}
                        label="Repeat time"
                        options={[
                            { value: 'Hourly', label: 'Hourly' },
                            { value: 'Daily', label: 'Daily' },
                            { value: 'Weekly', label: 'Weekly' }
                        ]}
                        value={automation.repeatTime}
                        onChange={handleSelectOrDate('repeatTime')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput
                        disabled={readOnly}
                        label="Linked segment"
                        options={segmentOptions}
                        value={automation.segmentUuid}
                        onChange={handleSelectOrDate('segmentUuid')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectInput
                        disabled={readOnly}
                        label="Linked voucher"
                        options={voucherOptions}
                        value={automation.voucherUuid}
                        onChange={handleSelectOrDate('voucherUuid')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="Internal notes"
                        value={automation.notes}
                        name="internalDescription"
                        multiline
                        readOnly={readOnly}
                        rows={3}
                        onChange={handleText('notes')}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

AutomationForm.propTypes = {
    automation: PropTypes.object,
    onChange: PropTypes.func
};

AutomationForm.defaultProps = {
    onChange: null
};

export default AutomationForm;

export const setLocalStore = (key, data) => {
    try {
        localStorage.setItem(key, data);
    } catch (error) {
        throw error;
    }
}

export const getLocalStore = (key) => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        throw error;
    }
}

export const removeLocalStore = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        throw error;
    }
}

export const setAuthToken = (tokenData) => {
    // console.info('DEBUG: setAuthToken -> tokenData', tokenData);
    const { idToken, refreshToken, expiresIn } = tokenData;
    setLocalStore('idToken', idToken);
    setLocalStore('refreshToken', refreshToken);
    setLocalStore('expiresIn', expiresIn);
    setLocalStore('loggedIn', true);
}


export const clearAuthToken = () => {
    removeLocalStore('idToken');
    removeLocalStore('refreshToken');
    removeLocalStore('expiresIn');
    setLocalStore('loggedIn', false);
}
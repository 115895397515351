import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Box from '@/components/Box/Box';
import Button from '@/components/Button/Button';
import Loader from '@/components/Loader/Loader';

import * as modalTypes from '@/state/modal/types';
import * as modals from '@/constants/modals';

import * as productTypes from '@/state/products/types';

import { selectSelectedProduct } from '@/state/products/reducers';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import CheckBox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import * as helpers from '../Table/helpers';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import arrayMove from 'array-move';
import { default as MerloTable } from '../Table/Table';
import { TableRow } from '@material-ui/core';

const styles = makeStyles({
    container: {
        textAlign: 'center',
        marginBottom: 20
    },
    box: {
        padding: 0,
        marginTop: 20,
        borderRadius: 2
    },
    toolbar: {
        minHeight: 0,
        marginBottom: 20,
        padding: 20
    },
    button: {
        marginLeft: 20,
        width: 150
    },
    checkBoxCell: {
        textAlign: 'center',
        paddingRight: 0,
        paddingLeft: 0,
        height: 71
    },
    tableRow: {
        backgroundColor: '#fff',
        cursor: 'pointer',
        width: '100%',
    },
    tableCell: styles => ({
        ...styles
    }),
    table: {
        minWidth: 500
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    helperClass: {
        display: 'flex',
        justifyContent: 'space-between',
        '& td': {
            borderBottom: 0
        },
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

const headerData = [
    { key: 'partCode', label: 'SKU / PART' },
    { key: 'erpTitle', label: 'ERP TITLE' },
    { key: 'optionTitle', label: 'OPTION TITLE' },
    {   key: 'weight',
        label: 'WEIGHT', 
        compute: rowData => ('weight' in rowData ? `${rowData.weight}` : 0.00),
        renderer: data => `${data}`
    },
    {
        key: 'price',
        label: 'PRICE',
        compute: rowData => ('price' in rowData ? rowData.price : 0),
        renderer: data => `$ ${data.toFixed(2)}`
    },
    {
        key: 'inStock',
        label: '',
        sortable: false,
        compute: rowData => rowData.inStock,
        renderer: data => {
            const color = data ? '#51bf00' : '#510000';
            return (
                <div style={{ color }}>
                    {data ? 'In stock!' : 'Out of stock!'}
                </div>
            );
        }
    },
    {
        key: 'reward.percentage',
        label: 'BEAN DOLLAR %',
        compute: rowData => (rowData.reward && rowData.reward.percentage ? rowData.reward.percentage : 0),
        renderer: data => `${data}%`
    },
];

const ProductLinkedParts = props => {
    const { readOnly, history, loading, onUpdate } = props;
    const classes = styles();
    const dispatch = useDispatch();
    const [selectedParts, setSelectedParts] = React.useState({});
    const [linkedParts, setLinkedParts] = React.useState([]);

    const product = useSelector(state => selectSelectedProduct(state));
    const { productParts } = product;
    console.log("Product", product);
    console.log("Product Parts", productParts);

    useEffect(() => {
        if (!loading && productParts !== undefined) {
            setLinkedParts(productParts);
            onUpdate(productParts.map(p => p.partCode))
        }
    }, [loading, productParts]);

    const addPartRef = useRef();
    addPartRef.current = () => {
        onUpdate(linkedParts.map(p => p.partCode));
    };

    const removePartRef = useRef();
    removePartRef.current = parts => {
        const allParts = linkedParts.filter(
            ap => parts.map(p => p.partCode).indexOf(ap.partCode) === -1
        );
        setSelectedParts({});
        onUpdate(allParts.map(p => p.partCode));
    };

    const addParts = () => {
        dispatch({
            type: modalTypes.MODAL_SET_COMPONENT,
            component: modals.PRODUCTS_ADD_PARTS_MODAL,
            props: { product, ref: addPartRef }
        });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    };

    const removeParts = () => {
        const filteredParts = linkedParts.filter(
            p => Object.keys(selectedParts).indexOf(p.partCode) === -1
        );
        const toRemove = linkedParts.filter(
            p => Object.keys(selectedParts).indexOf(p.partCode) > -1
        );

        if (product) {
            dispatch({
                type: modalTypes.MODAL_SET_COMPONENT,
                component: modals.REMOVE_PART_FROM_PRODUCT,
                props: { parts: toRemove, product, ref: removePartRef }
            });
            dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
        } else {
            dispatch({
                type: productTypes.REMOVE_FROM_PRODUCT_PART_LIST,
                parts: filteredParts
            });
            setSelectedParts({});
            onUpdate(filteredParts.map(p => p.partCode));
        }
    };

    const toolbarActions = () => {
        return (
            <React.Fragment>
                <Button
                    className={classes.button}
                    disabled={Object.keys(selectedParts).length === 0 || readOnly}
                    label="Remove Selected"
                    onClick={removeParts}
                />
                <Button
                    disabled={readOnly}
                    className={classes.button}
                    label="+ Add Parts"
                    onClick={addParts}
                />
            </React.Fragment>
        );
    };

    const onRowChecked = rowData => {
        // deselect and select
        const data = { ...selectedParts };
        const { partCode } = rowData;
        if (partCode in data) {
            // delete
            delete data[partCode];
        } else {
            data[partCode] = rowData;
        }

        setSelectedParts({ ...data });
    };

    const onRowClick = rowData => {
        const { partCode } = rowData;
        history.push(`/parts/${partCode}`);
    };

    const SortableItem = SortableElement(({value}) => {
        const isChecked = value["partCode"] in selectedParts;
        return (
            <TableRow
                key={value.partCode}
                hover
                classes={{
                    root: classes.tableRow,
                }}
                onClick={() => onRowClick(value)}
            >
                <TableCell
                    component="td"
                    scope="row"
                    className={classes.checkBoxCell}
                >
                    <CheckBox
                        onChange={() => { onRowChecked(value) }}
                        checked={isChecked}
                        color="primary"
                        onClick={(e) => e.stopPropagation()}
                    />
                </TableCell>
                {
                    headerData.map((hd, i) => {
                        let cellData;
                        if ('compute' in hd) {
                            // evalute the computed method for rendering the cell
                            const computedCellData = hd.compute({ ...value });
                            cellData = hd.renderer(computedCellData);
                        } else {
                            cellData = helpers.deepValue(value, hd.key);
                        }
                        let cellStyle = {};
                        if ('styles' in hd) {
                            cellStyle = hd.styles;
                        }

                        if ('visible' in hd) {
                            showColumn = hd.visible;
                        }

                        return (
                            <TableCell
                                component="td"
                                scope="row"
                                key={i}
                                classes={{ root: classes.tableCell }}
                                {...cellStyle}
                            >
                                {cellData}
                            </TableCell>
                        )
                    })
                }
                {/* <TableCell
                    component="td"
                    scope="row"
                    className={classes.checkBoxCell}
                >
                    <DragHandleIcon />
                </TableCell> */}
            </TableRow>
        ) 
    });

    const SortableList = SortableContainer(({items}) => {
    return (
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableBody>

                {items.map((value, index) => (
                            <SortableItem 
                                key={`item-${value.partCode}`} 
                                index={index} 
                                value={value} 
                            />
                            ))}
                </TableBody>
            </Table>
        </div>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newLinkedParts = arrayMove(linkedParts, oldIndex, newIndex);
        dispatch({
            type: productTypes.UPDATE_PART_LIST_ORDER,
            parts: newLinkedParts
        });
    };

    if(!readOnly) {
        return (
            <Box
                title="Linked Parts"
                toolbarAction={toolbarActions()}
                toolbarClass={classes.toolbar}
                rootClass={classes.box}
            >
                <SortableList items={linkedParts} onSortEnd={onSortEnd} distance={1} helperClass={classes.helperClass} />
            </Box>
        )
    } else {
        return (
            <Box
                title="Linked Parts"
                toolbarAction={toolbarActions()}
                toolbarClass={classes.toolbar}
                rootClass={classes.box}
            >
                <Loader loading={loading}>
                    <MerloTable
                        tableData={linkedParts}
                        loading={loading}
                        readOnly={readOnly}
                        checkBox
                        showHeader={false}
                        onRowClick={onRowClick}
                        onChecked={onRowChecked}
                        checkBoxData={selectedParts}
                        headerData={headerData}
                        checkBoxId="partCode"
                        serverSideSorting
                    />
                </Loader>
            </Box>
        );
    }
    
};

ProductLinkedParts.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired
};

export default withRouter(ProductLinkedParts);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import SearchInput from '@/components/SearchInput/SearchInput';
import Checkbox from '../Checkbox/Checkbox';
import Datepicker from '@/components/Datepicker/Datepicker';

import SingleSelect from '@/components/SingleSelect/SingleSelect';

const styles = makeStyles({
    flexContainer: {
        display: 'flex',
        height: 50
    },
    search: {
        flexBasis: '100%',
        marginRight: 24
    },
    source: {
        flexBasis: '30%',
        marginRight: 24
    },
    uploaded: {
        fontSize: 11,
        color: '#191919'
    }
});

const sources = ['POS', 'Shopify', 'Woocommerce'];

const OrderFilter = ({
    search,
    source,
    epicorYes,
    epicorNo,
    fromDate,
    toDate,
    onChange
}) => {
    const classes = styles();

    return (
        <React.Fragment>
            <div className={classes.flexContainer}>
                <SearchInput
                    placeholder="Search by order ID, epicor ID, email, order phone number, customer name"
                    className={classes.search}
                    onChange={onChange('search')}
                    value={search}
                />
                <SingleSelect
                    className={classes.source}
                    value={source}
                    onChange={onChange('source')}
                    placeholder="Any Source"
                    options={sources}
                />
                <div>
                    <Typography className={classes.uploaded}>
                        Uploaded to Epicor
                    </Typography>
                    <div className={classes.flexContainer}>
                        <Checkbox
                            label="Yes"
                            checked={epicorYes}
                            onChange={onChange('epicorYes')}
                        />
                        <Checkbox
                            label="No"
                            checked={epicorNo}
                            onChange={onChange('epicorNo')}
                        />
                    </div>
                </div>
            </div>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                    <Datepicker
                        variant="contained"
                        label="From date"
                        value={fromDate}
                        onChange={onChange('dateFrom')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Datepicker
                        variant="contained"
                        label="To date"
                        value={toDate}
                        onChange={onChange('dateTo')}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

OrderFilter.propTypes = {
    search: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    epicorYes: PropTypes.bool.isRequired,
    epicorNo: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    fromDate: PropTypes.instanceOf(moment).isRequired,
    toDate: PropTypes.instanceOf(moment).isRequired
};

export default OrderFilter;

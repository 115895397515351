// actions
import axios from 'axios';

export const getParts = async () => {
    try {
        const result = await axios.get(`/v1/parts`);

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const getPart = async (request) => {
    const { partCode } = request;
    try {
        const result = await axios.get(`/v1/parts/${partCode}`);

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const updatePart = async (request) => {
    const { partCode, data } = request;

    try {
        const result = await axios.put(`/v1/parts/${partCode}`, {
            ...data
        });

        return result.data;
    } catch (error) {
        throw error;
    }
}

export const upsertParts = (partsList, parts) => {
    const _partsList = [...partsList];

    if (parts && parts.length > 0) {
        parts.forEach(part => {
            const idx = _partsList.map(p => p.partCode).indexOf(part.partCode);
            if (idx > -1) {
                _partsList.splice(idx, 1, part);
            } else {
                _partsList.push(part);
            }
        });
    }

    return _partsList;
}
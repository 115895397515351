import { combineReducers } from 'redux';

import userReducer from './users/reducers';
import locationReducer from './locations/reducers';
import promotionReducer from './promotions/reducers';
import loyaltyReducer from './loyalty/reducers';
import orderReducer from './orders/reducers';
import productReducer from './products/reducers';
import partReducer from './parts/reducers';
import authReducer from './auth/reducers';

import modalReducer from './modal/reducers';
import tabsReducer from './tabs/reducers';
import snackReducer from './snack/reducers';
import permissionsReducer from './permissions/reducers';
import segmentsReducer from './segments/reducers';

import featureFlagReducer from './featureFlag/reducers';
import realtimeReducer from './realtime/reducers';

const rootReducer = combineReducers({
    users: userReducer,
    locations: locationReducer,
    promotions: promotionReducer,
    loyalty: loyaltyReducer,
    orders: orderReducer,
    modals: modalReducer,
    tabs: tabsReducer,
    products: productReducer,
    parts: partReducer,
    auth: authReducer,
    snack: snackReducer,
    permissions: permissionsReducer,
    segments: segmentsReducer,
    featureFlag: featureFlagReducer,
    realtime: realtimeReducer
});

export default rootReducer;

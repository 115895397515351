import React from 'react';
import PropTypes from 'prop-types';
import MuiRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const styles = makeStyles(theme => ({
    labelRoot: {
        userSelect: 'none',
        marginBottom: 0,
    },
    small: {
        '& svg': {
            height: 16,
            width: 16
        }
    },
    label: {
        fontSize: 14,
        fontWeight: 300,
    },
    icon: {
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        border: '2px solid #a7a7a7',
        width: 16,
        height: 16,
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        border: 'none'
    },
    checkBoxLabel: {
        marginBottom: 0,
    }
}));

const RadioBox = props => {
    const classes = styles();
    const { value, label, small } = props;

    const rootClass = small
        ? `${classes.labelRoot} ${classes.small}`
        : classes.labelRoot;
    const labelClass = small ? classes.label : '';


    return (
        <FormControlLabel
            classes={{
                root: rootClass,
                label: labelClass
            }}
            value={value}
            control={
                <MuiRadio
                    color="primary"
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                />
            }
            label={label}
            labelPlacement='end'
        />
    );
};

RadioBox.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    small: PropTypes.bool,
};

RadioBox.defaultProps = {
    label: 'Checkbox',
    small: true
};

export default RadioBox;
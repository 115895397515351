import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

const Realtime = ({ io, history }) => {
    const dispatch = useDispatch();
    
    const registerHandlers = socket => {
        socket.on('message', payload => {
            console.log('SocketIO message received: ', payload);
            // do something with the message based on its type
            // const { type, data, target } = payload;
            dispatch({ ...payload, history });
        });

        socket.on('error', error => {
            console.log('error', error);
        });

        socket.on('disconnect', () => {
            console.log('connection closed');
        });
    };

    const connectSocket = io => {
        io.connect(registerHandlers);
    };

    useEffect(() => {
        // establish connection to the realtime
        if (io.socket === null) {
            connectSocket(io);
        } else if (!io.socket.connected) {
            connectSocket(io);
        }
    }, []);

    return <div />;
};

Realtime.propTypes = {};

export default withRouter(Realtime);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@/components/Button/Button';
import TextInput from '@/components/TextInput/TextInput';

import * as authTypes from '@/state/auth/types';
import * as modalTypes from '@/state/modal/types';
import { selectAuthLoadingState } from '@/state/auth/reducers';

import * as validation from '@/constants/validation';

const styles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 14,
        color: '#ff0000',
        height: 20
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 100,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14,
    }
}));

const ResetPassword = props => {
    const { title, user } = props;

    const dispatch = useDispatch();
    const classes = styles();

    const [passwordState, setPassword] = React.useState({
        password: '',
        confirmPassword: '',
        isDirty: false,
    });

    const [validationState, setValidationState] = React.useState({
        errorMessage: null,
        isValid: false,
        isSame: false,
    });

    const loadingState = useSelector(state => selectAuthLoadingState(state));

    const onPasswordChange = type => event => {
        setPassword({ ...passwordState, [type]: event.target.value, isDirty: true });
    }

    useEffect(() => {
        const { password, confirmPassword, isDirty } = passwordState;
        if (isDirty) {
            const isValid = validation.validatePassword(password);
            const isSame = validation.validateConfirmPassword(password, confirmPassword);

            let errorMessage = '';
            if (isValid !== undefined) {
                const [firstError] = isValid.password;
                errorMessage = firstError;
            } else if (isSame !== undefined) {
                const [firstError] = isSame.confirm;
                errorMessage = firstError;
            }

            setValidationState({
                ...validationState,
                errorMessage,
                isValid: isValid === undefined || false,
                isSame: isSame === undefined || false
            });
        }
    }, [passwordState])

    const onCancel = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
    }

    const onReset = () => {
        dispatch({ type: authTypes.RESET_PASSWORD, userUuid: user.uuid, password: passwordState.password });
        onCancel();
    }

    const disabled = loadingState || !(validationState.isSame && validationState.isValid);

    return (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            <TextInput
                label='Password'
                value={passwordState.password}
                onChange={onPasswordChange('password')}
                error={passwordState.isDirty && !validationState.isValid}
            />
            <TextInput
                label='Confirm Password'
                value={passwordState.confirmPassword}
                onChange={onPasswordChange('confirmPassword')}
                errorMessage={validationState.errorMessage}
                error={passwordState.isDirty && !validationState.isSame}
            />
            <div className={classes.errorContainer}>
                {validationState.errorMessage}
            </div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant={null} label='Cancel' onClick={onCancel} disabled={loadingState} />
                <Button className={classes.button} label='Reset' onClick={onReset} loading={loadingState} disabled={disabled} />
            </div>
        </div>
    );
};

ResetPassword.propTypes = {
    title: PropTypes.string,
    user: PropTypes.object,
};

ResetPassword.defaultProps = {
    title: null,
    user: {}
}

export default ResetPassword;
import * as types from './types';

// state definition
const initialState = {
    healthCheck: [
        {
            status: -1,
            serviceName: 'ProductService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'NotificationService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'UserService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'ShopifyService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'AuthenticationService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'LocationService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'PromotionService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'LoyaltyService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'OrderService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'PermissionsService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'SegmentService',
            loading: false
        },
        {
            status: -1,
            serviceName: 'EpicorService',
            loading: false
        }
    ],
}

const realtimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SINGLE_HEALTH_CHECK':
            const updated = state.healthCheck.map(h => {
                if (h.serviceName === action.state.serviceName) {
                    h.status = action.state.status
                    h.loading = false
                }
                return h;
            });

            return {
                ...state,
                healthCheck: updated
            }

        case 'UPDATE_ALL_HEALTH_CHECK':
            return {
                ...state,
                healthCheck: action.state
            }

        default:
            return state;
    }
}

export default realtimeReducer;

export const selectHealthCheck = state => state.realtime.healthCheck;

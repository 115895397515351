import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import SearchInput from '@/components/SearchInput/SearchInput';
import SelectWithCheckbox from '@/components/SelectWithCheckbox/SelectWithCheckbox';



const ProductFilter = props => {
    const {
        onChange,
        searchFilter,
        statuses,
        filteredStatuses,
        collections,
        filteredCollections,
        numOfResults,
        removeFilter,
        clearAll,
        searchOnly
    } = props;

    const styles = makeStyles({
        flexContainer: {
            display: 'flex'
        },
        search: {
            flexBasis: searchOnly ? '100%' : '60%',
            marginRight: searchOnly ? 0 : 24
        },
        type: {
            flexBasis: '20%',
            marginRight: 24
        },
        collection: {
            flexBasis: '20%',
            marginRight: 24
        },
        status: {
            flexBasis: '20%'
        },
        filterContainer: {
            marginTop: 24
        },
        results: {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            marginRight: 40
        },
        filterText: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 8
        },
        iconButton: {
            marginLeft: 5,
            outline: '0 !important'
        },
        icon: {
            fontSize: 24
        },
        button: {
            outline: '0 !important'
        }
    });

    const classes = styles();

    const computedFilters = [...filteredStatuses, ...filteredCollections];
    return (
        <React.Fragment>
            <div className={classes.flexContainer}>
                <SearchInput
                    className={classes.search}
                    placeholder="Search by product title or associated SKU"
                    value={searchFilter}
                    onChange={onChange('search')}
                />
                {

                    !searchOnly &&
                    <React.Fragment>
                        <SelectWithCheckbox
                            className={classes.collection}
                            options={collections}
                            selected={filteredCollections}
                            onChange={onChange('filteredCollections')}
                            label="Collection"
                        />
                        <SelectWithCheckbox
                            className={classes.status}
                            options={statuses}
                            selected={filteredStatuses}
                            onChange={onChange('filteredStatuses')}
                            label="Status"
                        />
                    </React.Fragment>
                }
            </div>
            {
                !searchOnly &&
                <div className={classes.filterContainer}>
                    <div className={classes.flexContainer}>
                        <Typography className={classes.results}>
                            {`Filters (${numOfResults} results):`}
                        </Typography>

                        {
                            computedFilters.map((filter, i) => (
                                <Typography key={`userFilter-${i}`} className={classes.filterText}>
                                    {filter.label}
                                    <IconButton
                                        size="small"
                                        className={classes.iconButton}
                                        onClick={() => removeFilter(filter.value)}
                                    >
                                        <CloseIcon className={classes.icon} />
                                    </IconButton>
                                </Typography>
                            ))
                        }
                        <Button className={classes.button} onClick={clearAll}>
                            Clear All
                        </Button>
                    </div>
                </div>
            }

        </React.Fragment>
    );
};

ProductFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    searchFilter: PropTypes.string.isRequired,

    collections: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    filteredCollections: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),

    statuses: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    filteredStatuses: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    searchOnly: PropTypes.bool,
    numOfResults: PropTypes.number,
    removeFilter: PropTypes.func,
    clearAll: PropTypes.func
};

ProductFilter.defaultProps = {
    searchOnly: false,
    collections: [{ value: '', label: '' }],
    filteredCollections: [{ value: '', label: '' }],
    statuses: [{ value: '', label: '' }],
    filteredStatuses: [{ value: '', label: '' }],
    numOfResults: 0,
    removeFilter: () => { },
    clearAll: () => { },
}

export default ProductFilter;

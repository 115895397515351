export const GET_USERS_ORDERS = 'orders/GET_USERS_ORDERS';
export const GET_USERS_ORDERS_SUCCESS = 'orders/GET_USERS_ORDERS_SUCCESS';
export const GET_USERS_ORDERS_ERROR = 'orders/GET_USERS_ORDERS_ERROR';

export const GET_ALL_ORDERS = 'orders/GET_ALL_ORDERS';
export const GET_ALL_ORDERS_SUCCESS = 'orders/GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_ERROR = 'orders/GET_ALL_ORDERS_ERROR';

export const GET_ORDER = 'orders/GET_ORDER';
export const GET_ORDER_SUCCESS = 'orders/GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'orders/GET_ORDER_ERROR';

export const SUBMIT_TO_EPICOR = 'orders/SUBMIT_TO_EPICOR';
export const SUBMIT_TO_EPICOR_SUCCESS = 'orders/SUBMIT_TO_EPICOR_SUCCESS';
export const SUBMIT_TO_EPICOR_ERROR = 'orders/SUBMIT_TO_EPICOR_ERROR';

export const GET_ORDER_EPICOR_DATA = 'orders/GET_ORDER_EPICOR_DATA';
export const GET_ORDER_EPICOR_DATA_SUCCESS =
    'orders/GET_ORDER_EPICOR_DATA_SUCCESS';
export const GET_ORDER_EPICOR_DATA_ERROR = 'orders/GET_ORDER_EPICOR_DATA_ERROR';

export const ORDERS_LOADING_STATE = 'orders/ORDERS_LOADING_STATE';

export const GET_EPICOR_SETTING = 'orders/GET_EPICOR_SETTING';

export const UPDATE_EPICOR_SETTING = 'orders/UPDATE_EPICOR_SETTING';
export const UPDATE_EPICOR_SETTING_SUCCESS =
    'orders/UPDATE_EPICOR_SETTING_SUCCESS';
export const UPDATE_EPICOR_SETTING_ERROR = 'orders/UPDATE_EPICOR_SETTING_ERROR';

export const EPICOR_SETTING_LOADING_STATE =
    'orders/EPICOR_SETTING_LOADING_STATE';
export const GET_EPICOR_SETTING_SUCCESS = 'orders/GET_EPICOR_SETTING_SUCCESS';
export const GET_EPICOR_SETTING_ERROR = 'orders/GET_EPICOR_SETTING_ERROR';

export const UPDATE_ORDER = 'orders/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'orders/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'orders/UPDATE_ORDER_ERROR';

export const UPDATE_ORDER_SUCCESS_TARGET_UUID =
    'orders/UPDATE_ORDER_SUCCESS_TARGET_UUID';

export const SUBMIT_TO_GIVEX = 'orders/SUBMIT_TO_GIVEX';
export const SUBMIT_TO_GIVEX_SUCCESS = 'orders/SUBMIT_TO_GIVEX_SUCCESS';
export const SUBMIT_TO_GIVEX_ERROR = 'orders/SUBMIT_TO_GIVEX_ERROR';

export const MARK_REWARD_AS_MANUALLY_PROCESSED =
    'orders/MARK_REWARD_AS_MANUALLY_PROCESSED';
export const MARK_REWARD_AS_MANUALLY_PROCESSED_SUCCESS =
    'orders/MARK_REWARD_AS_MANUALLY_PROCESSED_SUCCESS';
export const MARK_REWARD_AS_MANUALLY_PROCESSED_ERROR =
    'orders/MARK_REWARD_AS_MANUALLY_PROCESSED_ERROR';
export const REWARD_SETTING_LOADING_STATE =
    'orders/REWARD_SETTING_LOADING_STATE';

export const GET_REWARD_SETTING = 'orders/GET_REWARD_SETTING';
export const GET_REWARD_SETTING_SUCCESS = 'orders/GET_REWARD_SETTING_SUCCESS';
export const GET_REWARD_SETTING_ERROR = 'orders/GET_REWARD_SETTING_ERROR';

export const UPDATE_REWARD_SETTING = 'orders/UPDATE_REWARD_SETTING';
export const UPDATE_REWARD_SETTING_SUCCESS =
    'orders/UPDATE_REWARD_SETTING_SUCCESS';
export const UPDATE_REWARD_SETTING_ERROR = 'orders/UPDATE_REWARD_SETTING_ERROR';

export const GET_UNSYNCED_GIVEX_ORDERS = 'orders/GET_UNSYNCED_GIVEX_ORDERS';
export const GET_UNSYNCED_GIVEX_ORDERS_SUCCESS =
    'orders/GET_UNSYNCED_GIVEX_ORDERS_SUCCESS';
export const GET_UNSYNCED_GIVEX_ORDERS_ERROR =
    'orders/GET_UNSYNCED_GIVEX_ORDERS_ERROR';

export const GIVEX_ORDERS_LOADING = 'orders/GIVEX_ORDERS_LOADING';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Box from '@/components/Box/Box';
import EpicorUpload from '@/components/Orders/EpicorUpload';
import AccrueRewardSetting from '@/components/Orders/AccrueRewardSetting';
import moment from 'moment';

import * as orderActions from '@/state/orders/actions';
import * as loyaltyActions from '@/state/loyalty/actions';

const styles = theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20
    }
});

const detailsMap = {
    brandCompany: 'Brand Company',
    brandSite: 'Brand Site',
    brandWarehouse: 'Brand Warehouse',
    brandGroup: 'Brand Group',
    currencyCode: 'Currency Code',

    mode: 'Order Upload Mode (ECC or BISCIT)',
    biscitUrl: 'Biscit API URL Base',
    biscitKey: 'Biscit API key',

    url: 'ECC URL',
    defaultCustomer: 'ECC Customer',
    soapUsername: 'ECC SOAP Username',
    soapPassword: 'ECC SOAP Password',
};

const givexDetailsMap = {
    giftCardGivexPrimaryUrl: 'Gift Card Givex Primary URL',
    giftCardGivexSecondaryUrl: 'Gift Card Givex Secondary URL',
    giftCardGivexUserId: 'Gift Card Givex User ID',
    giftCardGivexPassword: 'Gift Card Givex Password',
    loyaltyGivexPrimaryUrl: 'Loyalty Givex Primary',
    loyaltyGivexSecondaryUrl: 'Loyalty Givex Secondary URL',
    loyaltyGivexUserId: 'Loyalty Givex User ID',
    loyaltyGivexPassword: 'Loyalty Givex Password'
};



class Settings extends Component {
    constructor(props) {
        super();

        this.state = {
            details: {},
            givexDetails: {}
        };
    }

    async componentDidMount() {
        const response = await orderActions.getEpicorConnectionDetails();
        const givexResponse = await loyaltyActions.getGivexConnectionDetails();
        this.setState({
            details: response.details,
            givexDetails: givexResponse.details
        });
    }

    render() {
        const { readOnly, classes } = this.props;
        const { details, givexDetails } = this.state;

        const isProd = process.env.NODE_ENV === 'production';
        const cutoverDate = isProd
            ? window.__env__.BEAN_DOLLAR_START_DATE
            : process.env.REACT_APP_BEAN_DOLLAR_START_DATE;

        const cutoverDateBNE = moment(cutoverDate).tz('Australia/Brisbane').format('LLL');

        return (
            <React.Fragment>
                <Box title="Settings" rootClass={classes.root}>
                    <List>
                        <ListItem key="Upload Orders to Epicor">
                            <ListItemText primary="Upload Orders to Epicor" />
                            <EpicorUpload readOnly={readOnly} />
                        </ListItem>
                        <Divider />
                        <ListItem key="Epicor Connection Details">
                            <ListItemText primary="Epicor Connection Details" />
                        </ListItem>

                        {Object.keys(details).map(key => {
                            return (
                                <ListItem dense key={key}>
                                    <ListItemText primary={detailsMap[key]} />
                                    <ListItemSecondaryAction>
                                        <ListItemText primary={details[key]} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        <Divider />

                        <ListItem key="Givex Connection Details">
                            <ListItemText primary="Givex Connection Details" />
                        </ListItem>


                        {Object.keys(givexDetails).map(key => {
                            return (
                                <ListItem dense key={key}>
                                    <ListItemText
                                        primary={givexDetailsMap[key]}
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            primary={givexDetails[key]}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}

                        <Divider />

                        <ListItem key="Bean Dollar Cutover Date">
                            <ListItemText
                                        primary="Bean Dollar Cutover Date"
                                    />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={cutoverDateBNE}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        
                        <ListItem key="Allow Accrual of Merlo Bean-Dollars">
                            <ListItemText primary="Allow Accrual of Merlo Bean-Dollars (On orders placed after Cutover)" />
                            <AccrueRewardSetting readOnly={readOnly} />
                        </ListItem>

                        <Divider />

                        
                    </List>
                </Box>
            </React.Fragment>
        );
    }
}

Settings.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(Settings))
);

import * as types from './types';
import { upsertSegment, removeSegmentFromList } from './actions';

// state definition
const initialState = {
    segments: [],
    segmentUsers: [],
    segmentCount: 0,
    pushNotificationHistory: [],
    selectedSegment: {},
    fields: {},
    loading: false,
    saving: false,
    segmentHistoryLoading: false
};

const segmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SEGMENTS_SUCCESS:
            return {
                ...state,
                segments: action.segments,
                meta: action.meta
            };

        case types.SEGMENT_LOADING_STATE:
            return {
                ...state,
                loading: action.state
            };

        case types.SEGMENT_HISTORY_LOADING_STATE:
            return {
                ...state,
                segmentHistoryLoading: action.state
            };

        case types.GET_SEGMENT_USERS_SUCCESS:
            return {
                ...state,
                segmentUsers: action.users,
                segmentCount: action.count
            };

        case types.SET_SELECTED_SEGMENT:
        case types.GET_SEGMENT_DETAILS_SUCCESS:
            return {
                ...state,
                selectedSegment: action.segment,
            };

        case types.GET_SEGMENT_PUSH_NOTIFICATION_HISTORY_SUCCESS:
            return {
                ...state,
                pushNotificationHistory: action.history
            };

        case types.SEND_PUSH_NOTIFICATION_SUCCESS:
            return {
              ...state,
              pushNotificationHistory: [...state.pushNotificationHistory, action.notification]
            };

        case types.GET_SEGMENT_FIELDS_SUCCESS:
            return {
                ...state,
                // key by uuid and set value = uuid
                fields: action.fields.reduce((acc, field) => {
                    const { uuid, ...rest } = field;
                    acc[uuid] = { ...rest, value: uuid };
                    return acc;
                }, {}),
            };

        case types.CREATE_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: upsertSegment(state.segments, action.segment),
            };

        case types.CREATE_SEGMENT_SUCCESS_TARGET_UUID:
            return {
                ...state,
                selectedSegment: action.segment,
            };

        case types.UPDATE_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: upsertSegment(state.segments, action.segment)
            };

        case types.SEGMENT_SAVING_STATE:
            return {
                ...state,
                saving: action.state,
            };
        
        case types.CLEAR_SEGMENT_RESULTS:
            return {
                ...state,
                segmentUsers: [],
                segmentCount: 0,
            };
        
        case types.DELETE_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: removeSegmentFromList(state.segments, action.segment) 
            };

        default:
            return state;
    }
};

export default segmentsReducer;

export const selectSegments = state => state.segments.segments;
export const selectSegmentsLoading = state => state.segments.loading;
export const selectSegmentUsers = state => state.segments.segmentUsers;
export const selectSegmentCount = state => state.segments.segmentCount;
export const selectSegmentPushNotificationHistory = state => state.segments.pushNotificationHistory;
export const selectSelectedSegment = state => state.segments.selectedSegment;
export const selectSegmentFields = state => state.segments.fields;
export const selectSegmentSaving = state => state.segments.saving;
export const selectSegmentHistoryLoading = state => state.segments.segmentHistoryLoading;

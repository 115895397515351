import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    },
    anchorOrigin: {
        vertical: 55,
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    getContentAnchorEl: null
};

const styles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        boxShadow: '0 2px 4px 0px rgba(0, 0, 0, 0.2)'
    },
    selectRoot: {
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        padding: '15px 50px 15px 17px',
        '&:focus': {
            backgroundColor: `${theme.palette.background.paper} !important`
        }
    },
    inputRootClosed: {
        '& svg': {
            right: 15
        }
    },
    inputRootOpen: {
        '& svg': {
            right: 15,
            transform: 'rotate(180deg)'
        }
    },
    menuItem: {
        backgroundColor: `${theme.palette.background.paper} !important`
    },
    menuItemTextRoot: {
        margin: 0
    },
    menuItemText: {
        fontSize: 14
    },
    menuPaper: {
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f7f7f7 !important'
        },
        '&::-webkit-scrollbar': {
            width: 3
        },
        '&::-webkit-scrollbar-thumb': {
            height: 174,
            borderRadius: 15,
            backgroundColor: '#a5a5a5',
            outline: '1px solid slategrey'
        }
    }
}));

const SingleSelect = props => {
    const classes = styles();
    const [pressed, onPressed] = React.useState();
    const { style, value, options, onChange, placeholder, className } = props;

    const computedRootClass = className
        ? `${className} ${classes.formControl}`
        : classes.formControl;

    const inputRootClass = pressed
        ? classes.inputRootOpen
        : classes.inputRootClosed;

    return (
        <FormControl className={computedRootClass} style={style}>
            <Select
                displayEmpty
                disableUnderline
                onOpen={() => onPressed(!pressed)}
                onClose={() => onPressed(!pressed)}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                MenuProps={{
                    ...MenuProps,
                    classes: { paper: classes.menuPaper }
                }}
                classes={{
                    root: classes.selectRoot
                }}
                input={
                    <Input
                        classes={{
                            root: inputRootClass
                        }}
                    />
                }
                renderValue={val => {
                    if (val === '' || val === 'all') return placeholder;
                    return val;
                }}
            >
                {placeholder && (
                    <MenuItem value="all">
                        <ListItemText
                            classes={{
                                root: classes.menuItemTextRoot,
                                primary: classes.menuItemText
                            }}
                            primary={placeholder}
                        />
                    </MenuItem>
                )}
                {options.map((option, i) => (
                    <MenuItem
                        key={`menu-item-${i}`}
                        value={option}
                        classes={{
                            root: classes.menuItem
                        }}
                    >
                        <ListItemText
                            classes={{
                                root: classes.menuItemTextRoot,
                                primary: classes.menuItemText
                            }}
                            primary={option}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SingleSelect.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
};

SingleSelect.defaultProps = {
    style: null,
    value: null,
    className: null
};

export default SingleSelect;

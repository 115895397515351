import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import Button from '@/components/Button/Button';
import { syncMerloCloudWithKlaviyo, getAutomations, deleteAutomation } from '@/state/promotions/actions';
import { getLastRunUpdateAllProducts, updateAllProducts } from '@/state/products/actions';
import { deletePendingUsers, getLogs } from '@/state/users/actions';
import * as snackTypes from '@/state/snack/types';
import Box from '../Box/Box';

const styles = theme => ({
    root: {
        borderRadius: 2,
        padding: '13px 20px 30px 20px',
        marginTop: 20,
    }
});



class SettingsScripts extends Component {
    constructor(props) {
        super();

        this.state = {
            activeAutomation: false,
            automationUuid: null,
            loading: true,
            runDate: null,
            lastRunSocialScript: null,
            lastRunUpdateAllScript: null,
            lastRunDeletePendingUsersScript: null,
            socialScriptLoading: false,
            updateAllProductsLoading: false,
            deletePendingUsersLoading: false,
            deletedPendingUsers: null

        }
    }

    async componentDidMount() {
        this.getAutomations()
        this.getLastRunScripts()
    }

    async onUnlinkSocialClick() {
        this.setState({
            socialScriptLoading: true
        })

        const response = await runUnlinkSocials();

        this.setState({
            lastRunSocialScript: new Date(),
            socialScriptLoading: false
        })
    }

    async onClick(e) {
        this.setState({
            loading: true,
        })
        const response = await syncMerloCloudWithKlaviyo()
        this.setState({
            activeAutomation: true,
            automationUuid: response.uuid,
            loading: false,
            runDate: response.nextRunDate
        })
    }

    async getAutomations() {
        const automations = await getAutomations();
        const foundAutomation = automations.data.find(automation => automation.title === 'SyncWithCampginMonitor')

        if (foundAutomation && foundAutomation.active) {
            this.setState({
                activeAutomation: true,
                automationUuid: foundAutomation.uuid,
                runDate: foundAutomation.nextRunDate
            })
        } else {
            this.setState({
                activeAutomation: false,
                automationUuid: null
            })
        }

        this.setState({
            loading: false,
        })
    }

    async getLastRunScripts() {
        const unlinkSocialsResponse = await getLastRunUnlinkSocials();
        const updateAllResponse = await getLastRunUpdateAllProducts();
        const deletePendingUsersResponse = await getLogs();


        this.setState({
            lastRunSocialScript: unlinkSocialsResponse.data.lastRun,
            lastRunUpdateAllScript: updateAllResponse.data.lastRun,
            lastRunDeletePendingUsersScript: deletePendingUsersResponse.data.logs.length > 0 ?
                deletePendingUsersResponse.data.logs[0].executedDate : null,
            deletedPendingUsers: deletePendingUsersResponse.data.logs.length > 0 && deletePendingUsersResponse.data.logs[0].meta ?
                deletePendingUsersResponse.data.logs[0].meta.deleted : null,
            deletePendingUsersLoading: (deletePendingUsersResponse.data.logs.length > 0 && deletePendingUsersResponse.data.logs[0].progress === 'running')
        })
    }

    async runDeletePendingUsersScript() {
        this.setState({
            deletePendingUsersLoading: true
        })

        const response = await deletePendingUsers();
        const deletePendingUsersResponse = await getLogs();

        this.setState({
            lastRunDeletePendingUsersScript: new Date(),
            deletePendingUsersLoading: false,
            deletedPendingUsers: deletePendingUsersResponse.data.logs.length > 0 ?
                deletePendingUsersResponse.data.logs[0].meta.deleted : null
        })
    }

    async updateAllProducts() {
        const { setSnack } = this.props;

        this.setState({
            updateAllProductsLoading: true
        })
        try {
            await updateAllProducts();
            this.setState({
                updateAllProductsLoading: false,
                lastRunUpdateAllScript: new Date()
            })

            setSnack('success', 'All products will be updated in Shopify within 15 minutes');
        } catch (error) {
            this.setState({
                updateAllProductsLoading: false
            })
            setSnack('error', 'Failed to run script');
        }

    }

    async cancelAutomation() {
        this.setState({
            loading: true,
        })
        await deleteAutomation(this.state.automationUuid);
        await this.getAutomations()
        this.setState({
            loading: false,
        })
    }


    render() {
        const { readOnly, classes } = this.props;
        const { activeAutomation, loading, runDate, lastRunSocialScript, lastRunUpdateAllScript, deleteUsersProgress,
            socialScriptLoading, updateAllProductsLoading, lastRunDeletePendingUsersScript, deletePendingUsersLoading, deletedPendingUsers } = this.state;
        const isProd = process.env.NODE_ENV === 'production';
        const merloEnv = isProd ? window.__env__.MERLO_ENV : process.env.REACT_APP_MERLO_ENV;
        const deleteUserScondaryText = deletePendingUsersLoading ? 'Script is running and can take up to 10 minutes to complete. Please refresh or revisit the page later to review the results.' :
            (lastRunDeletePendingUsersScript ? `Last ran at ${moment(lastRunDeletePendingUsersScript).tz('Australia/Brisbane').format('DD-MM-YYYY HH:mm')} and deleted ${deletedPendingUsers} users` : null)
        return (
            <React.Fragment>
                <Box title="Scripts" rootClass={classes.root}>
                    <List>
                        <ListItem key='Sync Merlo Cloud With Klaviyo'>
                            <ListItemText
                                primary='Sync Merlo Cloud With Klaviyo'
                                secondary={activeAutomation ? `Set to run at ${moment(runDate).tz('Australia/Brisbane').format('YYYY-MM-DD HH:mm')}` : null}
                            />
                            {activeAutomation ?
                                (<Button
                                    onClick={() => this.cancelAutomation()}
                                    readOnly={readOnly}
                                    label="Cancel Script"
                                    loading={loading}
                                />) :
                                (<Button
                                    onClick={() => this.onClick()}
                                    readOnly={readOnly}
                                    label="Run Script"
                                    loading={loading}
                                />)
                            }
                        </ListItem>
                        {merloEnv !== 'production' &&
                            (<ListItem key='Delete All Social Accounts'>
                                <ListItemText
                                    primary='Delete All Social Accounts'
                                    secondary={lastRunSocialScript ? `Last ran at ${moment(lastRunSocialScript).tz('Australia/Brisbane').format('DD-MM-YYYY HH:mm')}` : null}
                                />
                                <Button
                                    onClick={() => this.onUnlinkSocialClick()}
                                    readOnly={readOnly}
                                    label="Run Script"
                                    loading={socialScriptLoading}
                                />
                            </ListItem>)}
                        <ListItem key='Update All Products'>
                            <ListItemText
                                primary='Update All Products'
                                secondary={lastRunUpdateAllScript ? `Last ran at ${moment(lastRunUpdateAllScript).tz('Australia/Brisbane').format('DD-MM-YYYY HH:mm')}` : null}
                            />
                            <Button
                                onClick={() => this.updateAllProducts()}
                                readOnly={readOnly}
                                label="Run Script"
                                loading={updateAllProductsLoading}
                            />
                        </ListItem>
                        <ListItem key='Delete All Pending Users older than 30 days'>
                            <ListItemText
                                primary='Delete All Pending Users older than 30 days'
                                secondary={deleteUserScondaryText}
                            />
                            <Button
                                onClick={() => this.runDeletePendingUsersScript()}
                                readOnly={readOnly}
                                label="Run Script"
                                loading={deletePendingUsersLoading}
                                disabled={deleteUsersProgress}
                            />
                        </ListItem>
                    </List>
                </Box>
            </React.Fragment>
        );
    }
}

SettingsScripts.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    setSnack: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setSnack: (level, message) => ({
        type: snackTypes.SET_SNACK,
        content: message,
        open: true,
        props: { variant: level }
    }),
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(SettingsScripts))
);

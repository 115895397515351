import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@/components/Box/Box';
import Button from '@/components/Button/Button';

const styles = makeStyles(theme => ({
    container: {
        textAlign: 'center'
    },
    link: {
        // color: '#077cc6',
        color: '#ffffff',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    button: {
        //  color: '#077cc6',

    }
}));

const ProductViewShopify = props => {
    const { product } = props;
    const classes = styles();
    // test env
    const getShopifyAdminLink = () => {
        const { shopifyId } = product;

        if (typeof(shopifyId) == 'undefined') return ''; //it has not got an id stored against it yet

        const isProd = process.env.NODE_ENV === 'production';
        const merloEnv = isProd ? window.__env__.MERLO_ENV : process.env.REACT_APP_MERLO_ENV;
        switch (merloEnv) {
            case 'development':
                return `https://shopify.dev.merlo.cloud/admin/products/${shopifyId}`;
            case 'staging':
                return `https://shopify.staging.merlo.cloud/admin/products/${shopifyId}`;
            case 'uat':
                return `https://merlo-ua-store.myshopify.com/admin/products/${shopifyId}`;
            case 'production':
                return `https://www.merlo.com.au/admin/products/${shopifyId}`;
            default:
                return '';
        }
    }

    return (
        
        <Box className={classes.container}>
            <Link
                className={classes.link}
                target="_blank"
                rel="noopener"
                href={getShopifyAdminLink()}
            >
                <Button
                    className={classes.button}
                    label='View product in Shopify Admin'
                    classes={{ label: classes.label }}
                />
            </Link>

        </Box>
    );
};

ProductViewShopify.propTypes = {
    product: PropTypes.object.isRequired,
};

ProductViewShopify.defaultProps = {
}

export default ProductViewShopify;

// <Button
//     variant={null}
//     label='View product in Shopify Admin'
//     className={classes.link}
//     onClick={onClick}
// />
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const PopupMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { menuActions, data, iconHorizontal, btnSize, btnIcon, btnStyle, menuStyle } = props;

    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const onClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(null);
    };

    const onMenuItemClick = action => e => {
        onClose(e);
        action.func(data);
    }

    const iconRotation = iconHorizontal ? <MoreHorizIcon /> : <MoreVertIcon />;
    const icon = btnIcon || iconRotation;

    const styles = makeStyles(theme => ({
        menu: {
            fontSize: 14,
            minWidth: 140,
            ...menuStyle
        }
    }));

    const classes = styles();

    return (
        <React.Fragment>
            <IconButton onClick={onClick} size={btnSize} style={{ outline: 'none', ...btnStyle }}>
                {icon}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {
                    menuActions.map((action, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={onMenuItemClick(action)}
                            classes={{ root: classes.menu }}
                        >
                            {action.label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </React.Fragment>
    );
};

PopupMenu.propTypes = {
    data: PropTypes.object,
    menuActions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            func: PropTypes.func
        })
    ).isRequired,
    iconHorizontal: PropTypes.bool,
    btnSize: PropTypes.string,
    btnIcon: PropTypes.element,
    btnStyle: PropTypes.object,
    menuStyle: PropTypes.object,
};

PopupMenu.defaultProps = {
    iconHorizontal: false,
    btnSize: 'medium',
    btnIcon: null,
    btnStyle: {},
    data: null,
    menuStyle: {}
};

export default PopupMenu;

import axios from 'axios';

export const envConfig = async () => {

    const isProd = 'NODE_ENV' in process.env && process.env.NODE_ENV === 'production';
    console.info('DEBUG: envConfig -> isProd', isProd);

    return new Promise(async resolve => {
        if (isProd) {
            const result = await axios.get('/config');
            const { data } = result;

            // assign the variables to window.__env__
            window.__env__ = {
                BASE_URL: data.baseUrl,
                AUTH_URL: data.authUrl,
                GOOGLE_MAPS_API_KEY: data.googleMapsApiKey,
                MERLO_ENV: data.merloEnv,
                PROJECT_VERSION: data.projectVersion,
                BEAN_DOLLAR_START_DATE: data.beanDollarStartDate,
                BEAN_DRIVE_SSO: data.beanDriveSSO,
            };
            resolve({ isProd: true, merloEnv: data.merloEnv });
        } else {
            // this will never work - data is not defined at this point.
            window.__env__ = {
                // BASE_URL: data.baseUrl,
                // AUTH_URL: data.authUrl,
                // GOOGLE_MAPS_API_KEY: data.googleMapsApiKey,
                // MERLO_ENV: data.merloEnv,
                PROJECT_VERSION: process.env.REACT_APP_PROJECT_VERSION,
            };

            resolve({ isProd: false, merloEnv: process.env.REACT_APP_MERLO_ENV });
        }

    });
};

export default envConfig;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { selectOrdersLoading } from '@/state/orders/reducers';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';

const ShippingDetails = props => {
    const { shippingAddress, readOnly } = props;

    const [addressData, setAddressData] = React.useState({
        address1: '',
        address2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        name: '',
        company: '',
        phone: ''
    });

    const loadingState = useSelector(state => selectOrdersLoading(state));

    useEffect(() => {
        if (
            !loadingState &&
            shippingAddress !== null &&
            shippingAddress !== undefined
        ) {
            const {
                address1,
                address2,
                city,
                state,
                postCode,
                country,
                name,
                company,
                phone
            } = shippingAddress;

            setAddressData({
                address1,
                address2,
                city,
                state,
                postCode,
                country,
                name,
                company,
                phone
            });
        }
    }, [loadingState]);

    return (
        <Box title="Shipping details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Name"
                            value={addressData.name}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Company"
                            value={addressData.company}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Address 1"
                            value={addressData.address1}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Address 2"
                            value={addressData.address2}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="City"
                            value={addressData.city}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Postcode"
                            value={addressData.postCode}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="State"
                            value={addressData.state}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Country"
                            value={addressData.country}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Phone"
                            value={(addressData.phone === 'null' || addressData.phone === null) ? '' : addressData.phone}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ width: 'calc(100% + 40px)', margin: '20px -20px 20px -20px' }}/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Courier type"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Delivery instructions"
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ShippingDetails.propTypes = {
    shippingAddress: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        suburb: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string
    })
};

ShippingDetails.defaultProps = {
    shippingAddress: null
};

export default ShippingDetails;

/*eslint-disable*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Map, Marker } from 'google-maps-react';

import Box from '@/components/Box/Box';
import TextInput from '@/components/TextInput/TextInput';

import { selectLocationsLoading } from '@/state/locations/reducers';
import Loader from '@/components/Loader/Loader';

const styles = makeStyles(theme => ({
    mapContainer: {
        position: 'relative',
        width: '100%',
        height: 275,
        marginTop: 10
    }
}));

const LocationMapDetails = props => {
    const { google, location, onChange, errors, lat, lng } = props;
    const classes = styles();

    const [mapState, setMapState] = React.useState({
        zoomLevel: 18
    });
    const loadingState = useSelector(state => selectLocationsLoading(state));

    useEffect(() => {
        if (!loadingState) {
            const { isNew } = location;
            setMapState({ zoomLevel: isNew ? 4 : 18 });
        }
    }, [loadingState]);

    // useEffect(() => {
    //     if(loadingState) {
    //         return;
    //     }
    //     const geocoder = new google.maps.Geocoder();
    //     const {
    //         address: {
    //             address1,
    //             address2,
    //             suburb,
    //             city,
    //             postcode,
    //             state,
    //             country
    //         }
    //     } = location;
    //     const addressFormatted = `${address1} ${address2} ${suburb} ${city} ${postcode} ${state} ${country}`
    //     geocoder.geocode( { 'address' : addressFormatted }, function( results, status ) {
    //         if( status == google.maps.GeocoderStatus.OK ) {
    
    //             //In this case it creates a marker, but you can get the lat and lng from the location.LatLng
    //             console.log(results[0].geometry.location);
    //             setMapState({
    //                 lat: geometry.location.lat(),
    //                 lng: geometry.location.lng(),
    //                 zoomLevel: 18
    //             });
    //         } else {
    //             console.error( 'Geocode was not successful for the following reason: ' + status );
    //         }
    //     } );
    // }, [address]);

    const onLocationChange = type => event => {
        onChange(type, event.target.value);
    };

    const onMapClick = (mapProps, map, clickEvent) => {
        onChange('lat', clickEvent.latLng.lat());
        onChange('lng', clickEvent.latLng.lng());
    };

    return (
        <Box title="Map Details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Longitude"
                            value={lng}
                            onChange={onLocationChange('lng')}
                            error={Boolean(errors.lng)}
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Latitude"
                            value={lat}
                            onChange={onLocationChange('lat')}
                            error={Boolean(errors.lat)}
                            type='number'
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <div className={classes.mapContainer}>
                        <Map
                            google={google}
                            zoom={mapState.zoomLevel}
                            center={{
                                lat: lat,
                                lng: lng
                            }}
                            disableDefaultUI={!location.isNew}
                            style={{
                                display: 'flex',
                                height: 275
                            }}
                            onClick={onMapClick}
                        >
                            <Marker
                                title="Merlo Store Location"
                                name="Storename"
                                position={{
                                    lat: lat,
                                    lng: lng
                                }}
                            />
                        </Map>
                    </div>
                </Grid>
            </Loader>
        </Box>
    );
};

LocationMapDetails.propTypes = {
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    google: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

// export default GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
// })(LocationMapDetails);
export default LocationMapDetails;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextInput from '@/components/TextInput/TextInput';
import Button from '@/components/Button/Button';
// import Select from '@/components/SingleSelect/SingleSelect';
import Checkbox from '@/components/Checkbox/Checkbox';

import * as modalTypes from '@/state/modal/types';
import * as userTypes from '@/state/users/types';

import { selectUserLoading } from '@/state/users/reducers';
import SelectInput from '@/components/TextInput/SelectInput';

import countries from '@/constants/countries';

const styles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    button: {
        width: 100,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14,
    },
    title: {
        fontSize: 24,
        padding: '20px 0px'
    },
    checkBoxContainer: {
        display: 'flex',
    },
}));

const AddressDetails = props => {
    const { edit, title, address, addressIndex, actionType } = props;

    const dispatch = useDispatch();
    const classes = styles();

    const [addressState, setAddressState] = React.useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        companyName: '',
        country: 'Australia', // default
        postcode: '',
        state: '',
        suburb: '',
        title: '',
        isDefault: false,
        shopifyId: ''
    });

    useEffect(() => {
        if (edit) {
            setAddressState({
                firstName: address.firstName,
                lastName: address.lastName,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                companyName: address.companyName,
                country: address.country,
                postcode: address.postcode,
                state: address.state,
                suburb: address.suburb,
                title: address.title,
                isDefault: address.isDefault,
                shopifyId: address.shopifyId,
                phone: address.phone
            });
        }
    }, [edit]);

    const loadingState = useSelector(state => selectUserLoading(state));

    const onChange = type => event => {
        if ( (type === 'state' && addressState.country === 'Australia') || type === 'country') {
            setAddressState({ ...addressState, [type]: event });
        } else {
            setAddressState({ ...addressState, [type]: event.target.value });
        }

        // clear state on country change
        if(type === 'country' && event !== 'Australia') {
            setAddressState((prevAddressState) => ({ ...prevAddressState, state: '' }))
        }
    }

    const onCheckBoxChange = type => event => {
        setAddressState({ ...addressState, [type]: event.target.checked });
    }

    const onCancel = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    }

    const onSave = () => {
        dispatch({ type: actionType, data: { address: addressState, addressIndex } });
        onCancel();
    }

    const isDisabled = () => {
        const { isDefault, suburb, companyName, title, address2, shopifyId, ...rest } = addressState;
        return Object.values(rest).some(x => x.trim().length === 0);
    }

    const states = [
        { label: 'New South Wales', value: 'New South Wales' },
        { label: 'Queensland', value: 'Queensland' },
        { label: 'South Australia', value: 'South Australia' },
        { label: 'Tasmania', value: 'Tasmania' },
        { label: 'Victoria', value: 'Victoria' },
        { label: 'Western Australia', value: 'Western Australia' },
        { label: 'Australian Capital Territory', value: 'Australian Capital Territory' },
    ]



    const disabled = isDisabled() || loadingState;

    return (
        <React.Fragment>
            <div className={classes.title}>{title}</div>
            <div className={classes.checkBoxContainer}>
                {
                    (!address.isDefault) &&
                    <Checkbox label='Set as default' onChange={onCheckBoxChange('isDefault')} checked={addressState.isDefault} />
                }
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput label='Title' onChange={onChange('title')} value={addressState.title} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label='Company Name' onChange={onChange('companyName')} value={addressState.companyName} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label='First name *' onChange={onChange('firstName')} value={addressState.firstName} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label='Last Name *' onChange={onChange('lastName')} value={addressState.lastName} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput label='Address 1 *' onChange={onChange('address1')} value={addressState.address1} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput label='Address 2' onChange={onChange('address2')} value={addressState.address2} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label='City *' onChange={onChange('city')} value={addressState.city} />
                </Grid>
                <Grid item xs={6}>
                    <TextInput label='Postcode *' onChange={onChange('postcode')} value={addressState.postcode} />
                </Grid>
                { addressState.country !== 'Australia' ?
                    <Grid item xs={6}>
                        <TextInput label='Region *' onChange={onChange('state')} value={addressState.state} />
                    </Grid>
                    :
                    <Grid item xs={6}>
                        <SelectInput label='State *' onChange={onChange('state')} value={addressState.state} options={states} />
                    </Grid>
                }
                <Grid item xs={6}>
                    <SelectInput label='Country *' onChange={onChange('country')} value={addressState.country} options={countries} />
                </Grid>
                <Grid item xs={6} >
                    <TextInput label='Phone *' onChange={onChange('phone')} value={addressState.phone} />
                </Grid>
                <Grid item xs={6} />
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant='outlined' label='Cancel' onClick={onCancel} disabled={loadingState} />
                    <Button className={classes.button} label='Save' onClick={onSave} loading={loadingState} disabled={disabled} />
                </div>
            </Grid>
        </React.Fragment>
    );
};

AddressDetails.propTypes = {
    edit: PropTypes.bool,
    title: PropTypes.string,
    address: PropTypes.object,
    addressIndex: PropTypes.number,
    actionType: PropTypes.string,
};

AddressDetails.defaultProps = {
    edit: false,
    title: '',
    address: {},
    addressIndex: undefined,
    actionType: ''
}

export default AddressDetails;
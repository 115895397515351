export const GET_OFFERS = 'promotion/GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'promotion/GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = 'promotion/GET_OFFERS_ERROR';

export const SELECT_OFFER = 'promotion/SELECT_OFFER';
export const SELECT_OFFER_SUCCESS = 'promotion/SELECT_OFFER_SUCCESS';
export const SELECT_OFFER_ERROR = 'promotion/SELECT_OFFER_ERROR';

export const CREATE_OFFER = 'promotion/CREATE_OFFER';
export const CREATE_OFFER_SUCCESS = 'promotion/CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_ERROR = 'promotion/CREATE_OFFER_ERROR';

export const UPDATE_OFFER = 'promotion/UPDATE_OFFER';
export const UPDATE_OFFER_SUCCESS = 'promotion/UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = 'promotion/UPDATE_OFFER_ERROR';

export const DELETE_OFFER = 'promotion/DELETE_OFFER';
export const DELETE_OFFER_SUCCESS = 'promotion/DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_ERROR = 'promotion/DELETE_OFFER_ERROR';

export const GET_USERS_VOUCHERS = 'promotion/GET_USERS_VOUCHERS';
export const GET_USERS_VOUCHERS_SUCCESS = 'promotion/GET_USERS_VOUCHERS_SUCCESS';
export const GET_USERS_VOUCHERS_ERROR = 'promotion/GET_USERS_VOUCHERS_ERROR';

export const GET_REDEMPTIONS = 'promotion/GET_REDEMPTIONS';
export const GET_REDEMPTIONS_SUCCESS = 'promotion/GET_REDEMPTIONS_SUCCESS';
export const GET_REDEMPTIONS_ERROR = 'promotion/GET_REDEMPTIONS_ERROR';

export const GET_VOUCHERS = 'promotion/GET_VOUCHERS';
export const GET_VOUCHERS_SUCCESS = 'promotion/GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_ERROR = 'promotion/GET_VOUCHERS_ERROR';

export const SELECT_VOUCHER = 'promotion/SELECT_VOUCHER';
export const SELECT_VOUCHER_SUCCESS = 'promotion/SELECT_VOUCHER_SUCCESS';
export const SELECT_VOUCHER_ERROR = 'promotion/SELECT_VOUCHER_ERROR';

export const CREATE_VOUCHER = 'promotion/CREATE_VOUCHER';
export const CREATE_VOUCHER_SUCCESS = 'promotion/CREATE_VOUCHER_SUCCESS';
export const CREATE_VOUCHER_ERROR = 'promotion/CREATE_VOUCHER_ERROR';

export const UPDATE_VOUCHER = 'promotion/UPDATE_VOUCHER';
export const UPDATE_VOUCHER_SUCCESS = 'promotion/UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_ERROR = 'promotion/UPDATE_VOUCHER_ERROR';
export const UPDATE_VOUCHER_SUCCESS_TARGET_UUID = 'promotion/UPDATE_VOUCHER_SUCCESS_TARGET_UUID';

export const CREATE_VOUCHER_CODES = 'promotions/CREATE_VOUCHER_CODES';
export const CREATE_VOUCHER_CODES_SUCCESS = 'promotions/CREATE_VOUCHER_CODES_SUCCESS';
export const CREATE_VOUCHER_CODES_ERROR = 'promotions/CREATE_VOUCHER_CODES_ERROR';

export const CREATE_SEGMENT_VOUCHER_CODES = 'promotions/CREATE_SEGMENT_VOUCHER_CODES';
export const CREATE_SEGMENT_VOUCHER_CODES_SUCCESS = 'promotions/CREATE_SEGMENT_VOUCHER_CODES_SUCCESS';
export const CREATE_SEGMENT_VOUCHER_CODES_ERROR = 'promotions/CREATE_SEGMENT_VOUCHER_CODES_ERROR';

// This is done internally via SELECT_VOUCHER
// export const GET_VOUCHER_CODES = 'promotions/GET_VOUCHER_CODES'
// export const GET_VOUCHER_CODES_SUCCESS = 'promotions/GET_VOUCHER_CODES_SUCCESS'
// export const GET_VOUCHER_CODES_ERROR = 'promotions/GET_VOUCHER_CODES_ERROR'

export const UPDATE_VOUCHER_CODE = 'promotions/UPDATE_VOUCHER_CODE'
export const ASSIGN_VOUCHER_CODE = 'promotions/ASSIGN_VOUCHER_CODE'
export const UPDATE_VOUCHER_CODE_SUCCESS = 'promotions/UPDATE_VOUCHER_CODE_SUCCESS'
export const UPDATE_VOUCHER_CODE_ERROR = 'promotions/UPDATE_VOUCHER_CODE_ERROR'

export const PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION = 'promotions/PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION';
export const PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION_ERROR = 'promotions/PROMOTION_VOUCHER_CODE_SEND_NOTIFICATION_ERROR';

export const GET_AUTOMATIONS = 'promotions/GET_AUTOMATIONS';
export const GET_AUTOMATIONS_SUCCESS = 'promotions/GET_AUTOMATIONS_SUCCESS';
export const GET_AUTOMATIONS_ERROR = 'promotions/GET_AUTOMATIONS_ERROR';

export const SELECT_AUTOMATION = 'promotions/SELECT_AUTOMATION';
export const SELECT_AUTOMATION_SUCCESS = 'promotions/SELECT_AUTOMATION_SUCCESS';
export const SELECT_AUTOMATION_ERROR = 'promotions/SELECT_AUTOMATION_ERROR';

export const CREATE_AUTOMATION = 'promotions/CREATE_AUTOMATION';
export const CREATE_AUTOMATION_SUCCESS = 'promotions/CREATE_AUTOMATION_SUCCESS';
export const CREATE_AUTOMATION_ERROR = 'promotions/CREATE_AUTOMATION_ERROR';

export const UPDATE_AUTOMATION = 'promotions/UPDATE_AUTOMATION';
export const UPDATE_AUTOMATION_SUCCESS = 'promotions/UPDATE_AUTOMATION_SUCCESS';
export const UPDATE_AUTOMATION_ERROR = 'promotions/UPDATE_AUTOMATION_ERROR';

export const DELETE_AUTOMATION = 'promotions/DELETE_AUTOMATION';
export const DELETE_AUTOMATION_SUCCESS = 'promotions/DELETE_AUTOMATION_SUCCESS';
export const DELETE_AUTOMATION_ERROR = 'promotions/DELETE_AUTOMATION_ERROR';

export const OFFERS_LOADING_STATE = 'promotion/OFFERS_LOADING_STATE';
export const VOUCHERS_LOADING_STATE = 'promotion/VOUCHERS_LOADING_STATE';
export const AUTOMATIONS_LOADING_STATE = 'promotion/AUTOMATIONS_LOADING_STATE';
export const REDEMPTIONS_LOADING_STATE = 'promotion/REDEMPTIONS_LOADING_STATE';

export const OFFERS_SAVE_STATE = 'promotion/OFFERS_SAVE_STATE';
export const VOUCHERS_SAVE_STATE = 'promotion/VOUCHERS_SAVE_STATE';
export const AUTOMATIONS_SAVE_STATE = 'promotion/AUTOMATIONS_SAVE_STATE';

export const DELETE_VOUCHER_CODE = 'promotion/DELETE_VOUCHER_CODE';
export const DELETE_VOUCHER_CODE_SUCCESS = 'promotions/DELETE_VOUCHER_CODE_SUCCESS';
export const DELETE_VOUCHER_CODE_ERROR = 'promotions/DELETE_VOUCHER_CODE_ERROR';


// shopify promotions
export const GET_SHOPIFY_PROMOTIONS = 'promotion/GET_SHOPIFY_PROMOTIONS';
export const GET_SHOPIFY_PROMOTIONS_SUCCESS = 'promotion/GET_SHOPIFY_PROMOTIONS_SUCCESS';
export const GET_SHOPIFY_PROMOTIONS_ERROR = 'promotion/GET_SHOPIFY_PROMOTIONS_ERROR';

export const CREATE_SHOPIFY_PROMOTION = 'promotion/CREATE_SHOPIFY_PROMOTION';
export const CREATE_SHOPIFY_PROMOTION_SUCCESS = 'promotion/CREATE_SHOPIFY_PROMOTION_SUCCESS';
export const CREATE_SHOPIFY_PROMOTION_ERROR = 'promotion/CREATE_SHOPIFY_PROMOTION_ERROR';

export const UPDATE_SHOPIFY_PROMOTION = 'promotion/UPDATE_SHOPIFY_PROMOTION';
export const UPDATE_SHOPIFY_PROMOTION_SUCCESS = 'promotion/UPDATE_SHOPIFY_PROMOTION_SUCCESS';
export const UPDATE_SHOPIFY_PROMOTION_ERROR = 'promotion/UPDATE_SHOPIFY_PROMOTION_ERROR';

export const DELETE_SHOPIFY_PROMOTION = 'promotion/DELETE_SHOPIFY_PROMOTION';
export const DELETE_SHOPIFY_PROMOTION_SUCCESS = 'promotion/DELETE_SHOPIFY_PROMOTION_SUCCESS';
export const DELETE_SHOPIFY_PROMOTION_ERROR = 'promotion/DELETE_SHOPIFY_PROMOTION_ERROR';

export const SET_SELECTED_SHOPIFY_PROMOTION = 'promotion/SET_SELECTED_SHOPIFY_PROMOTION';
export const SHOPIFY_PROMOTION_SAVE_STATE = 'promotion/SHOPIFY_PROMOTION_SAVE_STATE';

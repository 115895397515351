import axios from 'axios';

export const getUserLoyalty = async request => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/loyalty/points/${userUuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const refreshLoyalty = async request => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/loyalty/points/${userUuid}?refresh=true`);
        console.log('TCL: result.data', result.data)
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getUserFom = async request => {
    const { userUuid } = request;
    try {
        const result = await axios.get(`/v1/loyalty/user/${userUuid}/fom`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getOrderLoyalty = async request => {
    const { orderId } = request;
    try {
        const result = await axios.get(`/v1/loyalty/order/${orderId}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const resendGiftcard = async payload => {
    const { data } = payload;
    try {
        const result = await axios.post('/v1/loyalty/resend', { ...data });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getGivexConnectionDetails = async () => {
    try {
        const result = await axios.get('/v1/loyalty/connection-details');
        return result.data;
    } catch (error) {
        throw error;
    }
};
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import PopupMenu from '@/components/PopupMenu/PopupMenu';
import * as modalTypes from '@/state/modal/types';
import * as snackTypes from '@/state/snack/types';
import * as modals from '@/constants/modals';

import * as userTypes from '@/state/users/types';

const styles = makeStyles({
    flexContainer: {
        display: 'flex'
    },
    addressTitle: {
        fontSize: 14,
        fontWeight: 500,
        flexGrow: 1
    },
    addressContainer: {
        backgroundColor: '#f2f2f2',
        minHeight: 139,
        padding: 20
    },
    addressStatusContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    chip: {
        minWidth: 100,
        width: 100,
        backgroundColor: '#000',
        color: '#fff',
        fontSize: 11,
        height: 30
    }
});


const AddressBox = props => {
    const classes = styles();
    const dispatch = useDispatch();
    const {
        address,
        addressIndex,
        address: {
            title,
            address1,
            address2,
            suburb,
            city,
            state,
            country,
            postcode,
            companyName,
            isDefault,
            phone,
            firstName,
            lastName
        },
        updateAddress,
        deleteAddress,
        readOnly
    } = props;

    let addressDefault = null;
    if (isDefault) addressDefault = 'Default';

    const onEditAddress = () => {
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: modals.ADDRESS_DETAILS_MODAL, 
            props: { 
                edit: true, 
                title: 'Edit Address', 
                address, 
                addressIndex, 
                actionType: userTypes.UPDATE_USER_ADDRESS 
            } 
        });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    }

    const onSetDefault = () => {
        updateAddress(addressIndex, { isDefault: true });
    }

    // manage via actions
    const onDelete = () => {
        if(!isDefault) {
            deleteAddress(addressIndex);
        } else {
            dispatch({
                type: snackTypes.SET_SNACK,
                content: 'The default address cannot be deleted',
                open: true,
                props: { variant: 'error'}
            });
        }
    }


    const menuActions = [
        { label: 'Edit', func: onEditAddress },
        { label: 'Set as default', func: onSetDefault },
        { label: 'Delete', func: onDelete },
    ];


    return (
        <React.Fragment>
            <div className={classes.flexContainer}>
                <Typography className={classes.addressTitle}>
                    {title}
                </Typography>

                {
                    !readOnly &&
                    <PopupMenu
                        iconHorizontal
                        data={address}
                        btnSize="small"
                        menuActions={menuActions}
                    />
                }
            </div>
            <div className={classes.addressContainer}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography>{`${firstName} ${lastName}`}</Typography>
                        <Typography>{companyName}</Typography>
                        <Typography>{address1}</Typography>
                        <Typography>{address2}</Typography>
                        <Typography>{suburb}</Typography>
                        <Typography>{`${city}${city ? ',' : ''}`}</Typography>
                        <Typography>{`${state}${state ? ',' : ''} ${country} ${postcode}`}</Typography>
                        <Typography>{phone}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.addressStatusContainer}
                    >
                        {
                            addressDefault &&
                            <Chip
                                className={classes.chip}
                                label={addressDefault}
                            />
                        }
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

AddressBox.propTypes = {
    address: PropTypes.object,
    addressIndex: PropTypes.number.isRequired,
    updateAddress: PropTypes.func.isRequired,
    deleteAddress: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

AddressBox.defaultProps = {
    address: {}
};

export default AddressBox;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles, mergeClasses } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button/Button';
import TextInput from '@/components/TextInput/TextInput';

import * as modals from '@/constants/modals';
import * as modalTypes from '@/state/modal/types';

const styles = makeStyles({
    title: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 1.2,
        marginTop: 14,
        marginBottom:14,
    },
    buttonsContainer: {
        textAlign: 'center',
        marginTop: 40,
    },
    actionButton: {
        width: '140px',
        margin: '12px',
    }
});


const PromptBoolean = props => {

    const { title, yesLabel, noLabel, onYes, onNo, closeModal } = props;
    const classes = styles();

    const defaultOnNo = onNo != null ? onNo : () => closeModal();

    return (
        <div>
            <Typography className={classes.title}>{title}</Typography>
            <div className={classes.buttonsContainer}>
                <Button className={classes.actionButton} 
                    label={noLabel} 
                    variant={null} style={{color:'#077abe'}}
                    onClick={defaultOnNo}
                />
                <Button className={classes.actionButton} 
                    label={yesLabel} 
                    onClick={onYes}
                />
            </div>
        </div>
    )
}

PromptBoolean.propTypes = {
    closeModal: PropTypes.func.isRequired,

    // Customisable fields
    title: PropTypes.string.isRequired,
    yesLabel: PropTypes.string,
    onYes: PropTypes.func.isRequired,
    noLabel: PropTypes.string,
    onNo: PropTypes.func,
}

PromptBoolean.defaultProps = {
    yesLabel: 'Yes',
    noLabel: 'No',
    onNo: null,
}


const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => bindActionCreators({
    closeModal: () => ({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PromptBoolean);
import * as types from './types';
import * as actions from './actions';

// state definition
const initialState = {
    locations: [],
    selectedLocation: {},
    merloLocations: [],
    meta: {},
    loading: true,
    saveState: false
};

const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LOCATIONS_SUCCESS:
        case types.GET_MERLO_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.locations,
                meta: action.meta
            };

        case types.GET_LOCATION_SUCCESS:
            return {
                ...state,
                selectedLocation: action.location
            };
        
        case types.CREATE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: actions.upsertLocation(state.locations, action.location)
            }

        case types.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: actions.upsertLocation(state.locations, action.location)
            }

        case types.LOCATIONS_LOADING_STATE:
            return {
                ...state,
                loading: action.state
            };

        case types.LOCATION_SAVE_STATE:
            return {
                ...state,
                saveState: action.state
            }
        
        case types.CREATE_LOCATION_SUCCESS_TARGET_UUID:
            return {
                ...state,
                selectedLocation: action.location
            }

        default:
            return state;
    }
};

export default locationsReducer;

export const selectLocations = state => state.locations.locations;
export const selectLocation = state => state.locations.selectedLocation;
export const selectLocationsLoading = state => state.locations.loading;
export const selectLocationSaveState = state => state.locations.saveState;

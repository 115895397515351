export const deepValue = (obj, path) =>
    path.replace(/\[|\]\.?/g, '.')
        .split('.')
        .filter(s => s)
        .reduce((acc, cur) => acc && acc[cur], obj);


// Sorting helper functions


export const stableSort = (array, compare) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = compare(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);;
};

export const desc = (a, b, orderBy) => {
    if(typeof deepValue(b, orderBy) === 'string' && typeof deepValue(a, orderBy) === 'string') {
        return deepValue(b, orderBy).localeCompare(deepValue(a, orderBy));
    }
    return deepValue(b, orderBy) < deepValue(a, orderBy) ? -1 : 1;
};

export const getSorting = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
};


// account for the fact that some rows have custom render data for sorting - extact the sort method for the key
export const headerAwareGetSorting = (order, orderBy, headerData) => {
    // check check for compute key 
    const hd = headerData.find(h => h.key === orderBy);
    if ('sortData' in hd) {
        return (a, b) => hd.sortData(a, b, order, hd.compute)
    }

    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);

}
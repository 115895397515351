import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Button from '@/components/Button/Button';

const styles = makeStyles({
    marginTop: {
        marginTop: 40
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 230,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2
    },
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        fontWeight: 400
    },
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    button: {
        width: 160,
        marginTop: 15
    },
    grey: {
        backgroundColor: '#d3d3d3'
    }
});

const Help = () => {
    const classes = styles();
    return (
        <Grid container spacing={4} className={classes.marginTop}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography align="center" className={classes.title}>
                        FAQ / Help Manual
                    </Typography>
                    <Link
                        className={classes.link}
                        target="_blank"
                        rel="noopener"
                        href="https://docs.google.com/document/d/1tt5un-mrFXMabs6bbqJKp-sPyNIfnjcz_HqPgu8H8AQ"
                    >
                        <Button className={classes.button} label="View" />
                    </Link>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <Typography align="center" className={classes.title}>
                        Digital Style Guide (Figma)
                    </Typography>
                    <Link
                        className={classes.link}
                        target="_blank"
                        rel="noopener"
                        href="https://www.figma.com/file/1nCV93P8LkqxjsbV8N273g/Style-Guide-MER"
                    >
                        <Button className={classes.button} label="View" />
                    </Link>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Help;

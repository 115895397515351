import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import Box from '@/components/Box/Box';
import SubHeader from '@/components/SubHeader/SubHeader';
import OfferTileDetailsForm from '@/components/Offers/OfferTileDetailsForm';
import OfferTileScheduleForm from '@/components/Offers/OfferTileScheduleForm';
import Button from '@/components/Button/Button';
import OfferImageUpload from '@/components/Offers/OfferImageUpload';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import Loader from '@/components/Loader/Loader';

import {
    selectOffersLoading,
    selectOffersSaveState
} from '@/state/promotions/reducers';
import * as types from '@/state/promotions/types';

const styles = () => ({
    marginTop: {
        marginTop: 60
    },
    marginBottom: {
        marginBottom: 60
    },
    title: {
        fontSize: 10,
        color: '#a7a7a7',
        letterSpacing: 0.16,
        textTransform: 'uppercase'
    },
    text1: {
        fontSize: 16,
        lettingSpacing: 0.26
    },
    cta: {
        height: 50,
        width: '100%',
        fontSize: 18,
        letterSpacing: 0.11
    },
    flexContainer: {
        display: 'flex'
    },
    imageUploadContainer: {
        width: 300,
        maxWidth: 300,
        minWidth: 300
    },
    detailContainer: {
        width: '100%',
        marginLeft: 20,
    },
    exampleContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    example: {
        minWidth: 336,
        maxWidth: 600
    },
    exampleContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 10,
        '& p': {
            maxWidth: 220,
            wordBreak: 'break-word'
        }
    }
});

class OfferTileDetails extends React.Component {
    constructor(props) {
        super();
        const uuid = this.getUuidFromRoute(props);
        props.selectOfferTile(uuid);
    }

    state = {
        image: {
            src: '',
            isNew: false
        },
        errors: {

        }
    };

    getUuidFromRoute = props => {
        const {
            match: { params }
        } = props;
        const { uuid } = params;
        return uuid;
    };

    onChange = data => {
        this.setState(prevState => ({
            ...prevState,
            ...data
        }), () => {
            // validate CTA
            if ('linkUrl' in data) {
                this.validateCTA(data.linkUrl)
            }
        });

    };

    validateCTA = cta => {
        const regex = /^(https:\/\/)[^\s(["<,>]*\.[^\s[",><]*/ig;
        const regTest = new RegExp(regex);
        const test = regTest.test(cta);

        const { errors } = this.state;
        if (!test) {
            // set an error state for this field
            this.setState(prevState => ({
                ...prevState,
                errors: {
                    imageUrl: 'Image must start with https:// and be a valid url'
                }
            }));
        } else if (test && 'imageUrl' in errors) {
            this.setState(prevState => ({
                ...prevState,
                errors: {}
            }));
        }
    }

    onImageChange = image => {
        this.setState({
            image
        });
    };

    onSave = () => {
        const { updateOfferTile, selectedOfferTile } = this.props;
        const { errors, ...rest } = this.state;
        updateOfferTile({
            ...selectedOfferTile,
            ...rest
        });
    };

    render() {
        const {
            selectedOfferTile,
            offersLoadingState,
            offersSaveState,
            classes,
            readOnly
        } = this.props;

        const { errors } = this.state;

        const hasErrors = Object.keys(errors).length > 0;

        return (
            <React.Fragment>
                <SubHeader
                    onSave={() => {
                        this.onSave();
                    }}
                    lastUpdated={
                        selectedOfferTile.updatedAt || moment().toISOString()
                    }
                    saving={offersSaveState}
                    disabled={offersSaveState || hasErrors}
                    readOnly={readOnly}
                />
                <div className={classes.marginTop} />
                <div className={classes.flexContainer}>
                    <div className={classes.imageUploadContainer}>
                        <OfferImageUpload
                            loading={offersLoadingState}
                            offer={selectedOfferTile}
                            onChange={this.onImageChange}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className={classes.detailContainer}>
                        <Box rootClass={classes.root} title="Offer tile details">
                            <OfferTileDetailsForm
                                onChange={this.onChange}
                                offerTile={selectedOfferTile}
                                loading={offersLoadingState}
                                errors={errors}
                                readOnly={readOnly}
                            />
                        </Box>
                        <Box rootClass={classes.root} title="Schedule">
                            <OfferTileScheduleForm
                                onChange={this.onChange}
                                offerTile={selectedOfferTile}
                                loading={offersLoadingState}
                                readOnly={readOnly}
                            />
                        </Box>
                    </div>
                    
                </div>
                
                <div className={classes.marginBottom} />
            </React.Fragment>
        );
    }
}
OfferTileDetails.propTypes = {
    selectOfferTile: PropTypes.func.isRequired,
    selectedOfferTile: PropTypes.object.isRequired,
    offersLoadingState: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    updateOfferTile: PropTypes.func.isRequired,
    offersSaveState: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    selectedOfferTile: state.promotions.selectedOffer,
    offersSaveState: selectOffersSaveState(state),
    offersLoadingState: selectOffersLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            selectOfferTile: uuid => ({ type: types.SELECT_OFFER, uuid }),
            updateOfferTile: offer => ({ type: types.UPDATE_OFFER, offer })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(OfferTileDetails))
);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '170px 0 10px',
        maxWidth: '80%',
        flexBasis: '80%'
    },
});

const PageContainer = props => {
    const { children, classes } = props;

    return (
        <div className={classes.main}>
            {children}
        </div>
    );
};

PageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageContainer);
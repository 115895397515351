import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';
import theme from './constants/theme';
import store from './state/store';
import App from './App';
import history from './utils/history';

import './assets/styles/main.scss';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router history={history}>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </ThemeProvider>,
    document.getElementById('app')
);

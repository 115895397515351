import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import { selectOrdersLoading } from '@/state/orders/reducers';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';
import Button from '@/components/Button/Button';

const styles = makeStyles({
    buttonContainer: {
        marginTop: 40,
        paddingBottom: 20
    }
});

const CustomerDetails = props => {
    const { customer, onViewUser, readOnly } = props;
    const classes = styles();

    const [userData, setUserData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: '',
        uuid: null
    });

    const loadingState = useSelector(state => selectOrdersLoading(state));

    useEffect(() => {
        if (!loadingState) {
            const {
                firstName,
                lastName,
                email,
                phone,
                type,
                userUuid
            } = customer;

            setUserData({
                firstName,
                lastName,
                email,
                phone,
                type,
                uuid: userUuid
            });
        }
    }, [loadingState]);

    return (
        <Box title="Guest / Customer details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="First name"
                            value={userData.firstName}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Last name"
                            value={userData.lastName}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Email address"
                            value={userData.email}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Phone number"
                            value={(userData.phone === 'null' || userData.phone === null) ? '' : userData.phone}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="User type"
                            value={userData.type}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="center"
                    className={classes.buttonContainer}
                >
                    <Button
                        disabled={userData.type !== 'Registered'}
                        label="View User"
                        onClick={() => onViewUser(userData.uuid)}
                        style={{ padding: '10px 50px' }}
                    />
                </Grid>
            </Loader>
        </Box>
    );
};

CustomerDetails.propTypes = {
    customer: PropTypes.object,
    onViewUser: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

CustomerDetails.defaultProps = {
    customer: {}
};

export default CustomerDetails;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/WarningRounded';

import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import NotificationForm from '../../components/MobileApp/NotificationForm';

import { getSegmentUsers } from '@/state/segments/actions';

import { selectModalState } from '@/state/modal/reducers';

import * as modalTypes from '@/state/modal/types';

import {
    selectSegmentsLoading
} from '@/state/segments/reducers';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    checkboxContainer: {
        textAlign: 'center'
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '125px',
        margin: '12px'
    },
    warningContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    warningIcon: {
        color: '#eeb111',
        stroke: '#3325006e'
    },
    warningText: {
        color: '#f50057',
        padding: '0px 10px'
    }
});

const NotificationConfirm = props => {
    const { notification, onConfirmNotification } = props;

    const dispatch = useDispatch();
    const classes = styles();

    const [confirmed, setConfirmed] = React.useState(false);
    const [segmentCount, setSegmentCount] = React.useState(0);
    const [hasFetched, setHasFetched] = React.useState(false);

    const onClickConfirm = () => {
        setConfirmed(prevState => !prevState);
    };

    const onClose = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    };

    // Check modal state to stop react attempting to update state on unmounted component
    const isOpen = useSelector(state => selectModalState(state));
    const loading = useSelector(state => selectSegmentsLoading(state));
    console.log('TCL: loading', loading)
    
    useEffect(() => {
        if (isOpen) {
            // request the segment results and store the count
            (async () => {
                const segmentUsers = await getSegmentUsers({
                    segmentUuid: notification.segmentUuid
                });

                const { result } = segmentUsers;

                setSegmentCount(result.length);
                setHasFetched(true);
            })();
        } else setHasFetched(false);
    }, [isOpen]);

    return (
        <React.Fragment>
            <Typography className={classes.title}>
                Notification confirmation
            </Typography>
            <NotificationForm
                internalDescription={notification.internalDescription}
                segmentUuid={notification.segmentUuid}
                title={notification.title}
                body={notification.body}
            />
            <div className={classes.warningContainer}>
                <Warning className={classes.warningIcon} />
                {hasFetched ? (
                    <div className={classes.warningText}>
                        Warning - this will send a notification to up to{' '}
                        {segmentCount} people{' '}
                    </div>
                ) : (
                    <div className={classes.warningText}>
                      Fetching segment users...
                    </div>
                )}

                <Warning className={classes.warningIcon} />
            </div>
            <div className={classes.checkboxContainer}>
                <Checkbox
                    label="Confirm"
                    value="confirm"
                    checked={confirmed}
                    onChange={onClickConfirm}
                />
            </div>

            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant="outlined"
                    style={{ color: '#077abe' }}
                    onClick={onClose}
                />
                <Button
                    className={classes.actionButton}
                    label="Send"
                    loading={loading}
                    disabled={!confirmed || !hasFetched}
                    onClick={onConfirmNotification}
                />
            </div>
        </React.Fragment>
    );
};

NotificationConfirm.propTypes = {
    onConfirmNotification: PropTypes.func,
    notification: PropTypes.object,
};

NotificationConfirm.defaultProps = {
    onConfirmNotification: () => {},
    notification: {},
};

export default NotificationConfirm;

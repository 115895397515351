import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const styles = makeStyles(theme => ({
    root: {
        margin: `${theme.spacing(1)}px 0px`
    },
    charCountHelper: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.label.primary
    },
    labelRoot: {
        fontSize: 14,
        lineHeight: '1.3',
        color: theme.palette.label.primary
    },
    inputBorder: {
        '& fieldset': {
            borderColor: theme.palette.label.primary
        }
    },
    inputRoot: {
        fontSize: 14,
        padding: '12px 10px',
        '& fieldset': {
            borderColor: theme.palette.label.primary
        }
    },
    inputEl: {
        padding: 0
    },
    inputError: {
        color: 'red'
    },
    inputDisabled: {
        color: `${theme.palette.label.disabled} !important`,
        '& fieldset': {
            borderColor: `${theme.palette.label.disabled} !important`
        }
    },
    inputFocused: {
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    valid: {
        color: '#000'
    }
}));

const SelectInput = props => {
    const { label, options, value, onChange, disabled, placeholder, readOnly } = props;

    const classes = styles();
    const computedHelperClass = value
        ? `${classes.valid} ${classes.charCountHelper}`
        : classes.charCountHelper;

    const onChangeLocal = event => {
        onChange(event.target.value);
    };

    const arrayOfObjects = typeof options[0] === 'object' && options[0].constructor === Object;

    return (
        <TextField
            select={!readOnly}
            fullWidth
            value={value}
            onChange={onChangeLocal}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            classes={{
                root: classes.root
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot,
                    disabled: classes.inputDisabled
                }
            }}
            InputProps={{
                readOnly: disabled,
                classes: {
                    root: `${classes.inputRoot}`,
                    input: classes.inputEl,
                    error: classes.inputError,
                    disabled: classes.inputDisabled,
                    focused: classes.inputFocused
                }
            }}
            FormHelperTextProps={{
                classes: {
                    root: computedHelperClass
                }
            }}
        >
            {
                options.map((option, key) => {
                    return arrayOfObjects
                        ? <MenuItem key={`option-${key}`} value={option.value}>{option.label}</MenuItem>
                        : <MenuItem key={`option-${key}`} value={option}>{option}</MenuItem>
                })
            }
        </TextField>
    );
};

SelectInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object)
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
};

SelectInput.defaultProps = {
    label: '',
    disabled: false,
    placeholder: '',
    value: '',
    options: [''],
    readOnly: false,
};

export default SelectInput;

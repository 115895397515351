import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FileSaver from 'file-saver';
import Button from '@/components/Button/Button';

import { getSegmentCsv } from '@/state/segments/actions';

import * as modalTypes from '@/state/modal/types';

import { jsonToCsv, download, slugify } from '@/utils/helpers';

const styles = makeStyles(theme => ({
    selectContainer: {
        maxHeight: 295,
        overflowY: 'auto',
        borderRadius: 2,
        border: 'solid 0.5px #d5d5d5',
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f7f7f7 !important'
        },
        '&::-webkit-scrollbar': {
            width: 3
        },
        '&::-webkit-scrollbar-thumb': {
            height: 174,
            borderRadius: 15,
            backgroundColor: '#a5a5a5',
            outline: '1px solid slategrey'
        }
    },
    listRoot: {
        backgroundColor: '#f7f7f7'
    },
    iconRoot: {
        minWidth: 0
    },
    listItemText: {
        fontSize: 14
    },
    checkboxRoot: {
        '& svg': {
            height: 16,
            width: 16
        }
    },
    checkboxChecked: {
        color: `${theme.palette.primary.light}`
    },
    checkboxDisabled: {
        color: 'grey'
    },
    subheader: {
        padding: '10px'
    },
    listItemRoot: {
        paddingTop: 0,
        paddingBottom: 0
    },
    subtitle: {
        paddingLeft: 14,
        paddingRight: 14,
        marginTop: 5,
        fontWeight: 500
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: '20px 20px 0px 20px'
    },
    button: {
        width: 150,
        height: 40,
        padding: '5px 10px',
        margin: '0px 10px',
        fontSize: 14
    }
}));

const options = [
    {
        title: 'Mandatory',
        checkable: false,
        items: [
            {
                label: 'Segment Name',
                value: 'segmentName'
            },
            {
                label: 'Segment Run Timestamp',
                value: 'segmentTimestamp'
            },
            {
                label: 'User ID',
                value: 'userUuid'
            },
            {
                label: 'Email',
                value: 'email'
            },
            {
                label: 'Firstname',
                value: 'firstname'
            },
            {
                label: 'Lastname',
                value: 'lastname'
            },
            {
                label: 'Email Opt-in',
                value: 'emailOptIn'
            },
        ]
    },
    {
        title: 'Optional',
        checkable: true,
        items: [
            {
                label: 'Friends of Merlo Number',
                value: 'fom'
            },
            {
                label: 'Current Points',
                value: 'fomPoints'
            },
            {
                label: 'Current Credit',
                value: 'fomCredit'
            },
            {
                label: 'Birthday (\'MMM DD\' format e.g. "September 4")',
                value: 'birthday'
            },
            {
                label: 'Mobile Number (+ SMS Opt-In)',
                value: 'mobileNumberAndSMSOptIn'
            },
            {
                label: 'Shipping Postcode',
                value: 'shippingPostcode'
            },
            {
                label: 'Full Shipping Address',
                value: 'formattedDefaultAddress'
            }
        ]
    }
];

const SegmentExportCSV = ({ segmentUuid, segmentName }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const [values, setValues] = useState([]);

    // Local loading state until the segment service gets updated
    const [loading, setLoading] = useState(false);

    // Temporary local error state
    const [error, setError] = useState(false);

    const handleToggle = value => {
        const exists = values.some(val => val === value);
        if (exists) {
            setValues(values.filter(val => val !== value));
        } else {
            setValues([...values, value]);
        }
    };

    const onCancel = () => {
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
    };

    const onDownload = async () => {
        setLoading(true);
        try {
            const segmentResult = await getSegmentCsv({
                segmentUuid,
                optionalFields: values
            });

            const blob = new Blob([segmentResult], { type: 'text/csv' });
            FileSaver.saveAs(blob, `${slugify(segmentName)}.csv`);
            setLoading(false);
            if (error) setError(false);
        } catch (error) {
            setError(error.response.data.message);
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Typography variant="h6">Export CSV</Typography>
            <Typography
                style={{ fontSize: 14, fontWeight: 300, marginBottom: 20 }}
            >
                Choose which fields you would like to export from this segment
            </Typography>
            <div className={classes.selectContainer}>
                <List
                    classes={{
                        root: classes.listRoot
                    }}
                >
                    {options.map(option => {
                        const { items, checkable } = option;

                        return (
                            <Fragment key={option.title}>
                                <Typography className={classes.subtitle}>
                                    {option.title}
                                </Typography>
                                {items.map(item => (
                                    <ListItem
                                        key={item.value}
                                        className={classes.listItemRoot}
                                        role={undefined}
                                        button
                                        onClick={() => handleToggle(item.value)}
                                    >
                                        <ListItemIcon
                                            classes={{
                                                root: classes.iconRoot
                                            }}
                                        >
                                            <Checkbox
                                                size="medium"
                                                edge="start"
                                                checked={
                                                    checkable
                                                        ? values.indexOf(
                                                            item.value
                                                        ) > -1
                                                        : true
                                                }
                                                tabIndex={-1}
                                                color="primary"
                                                disabled={!checkable}
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                    checked:
                                                        classes.checkboxChecked,
                                                    disabled:
                                                        classes.checkboxDisabled
                                                }}
                                            />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={item.label}
                                            classes={{
                                                primary: classes.listItemText
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </Fragment>
                        );
                    })}
                </List>
            </div>
            {error && (
                <Typography
                    style={{ textAlign: 'center', marginTop: 20, color: 'red' }}
                >
                    { error}
                </Typography>
            )}
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    variant={null}
                    label="Cancel"
                    onClick={onCancel}
                    disabled={loading}
                />
                <Button
                    className={classes.button}
                    label="Download Now"
                    onClick={onDownload}
                    loading={loading}
                    disabled={loading}
                />
            </div>
        </Fragment>
    );
};

// Technically these are required but it is throwing an error.
// The modal isn't getting the props before first render?
SegmentExportCSV.propTypes = {
    segmentUuid: PropTypes.string,
    segmentName: PropTypes.string
};

SegmentExportCSV.defaultProps = {
    segmentUuid: '',
    segmentName: ''
};

export default SegmentExportCSV;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';



import { makeStyles, Typography } from '@material-ui/core';
import * as snackTypes from '@/state/snack/types';
import { selectUserLoading } from '@/state/users/reducers';
import Loader from '@/components/Loader/Loader';
import { sendAppLink, SMSLogs } from '@/state/users/actions';
import Box from '../Box/Box';
import TextInput from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';


import Button from '../Button/Button';

const styles = makeStyles(theme => ({
    SMSButtonContainer: {
        textAlign: 'left',
        margin: 'auto'
    },
    smsHelper: {
        fontSize: 12,
        color: theme.palette.label.primary
    }
}));

const UserMeta = props => {
    const classes = styles();
    const { user, readOnly } = props;

    const [metaData, setMetaData] = React.useState({
        appInstalled: '',
        lastAppLogin: '',
    });

    const loadingState = useSelector(state => selectUserLoading(state));
    const dispatch = useDispatch();
    const [SMSSending, setSMSSending] = React.useState(false);
    const [SMSLastSent, setSMSLastSent] = React.useState(null);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const response = await SMSLogs({ uuid: user.uuid });
            if (response.data.length > 0) {
                setSMSLastSent(moment(response.data[0].createdAt))
            }
        }

        if (!loadingState) {
            const { meta, registrationDate } = user;

            fetchData();

            setMetaData({
                registeredDate: moment(new Date(registrationDate)).format('DD/MM/YYYY'),
                lastAppLogin: (meta.lastAppLogin) ? moment(meta.lastAppLogin).format('DD/MM/YYYY') : 'never'
            });

        }
    }, [loadingState])

    const onSendSMSClick = async () => {
        try {
            setSMSSending(true)
            const result = await sendAppLink({ phoneNumber: user.profile.mobileNumber, uuid: user.uuid });
            dispatch({
                type: snackTypes.SET_SNACK,
                content: result.data.message,
                open: true,
                props: { variant: 'success' }
            });
            setSMSLastSent(moment());
        } catch (error) {
            dispatch({
                type: snackTypes.SET_SNACK,
                content: error.response.data.message || error.message,
                open: true,
                props: { variant: 'error' }
            });
        }
        setSMSSending(false);
    }

    return (
        <Box title="Meta">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Registered date"
                            value={metaData.registeredDate}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Last App Login"
                            value={metaData.lastAppLogin}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.SMSButtonContainer}>
                        <Button onClick={onSendSMSClick} loading={SMSSending} label="Send Merlo App Link" disabled={readOnly} />
                        <Typography className={classes.smsHelper} variant="body1">{SMSLastSent === null ? 'SMS has not been sent' : `Last Sent: ${SMSLastSent.format('DD/MM/YYYY HH:mm')}`}</Typography>
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

UserMeta.propTypes = {
    user: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
};

UserMeta.defaultProps = {
    user: null,
};

export default UserMeta;

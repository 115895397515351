import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import * as snackTypes from '../snack/types';

function* getUserLoyalty(userUuid) {
    try {
        yield put({ type: types.LOYALTY_LOADING, state: true });
        const response = yield call(actions.getUserLoyalty, userUuid);
        const fom = yield call(actions.getUserFom, userUuid);
        const { data } = response;
        const loyaltyData = { ...data, ...fom };
        
        yield put({ type: types.GET_USERS_LOYALTY_SUCCESS, data: loyaltyData });
        yield put({ type: types.LOYALTY_LOADING, state: false });

    } catch (error) {
        yield put({ type: types.GET_USERS_LOYALTY_ERROR, error });
        yield put({ type: types.LOYALTY_LOADING, state: false });
    }
}

function* refreshUserLoyalty(userUuid) {
    try {
        yield put({ type: types.LOYALTY_LOADING, state: true });
        const response = yield call(actions.refreshLoyalty, userUuid);
        const fom = yield call(actions.getUserFom, userUuid);
        const { data } = response;
        const loyaltyData = { ...data, ...fom };
        
        yield put({ type: types.GET_USERS_LOYALTY_SUCCESS, data: loyaltyData });
        yield put({ type: types.LOYALTY_LOADING, state: false });

    } catch (error) {
        yield put({ type: types.GET_USERS_LOYALTY_ERROR, error });
        yield put({ type: types.LOYALTY_LOADING, state: false });
    }
}

function* getOrderLoyalty(orderId) {
    try {
        yield put({ type: types.LOYALTY_LOADING, state: true });
        const response = yield call(actions.getOrderLoyalty, orderId);
        const { data } = response;
        yield put({ type: types.GET_ORDER_LOYALTY_SUCCESS, data });
        yield put({ type: types.LOYALTY_LOADING, state: false });
    } catch (error) {
        yield put({ type: types.GET_ORDER_LOYALTY_ERROR, error });
        yield put({ type: types.LOYALTY_LOADING, state: false });
    }
}

function* resendGiftcard(payload) {
    try {
        yield put({ type: types.LOYALTY_LOADING, state: true });
        yield call(actions.resendGiftcard, payload);
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Successfully resent gift card',
            open: true,
            props: { variant: 'success'}
        });
        yield put({ type: types.LOYALTY_LOADING, state: false });
    } catch (error) {
        yield put({ type: types.LOYALTY_LOADING, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong, gift card not sent',
            open: true,
            props: { variant: 'error'}
        });
    }
}

// flow
export default [
    takeLatest(types.GET_USERS_LOYALTY, getUserLoyalty),
    takeLatest(types.REFRESH_USERS_LOYALTY, refreshUserLoyalty),
    takeLatest(types.GET_ORDER_LOYALTY, getOrderLoyalty),
    takeLatest(types.RESEND_GIFT_CARD, resendGiftcard),
];

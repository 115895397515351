import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import TextInput from '@/components/TextInput/TextInput';
import Loader from '@/components/Loader/Loader';

const styles = makeStyles(theme => ({
    addPromoContainer: {
        textAlign: 'end',
        marginTop: 20,
        marginBottom: 20
    },
    errorText: {
        fontSize: 10,
        color: 'red',
    }
}));

const OfferTileDetailsForm = props => {
    const classes = styles();
    const { offerTile, loading, onChange, readOnly, errors } = props;

    const [offer, setOffer] = useState({
        text1: '',
        linkUrl: '',
    });

    useEffect(() => {
        if (!loading) {
            setOffer({
                text1: offerTile.text1 || '',
                linkUrl: offerTile.linkUrl || '',
            });
        }
    }, [loading]);

    const handleChange = type => event => {
        setOffer({
            ...offer,
            [type]: event.target.value
        });
        onChange({ [type]: event.target.value });
    };


    const { text1, linkUrl } = offer;


    return (
        <Loader loading={loading}>
            <Grid className={classes.form} container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        label="Title"
                        required
                        value={text1}
                        name="text1"
                        readOnly={readOnly}
                        onChange={handleChange('text1')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="Link URL"
                        required
                        value={linkUrl}
                        name="linkUrl"
                        error={'linkUrl' in errors}
                        readOnly={readOnly}
                        onChange={handleChange('linkUrl')}
                    />
                    {
                        'linkUrl' in errors &&
                        <div className={classes.errorText}>{errors.linkUrl}</div>
                    }
                </Grid>
            </Grid>
        </Loader>
    );
};

OfferTileDetailsForm.propTypes = {
    offerTile: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    errors: PropTypes.object.isRequired,
};

OfferTileDetailsForm.defaultProps = {
    readOnly: false
};

export default OfferTileDetailsForm;

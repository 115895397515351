export const USER_PERMISSIONS_MODAL = 'modal/USER_PERMISSIONS_MODAL';
export const NOTIFICATION_CONFIRM_MODAL = 'modal/NOTIFICATION_CONFIRM_MODAL';
export const PROMOTIONS_ADD_VOUCHER_MODAL = 'modal/PROMOTIONS_ADD_VOUCHER_MODAL';
export const PROMOTIONS_GENERATE_VOUCHER_CODES_MODAL = 'modal/PROMOTIONS_GENERATE_VOUCHER_CODES_MODAL';
export const PROMOTIONS_ASSIGN_VOUCHER_CODE_MODAL = 'modal/PROMOTIONS_ASSIGN_VOUCHER_MODAL';
export const PROMOTIONS_EDIT_VOUCHER_CODE_MODAL = 'modal/PROMOTIONS_EDIT_VOUCHER_CODE_MODAL';
export const PROMOTIONS_NOTIFY_VOUCHER_CODE_MODAL = 'modal/PROMOTIONS_NOTIFY_VOUCHER_CODE_MODAL';
export const PROMOTIONS_EDIT_AUTOMATION_MODAL = 'modal/PROMOTIONS_EDIT_AUTOMATION_MODAL';
export const PROMOTIONS_VIEW_REDEMPTION_MODAL = 'modal/PROMOTIONS_VIEW_REDEMPTION_MODAL';
export const PRODUCTS_EDIT_DETAILS_MODAL = 'modal/PRODUCTS_EDIT_DETAILS_MODAL';
export const PRODUCTS_ADD_PARTS_MODAL = 'modal/PRODUCTS_ADD_PARTS_MODAL';
export const PRODUCTS_REMOVE_PARTS_MODAL = 'modal/PRODUCTS_REMOVE_PARTS_MODAL';
export const RESET_PASSWORD_MODAL = 'modal/RESET_PASSWORD_MODAL';
export const ADDRESS_DETAILS_MODAL = 'modal/ADDRESS_DETAILS_MODAL';
export const ADD_PARTS_TO_PRODUCT_MODAL = 'modal/ADD_PARTS_TO_PRODUCT_MODAL';
export const REMOVE_PART_FROM_PRODUCT = 'modal/REMOVE_PART_FROM_PRODUCT';
export const REMOVE_PRODUCT_FROM_PART = 'modal/REMOVE_PRODUCT_FROM_PART';
export const DELETE_LOCATION_MODAL = 'modal/DELETE_LOCATION_MODAL';
export const ARCHIVE_USER_MODAL = 'modal/ARCHIVE_USER_MODAL';
export const SEGMENT_EXPORT_CSV = 'modal/SEGMENT_EXPORT_CSV';

// Generic modals - safe to reuse
export const PROMPT_NUMBER_MODAL = 'modal/PROMPT_NUMBER';
export const PROMPT_BOOLEAN_MODAL = 'modal/PROMPT_BOOLEAN';

export const CONFIRMATION_MODAL = 'modal/CONFIRMATION_MODAL';
export const SHOPIFY_PROMOTION_MODAL = 'modal/SHOPIFY_PROMOTION_MODAL';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import VoucherDetailsForm from '@/components/Vouchers/VoucherDetailsForm';
import Button from '../../components/Button/Button';

import * as modalTypes from '@/state/modal/types';
import * as types from '@/state/promotions/types';
import { selectVouchersSaveState } from '@/state/promotions/reducers';

const styles = makeStyles({
    title: {
        fontSize: 20,
        lineHeight: 1.2,
        marginBottom: 14
    },
    buttonsContainer: {
        textAlign: 'center'
    },
    actionButton: {
        width: '140px',
        margin: '12px'
    }
});

const AddVoucher = props => {
    const classes = styles();
    const { closeModal, createVoucher, vouchersSaveState } = props;
    const [values, setValues] = React.useState({
        title: '',
        description: '',
        internalReference: '',
        status: 'live',
        uuid: '',
        createdAt: moment(),
        defaultNumberOfRedemptionsPerUser: '',
        defaultNumberOfRedemptions: '',
        defaultExpiry: '',
        SKU: [],
        emailBody: ''
    });

    const onHandleChange = (key, value) => {
        setValues(prevState => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const onAddSku = sku => {
        setValues(prevState => {
            return {
                ...prevState,
                SKU: [...prevState.SKU, sku]
            };
        });
    };

    const onRemoveSku = sku => {
        setValues(prevState => {
            const SKU = prevState.SKU.filter(item => item !== sku);
            return {
                ...prevState,
                SKU
            };
        });
    };

    const submit = () => {
        const modifiedVoucher = {
            title: values.title,
            internalReference: values.internalReference,
            description: values.description,
            defaultNumberOfRedemptions: Number(
                values.defaultNumberOfRedemptions
            ),
            defaultExpiry: Number(values.defaultExpiry),
            status: 'live',
            SKU: values.SKU,
            emailBody: values.emailBody
        };

        createVoucher(modifiedVoucher);
    };

    // This fields are required. Disabled submit until they're filled.
    const canSubmit =
        values.title !== '' &&
        values.maxRedemptions !== '' &&
        values.description !== '' &&
        values.defaultExpiry !== '';

    return (
        <div>
            <Typography className={classes.title}>Create Voucher</Typography>
            <VoucherDetailsForm
                voucher={{ ...values }}
                onChange={onHandleChange}
                onAddSku={onAddSku}
                onRemoveSku={onRemoveSku}
                loading={false}
            />
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.actionButton}
                    label="Cancel"
                    variant={null}
                    style={{ color: '#077abe' }}
                    loading={vouchersSaveState}
                    disabled={vouchersSaveState}
                    onClick={closeModal}
                />
                <Button
                    className={classes.actionButton}
                    loading={vouchersSaveState}
                    disabled={!canSubmit || vouchersSaveState}
                    label="Generate voucher"
                    onClick={submit}
                />
            </div>
        </div>
    );
};

AddVoucher.propTypes = {
    closeModal: PropTypes.func.isRequired,
    createVoucher: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  vouchersSaveState: selectVouchersSaveState(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createVoucher: data => ({ type: types.CREATE_VOUCHER, data }),
            closeModal: () => ({
                type: modalTypes.MODAL_SET_OPEN_STATE,
                state: false
            })
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddVoucher);

import axios from 'axios';
import { uploadImageToCloud } from '@/state/images/actions';

export const getLocations = async () => {
    try {
        const result = await axios.get('/v1/locations');

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getLocation = async request => {
    const { locationUuid } = request;
    try {
        const result = await axios.get(`/v1/locations/${locationUuid}`);

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const getMerloLocations = async () => {
    try {
        const result = await axios.get(
            '/v1/locations/search?isMerloOwned=true'
        );

        return result.data;
    } catch (error) {
        throw error;
    }
};

export const createLocation = async locationData => {
    try {
        const result = await axios.post('/v1/locations', locationData);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const formatLocationPayload = async locationData => {
    console.info('TCL: locationData', locationData);
    try {
        const payload = {
            ...locationData
        };
        if ('image' in locationData) {
            if (locationData.image.src === null) {
                payload.image = '';
            } else if (locationData.image.isNew) {
                // check for new key
                const { image } = locationData;
                const { src } = image;
                const result = await uploadImageToCloud(src);
                const { data } = result;
                const { publicUrl } = data;
                payload.image = publicUrl;
            }
        }

        return payload;
    } catch (error) {
        throw error;
    }
};

export const updateLocation = async location => {
    try {
        const result = await axios.put('/v1/locations', location);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const deleteLocation = async request => {
    try {
        const { locationUuid } = request;
        const result = await axios.delete(`/v1/locations/${locationUuid}`);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const upsertLocation = (locations, location) => {
    const _locations = [...locations];
    const idx = _locations.map(l => l.uuid).indexOf(location.uuid);
    if (idx > -1) {
        _locations.splice(idx, 1, location);
    } else {
        _locations.push(location);
    }

    return _locations;
}

export const mapLocationsToSelect = locations => {
    return locations.reduce((acc, loc) => {
        acc.push({ label: loc.title, value: loc.uuid });
        return acc;
    }, []);
};

export const GET_USERS = 'users/GET_USERS';
export const GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'users/GET_USERS_ERROR';

export const GET_USER = 'users/GET_USER';
export const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'users/GET_USER_ERROR';

export const UPDATE_USER = 'users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'users/UPDATE_USER_ERROR';

export const UPDATE_USER_ADDRESS = 'users/UPDATE_USER_ADDRESS';
export const UPDATE_USER_ADDRESS_SUCCESS = 'users/UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_ERROR = 'users/UPDATE_USER_ADDRESS_ERROR';

export const CREATE_USER_ADDRESS = 'users/CREATE_USER_ADDRESS';
export const CREATE_USER_ADDRESS_SUCCESS = 'users/CREATE_USER_ADDRESS_SUCCESS';
export const CREATE_USER_ADDRESS_ERROR = 'users/CREATE_USER_ADDRESS_ERROR';

export const DELETE_USER_ADDRESS = 'users/DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_SUCCESS = 'users/DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_ERROR = 'users/DELETE_USER_ADDRESS_ERROR';

export const ARCHIVE_USER = 'users/ARCHIVE_USER';
export const ARCHIVE_USER_SUCCESS = 'users/ARCHIVE_USER_SUCCESS';
export const ARCHIVE_USER_ERROR = 'users/ARCHIVE_USER_ERROR';

export const FORCE_DELETE_USER = 'users/FORCE_DELETE_USER';
export const FORCE_DELETE_USER_SUCCESS = 'users/FORCE_DELETE_USER_SUCCESS';
export const FORCE_DELETE_USER_ERROR = 'users/FORCE_DELETE_USER_ERROR';

export const RESTORE_USER = 'users/RESTORE_USER';
export const RESTORE_USER_SUCCESS = 'users/RESTORE_USER_SUCCESS';
export const RESTORE_USER_ERROR = 'users/RESTORE_USER_ERROR';

export const USERS_LOADING_STATE = 'users/USERS_LOADING_STATE';


import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { Typography, RadioGroup } from '@material-ui/core';
import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import TextInput from '@/components/TextInput/TextInput';

import { selectSelectedProduct } from '@/state/products/reducers';


const ProductQuiz = props => {
    const { readOnly, onUpdate, loading, isNew } = props;

    const [quizTagsState, setQuizTagsState] = React.useState({
        quizTags: '',
    });

    const product = useSelector(state => selectSelectedProduct(state));

    if (!isNew) {
        useEffect(() => {
            if (!loading && Object.keys(product).length > 0) {
                const { quizTags } = product;

                setQuizTagsState({
                    quizTags,
                });

            }
        }, [loading]);
    }

    const onChange = type => event => {
        setQuizTagsState({ ...quizTagsState, [type]: event.target.value });
        onUpdate({ [type]: event.target.value });
    };

    const {
        quizTags,
    } = quizTagsState;

    return (
        <Box title="Product Quiz">
            <Loader loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Quiz Tags"
                            readOnly={readOnly}
                            value={quizTags}
                            helperText="Separate each quiz tag with a comma"
                            onChange={onChange('quizTags')}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductQuiz.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

ProductQuiz.defaultProps = {
    isNew: false
};

export default ProductQuiz;

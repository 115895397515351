import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';

import * as loyaltyTypes from '../loyalty/types';
import * as snackTypes from '../snack/types';

function* getOrder(orderId) {
    try {
        yield put({ type: types.ORDERS_LOADING_STATE, state: true });
        const response = yield call(actions.getOrder, orderId);
        const { data } = response;
        yield put({ type: types.GET_ORDER_SUCCESS, data });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_ORDER_ERROR, error });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    }
}

function* getAllOrders(request) {
    try {
        yield put({ type: types.ORDERS_LOADING_STATE, state: true });
        const {
            currentPage,
            pageSize,
            search,
            source,
            dateFrom,
            dateTo,
            epicorYes: showUploadedToEpicor,
            epicorNo: showNotUploadedToEpicor,
            loyaltyYes: showLoyaltyUploaded,
            loyaltyNo: showLoyaltyNotUploaded,
            sortBy,
            order
        } = request;
        const response = yield call(
            actions.getAllOrders,
            currentPage,
            pageSize,
            search,
            source,
            dateFrom,
            dateTo,
            showUploadedToEpicor,
            showNotUploadedToEpicor,
            showLoyaltyUploaded,
            showLoyaltyNotUploaded,
            sortBy,
            order
        );
        const { data, meta } = response;
        yield put({ type: types.GET_ALL_ORDERS_SUCCESS, data, meta });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_USERS_ORDERS_ERROR, error });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    }
}

function* getUnsyncedGivexOrders(request) {
    try {
        yield put({ type: types.GIVEX_ORDERS_LOADING, state: true });
        const {
            currentPage,
            pageSize,
            dateFrom,
            dateTo,
            loyaltyNo: showLoyaltyNotUploaded,
            sortBy,
            order
        } = request;
        const response = yield call(
            actions.getAllOrders,
            currentPage,
            pageSize,
            null,
            null,
            dateFrom,
            dateTo,
            false,
            false,
            false,
            showLoyaltyNotUploaded,
            sortBy,
            order
        );
        const { data, meta } = response;
        yield put({
            type: types.GET_UNSYNCED_GIVEX_ORDERS_SUCCESS,
            data,
            meta
        });
        yield put({ type: types.GIVEX_ORDERS_LOADING, state: false });
    } catch (error) {
        yield put({ type: types.GET_UNSYNCED_GIVEX_ORDERS_ERROR, error });
        yield put({ type: types.GIVEX_ORDERS_LOADING, state: false });
    }
}

function* getUserOrders(userUuid) {
    try {
        yield put({ type: types.ORDERS_LOADING_STATE, state: true });
        const response = yield call(actions.getUserOrders, userUuid);
        const { data } = response;
        yield put({ type: types.GET_USERS_ORDERS_SUCCESS, data });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_USERS_ORDERS_ERROR, error });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    }
}

function* submitToEpicor(order) {
    try {
        yield put({ type: types.ORDERS_LOADING_STATE, state: true });
        const response = yield call(actions.submitToEpicor, order);
        const { data } = response;
        yield put({ type: types.SUBMIT_TO_EPICOR_SUCCESS, data });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.SUBMIT_TO_EPICOR_ERROR, error });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    }
}

function* getEpicorData(request) {
    try {
        const response = yield call(actions.getEpicorRawData, request);
        yield put({
            type: types.GET_ORDER_EPICOR_DATA_SUCCESS,
            epicorData: response
        });
    } catch (error) {
        yield put({ type: types.GET_ORDER_EPICOR_DATA_ERROR, error });
    }
}

function* getEpicorUploadSetting(request) {
    try {
        yield put({ type: types.EPICOR_SETTING_LOADING_STATE, state: true });
        const response = yield call(actions.getEpicorUploadSetting);
        yield put({ type: types.GET_EPICOR_SETTING_SUCCESS, response });
        yield put({ type: types.EPICOR_SETTING_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_EPICOR_SETTING_ERROR, error });
        yield put({ type: types.EPICOR_SETTING_LOADING_STATE, state: false });
    }
}

function* updateEpicorUploadSetting(request) {
    try {
        yield put({ type: types.EPICOR_SETTING_LOADING_STATE, state: true });
        yield call(actions.updateEpicorSetting, request);
    } catch (error) {
        yield put({ type: types.UPDATE_EPICOR_SETTING_ERROR, error });
        yield put({ type: types.EPICOR_SETTING_LOADING_STATE, state: false });
    }
}

function* updateOrder(request) {
    // console.info('DEBUG: function*updateProduct -> request', request);
    try {
        yield put({ type: types.ORDERS_LOADING_STATE, state: true });
        // format the data packet
        const { orderId, orderData } = request;
        yield call(actions.updateOrder, { orderId, payload: orderData });
    } catch (error) {
        yield put({ type: types.UPDATE_ORDER_ERROR, error });
        yield put({ type: types.ORDERS_LOADING_STATE, state: false });
    }
}

function* submitToGivex({ orderId }) {
    try {
        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: true });
        const data = yield call(actions.processRewards, orderId);

        yield put({ type: types.SUBMIT_TO_GIVEX_SUCCESS, data });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Order rewards were successfully processed',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: false });
    } catch (error) {
        yield put({ type: types.SUBMIT_TO_GIVEX_ERROR, error });
        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: false });
    }
}

function* markRewardAsManuallyProcessed({ orderId }) {
    try {
        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: true });

        const data = yield call(actions.manuallyProcessRewards, orderId);
        yield put({
            type: types.MARK_REWARD_AS_MANUALLY_PROCESSED_SUCCESS,
            data
        });

        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Order rewards have been marked as manually processed',
            open: true,
            props: { variant: 'success' }
        });

        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: false });
    } catch (error) {
        yield put({
            type: types.MARK_REWARD_AS_MANUALLY_PROCESSED_ERROR,
            error
        });
        yield put({ type: loyaltyTypes.LOYALTY_LOADING, state: false });
    }
}

function* getRewardSetting() {
    try {
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: true });
        const response = yield call(actions.getRewardSetting);
        yield put({
            type: types.GET_REWARD_SETTING_SUCCESS,
            value: response.value
        });
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_REWARD_SETTING_ERROR, error });
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: false });
    }
}

function* toggleRewardSetting() {
    try {
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: true });
        const response = yield call(actions.toggleRewardSetting);
        yield put({
            type: types.UPDATE_REWARD_SETTING_SUCCESS,
            value: response.value
        });
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.UPDATE_REWARD_SETTING_ERROR, error });
        yield put({ type: types.REWARD_SETTING_LOADING_STATE, state: false });
    }
}

// flow
export default [
    takeLatest(types.GET_USERS_ORDERS, getUserOrders),
    takeLatest(types.GET_ALL_ORDERS, getAllOrders),
    takeLatest(types.GET_ORDER, getOrder),
    takeLatest(types.SUBMIT_TO_EPICOR, submitToEpicor),
    takeLatest(types.GET_ORDER_EPICOR_DATA, getEpicorData),
    takeLatest(types.GET_EPICOR_SETTING, getEpicorUploadSetting),
    takeLatest(types.UPDATE_EPICOR_SETTING, updateEpicorUploadSetting),
    takeLatest(types.UPDATE_ORDER, updateOrder),
    takeLatest(types.SUBMIT_TO_GIVEX, submitToGivex),
    takeLatest(
        types.MARK_REWARD_AS_MANUALLY_PROCESSED,
        markRewardAsManuallyProcessed
    ),
    takeLatest(types.GET_REWARD_SETTING, getRewardSetting),
    takeLatest(types.UPDATE_REWARD_SETTING, toggleRewardSetting),
    takeLatest(types.GET_UNSYNCED_GIVEX_ORDERS, getUnsyncedGivexOrders)
];

import * as types from './types';
import * as partActions from './actions';

const initialState = {
    parts: [],
    partsMeta: {},
    selectedPart: {},
    loadingState: true,
    saving: false,
}

const partsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PARTS_SUCCESS:
            return {
                ...state,
                parts: action.parts,
                partsMeta: action.meta
            };
        case types.GET_PART_DETAIL_SUCCESS:
            return {
                ...state,
                selectedPart: action.part
            };
        case types.PARTS_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.PART_SAVING:
            return {
                ...state,
                saving: action.state
            };
        
        case types.REMOVE_PART_FROM_PRODUCT:
            return {
                ...state,
                selectedPart: {
                    ...state.selectedPart, 
                    linkedProduct: null
                }
            };
        
        case types.UPDATE_PARTS_LIST:
            return {
                ...state,
                parts: partActions.upsertParts(state.parts, action.parts)
            }
        
        default:
            return state;
    }
}

export default partsReducer;

export const selectParts = state => state.parts.parts;
export const selectSelectedPart = state => state.parts.selectedPart;
export const selectPartsLoading = state => state.parts.loadingState;
export const selectPartSaving = state => state.parts.saving;